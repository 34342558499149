<template>
  <button @click="upload" :aria-label="$t('buttons.uploadFiles')" :title="$t('buttons.uploadFiles')" class="action" id="upload-button">
    <upload-cloud-icon size="1.5x" class="custom-class"></upload-cloud-icon>
    <span v-if="!isMobile">{{ $t('buttons.uploadFiles') }}</span>
    <span v-else>{{ $t('buttons.upload') }}</span>
  </button>
</template>

<script>
import { UploadCloudIcon } from 'vue-feather-icons'

export default {
  name: 'upload-button',
  components: {
    UploadCloudIcon
  },
  computed: {
    isMobile () {
      return window.innerWidth <= 768
    },
  },
  methods: {
    upload: function () {
      document.getElementById('upload-input').click()
    }
  }
}
</script>
