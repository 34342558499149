<template>
  <div>
    <div v-if="isReady" id="transaction-ok" style="position: absolute; top: 0; left: 0; width: 100%; ">
      <vue-headful
        title="Planes de precios de Iberbox"
        description="Contrata nuestros servicios y disfruta del almacenamiento cifrado más seguro."
        keywords="iberbox, planes de precio, copias de seguridad, cifrado punto a punto, nube segura"
        image="https://iberbox.com/img/logo.svg"
      />

      <div style="width: 90%; margin: 0 auto; max-width: 600px;">
        <a href="https://www.iberbox.com"><img style="float:left; width: 3em; height: 2.3em; display: block;" src="/img/logo.svg" alt="IBERBOX"></a>
        <h1 style="margin: .3em 0 .3em;" >IBERBOX</h1>
      </div>

      <div style="width: 90%; margin: 0 auto; max-width: 600px;">
        <h1>{{ $t("settings.transactionCompleted") }}</h1>
        <form class="card">
          <div class="card-title">
            <center><h2>{{ $t("settings.thankYouForTrustingUs") }}</h2></center>
          </div>

          <template v-if="checkingResult && checkAttempts < 6">
            <div class="card-content" style="font-size: 1.2em">
              <p>
                {{ $t("pricing.checkingResult") }}
              </p>
              <br />
              <div>
                <div class="spinner" style="position: relative;">
                  <div class="bounce1"></div>
                  <div class="bounce2"></div>
                  <div class="bounce3"></div>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="finishedOk">
            <div class="card-content" style="font-size: 1.2em">
              <p>
                {{ $t("pricing.transactionVerified") }}
              </p>
            </div>

            <div class="card-action">
                <input
                  v-on:click="goToAccount"
                  style="float: left; width: 33%"
                  class="button button--block"
                  type="button"
                  :value="$t('pricing.continue')"
                />
                <input
                  v-on:click="downloadRecoveryKey"
                  style="float: right; width: 63%"
                  class="button button--block"
                  type="button"
                  :value="$t('pricing.downloadRecoveryKey')"
                />
              <div ref="recoveryKeyQr" style="display: none;">
                  <qrcode-vue :value="recoveryKeyLink" size="100" level="M"></qrcode-vue>
              </div>
              <br /><br />
            </div>
          </template>
          <template v-else>
            <div class="card-content" style="font-size: 1.2em">
              <p>
                {{ $t("pricing.unableToVerify") }}
              </p>
            </div>

            <div class="card-action">
                <input
                  v-on:click="goToCancel"
                  style="float: left; width: 48%"
                  class="button button--block"
                  type="button"
                  :value="$t('buttons.cancel')"
                />
                <input
                  v-on:click="retry"
                  style="float: right; width: 48%"
                  class="button button--block"
                  type="button"
                  :value="$t('buttons.retry')"
                />
              <br /><br />
            </div>
          </template>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import * as auth from "@/utils/auth";
import { files as api } from "@/api";
import { name, logoURL } from "@/utils/constants";
import vueHeadful from "vue-headful";
import jsPDF from 'jspdf'
import i18n from '@/i18n'
import moment from 'moment'
import QrcodeVue from 'qrcode.vue'

export default {
  name: "transaction-ok",
  components: {
    "vue-headful": vueHeadful,
    QrcodeVue
  },
  computed: {
    name: () => name,
    logoURL: () => logoURL,
  },
  data: function () {
    return {
      isReady: false,
      checkingResult: true,
      checkAttempts: 0,
      finishedOk: false,
      recoveryKey: '',
      recoveryKeyLink: ''
    };
  },
  mounted() {},
  created() {
    this.isReady = true;
    setTimeout(this.checkAccountState, 5000);
  },
  methods: {
    async checkAccountState () {
        console.log("Checking...")
        try 
        {
            var action = await api.getAccountInfo()
            if (action.accountInfo && action.accountInfo.credit)
            {
                console.log("Payment method available")
                action = await ibb.getNodeByPath("/");

                  if (action.error.type || !action.node) {
                    await auth.getNodes();
                  }
                  this.finishedOk = true;
                  this.checkingResult = false;
                  return;
            }
        }
        catch (e)
        {
            console.log("Error checking account: " + e);
        }

        console.log("Check failed")
        this.checkAttempts++;
        setTimeout(this.checkAccountState, 5000);
    },
    async goToCancel () {
      var action = await ibb.getNodeByPath("/");
      if (action.error.type || !action.node)
      {
          await auth.logout();
      }
      else
      {
          this.$router.push({ path: '/profile/invoicing' })
      }
    },
    async goToAccount () {
      var action = await ibb.getNodeByPath("/");
      if (action.error.type || !action.node)
      {
          this.$router.push({ path: '/profile/invoicing/plans' })
      }
      else
      {
          this.$router.push({ path: '/received' })
      }
    },
    retry() {
      this.$router.push({ path: "/profile/invoicing/plans" });
    },
    goToInvoicingSettings() {
      this.$router.push({ path: "/profile/invoicing" });
    },
    async downloadRecoveryKey () {
      try {
        var action = await api.getAccountInfo()
        var companyName = action.accountInfo.name;
        var companyNIF = action.accountInfo.nif;
        action = await api.getUserInfo()
        var userEmail = action.userInfo.email;
        var userFirstname = action.userInfo.firstname;
        var userLastname = action.userInfo.lastname;

        this.recoveryKey = await api.getRecoveryKey()
        this.recoveryKeyLink = "https://iberbox.com/recover?email=" +  Base64.encodeURI(userEmail) + "#" + this.recoveryKey;
        await this.$nextTick();

        var offset = 50
        var doc = new jsPDF('p', 'pt');

        var img = new Image();
        img.src = '/img/icons/Bmail Resources/Icons/Application Icon/bmail_favicon_32.png';
        doc.addImage(img, 'PNG', 40, 33);

        var img2 = new Image();
        img2.src = '/img/icons/logo-2.png';
        doc.addImage(img2, 'JPEG', 390, 735, 200, 105);

        doc.text(i18n.t('recovery.documentTitle'), 80, offset);
        offset += 40
        
        doc.setFontSize(12);
        doc.setFontType("normal");
        var splitMessage = doc.splitTextToSize("            " + i18n.t('recovery.documentDescription'), 500);
        doc.text(splitMessage, 40, offset);

        offset += 12 * splitMessage.length + 40

        doc.setLineWidth(1.0); 
        doc.setDrawColor(0, 0, 0);
        doc.line(40, offset, 570, offset, 'S');
        offset += 40

        if (companyName.length != 0 && companyNIF.length != 0)
        {
            doc.setFontSize(13);
            doc.setFontType("normal");
            doc.text(i18n.t('recovery.documentCompany'), 40, offset);

            doc.setFontSize(12);
            doc.setFontType("bold");
            doc.text(companyName, 120, offset);
            offset += 20

            doc.setFontType("normal");
            doc.setFontSize(13);
            doc.text(i18n.t('recovery.documentNIF'), 40, offset);

            doc.setFontSize(12);
            doc.setFontType("bold");
            doc.text(companyNIF, 120, offset);
            offset += 40

            doc.setLineWidth(1.0); 
            doc.setDrawColor(0, 0, 0);
            doc.line(40, offset, 570, offset, 'S');
            offset += 40
        }

        doc.setFontType("normal");
        doc.setFontSize(16);
        doc.text(i18n.t('recovery.documentKeyTitle'), 300, offset, null, null, 'center');
        offset += 50

        doc.setFontType("bold");
        doc.setFontSize(13);
        doc.text(this.recoveryKey, 300, offset, null, null, 'center');
        offset += 20

        doc.addImage(this.$refs["recoveryKeyQr"].firstChild.firstChild.toDataURL('image/jpeg'), 250, offset, 100, 100);
        
        
        offset = 580

        doc.setFontType("bold");
        doc.setFontSize(11);
        doc.text(i18n.t('recovery.documentWarning'), 40, offset);
        offset += 20

        doc.setFontType("normal");
        doc.setFontSize(11);
        doc.text(i18n.t('recovery.documentStartRecovery'), 40, offset);
        offset += 20

        doc.setFontType("normal");
        doc.setFontSize(11);
        doc.text(i18n.t('recovery.documentValidation'), 40, offset);
        offset += 20

        doc.setFontType("normal");
        doc.setFontSize(11);
        doc.text(i18n.t('recovery.document2fa'), 40, offset);
        offset += 20

        doc.setFontType("normal");
        doc.setFontSize(11);
        doc.text(i18n.t('recovery.documentImportant'), 40, offset);
        offset += 20

        offset += 70

        doc.setLineWidth(1.0); 
        doc.setDrawColor(0, 0, 0);
        doc.line(40, 700, 570, 700, 'S');

        doc.setFontSize(10);
        doc.text(i18n.t('recovery.documentSaveSecurely'), 40, 725)

        doc.setFontSize(11);
        doc.setFontType("bold");
        doc.text(i18n.t('recovery.documentGeneratedBy'), 40, 755)
        doc.setFontSize(10);
        doc.text(userFirstname + " " + userLastname, 40, 755 + 30)
        doc.text(userEmail, 40, 755 + 45)

        doc.setFontSize(11);
        doc.setFontType("bold");
        doc.text(i18n.t('recovery.documentDate'), 430, 750)

        doc.setFontSize(10);
        doc.text(moment().format('DD/MM/YYYY - HH:mm'), 470, 750)

        doc.save(i18n.t('recovery.documentName') + '.pdf');
      } catch (e) {
        this.$showError(e)
      }
    }
  },
};
</script>
