import { fetchURL, removePrefix } from './utils'
import { baseURL } from '@/utils/constants'
import store from '@/store'
import i18n from '@/i18n'

import moment from 'moment'

window.gRequestedThumbnails = new Set();

export function typeByExtension(extension) {
    var type = "text";
    if (extension == "jpg" || extension == "jpeg" || extension == "png" || extension == "bmp" || extension == "heic" || extension == "heif") {
      type = "image";
    }   
    else if (extension == "mp3" || extension == "wav" || extension == "flac") {
      type = "audio";
    }
    else if (extension == "avi" || extension == "mp4" || extension == "mkv") {
      type = "video";
    }
    else if (extension == "pdf") {
      type = "pdf";
    }
    else if (extension == "stl") {
      type = "stl";
    } 
    else if (extension == 'txt') {
      type = "txt";
    } 
    else if (extension == 'zip') {
      type = "zip";
    }
    return type;
}

export function nodeToObject(basepath, node) {
    var re = /(?:\.([^.]+))?$/;

    var obj = {};
    obj["handle"] = node.handle;
    obj["parentHandle"] = node.parentHandle;
    obj["isDir"] = (node.type != Module.NodeType.FILE.value);
    obj["mode"] = 2147484157;
    obj["size"] = node.size;
    obj["extension"] = re.exec(node.name)[1] || "";
    obj["extension"] = obj["extension"].toLowerCase();

    var type = "text";
    if (!obj["isDir"]) {
      type = typeByExtension(obj["extension"]);
    }
    else
    {
        if (node.type == Module.NodeType.DEVICE.value)
        {
            type = "device";
        }
        else if (node.type == Module.NodeType.BACKUP.value)
        {
            type = "backup";
        } 
        else if (node.type == Module.NodeType.SNAPSHOT.value)
        {
            type = "snapshot";
        }
        else if (node.type == Module.NodeType.INBOX.value)
        {
            type = "inbox";
            obj["message"] = node.message
        }
        else if (node.type == Module.NodeType.RECORD.value)
        {
            type = "record";
            obj["message"] = node.message
        }
        else
        {
            type = "folder";
        }
    }
    
    obj["name"] = node.name ? node.name : (basepath.startsWith('//reg') ? "Registro" 
                                        : ((basepath.startsWith('//cnt') ? "Contactos" 
                                        : ((basepath == "/") ? "Mis archivos" : "SIN_NOMBRE"))));

    obj["path"] = node.name ? (basepath + obj["name"]) : basepath;
    obj["modified"] = moment(node.ctime * 1000).format();
    obj["type"] = type;
    obj["nodeType"] = node.type;
    obj["contact"] = node.contact;
    return obj;
} 

export async function getNextSnapshots () {
    var action = await ibb.getNextSnapshotsForAccount();
    if (action.error.type) {
      throw new Error("Error getting next snapshots: " +  action.error.type)
    }

    var data = {};
    data["items"] = [];
    data["numFiles"] = action.nodeList.length;
    for (var i = 0; i < action.nodeList.length; i++) 
    {
        data["items"].push({});
        data["items"][i]["date"] = moment(action.numberList[i] * 1000).format();
        data["items"][i]["device"] = action.nodeList[i].device;
        data["items"][i]["name"] = action.nodeList[i].name;
        data["items"][i]["path"] = action.nodeList[i].lpath;
        data["items"][i]["type"] = i18n.t('info.snapshot');
        data["items"][i]["progress"] = 0;
        data["items"][i]["handle"] = action.nodeList[i].handle;
        data["items"][i]["status"] = i18n.t('info.pending');
        data["items"][i]["index"] = i;
        data["items"][i]["enabled"] = action.nodeList[i].enabled;
    }

    return data;
}

export async function getLastSnapshots () {
    var action = await ibb.getLastSnapshotsForAccount();
    if (action.error.type) {
      throw new Error("Error getting last snapshots: " +  action.error.type)
    }

    var data = {};
    data["items"] = [];
    data["numFiles"] = action.nodeList.length;
    for (var i = 0; i < action.nodeList.length; i++) 
    {
        data["items"].push({});
        data["items"][i]["date"] = moment(action.nodeList[i].ctime * 1000).format();
        data["items"][i]["device"] = action.nodeList[i].device;
        data["items"][i]["name"] = action.nodeList[i].name;
        data["items"][i]["type"] = i18n.t('info.snapshot');
        data["items"][i]["path"] = action.nodeList[i].lpath;
        data["items"][i]["progress"] = 100;
        data["items"][i]["handle"] = action.nodeList[i].handle;
        data["items"][i]["status"] = i18n.t('info.correct');
        data["items"][i]["index"] = i;
    }

    return data;
}

export async function getUploads () {
    var action = await ibb.getTransfersByType(Module.TransferType.UPLOAD.value);
    if (action.error.type) {
      throw new Error("Error getting uploads: " +  action.error.type)
    }

    var re = /(?:\.([^.]+))?$/;
    var data = {};
    data["isTransfers"] = 1;
    data["transferType"] = Module.TransferType.UPLOAD.value;
    data["items"] = [];
    data["numFiles"] = action.actionList.length;
    data["numDirs"] = 0;
    //store.commit('addUploadTransfers', action.actionList)
    for (var i = 0, j = 0; i < action.actionList.length; i++) 
    {
      if (action.actionList[i].customData === '') {
        data["items"].push({});
        data["items"][j]["transferType"] = action.actionList[i].type;
        data["items"][j]["name"] = action.actionList[i].text ? action.actionList[i].text : action.actionList[i].name;
        data["items"][j]["extension"] = re.exec(data["items"][j]["name"])[1] || "";
        data["items"][j]["extension"] = data["items"][j]["extension"].toLowerCase();
        data["items"][j]["type"] = (action.actionList[i].type == Module.ActionType.UPLOAD.value) ? typeByExtension(data["items"][j]["extension"]) : "folder";
        data["items"][j]["size"] = action.actionList[i].size;
        data["items"][j]["completed"] = action.actionList[i].completed;
        data["items"][j]["progress"] = action.actionList[i].size ? action.actionList[i].completed * 100 / action.actionList[i].size : 0;
        data["items"][j]["status"] = action.actionList[i].status;
        data["items"][j]["errorCode"] = action.actionList[i].errorCode;
        data["items"][j]["tag"] = action.actionList[i].tag;
        data["items"][j]["handle"] = action.actionList[i].handle;
        data["items"][j]["index"] = i;
        j++;
      }
    }

    return data;
}

export async function getDownloads () {
    var action = await ibb.getTransfersByType(Module.TransferType.DOWNLOAD.value);
    if (action.error.type) {
      throw new Error("Error getting downloads: " +  action.error.type)
    }

    var re = /(?:\.([^.]+))?$/;
    var data = {};
    data["isTransfers"] = 1;
    data["transferType"] = Module.TransferType.DOWNLOAD.value;
    data["items"] = [];
    data["numFiles"] = action.actionList.length;
    data["numDirs"] = 0;
    //store.commit('addDownloadTransfers', action.actionList)
    for (var i = 0, j = 0; i < action.actionList.length; i++) 
    {
      if (action.actionList[i].customData === '') {
        data["items"].push({});
        data["items"][j]["transferType"] = action.actionList[i].type;
        data["items"][j]["name"] = action.actionList[i].text ? action.actionList[i].text : action.actionList[i].name;
        data["items"][j]["extension"] = re.exec(data["items"][j]["name"])[1] || "";
        data["items"][j]["extension"] = data["items"][j]["extension"].toLowerCase();
        data["items"][j]["type"] = (action.actionList[i].type == Module.ActionType.DOWNLOAD.value) ? typeByExtension(data["items"][j]["extension"]) : "folder";
        data["items"][j]["size"] = action.actionList[i].size;
        data["items"][j]["completed"] = action.actionList[i].completed;
        data["items"][j]["progress"] = action.actionList[i].size ? action.actionList[i].completed * 100 / action.actionList[i].size : 0;
        data["items"][j]["status"] = action.actionList[i].status;
        data["items"][j]["errorCode"] = action.actionList[i].errorCode;
        data["items"][j]["tag"] = action.actionList[i].tag;
        data["items"][j]["handle"] = action.actionList[i].handle;
        data["items"][j]["index"] = i;
        j++;
      }
    }

    return data;
}

export async function getCompletedTransfers () {
    var action = await ibb.getTransfersByStatus(Module.TransferType.LAST_TYPE.value, Module.TransferStatus.FINISHED.value);
    if (action.error.type) {
      throw new Error("Error getting completed: " +  action.error.type)
    }

    var re = /(?:\.([^.]+))?$/;
    var data = {};
    data["isTransfers"] = 1;
    data["items"] = [];
    data["numFiles"] = action.actionList.length;
    data["numDirs"] = 0;
    for (var i = 0; i < action.actionList.length; i++) 
    {
        data["items"].push({});
        data["items"][i]["transferType"] = action.actionList[i].type;
        data["items"][i]["name"] = action.actionList[i].text ? action.actionList[i].text : action.actionList[i].name;
        data["items"][i]["extension"] = re.exec(data["items"][i]["name"])[1] || "";
        data["items"][i]["extension"] = data["items"][i]["extension"].toLowerCase();
        data["items"][i]["type"] = (action.actionList[i].type == Module.ActionType.DOWNLOAD.value || action.actionList[i].type == Module.ActionType.UPLOAD.value) 
                                   ? typeByExtension(data["items"][i]["extension"]) : "folder";
        data["items"][i]["size"] = action.actionList[i].size;
        data["items"][i]["completed"] = action.actionList[i].completed;
        data["items"][i]["progress"] = action.actionList[i].size ? action.actionList[i].completed * 100 / action.actionList[i].size : 0;
        data["items"][i]["status"] = action.actionList[i].status;
        data["items"][i]["errorCode"] = action.actionList[i].errorCode;
        data["items"][i]["tag"] = action.actionList[i].tag;
        data["items"][i]["index"] = i;
    }

    return data;
}

export async function getUserInfo () {
  var action = await ibb.getUserInfo();
  if (action.error.type) {
    throw new Error("Error getting user info: " + action.error.type)
  }

  return action;
}

export async function getAccountCoupons() {
  var action = await ibb.getAccountCoupons();
  if (action.error.type) {
    throw new Error("Error getting account coupons: " + action.error.type)
  }

  return action;
}

export async function getReferralsInfo() {
  var action = await ibb.getReferralsInfo();
  if (action.error.type) {
    throw new Error("Error getting referrals info: " + action.error.type)
  }

  return action;
}

export async function getBalancesInfo() {
  var action = await ibb.getBalancesInfo();
  if (action.error.type) {
    throw new Error("Error getting balances info: " + action.error.type)
  }

  return action;
}

export async function getAccountInfo () {
    var action = await ibb.getAccountInfo();
    if (action.error.type) {
      throw new Error("Error getting account info: " + action.error.type)
    }

    return action;
}

export async function getSubscriptionOptions () {
  var action = await ibb.getSubscriptionOptions();
  if (action.error.type) {
    throw new Error("Error getting subscription opctions: " + action.error.type)
  }

  return action.subscriptionInfoList;
}

export async function getSubscriptionOptionsWithFeatures (features) {
  var action = await ibb.getSubscriptionOptionsWithFeatures(features);
  if (action.error.type) {
    throw new Error("Error getting subscription opctions with features: " + action.error.type)
  }

  return action.subscriptionInfoList;
}

export async function setSubscription (id) {
  var action = await ibb.setSubscription(id);
  if (action.error.type) {
    throw new Error("Error setting subscription: " + action.error.type)
  }
}

export async function getParent (handle) {
    var action = await ibb.getNodeById(handle);
    if (action.error.type) {
      throw new Error("Error getting node path: " + action.error.type)
    }

    return nodeToObject("/", action.node);
}

export async function fetchId (handle, sortOrder = Module.SortOrderType.SORT_ORDER_DEFAULT.value, ibbInstance = ibb) {
    var action = await ibbInstance.getNodePath(handle);
    if (action.error.type) {
      throw new Error("Error getting node path: " + action.error.type)
    }

    var path = action.text;
    action = await ibbInstance.getNodeById(handle);
    if (action.error.type) {
      throw new Error("Error getting node by id: " + action.error.type)
    }

    return await fetchAction(action, path, sortOrder, ibbInstance);
}

export async function fetch (url, sortOrder = Module.SortOrderType.SORT_ORDER_DEFAULT.value, ibbInstance = ibb) {
    url = removePrefix(url)
    var path = decodeURIComponent(url);
    var action = await ibbInstance.getNodeByPath(path);
    if (action.error.type) {
      throw new Error("Error getting node by path: " + action.error.type)
    }
    return await fetchAction(action, path, sortOrder, ibbInstance);
}

async function fetchAction (action, path, sortOrder = Module.SortOrderType.SORT_ORDER_DEFAULT.value, ibbInstance = ibb) {
  console.log("fetch: " + path);

  var data;
  data = nodeToObject(path, action.node);
  data["path"] = path;

  if (action.node.type != Module.NodeType.FILE.value) {
    data["sorting"] = {};
    data["sorting"]["by"] = "name";
    data["sorting"]["asc"] = false;


    action = await ibbInstance.getSortedChildren(action.node.handle, sortOrder);
    if (action.error.type) {
      throw new Error("Error getting child nodes: " + action.error.type)
    }

    data["items"] = [];
    data["numDirs"] = 0;
    data["numFiles"] = 0;
    data["nodeType"] = action.node.type;
    data["message"] = action.node.message;
    for (var i = 0; i < action.nodeList.length; i++) {
      data["items"].push(nodeToObject(path, action.nodeList[i]));
      data["items"][i]["isDir"] ? data["numDirs"]++ : data["numFiles"]++;
    }
  }
  else {
    var node = action.node;
    var fileid = node.handle + "/" + node.name;
    if (!Module.isFileAvailable(fileid)) {
      action = await ibbInstance.downloadWithCustomData(node.handle, fileid, 'preview');
      if (action.error.type) {
        throw new Error("Error downloading node: " + action.error.type)
      } 
    }

    if (data["type"] == "text" || data["type"] == "txt") {
      var fileData = Module.getFileData(fileid);
      var decoder = new TextDecoder("utf-8", { fatal: true });
      try {
        data["content"] = decoder.decode(fileData);
      }
      catch(error) {
        data["type"] = "blob";
      }
    }
  }

  //const res = await fetchURL(`/api/resources${url}`, {})
  //if (res.status === 200) {
    //let data = await res.json()

    if (path.startsWith('//')) {
      data.url = path.slice(1);
    }

    if (data.isDir) {
      if (!data.url.endsWith('/')) data.url += '/'
      data.items = data.items.map((item, index) => {
        item.index = index
        item.url = `${data.url}${encodeURIComponent(item.name)}`

        if (item.isDir) {
          item.url += '/'
        }

        return item
      })
    }

    return data
  //} else {
  //  throw new Error(res.status)
  //}
}

async function resourceAction (url, method, content) {
  console.log("resourceAction: " + url + " method: " + method + " content: " + typeof(content));

  url = removePrefix(url)

  let opts = { method }

  if (content) {
    opts.body = content
  }

  const res = await fetchURL(`/api/resources${url}`, opts)

  if (res.status !== 200) {
    throw new Error(res.responseText)
  } else {
    return res
  }
}

export async function removeId (handle) {
  var action = await ibb.deleteNode(handle);
  if (action.error.type) {
    throw new Error("Error deleting node: " + action.error.type)
  }

  return "200 OK";
}

export async function remove (url) {
  
  url = removePrefix(url)
  var path = decodeURIComponent(url);
  var action = await ibb.getNodeByPath(path);
  if (action.error.type) {
    throw new Error("Error getting node by path: " + action.error.type)
  }

  if (url.startsWith('//bin')) {
    action = await ibb.deleteNode(action.node.handle);
    if (action.error.type) {
      throw new Error("Error deleting node: " + action.error.type)
    }  
  }
  else {
    var h = action.node.handle;
    action = await ibb.getNodeByPath("//bin/");
    if (action.error.type) {
      throw new Error("Error getting the recycle bin: " + action.error.type)
    }

    action = await ibb.moveNode(h, action.node.handle);
    if (action.error.type) {
      throw new Error("Error moving node to the recycle bin: " + action.error.type)
    }  
  }

  return "200 OK";


  return resourceAction(url, 'DELETE')
}

export async function putId (handle, content = '') {
  var encoder = new TextEncoder();
  content = encoder.encode(content);
  
  var action = await ibb.getNodeById(handle);
  if (action.error.type) {
    throw new Error("Error getting node by id: " + action.error.type)
  }

  var oldnode = action.node;
  var name = oldnode.name;
  
  Module.createFile(name, content);
    
  // Send a message to user before closing the tab during file upload
  window.onbeforeunload = () => "Files are being uploaded."

  var uploadAction = await ibb.uploadWithCustomData(name, oldnode.parentHandle, 'edit');
  if (action.error.type) {
    return Promise.reject("Error uploading file: " + action.error.type);
  }

  window.onbeforeunload = null

  await ibb.deleteNode(oldnode.handle);
  if (action.error.type) {
    return Promise.reject("Error deleting old file: " + action.error.type);
  }

  return uploadAction.node.handle;

//  return resourceAction(url, 'PUT', content)
}

export async function downloadRecoveryKey () {
    var action = await ibb.getRecoveryKey();
    if (action.error.type) {
      throw new Error("Error getting recovery key: " + action.error.type)
    }
    
    var encoder = new TextEncoder();
    var content = encoder.encode(action.text);
    var millis = new Date().getTime();
    var fileId = "recovery" + millis;
    Module.createFile(fileId, content);
    Module.downloadFileWithName(fileId, "IberboxRecoveryKey.txt");
}

export async function getRecoveryKey () {
  var action = await ibb.getRecoveryKey();
  if (action.error.type) {
    throw new Error("Error getting recovery key: " + action.error.type)
  }
  
  return action.text;
}

export async function getSecretRecoveryKey(handle) {
  var action = await ibb.getSecretRecoveryKey(handle);
  if (action.error.type) {
    throw new Error("Error getting secret recovery key: " + action.error.type)
  }
  
  return action.text;
}

export async function getInvoicing () {
    var action = await ibb.getInvoicingInfo();
    if (action.error.type) {
      return null
    }
    
    return action.invoicingInfo;
}

export async function setInvoicingAddress (companyName, companyAddress, companyAddress2, companyNIF) 
{
    var action = await ibb.setInvoicingAddress(companyName, companyAddress, companyAddress2, companyNIF);
    if (action.error.type) {
      throw new Error("Error setting invoicing address: " + action.error.type)
    }
}

export async function downloadId (handle) {
    var action = await ibb.getNodeById(handle);
    if (action.error.type) {
      throw new Error("Error getting node: " + action.error.type)
    }
    
    var node = action.node;
    var fileid = node.handle + "/" + node.name;
    if (Module.isFileAvailable(fileid)) {
        Module.downloadFileWithName(fileid, node.name);
        //store.commit('addDownload', node)
        return;
    }

    var name;
    var file;
    if (node.type == Module.NodeType.FILE.value) {
      file = fileid;
      name = node.name;
      if (node.parentHandle !== '') {
        var nodeParent = await ibb.getNodeById(node.parentHandle)
        while (nodeParent.node.parentHandle !== '') {
          nodeParent = await ibb.getNodeById(nodeParent.node.parentHandle)
        }
        if (nodeParent.node.type === Module.NodeType.SECRET_ROOT.value) {
          action = await ibb.downloadWithCustomData(node.handle, file, 'secret');
        } else {
          action = await ibb.download(node.handle, file);
        }
      } else {
        if (node.type === Module.NodeType.SECRET_ROOT.value) {
          action = await ibb.downloadWithCustomData(node.handle, file, 'secret');
        } else {
          action = await ibb.download(node.handle, file);
        }
      }
    }
    else {
      file = fileid;
      if (node.parentHandle !== '') {
        var nodeParent = await ibb.getNodeById(node.parentHandle)
        while (nodeParent.node.parentHandle !== '') {
          nodeParent = await ibb.getNodeById(nodeParent.node.parentHandle)
        }
        if (nodeParent.node.type === Module.NodeType.SECRET_ROOT.value) {
          action = await ibb.downloadZipWithCustomData(node.handle, file, 'secret');
        } else {
          action = await ibb.downloadZip(node.handle, file);
        }
      } else {
        if (node.type === Module.NodeType.SECRET_ROOT.value) {
          action = await ibb.downloadZipWithCustomData(node.handle, file, 'secret');
        } else {
          action = await ibb.downloadZip(node.handle, file);
        }
      } 
      file = file + ".zip";  
      name = node.name + ".zip";
    }

    if (action.error.type) {
      throw new Error("Error downloading node: " + action.error.type)
    }  

    Module.downloadFileWithName(file, name);
    //store.commit('addDownload', node)
}

export async function download (format, ...files) {


for (let userfile of files) {
  var url = removePrefix(userfile)
  var path = decodeURIComponent(userfile);
  var action = await ibb.getNodeByPath(path);
  if (action.error.type) {
    throw new Error("Error getting node by path: " + action.error.type)
  }

  var node = action.node;
  var fileid = node.handle + "/" + node.name;
  if (Module.isFileAvailable(fileid)) {
      Module.downloadFileWithName(fileid, node.name);
      //store.commit('addDownload', node)
      return;
  }

  var name;
  var file;
  if (node.type == Module.NodeType.FILE.value) {
    file = fileid;
    name = node.name;
    action = await ibb.download(node.handle, file);
  }
  else {
    file = fileid;
    action = await ibb.downloadZip(node.handle, file);  
    file = file + ".zip";  
    name = node.name + ".zip";
  }

  if (action.error.type) {
    throw new Error("Error downloading node: " + action.error.type)
  }  

  Module.downloadFileWithName(file, name);
  //store.commit('addDownload', node)
}

  /*let url = `${baseURL}/api/raw`

  if (files.length === 1) {
    url += removePrefix(files[0]) + '?'
  } else {
    let arg = ''

    for (let file of files) {
      arg += removePrefix(file) + ','
    }

    arg = arg.substring(0, arg.length - 1)
    arg = encodeURIComponent(arg)
    url += `/?files=${arg}&`
  }

  if (format !== null) {
    url += `algo=${format}&`
  }

  url += `auth=${store.state.jwt}`
  window.open(url)*/
}


export async function createFile(parentHandle, name) {
    Module.createFile(name, new Uint8Array([10]));
    var action = await ibb.uploadWithCustomData(name, parentHandle, 'edit');
    if (action.error.type) {
      throw new Error("Error creating file: " + action.error.type)
    }
    return action.node.handle;
}

export async function createFolder(parentHandle, name) {
    var action = await ibb.createFolder(parentHandle, name);
    if (action.error.type) {
      return Promise.reject("Error creating folder " + action.error.type);  
    }
    return Promise.resolve("200 OK");
}

export async function createInbox(name, description) {
    var action = await ibb.createInbox(name, description);
    if (action.error.type) {
      return Promise.reject("Error creating inbox " + action.error.type);  
    }
    return Promise.resolve("200 OK");
}

export async function createResellerCoupon(username) {
  var action = await ibb.createResellerCoupon(username);
  if (action.error.type) {
    return Promise.reject("Error creating coupon " + action.error.type);  
  }
  return Promise.resolve("200 OK");
}

export async function createSecret(name, password) {
  var action = await ibb.createSecret(name, password);
  if (action.error.type) {
    throw new Error("Error creating secret: " + action.error.type) 
  }
  return action.node.handle;
}

export async function updateSecret(handle, name, password) {
  var action = await ibb.updateSecret(handle, name, password);
  if (action.error.type) {
    throw new Error("Error updatting secret: " + action.error.type) 
  }
  return Promise.resolve("200 OK");
}

export async function openSecret(name, password) {
  var action = await ibb.openSecret(name, password);
  if (action.error.type) {
    throw new Error("Error opening secret: " + action.error.type) 
  }
  return action.node.handle;
}

export async function getOpenSecrets() {
  var action = await ibb.getOpenSecrets();
  if (action.error.type) {
    throw new Error("Error getting open secret: " + action.error.type) 
  }
  return action.nodeList;
}

export async function closeSecret(handle) {
  var action = await ibb.closeSecret(handle);
  if (action.error.type) {
    throw new Error("Error closing secret: " + action.error.type) 
  }
  return Promise.resolve("200 OK");
}

export async function deleteSecret(handle) {
  var action = await ibb.deleteSecret(handle);
  if (action.error.type) {
    throw new Error("Error deleting secret: " + action.error.type) 
  }
  return Promise.resolve("200 OK");
}

export async function deleteCoupon(id) {
  var action = await ibb.deleteCoupon(id);
  if (action.error.type) {
    return Promise.reject("Error deleting coupon " + action.error.type);  
  }
  return Promise.resolve("200 OK");
}

export async function postId(parentHandle, content, previousHandle = false, onupload) {
  var file = await Module.getFile(content);
  if (!file) {
    await Module.registerFile(content);
  }
  //store.commit('addUpload', content)

  window.onbeforeunload = () => "Files are being uploaded."
  var name = content.name;

  if (parentHandle !== '') {
    var nodeParent = await ibb.getNodeById(parentHandle)
    while (nodeParent.node.parentHandle !== '') {
      nodeParent = await ibb.getNodeById(nodeParent.node.parentHandle)
    } 
    if (nodeParent.node.type === Module.NodeType.SECRET_ROOT.value) {
      return ibb.uploadWithCustomData(content.name, parentHandle, 'secret')
      .then(function(action)
      {
        if (action.error.type) {
          return;
        }

        if (previousHandle) {
          ibb.deleteNode(previousHandle);
        }

        var re = /(?:\.([^.]+))?$/;
        var extension = re.exec(action.node.name)[1] || "";
        extension = extension.toLowerCase();

        if (extension == "jpg" || extension == "jpeg" || extension == "png" || extension == "bmp" || extension == "heic" || extension == "heif") {
          var file = Module.getFile(name);

          console.log("Image uploaded. Creating metadata. Orientation: " + file['orientation']);
          var handle = action.node.handle;
          var url = Module.getObjectURL(name);

          var thumbnailName = "///thumbnails/" + handle + ".jpg";
          var previewName = "///previews/" + handle + ".jpg";
          Module.createMetadata(url, Module.MetadataType.META_THUMBNAIL.value, function(data) {
              console.log("Thumbnail created: " + data.length + " bytes");
              Module.createFile(thumbnailName, data);
              ibb.uploadMetadata(thumbnailName, handle, Module.MetadataType.META_THUMBNAIL.value);
          }, file['orientation']);

          Module.createMetadata(url, Module.MetadataType.META_PREVIEW.value, function(data) {
              console.log("Preview created: " + data.length + " bytes");
              Module.createFile(previewName, data);
              ibb.uploadMetadata(previewName, handle, Module.MetadataType.META_PREVIEW.value);
          }, file['orientation']);
        }
      })
      .finally(() => { window.onbeforeunload = null });
    } else {
      return ibb.upload(content.name, parentHandle)
      .then(function(action)
      {
        if (action.error.type) {
          return;
        }

        if (previousHandle) {
          ibb.deleteNode(previousHandle);
        }

        var re = /(?:\.([^.]+))?$/;
        var extension = re.exec(action.node.name)[1] || "";
        extension = extension.toLowerCase();

        if (extension == "jpg" || extension == "jpeg" || extension == "png" || extension == "bmp" || extension == "heic" || extension == "heif") {
          var file = Module.getFile(name);

          console.log("Image uploaded. Creating metadata. Orientation: " + file['orientation']);
          var handle = action.node.handle;
          var url = Module.getObjectURL(name);

          var thumbnailName = "///thumbnails/" + handle + ".jpg";
          var previewName = "///previews/" + handle + ".jpg";
          Module.createMetadata(url, Module.MetadataType.META_THUMBNAIL.value, function(data) {
              console.log("Thumbnail created: " + data.length + " bytes");
              Module.createFile(thumbnailName, data);
              ibb.uploadMetadata(thumbnailName, handle, Module.MetadataType.META_THUMBNAIL.value);
          }, file['orientation']);

          Module.createMetadata(url, Module.MetadataType.META_PREVIEW.value, function(data) {
              console.log("Preview created: " + data.length + " bytes");
              Module.createFile(previewName, data);
              ibb.uploadMetadata(previewName, handle, Module.MetadataType.META_PREVIEW.value);
          }, file['orientation']);
        }
      })
      .finally(() => { window.onbeforeunload = null });
    }
  } else {
    if (nodeParent.node.type === Module.NodeType.SECRET_ROOT.value) {
      return ibb.uploadWithCustomData(content.name, parentHandle, 'secret')
      .then(function(action)
      {
        if (action.error.type) {
          return;
        }

        if (previousHandle) {
          ibb.deleteNode(previousHandle);
        }

        var re = /(?:\.([^.]+))?$/;
        var extension = re.exec(action.node.name)[1] || "";
        extension = extension.toLowerCase();

        if (extension == "jpg" || extension == "jpeg" || extension == "png" || extension == "bmp" || extension == "heic" || extension == "heif") {
          var file = Module.getFile(name);

          console.log("Image uploaded. Creating metadata. Orientation: " + file['orientation']);
          var handle = action.node.handle;
          var url = Module.getObjectURL(name);

          var thumbnailName = "///thumbnails/" + handle + ".jpg";
          var previewName = "///previews/" + handle + ".jpg";
          Module.createMetadata(url, Module.MetadataType.META_THUMBNAIL.value, function(data) {
              console.log("Thumbnail created: " + data.length + " bytes");
              Module.createFile(thumbnailName, data);
              ibb.uploadMetadata(thumbnailName, handle, Module.MetadataType.META_THUMBNAIL.value);
          }, file['orientation']);

          Module.createMetadata(url, Module.MetadataType.META_PREVIEW.value, function(data) {
              console.log("Preview created: " + data.length + " bytes");
              Module.createFile(previewName, data);
              ibb.uploadMetadata(previewName, handle, Module.MetadataType.META_PREVIEW.value);
          }, file['orientation']);
        }
      })
      .finally(() => { window.onbeforeunload = null });
    } else {
      return ibb.upload(content.name, parentHandle)
      .then(function(action)
      {
        if (action.error.type) {
          return;
        }

        if (previousHandle) {
          ibb.deleteNode(previousHandle);
        }

        var re = /(?:\.([^.]+))?$/;
        var extension = re.exec(action.node.name)[1] || "";
        extension = extension.toLowerCase();

        if (extension == "jpg" || extension == "jpeg" || extension == "png" || extension == "bmp" || extension == "heic" || extension == "heif") {
          var file = Module.getFile(name);

          console.log("Image uploaded. Creating metadata. Orientation: " + file['orientation']);
          var handle = action.node.handle;
          var url = Module.getObjectURL(name);

          var thumbnailName = "///thumbnails/" + handle + ".jpg";
          var previewName = "///previews/" + handle + ".jpg";
          Module.createMetadata(url, Module.MetadataType.META_THUMBNAIL.value, function(data) {
              console.log("Thumbnail created: " + data.length + " bytes");
              Module.createFile(thumbnailName, data);
              ibb.uploadMetadata(thumbnailName, handle, Module.MetadataType.META_THUMBNAIL.value);
          }, file['orientation']);

          Module.createMetadata(url, Module.MetadataType.META_PREVIEW.value, function(data) {
              console.log("Preview created: " + data.length + " bytes");
              Module.createFile(previewName, data);
              ibb.uploadMetadata(previewName, handle, Module.MetadataType.META_PREVIEW.value);
          }, file['orientation']);
        }
      })
      .finally(() => { window.onbeforeunload = null });
    }
  }
}

export async function post (url, content = '', overwrite = false, onupload) {
  url = removePrefix(url)
  console.log("post: " + url + " " + content);

  var path = decodeURIComponent(url);
  
  var previousNode;
  var action = await ibb.getNodeByPath(path);
  if (!action.error.type) {
    previousNode = action.node;
  }

  var index = path.lastIndexOf("/", path.length - 2)
  var basepath = path.substring(0, index + 1);
  
  action = await ibb.getNodeByPath(basepath);
  if (action.error.type) {
    return Promise.reject("Error getting base path: " + basepath + "("  + action.error.type + ")");
  }

  if (content)
  {
    await Module.registerFile(content);
    
    // Send a message to user before closing the tab during file upload
    window.onbeforeunload = () => "Files are being uploaded."

    var name = content.name;

    return ibb.upload(content.name, action.node.handle)
              .then(function(action)
              {
                if (previousNode) {
                  ibb.deleteNode(previousNode.handle);
                }

                var re = /(?:\.([^.]+))?$/;
                var extension = re.exec(action.node.name)[1] || "";
                extension = extension.toLowerCase();

                if (extension == "jpg" || extension == "jpeg" || extension == "png" || extension == "bmp" || extension == "heic" || extension == "heif") {
                  var file = Module.getFile(name);
			
	          console.log("Image uploaded. Creating metadata. Orientation: " + file['orientation']);
                  var handle = action.node.handle;
                  var url = Module.getObjectURL(name);

                  var thumbnailName = "///thumbnails/" + handle + ".jpg";
                  var previewName = "///previews/" + handle + ".jpg";
                  Module.createMetadata(url, Module.MetadataType.META_THUMBNAIL.value, function(data) {
                      console.log("Thumbnail created: " + data.length + " bytes");
                      Module.createFile(thumbnailName, data);
                      ibb.uploadMetadata(thumbnailName, handle, Module.MetadataType.META_THUMBNAIL.value);
                  }, file['orientation']);

                  Module.createMetadata(url, Module.MetadataType.META_PREVIEW.value, function(data) {
                      console.log("Preview created: " + data.length + " bytes");
                      Module.createFile(previewName, data);
                      ibb.uploadMetadata(previewName, handle, Module.MetadataType.META_PREVIEW.value);
                  }, file['orientation']);
                }
              })
              .finally(() => { window.onbeforeunload = null });
  }

  if (path[path.length - 1] == '/') {
    name = path.substring(index + 1, path.length - 1);
    action = await ibb.createFolder(action.node.handle, name);
    if (action.error.type) {
      return Promise.reject("Error creating folder");  
    }
  }
  else {
    name = path.substring(index + 1);
    Module.createFile(name, new Uint8Array([10]));
    action = await ibb.upload(name, action.node.handle);
    if (action.error.type) {
      return Promise.reject("Error creating file");  
    }
  }

  return Promise.resolve("200 OK");


  return new Promise((resolve, reject) => {
    let request = new XMLHttpRequest()
    request.open('POST', `${baseURL}/api/resources${url}?override=${overwrite}`, true)
    request.setRequestHeader('X-Auth', store.state.jwt)

    if (typeof onupload === 'function') {
      request.upload.onprogress = onupload
    }

    // Send a message to user before closing the tab during file upload
    window.onbeforeunload = () => "Files are being uploaded."

    request.onload = () => {
      if (request.status === 200) {
        resolve(request.responseText)
      } else if (request.status === 409) {
        reject(request.status)
      } else {
        reject(request.responseText)
      }
    }

    request.onerror = (error) => {
      reject(error)
    }

    request.send(content)
    // Upload is done no more message before closing the tab 
  }).finally(() => { window.onbeforeunload = null })
}

export async function copyId (items) {
  let promises = []

  for (let item of items) {
      var fromhandle = item.from    
      var tohandle = item.to

      console.log("copy from: " + fromhandle);
      console.log("to: " + tohandle);

      var action = await ibb.copyNode(fromhandle, tohandle);
      if (action.error.type) {
        promises.push(Promise.reject("Error copying node"));
        continue;
      }

      promises.push(Promise.resolve("200 OK"));
  }

  return Promise.all(promises)
}

export async function renameId (items) {
  let promises = []

  for (let item of items) {
      var fromhandle = item.from    
      var toname = item.to

      console.log("rename from: " + fromhandle);
      console.log("to: " + toname);

      var action = await ibb.renameNode(fromhandle, toname);
      if (action.error.type) {
        promises.push(Promise.reject("Error renaming node"));
        continue;
      }
      promises.push(Promise.resolve("200 OK"));
  }

  return Promise.all(promises)
}

export async function setInboxInfo (handle, name, description) {
    var action = await ibb.setInboxInfo(handle, name, description);
    if (action.error.type) {
      return Promise.reject("Error changing inbox info " + action.error.type);  
    }
    return Promise.resolve("200 OK");
}

export async function moveId (items) {
  let promises = []

  for (let item of items) {
      var fromhandle = item.from    
      var tohandle = item.to

      console.log("move from: " + fromhandle);
      console.log("to: " + tohandle);

      var action = await ibb.moveNode(fromhandle, tohandle);
      if (action.error.type) {
        promises.push(Promise.reject("Error moving node"));
        continue;
      }

      promises.push(Promise.resolve("200 OK"));
  }

  return Promise.all(promises)
}

async function moveCopy (items, copy = false) {
  let promises = []

  for (let item of items) {
    var fromurl = removePrefix(item.from)    
    var frompath = decodeURIComponent(fromurl);
    if (frompath.charAt(frompath.length - 1) == '/')
    {
      frompath = frompath.substring(0, frompath.length - 1);
    }  

    var fromindex = frompath.lastIndexOf("/")
    var frombasepath = frompath.substring(0, fromindex + 1);
    var fromname = frompath.substring(fromindex + 1);

    var tourl = removePrefix(item.to)
    var topath = decodeURIComponent(tourl);
    var toindex = topath.lastIndexOf("/")
    var tobasepath = topath.substring(0, toindex + 1);
    var toname = topath.substring(toindex + 1);
  
    console.log("Copy? " + copy);
    console.log("frompath: " + frompath + " frombasepath:  " + frombasepath + " fromname:" + fromname);
    console.log("topath: " + topath + " tobasepath:  " + tobasepath + " toname:" + toname);

    var action = await ibb.getNodeByPath(frompath);
    if (action.error.type) {
      promises.push(Promise.reject("Error getting from path: " + frompath + "("  + action.error.type + ")"));
      continue;
    }
    var fromnode = action.node;

    if (copy) {
      if (fromname == toname) {
        action = await ibb.getNodeByPath(tobasepath);
        if (action.error.type) {
          promises.push(Promise.reject("Error getting destination path: " + tobasepath + "("  + action.error.type + ")"));
          continue;
        }

        action = await ibb.copyNode(fromnode.handle, action.node.handle);
        if (action.error.type) {
          promises.push(Promise.reject("Error copying node"));
          continue;
        }

        promises.push(Promise.resolve("200 OK"));
      }
      else {
        promises.push(Promise.reject("Operation not supported")); 
      }
    }
    else {
      if (frombasepath == tobasepath) {
        action = await ibb.renameNode(fromnode.handle, toname);
        if (action.error.type) {
          promises.push(Promise.reject("Error renaming node"));
          continue;
        }
        promises.push(Promise.resolve("200 OK"));
      }
      else if (fromname == toname) {
        action = await ibb.getNodeByPath(tobasepath);
        if (action.error.type) {
          promises.push(Promise.reject("Error getting destination path: " + tobasepath + "("  + action.error.type + ")"));
          continue;
        }

        action = await ibb.moveNode(fromnode.handle, action.node.handle);
        if (action.error.type) {
          promises.push(Promise.reject("Error moving node"));
          continue;
        }

        promises.push(Promise.resolve("200 OK"));
      }
      else {
        promises.push(Promise.reject("Operation not supported")); 
      }
    }

    //const from = removePrefix(item.from)
    //const to = encodeURIComponent(removePrefix(item.to))
    //const url = `${from}?action=${copy ? 'copy' : 'rename'}&destination=${to}`
    //promises.push(resourceAction(url, 'PATCH'))
  }

  return Promise.all(promises)
}

export function move (items) {
  return moveCopy(items)
}

export function copy (items) {
  return moveCopy(items, true)
}

export async function checksum (url, algo) {
  const data = await resourceAction(`${url}?checksum=${algo}`, 'GET')
  return (await data.json()).checksums[algo]
}
