<template>
  <div id="recover" :class="{ recaptcha: recaptcha }">
    <vue-headful title="Recupera tu cuenta en Iberbox" description="Si has olvidado tu contraseña, puedes acceder a tu cuenta utilizando tu clave de recuperación." keywords="iberbox, copias de seguridad, cifrado punto a punto, nube segura" image="https://iberbox.com/img/logo.svg" />
    <div class="contenedor-body">
      <div class="contenedor-login visible-xs">
        <form @submit="submit" autocomplete="off">
          <img src="/img/icons/Login/ibbLogo.svg">
          <h1>{{ $t('recovery.accountRecovery') }}</h1>

          <template v-if="mfaenabled">
            {{ $t('prompts.entermfacode') }}
            <br /><br />
          <v-otp-input ref="otpInput2" class="otp" input-classes="otp-input" input-type="number" separator=" " :num-inputs="6" :should-auto-focus="true" :is-input-num="true" @on-complete="handleOnComplete2"/>
            <br /><br />
          </template>
          
          <div v-if="createMode == 1" :class="[isFocusEmail ? 'campo-activo' : 'campo']">
            <user-icon size="1.5x" class="custom-class"></user-icon>
            <input class="input input--block" type="email" id="usernameInput" v-model="username" :placeholder="$t('login.username')" @focus="focusEmailOn" @blur="focusEmailOff">
          </div>
          
          <template v-if="createMode == 2">
            <div class="email">
              <p>{{ username }}</p>
              <p @click="changeEmail" class="change-email">{{ $t('login.changeEmail') }}</p>
            </div>
            <div class="pin">{{ $t('login.pin') }}</div>
            <v-otp-input ref="otpInput" class="otp" input-classes="otp-input" input-type="number" separator=" " :num-inputs="6" :should-auto-focus="true" :is-input-num="true" @on-complete="handleOnComplete"/>
            <div :class="[isFocusKey ? 'campo-activo' : 'campo']">
              <user-icon size="1.5x" class="custom-class"></user-icon>
              <input ref="recoveryKeyInput" class="input input--block" type="password" id="recoveryKeyInput" v-model="recoveryKey" :placeholder="$t('recovery.recoveryKey')" autocomplete="new-password" @focus="focusKeyOn" @blur="focusKeyOff"/>
            </div>
          </template>

          <div v-if="recaptcha" id="recaptcha"></div>
          <input class="button button--block" id="recoverButton" type="submit" :value="createMode == 1 ? $t('recovery.start') : $t('recovery.recover')">
        </form>
        <div class="mensaje">
          <template v-if="error !== ''">
            <div class="wrong">
              <alert-circle-icon size="1.5x" class="custom-class"></alert-circle-icon>
              <p v-html="error"></p>
            </div>
          </template>
          <template v-else-if="info !== ''">
            <div class="logininfo">{{ info }}</div>
          </template>
        </div>
      </div>
      <div class="contenedor-publicidad">
        <div class="texto">
          <div>
            <h3>{{ $t('login.theCloud') }}</h3>
            <h2>{{ $t('login.business') }}.</h2>
          </div>
          <img src="/img/icons/Login/ibbLogoWhiteV.svg">
        </div>
        <p>{{ $t('recovery.rememberedPassword') }}</p>
        <button :aria-label="$t('login.submit')" :title="$t('login.submit')" @click="toggleMode">
          <span>{{ $t('login.submit') }}</span>
        </button>
        <div class="contenedor-imagen">
          <img src="/img/icons/Login/ibbWebImage.png" class="desktop hidden-xs">
          <img src="/img/icons/Login/ibbWebCroppedImage.png" class="desktop visible-xs">
        </div>
      </div>
      <div class="contenedor-login hidden-xs">
        <form @submit="submit" autocomplete="off">
          <h1>{{ $t('recovery.accountRecovery') }}</h1>

          <template v-if="mfaenabled">
            {{ $t('prompts.entermfacode') }}
            <br /><br />
          <v-otp-input ref="otpInput2" class="otp" input-classes="otp-input" input-type="number" separator=" " :num-inputs="6" :should-auto-focus="true" :is-input-num="true" @on-complete="handleOnComplete2"/>
            <br /><br />
          </template>
          
          <div v-if="createMode == 1" :class="[isFocusEmail ? 'campo-activo' : 'campo']">
            <user-icon size="1.5x" class="custom-class"></user-icon>
            <input class="input input--block" type="email" id="usernameInput" v-model="username" :placeholder="$t('login.username')" @focus="focusEmailOn" @blur="focusEmailOff">
          </div>
          
          <template v-if="createMode == 2">
            <div class="email">
              <p>{{ username }}</p>
              <p @click="changeEmail" class="change-email">{{ $t('login.changeEmail') }}</p>
            </div>
            <div class="pin">{{ $t('login.pin') }}</div>
            <v-otp-input ref="otpInput" class="otp" input-classes="otp-input" input-type="number" separator=" " :num-inputs="6" :should-auto-focus="true" :is-input-num="true" @on-complete="handleOnComplete"/>
            <div :class="[isFocusKey ? 'campo-activo' : 'campo']">
              <user-icon size="1.5x" class="custom-class"></user-icon>
              <input ref="recoveryKeyInput" class="input input--block" type="password" id="recoveryKeyInput" v-model="recoveryKey" :placeholder="$t('recovery.recoveryKey')" autocomplete="new-password" @focus="focusKeyOn" @blur="focusKeyOff"/>
            </div>
          </template>

          <div v-if="recaptcha" id="recaptcha"></div>
          <input class="button button--block" id="recoverButton" type="submit" :value="createMode == 1 ? $t('recovery.start') : $t('recovery.recover')">
        </form>
        <div class="mensaje">
          <template v-if="error !== ''">
            <div class="wrong">
              <alert-circle-icon size="1.5x" class="custom-class"></alert-circle-icon>
              <p v-html="error"></p>
            </div>
          </template>
          <template v-else-if="info !== ''">
            <div class="logininfo">{{ info }}</div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as auth from '@/utils/auth'
import { name, logoURL, recaptcha, recaptchaKey } from '@/utils/constants'
import OtpInput from "@bachdgvn/vue-otp-input";
import Password from 'vue-password-strength-meter'
import vueHeadful from 'vue-headful';
import { UserIcon, AlertCircleIcon } from 'vue-feather-icons'

export default {
  name: 'recover',
  computed: {
    name: () => name,
    logoURL: () => logoURL
  },
  data: function () {
    return {
      createMode: 1,
      error: '',
      username: '',
      password: '',
      recaptcha: recaptcha,
      recoveryKey: '',
      pin: '',
      id: '',
      mfaenabled: false,
      code: -1,
      info: '',
      isFocusEmail: false,
      isFocusKey: false
    }
  },
  components: {
    Password,
    UserIcon,
    AlertCircleIcon,
    'v-otp-input': OtpInput,
    'vue-headful': vueHeadful
  },
  mounted () {
    if (this.$route.query.email) {
      this.username = Base64.decode(this.$route.query.email);
    }

    if (this.$route.query.id && this.username.length != 0) {
      this.id = this.$route.query.id;
      this.createMode = 2;
    }
    else if (this.$route.hash && this.$route.hash.length > 1) {
      this.recoveryKey = this.$route.hash.substring(1);
      this.$router.replace({path: window.location.pathname})
    }

    if (this.$route.query.pin) {
      this.pin = this.$route.query.pin;
    }

    if (!recaptcha) return

    window.grecaptcha.render('recaptcha', {
      sitekey: recaptchaKey
    })
  },
  methods: {
    focusEmailOn () {
      this.isFocusEmail = true;
    },
    focusEmailOff () {
      this.isFocusEmail = false;
    },
    focusKeyOn () {
      this.isFocusKey = true;
    },
    focusKeyOff () {
      this.isFocusKey = false;
    },
    handleOnComplete: async function (value) {
      this.pin = parseInt(value);
      setTimeout(function(){ document.getElementById('recoveryKeyInput').focus(); }, 0);
    },
    handleOnComplete2: async function (value) {
      this.code = parseInt(value);

      let captcha = ''
      let redirect = '/profile/settings'
      document.getElementById("recoverButton").disabled = true;
      document.getElementById("recoveryKeyInput").disabled = true;

      try {
        this.info = this.$t('login.loggingin')
        var result = await auth.recoverAccount(this.id, this.username, this.recoveryKey, this.pin, this.code);
        this.info = '';
        if (result)
        {
            if (this.$refs.otpInput) {
              this.$refs.otpInput.clearInput();
            }
            if (this.$refs.otpInput2) {
              this.$refs.otpInput2.clearInput();
            }
            this.mfaenabled = false;
            document.getElementById("recoverButton").disabled = false;
            document.getElementById("recoveryKeyInput").disabled = false;

            this.error = this.$t('login.wrongCredentials');
            var thisComponent = this;
            setTimeout(function(){ thisComponent.error = '' }, 5000);
            return;
        }

        this.$showSuccess(this.$t('recovery.success'))
        this.$router.push({ path: redirect })
      } catch (e) {
        this.info = '';
        if (this.$refs.otpInput) {
          this.$refs.otpInput.clearInput();
        }
        if (this.$refs.otpInput2) {
          this.$refs.otpInput2.clearInput();
        }
        this.mfaenabled = false;
        document.getElementById("recoverButton").disabled = false;
        document.getElementById("recoveryKeyInput").disabled = false;
        
        this.error = e;
        var thisComponent = this;
        setTimeout(function(){ thisComponent.error = '' }, 5000);
      }

      document.getElementById("loginButton").disabled = false;
      document.getElementById("usernameInput").disabled = false;
      document.getElementById("passwordInput").disabled = false;
    },
    toggleMode () {      
      this.username = '';
      this.password = '';
      this.recoveryKey = '';
      this.pin = '';
      this.id = '';
      this.error = '';
      
      this.createMode = 1;
      this.$router.push({ path: '/login' });
    },
    changeEmail () {
      this.username = '';
      this.password = '';
      this.recoveryKey = '';
      this.pin = '';
      this.id = '';
      this.error = '';
      
      this.createMode = 1;
      this.$router.push({ path: '/recover' });
    },
    async submit (event) {
      event.preventDefault()
      event.stopPropagation()

      var button = document.getElementById('recoverButton');
      if (button.disabled) {
          return;
      }

      let redirect = '/profile/settings'

      let captcha = ''
      if (recaptcha) {
        captcha = window.grecaptcha.getResponse()

        if (captcha === '') {
          this.error = this.$t('login.wrongCredentials')
          return
        }
      }

      if (this.createMode == 1) {
          if (!this.username || this.username.length == 0) {
            this.error = this.$t('login.enterEmail');
            return;
          }

          try {
            button.disabled = true;
            this.id = await auth.forgotPassword(this.username);
            button.disabled = false;
            this.createMode = 2;
            this.error = '';

            if (this.recoveryKey.length)
            {
                await this.$nextTick();
                this.$refs.recoveryKeyInput.type="hidden";
            }

            this.$router.replace({ path: '/recover', query: { email: Base64.encodeURI(this.username), id: this.id } });
          } catch (e) {
            this.error = this.$t('recovery.notFound');
            var thisComponent = this;
            setTimeout(function(){ thisComponent.error = '' }, 5000);
            button.disabled = false;
          }
          return;
      }

      if (this.pin.length == 0) {
        this.error = this.$t('login.enterPin');
        var thisComponent = this;
        setTimeout(function(){ thisComponent.error = '' }, 5000);
        return;
      }
      if (this.recoveryKey.length == 0) {
        this.error = this.$t('recovery.enterRecoveryKey');
        var thisComponent = this;
        setTimeout(function(){ thisComponent.error = '' }, 5000);
        return;
      }

      document.getElementById("recoverButton").disabled = true;
      document.getElementById("recoveryKeyInput").disabled = true;
      try {
        button.disabled = true;
        this.info = this.$t('login.loggingin')
        var result = await auth.recoverAccount(this.id, this.username, this.recoveryKey, this.pin);
        this.info = '';
        if (result == Module.ErrorType.E_CANCELLED.value)
        {
            this.mfaenabled = true;
            return;
        }
        else if (result != Module.ErrorType.E_SUCCESS.value)
        {
            document.getElementById("recoverButton").disabled = false;

            if (result == Module.ErrorType.E_CRYPTO.value)
            {
                this.$refs.recoveryKeyInput.disabled = false;
                this.$refs.recoveryKeyInput.type = "password";
                this.recoveryKey=""
                this.error = this.$t('recovery.wrongRecoveryKey');
            }
            else
            {
                this.error = this.$t('login.wrongCredentials');
            }
            button.disabled = false;

            var thisComponent = this;
            setTimeout(function(){ thisComponent.error = '' }, 5000);
            return;
        }

        this.$showSuccess(this.$t('recovery.success'))
        this.$router.push({ path: redirect })
      } catch (e) {
          this.info = '';
          document.getElementById("recoverButton").disabled = false;
          document.getElementById("recoveryKeyInput").disabled = false;
          button.disabled = false;

          this.error = e;
          var thisComponent = this;
          setTimeout(function(){ thisComponent.error = '' }, 5000);
      }
    }
  }
}
</script>
