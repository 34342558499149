const name = 'IBERBOX'
const disableExternal = true
const baseURL = '/'
const staticURL = '/'
const recaptcha = false
const recaptchaKey = "RECAPTCHAKEY"
const signup = true
const version = 1
const logoURL = `/img/logo.svg`
const noAuth = false
const loginPage = '%2Finfo%2F'

export {
  name,
  disableExternal,
  baseURL,
  logoURL,
  recaptcha,
  recaptchaKey,
  signup,
  version,
  noAuth,
  loginPage
}
