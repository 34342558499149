import { sync } from 'vuex-router-sync'
import store from '@/store'
import router from '@/router'
import i18n from '@/i18n'
import Vue from '@/utils/vue'
import BoostrapVue from 'bootstrap-vue'
import { recaptcha, loginPage } from '@/utils/constants'
import { login, validateLogin } from '@/utils/auth'
import App from '@/App'

Vue.use(BoostrapVue);

/*import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'*/

sync(store, router)

async function start () {
  if (loginPage) {
    var result = await validateLogin()
    var currentUrl = window.location.pathname
    if (result == Module.ErrorType.E_INVOICING.value &&
          (currentUrl != "/transaction-error" && currentUrl != "/transaction-ok"))
    {
      router.push({path: '/profile/invoicing/plans'})
    }
  } else {
    await login('', '', '')
  }

  if (recaptcha) {
    await new Promise (resolve => {
      const check = () => {
        if (typeof window.grecaptcha === 'undefined') {
          setTimeout(check, 100)
        } else {
          resolve()
        }
      }

      check()
    })
  }

  new Vue({
    el: '#app',
    store,
    router,
    i18n,
    template: '<App/>',
    components: { App }
  })
}

window.onerror = function myErrorHandler(errorMsg, file, line, column, errorObj) {
    alert("Se ha producido un error. Por favor, recarga la página si deja de funcionar correctamente.\r\nSi el problema persiste, intenta cerrar la sesión o borrar la cache del navegador. Si quieres que arreglemos el error lo más pronto posible o necesitas ayuda, por favor contacta con nosotros en info@iberbox.com\r\nLa descripción del error es la siguiente:\r\n" + errorMsg + "\r\n" + errorObj.stack);
    return false;
}

if (typeof ibb !== 'undefined') {
  console.log("IBERBOX is already loaded. Starting web app");
  start()
}
else {
  console.log("IBERBOX is not ready yet. Waiting...");
  Module["startFunctionBackup"] = Module['onRuntimeInitialized'];
  Module['onRuntimeInitialized'] = function() {
    if (Module["startFunctionBackup"]) {
	console.log("Runtime initialized");
        Module["startFunctionBackup"]();
    }
    console.log("Starting web app");
    start();
  }
}

