<template>
 <div>
  <div id="listing"
    :class="user.viewMode"
    @dragenter="dragEnter"
    @dragend="dragEnd">

    <div v-if="contacts.length > 0">
      <item-contact v-for="(contact) in contacts"
        :key="contact.handle"
        v-bind:accountHandle="contact.accountHandle"
        v-bind:ctime="contact.ctime"
        v-bind:mtime="contact.mtime"
        v-bind:name="contact.name"
        v-bind:handle="contact.handle"
        v-bind:index="contacts.indexOf(contact)"
        v-bind:isContainerRecord="isContainerRecord"
        v-bind:checkedAll="checkedAll"
        v-bind:buscador="buscador"
        @show-outboxes="showOutboxes">
      </item-contact>
    </div>

    <input style="display:none" type="file" id="upload-input" @change="uploadInput($event)" multiple>

    <div :class="{ active: $store.state.multiple }" id="multiple-selection">
      <p>{{ $t('files.multipleSelectionEnabled') }}</p>
      <div @click="$store.commit('multiple', false)" tabindex="0" role="button" :title="$t('files.clear')" :aria-label="$t('files.clear')" class="action">
        <i class="material-icons">clear</i>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import ItemContact from './ListingItemContact'
import css from '@/utils/css'
import { users, files as api } from '@/api'
import buttons from '@/utils/buttons'

export default {
  name: 'listing-contact',
  components: { 
    ItemContact,
  },
  data: function () {
    return {
    }
  },
  props: ['isContainerRecord', 'checkedAll', 'buscador', 'contacts'],
  computed: {
    ...mapGetters([
      'isFiles',
      'isRegister',
      'selectedCount'
    ]),
    ...mapState(['selected', 'user', 'isMenu', 'req']),
  },
  watch: {
    contacts(val) {
      this.$store.commit('updateRequest', val)
    }
  },
  mounted: function () {
    // Check the columns size for the first time.
    this.resizeEvent()

    // Add the needed event listeners to the window and document.
    window.addEventListener('resize', this.resizeEvent)
    window.addEventListener('scroll', this.scrollEvent)
    if (!this.isRegister)
    {
      window.addEventListener('keydown', this.keyEvent)
    }
  },
  beforeDestroy () {
    // Remove event listeners before destroying this page.
    window.removeEventListener('resize', this.resizeEvent)
    window.removeEventListener('scroll', this.scrollEvent)
    if (!this.isRegister)
    {
      window.removeEventListener('keydown', this.keyEvent)
    }
  },
  methods: {
    ...mapMutations([ 'updateUser', 'resetSelected']),
    showOutboxes(handle) {
      this.$emit('show-outboxes', handle);
    },
    isMobile: function () {
      return window.innerWidth <= 768
    },
    keyEvent (event) {
      if (!event.ctrlKey && !event.metaKey) {
        return
      }

      let key = String.fromCharCode(event.which).toLowerCase()

      switch (key) {
        case 'f':
          event.preventDefault()
          this.$store.commit('showHover', 'search')
          break
        case 'c':
        case 'x':
          this.copyCut(event, key)
          break
        case 'v':
          this.paste(event)
          break
      }
    },
    copyCut (event, key) {
      if (event.target.tagName.toLowerCase() === 'input') {
        return
      }

      let items = []

      for (let i of this.selected) {
        items.push({
          from: this.req.items[i].handle,
          name: encodeURIComponent(this.req.items[i].name)
        })
      }

      if (items.length == 0) {
        return
      }

      this.$store.commit('updateClipboard', {
        key: key,
        items: items
      })
    },
    paste (event) {
      if (event.target.tagName.toLowerCase() === 'input') {
        return
      }

      let items = []

      for (let item of this.$store.state.clipboard.items) {
        const from = item.from
        const to = this.req.handle
        console.log('Pasting from ' + from + " to " + to);
        items.push({ from, to })
      }

      if (items.length === 0) {
        return
      }

      if (this.$store.state.clipboard.key === 'x') {
        api.moveId(items).then(() => {
          this.$store.commit('setReload', true)
        }).catch(this.$showError)
        return
      }

      api.copyId(items).then(() => {
        this.$store.commit('setReload', true)
      }).catch(this.$showError)
    },
    resizeEvent () {
      // Update the columns size based on the window width.
      let columns = Math.floor(document.querySelector('main').offsetWidth / 300)
      let items = css(['#listing.mosaic .item', '.mosaic#listing .item'])
      if (columns === 0) columns = 1
      items.style.width = `calc(${100 / columns}% - 1em)`
    },
    scrollEvent () {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        this.show += 50
      }
    },
    dragEnter () {
      // When the user starts dragging an item, put every
      // file on the listing with 50% opacity.
      let items = document.getElementsByClassName('item')

      Array.from(items).forEach(file => {
        file.style.opacity = 0.5
      })
    },
    dragEnd () {
      this.resetOpacity()
    },
    checkConflict (files, items, base) {
      if (typeof items === 'undefined' || items === null) {
        items = []
      }

      let conflict = false
      let res;
      for (let i = 0; i < files.length; i++) {
        res = items.findIndex(function hasConflict (element) {
          return (element.name === this)
        }, files[i].name)

        if (res >= 0) {
          conflict = true
          break
        }
      }

      if (!conflict) {
        this.handleFiles(files, base)
        return
      }

      this.$store.commit('showHover', {
        prompt: 'replace',
        confirm: (event) => {
          event.preventDefault()
          this.$store.commit('closeHovers')
          this.handleFiles(files, base, items[res].handle)
        }
      })
    },
    uploadInput (event) {
      this.checkConflict(event.currentTarget.files, this.req.items, '')
    },
    resetOpacity () {
      let items = document.getElementsByClassName('item')

      Array.from(items).forEach(file => {
        file.style.opacity = 1
      })
    },
    handleFiles (files, base, overwrite = false) {
      buttons.loading('upload')
      let promises = []
      let progress = new Array(files.length).fill(0)

      let onupload = (id) => (event) => {
        progress[id] = (event.loaded / event.total) * 100

        let sum = 0
        for (let i = 0; i < progress.length; i++) {
          sum += progress[i]
        }

        this.$store.commit('setProgress', Math.ceil(sum / progress.length))
      }

      console.log("Uploading to " + this.req.url + " base: " + base);

      for (let i = 0; i < files.length; i++) {
        let file = files[i]
        promises.push(api.postId(base ? base : this.req.handle, file, overwrite, onupload(i)))
      }
      this.$showSuccess(this.$t('transfers.uploadadded'))


      let finish = () => {
        buttons.success('upload')
        this.$store.commit('setProgress', 0)
      }

      Promise.all(promises)
        .then(() => {
          finish()
          this.$store.commit('setReload', true)
        })
        .catch(error => {
          finish()
          this.$showError(error)
        })

      return false
    },
  }
}
</script>