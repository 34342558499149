<template>
  <div class="item"
  role="button"
  tabindex="0"
  draggable="false"
  :data-dir="isDir"
  :aria-label="name"
  :aria-selected="isSelected" style="cursor: auto;">
    <div style="display: flex; align-items: center; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
      <div>
        <img v-if="type === 'image'" class="material-icons" :src="image">
        <img v-else class="material-icons" :src="icon">
      </div>

      <div>
        <p class="transfername" :title="name" style="width: 80%; font-weight: 600; color: black;">{{ name }}</p>
        <p v-if="(isActive || progress != 100) && !readonly" class="size" style="display: contents;">
            <progress-bar size="medium" :val="progress" style="calc(100% - 0.2em);"></progress-bar>
        </p>
        <button v-if="!readonly" @click="deleteItem" id ="deleteItem"
          class="button button--flat"
          :aria-label="$t('buttons.delete')"
          :title="$t('buttons.delete')">
          <img src="/img/icons/privateSpacesIcon/closeIcon.svg">
        </button> 
      </div>
    </div>
    <button @click="download" id="downloadButon"
      class="button button--flat"
      :aria-label="$t('download.downloadFile')"
      :title="$t('download.downloadFile')">
      <download-icon size="1.5x" class="custom-class"></download-icon>
    </button>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex'
import filesize from 'filesize'
import moment from 'moment'
import { share as api } from '@/api'
import ProgressBar from 'vue-simple-progress'
import { DownloadIcon } from 'vue-feather-icons'

export default {
  name: 'item-record',
  data: function () {
    return {
      timer: 0,
      image: '/img/icons/mimetype_image.png',
    }
  },
  components: {
    ProgressBar,
    DownloadIcon
  },
  props: ['progress', 'name', 'isDir', 'url', 'readonly', 'size', 'modified', 'index', 'status', 'errorCode', 'handle', 'tag', 'type'],
  computed: {
    ...mapState(['selected', 'req']),
    ...mapGetters(['selectedCount']),
    isSelected () {
      return (this.selected.indexOf(this.index) !== -1)
    },
    isActive: function () {
      if (this.status === Module.TransferStatus.ACTIVE.value) {
        return true;
      }
      return false;
    },
    icon () {
      if (this.type === 'audio') {
        return '/img/icons/mimetype_mp3.png'
      }
      if (this.type === 'video') {
        return '/img/icons/mimetype_mp4.png'
      } 
      if (this.type === 'folder') {
        return '/img/icons/mimetype_folder.png';
      }
      if (this.type === 'txt') {
        return '/img/icons/mimetype_txt.png'
      }
      if (this.type === 'pdf') {
        return '/img/icons/mimetype_pdf.png'
      }
      return '/img/icons/mimetype_file.png'
    },
    canDrop () {
      if (!this.isDir) return false

      for (let i of this.selected) {
        if (this.req.items[i].url === this.url) {
          return false
        }
      }

      return true
    }
  },
  watch: {
    handle() {
      this.setImage();
    }
  },
  methods: {
    ...mapMutations(['addSelected', 'removeSelected', 'resetSelected']),
    humanSize: function () {
      return filesize(this.size)
    },
    deleteItem: async function () {
      if (this.status === Module.TransferStatus.FINISHED.value) {
        try {
          var action = await ibbLink.removeFromRecord(this.handle);
          if (action.error.type) {
            throw new Error("Error removing file from record: " +  action.error.type)
          }
        } catch (e) {
          this.$showError(e)
        }
      } else {
        try {
          action = await ibbLink.cancelTransferByTag(this.tag)
          if (action.error.type) {
            throw new Error("Error canceling transfer by tag: " +  action.error.type)
          }
        } catch (e) {
          this.$showError(e)
        }
      }
      Module.deleteFile(this.name);
    },
    humanTime: function () {
      return moment(this.modified).fromNow()
    },
    statusString: function () {
      switch (this.status)
      {
          case Module.TransferStatus.QUEUED.value:
            return this.$t('transfers.queued')
          case Module.TransferStatus.RETRYING.value:
            return this.$t('transfers.retrying')
          case Module.TransferStatus.ACTIVE.value:
            return this.$t('transfers.active')
          case Module.TransferStatus.FINISHED.value:
          {
              if (this.errorCode == Module.ErrorType.E_SUCCESS.value)
              {
                return this.$t('transfers.completed')
              }
              else if (this.errorCode == Module.ErrorType.E_CANCELLED.value)
              {
                return this.$t('transfers.cancelled')
              }
              else
              {
                return this.$t('transfers.failed')
              }
          }
      }

      return "";
    },
    isMobile: function () {
      return window.innerWidth <= 736
    },
    isIos: function() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    setImage: function () {
      if (this.type === 'image' && this.handle !== undefined) {
        var thumbnailName = "///thumbnails/" + this.handle + ".jpg";
        if (!window.gRequestedThumbnails.has(thumbnailName))
        {
          var uri = Module.getObjectURL(thumbnailName);
          if (uri)
          {
            console.log('URI', uri);
            this.image = uri;
          }
          else
          {
            window.gRequestedThumbnails.add(thumbnailName);
            var promise = ibb.downloadMetadata(this.handle, Module.MetadataType.META_THUMBNAIL.value, thumbnailName);
            promise.then(function(action) {
              if (!action.error.type)
              {
                window.gRequestedThumbnails.delete(thumbnailName);
                var uri = Module.getObjectURL(thumbnailName);
                this.image = uri;
              }
            });
          }
        }
        else {
          console.log("Thumbnail already requested");
        }
        this.image = this.image || '/img/icons/mimetype_image.png';
      }
    },
    async download() {
      try {
        await api.getFolderLink(document.location.href)
      } catch (e) {
        console.log(e);
      }
      this.$showSuccess(this.$t('transfers.downloadadded'))
      var file = this.handle + "/" + this.name;
      if (Module.isFileAvailable(file)) {
        Module.downloadFileWithName(file, this.name);
        return;
      }
      var action = await ibbLink.download(this.handle, file);
      if (action.error.type) {
        throw new Error("Error downloading node: " + action.error.type)
      } 
      Module.downloadFileWithName(file, this.name);
    }
  }
}
</script>
