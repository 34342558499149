<template>
  <div class="card floating" id="share">
    <div class="card-title">
      <div class="text">{{ $t('prompts.shareLink') }}
        <p class="archivo">{{ this.fileName }}</p>
      </div>
      <button class="button button-close"
        @click="$store.commit('closeHovers')"
        :aria-label="$t('buttons.close')"
        :title="$t('buttons.close')">
        <img src="/img/icons/other-stuff/lnr-cross.svg">
      </button>
    </div>

    <div class="card-content">
      <ul>
        <li v-if="!hasPermanent">
          <a @click="getPermalink" :aria-label="$t('buttons.permalink')">{{ $t('buttons.permalink') }}</a>
        </li>

        <li v-for="link in links" :key="link.hash" style="display: block;">
          <p v-if="link.expire !== 0">{{ humanTime(link.expire) }}</p>
          <p v-else>{{ $t('permanent') }}</p>
          <div class="link-container">
            <p class="link">{{ link.link }}</p>
            <div class="botones">
              <button class="button button-copy"
              :data-clipboard-text="link.link"
              :aria-label="$t('buttons.copyToClipboard')"
              :title="$t('buttons.copy')">
                <p>{{ $t('buttons.copy') }}</p>
                <link-icon size="1x" class="custom-class"></link-icon>
              </button>
              <a class="button button-link" :href="link.link" 
              target="_blank"
              :title="$t('buttons.link')">
                <p>{{ $t('buttons.link') }}</p>
                <external-link-icon size="1x" class="custom-class"></external-link-icon>
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { share as api } from '@/api'
import { baseURL } from '@/utils/constants'
import moment from 'moment'
import Clipboard from 'clipboard'
import { LinkIcon, ExternalLinkIcon } from 'vue-feather-icons'

export default {
  name: 'share',
  data: function () {
    return {
      time: '',
      unit: 'hours',
      hasPermanent: true,
      links: [],
      clip: null,
      fileName: ''
    }
  },
  components: {
    LinkIcon,
    ExternalLinkIcon
  },
  computed: {
    ...mapState([ 'req', 'selected', 'handleArchivo', 'handleBuzon' ]),
    ...mapGetters([ 'isListing', 'selectedCount' ]),
    url () {
      if (!this.isListing) {
        return this.req.url
      }

      if (this.selectedCount === 0 || this.selectedCount > 1) {
        // This shouldn't happen.
        return
      }

      return this.req.items[this.selected[0]].url
    },
    nodehandle () {
      if (this.handleArchivo !== null) {
        return this.handleArchivo
      }

      if (this.handleBuzon !== null) {
        return this.handleBuzon
      }

      if (this.selected.length === 0) {
        return this.req.handle
      }

      if (this.selectedCount === 0 || this.selectedCount > 1) {
        // This shouldn't happen.
        return
      }

      return this.req.items[this.selected[0]].handle
    }
  },
  async beforeMount () {
    try {
      const links = await api.getId(this.nodehandle)
      this.links = links
      this.fileName = this.links[0].name;
      this.sort()

      for (let link of this.links) {
        if (link.expire === 0) {
          this.hasPermanent = true
          break
        }
      }
    } catch (e) {
      this.$showError(e)
    }
  },
  mounted () {
    this.setIsPrompt(true);
    this.clip = new Clipboard('.button-copy')
    this.clip.on('success', () => {
      this.$showSuccess(this.$t('success.linkCopied'))
    })
  },
  beforeDestroy () {
    this.clip.destroy()
    this.setIsPrompt(false);
    this.setHandleArchivo(null);
  },
  methods: {
    ...mapMutations(['closeHovers', 'setIsPrompt', 'setHandleArchivo']),
    submit: async function () {
      if (!this.time) return

      try {
        const res = await api.create(this.url, this.time, this.unit)
        this.links.push(res)
        this.sort()
      } catch (e) {
        this.$showError(e)
      }
    },
    getPermalink: async function () {
      try {
        const res = await api.create(this.url)
        this.links.push(res)
        this.sort()
        this.hasPermanent = true
      } catch (e) {
        this.$showError(e)
      }
    },
    humanTime (time) {
      return moment(time * 1000).fromNow()
    },
    buildLink (hash) {
      return `${window.location.origin}${baseURL}/share/${hash}`
    },
    sort () {
      this.links = this.links.sort((a, b) => {
        if (a.expire === 0) return -1
        if (b.expire === 0) return 1
        return new Date(a.expire) - new Date(b.expire)
      })
    }
  }
}
</script>

