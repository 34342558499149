<template>
<div>
  <div class="item"
  role="button"
  tabindex="0"
  style="cursor: auto;"
  draggable="true"
  @dragstart="dragStart"
  @touchstart="touchStart"
  @touchend="touchEnd"
  @touchmove="touchMove"
  @dragover="dragOver"
  @drop="drop"
  @click="click"
  @dblclick="dblclick"
  :aria-label="id"
  :aria-selected="isSelected">
    <div class="invoicerow">
      <div class="sessiondate">
        <user-icon size="1.2x" class="custom-class"></user-icon>
        <p>{{ humanTime() }}</p>
      </div>
      <div v-if="!isMobile()" class="sessionapp">
        <compass-icon size="1.2x" class="custom-class"></compass-icon>
        <p v-if="activeSessionHandle == handle">{{ $t('settings.currentSession') }}</p>
        <p v-else>{{ humanUserAgent() }}</p>
      </div>
      <div v-if="!isMobile()" class="sessionip">
        <monitor-icon size="1.2x" class="custom-class"></monitor-icon>
        <p>{{ ip }}</p>
      </div>
      <div v-if="isMobile()" class="column info">
        <button @click="isShow = true" :aria-label="$t('buttons.moreInformation')" :title="$t('buttons.moreInformation')" class="action" id="info-button">
          <info-icon size="1.5x" class="custom-class"></info-icon>
        </button>
        <div class="information" :style="infoStyle">
          <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
            <h3>{{ $t('settings.sessionInformation') }}</h3>
            <x-icon @click="isShow = false" size="1.5x" class="custom-class"></x-icon>
          </div>
          <div class="sessiondate">
            <user-icon size="1.2x" class="custom-class"></user-icon>
            <p>{{ humanTime() }}</p>
          </div>
          <div class="sessionapp">
            <compass-icon size="1.2x" class="custom-class"></compass-icon>
            <p v-if="activeSessionHandle == handle">{{ $t('settings.currentSession') }}</p>
            <p v-else>{{ humanUserAgent() }}</p>
          </div>
          <div class="sessionip">
            <monitor-icon size="1.2x" class="custom-class"></monitor-icon>
            <p>{{ ip }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex'
import filesize from 'filesize'
import moment from 'moment'
import i18n from '@/i18n'
import { UserIcon, CompassIcon, MonitorIcon, XIcon, InfoIcon } from 'vue-feather-icons'

export default {
  name: 'item',
  data: function () {
    return {
      timer: 0,
      isShow: false
    }
  },
  components: {
    UserIcon,
    CompassIcon,
    MonitorIcon,
    InfoIcon,
    XIcon
  },
  props: ['active', 'ctime', 'handle', 'id', 'ip', 'ltime', 'userAgent'],
  computed: {
    ...mapState(['selected', 'req', 'activeSessionHandle']),
    ...mapGetters(['selectedCount']),
    isSelected () {
      return (this.selected.indexOf(this.index) !== -1)
    },
    icon () {
      return 'today'
    },
    canDrop () {
      return false
    },
    infoStyle () {
      if (this.isShow) {
        return {display: 'block'}
      } else {
        return {display: 'none'}
      }
    },
  },
  mounted () {
  },
  methods: {
    ...mapMutations(['addSelected', 'removeSelected', 'resetSelected']),
    isMobile: function () {
      return window.innerWidth <= 450
    },
    humanSize: function () {
      return filesize(this.size)
    },
    humanUserAgent: function () {
      if (!this.userAgent) {
        return i18n.t('settings.unknownUserAgent');
      }

      if (this.userAgent.startsWith('com.iberbox.android')) {
        return i18n.t('settings.androidUserAgent');
      }
      if (this.userAgent.startsWith('com.iberbox.ios')) {
        return i18n.t('settings.iosUserAgent');
      }
      else if(this.userAgent.startsWith('IberboxDesktop')) {
        if (this.userAgent.includes('macOS')) {
          return i18n.t('settings.desktopOnMacOsUserAgent');
        }
        else if (this.userAgent.includes('Windows')) {
          return i18n.t('settings.desktopOnWindowsUserAgent');
        }
        else if (this.userAgent.includes('Linux')) {
          return i18n.t('settings.desktopOnlinuxUserAgent');
        }
        else {
          return i18n.t('settings.desktopUserAgent');
        }
      }
      else if(this.userAgent.includes('Mozilla')) {
        if (this.userAgent.includes('iOS')) {
          return i18n.t('settings.webBrowserOnIOSOsUserAgent');
        }
        if (this.userAgent.includes('Android')) {
          return i18n.t('settings.webBrowserOnAndroidOsUserAgent');
        }
        if (this.userAgent.includes('Macintosh')) {
          return i18n.t('settings.webBrowserOnMacOsUserAgent');
        }
        else if (this.userAgent.includes('Windows')) {
          return i18n.t('settings.webBrowserOnWindowsUserAgent');
        }
        else if (this.userAgent.includes('Linux')) {
          return i18n.t('settings.webBrowserOnlinuxUserAgent');
        }
        else {
          return i18n.t('settings.webBrowserUserAgent');
        }
      }
      else if(this.userAgent.startsWith('IberboxTests')) {
        return "Tests";
      }
      return i18n.t('settings.unknownUserAgent');
    },
    humanTime: function () {
      return moment(this.ltime * 1000).format("DD/MM/YYYY HH:mm")
    },
    humanTotal: function () {
      return "" + (this.id / 100.0).toFixed(2) + " €"
    },
    dragStart: function () {
      /*if (this.selectedCount === 0) {
        this.addSelected(this.index)
        return
      }

      if (!this.isSelected) {
        this.resetSelected()
        this.addSelected(this.index)
      }
      else {
        this.resetSelected()
      }*/
    },
    dragOver: function (event) {
      if (!this.canDrop) return

      event.preventDefault()
      let el = event.target

      for (let i = 0; i < 5; i++) {
        if (!el.classList.contains('item')) {
          el = el.parentElement
        }
      }

      el.style.opacity = 1
    },
    drop: function (event) {
      if (!this.canDrop) return
      event.preventDefault()

      if (this.selectedCount === 0) return

    },
    isIos: function() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    click: function (event) {
      /*if (!this.isMobile()) {
        this.select(event);
        return;
      }

      if (this.selectedCount !== 0) {
        if (this.$store.state.selected.indexOf(this.index) !== -1) {
          this.removeSelected(this.index)
          return
        }

        this.addSelected(this.index)
        return;
      }
      this.open();*/
    },
    dblclick: function (event) {
      if (!this.isMobile()) {
        this.open();
      }
    },
    select: function (event) {
      if (this.selectedCount !== 0) event.preventDefault()
      if (this.$store.state.selected.indexOf(this.index) !== -1) {
        this.removeSelected(this.index)
        return
      }

      if (event.shiftKey && this.selected.length === 1) {
        let fi = 0
        let la = 0

        if (this.index > this.selected[0]) {
          fi = this.selected[0] + 1
          la = this.index
        } else {
          fi = this.index
          la = this.selected[0] - 1
        }

        for (; fi <= la; fi++) {
          this.addSelected(fi)
        }

        return
      }

      //if (!event.ctrlKey && !this.$store.state.multiple) this.resetSelected()
      if (!event.ctrlKey) this.resetSelected()
      this.addSelected(this.index)
    },
    touchStart: function (event) {
      if (!this.isIos()) {
        return;
      }

      this.timer = setTimeout(() => {
        this.timer = 0;
        this.dragStart();
      }, 600)
    },
    touchEnd: function (event) {
      if (!this.isIos()) {
        return;
      }

      if (this.timer) {
        clearTimeout(this.timer);
	this.timer = 0;
      }
      else {
        event.preventDefault();
      }
    },
    touchMove: function (event) {
      if (!this.isIos()) {
        return;
      }

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = 0;
      }
    },
    open: function () { 
      this.$router.push({path: this.url})
    }
  }
}
</script>
