<template>
  <div id="register" :class="{ recaptcha: recaptcha }">
    <vue-headful title="Regístrate en Iberbox" description="Crea una cuenta de Iberbox y disfruta del almacenamiento seguro más avanzado para profesionales y empresas." keywords="iberbox, copias de seguridad, cifrado punto a punto, nube segura" image="https://iberbox.com/img/logo.svg" />
    <div class="contenedor-body">
      <div class="contenedor-login visible-xs">
        <form @submit="submit" autocomplete="off">
          <img src="/img/icons/Login/ibbLogo.svg">
          <h1>{{ $t('buttons.registry') }}</h1>

          <div v-if="createMode == 1" :class="[isFocusName ? 'campo-activo' : 'campo']">
            <user-icon size="1.5x" class="custom-class"></user-icon>
            <input class="input input--block" type="text" id="firstnameInput" v-model="firstname" :placeholder="$t('login.firstname')" @focus="focusNameOn" @blur="focusNameOff">
          </div>
          <div v-if="createMode == 1" :class="[isFocusSurname ? 'campo-activo' : 'campo']">
            <user-icon size="1.5x" class="custom-class"></user-icon>
            <input class="input input--block" type="text" id="lastnameInput" v-model="lastname" :placeholder="$t('login.lastname')" @focus="focusSurnameOn" @blur="focusSurnameOff">
          </div>
          <div v-if="createMode == 1" :class="[isFocusEmail ? 'campo-activo' : 'campo']">
            <mail-icon size="1.5x" class="custom-class"></mail-icon>
            <input class="input input--block" type="email" id="usernameInput" v-model="username" :placeholder="$t('login.username')" :disabled="emailDisabled" @focus="focusEmailOn" @blur="focusEmailOff">
          </div>

          <div v-if="createMode == 1" class="terms">
            <input class="input" type="checkbox" id="checkbox" v-model="checked">
            <label for="checkbox" v-html=" $t('login.termsOfService') "></label>
          </div>

          <div class="email" v-if="createMode == 2">
            <p>{{ username }}</p>
            <p v-if="this.accountHandleJoin === null && this.accountKeyJoin === null && this.registrationId === null" @click="changeEmail" class="change-email">{{ $t('login.changeEmail') }}</p>
          </div>
          
          <div class="pin" v-if="createMode == 2">{{ $t('login.pin') }}</div>
          <v-otp-input v-if="createMode == 2" ref="otpInput" class="otp" input-classes="otp-input" separator=" " :num-inputs="6" :should-auto-focus="true" :is-input-num="true" @on-complete="handleOnComplete"/>
          
          <input class="input input--block" v-if="createMode == 2" type="email" id="username" v-model="username" style="display: none;" autocomplete="off">
          <div v-if="createMode == 2" :class="[isFocusPassword ? 'campo-activo' : 'campo']">
            <user-icon size="1.5x" class="custom-class"></user-icon>
            <password id="passwordInput" v-model="password" @score="changeScore" :placeholder="$t('login.password')" :badge="false" :showStrengthMeter="false" :toggle="true" :labelHide="$t('login.hidePassword')" :labelShow="$t('login.showPassword')" @focus="focusPasswordOn" @blur="focusPasswordOff"/>
          </div>
          
          <div v-if="createMode == 2" class="scorebar">
            <div class="scorebar-value" :style="scoreBarStyles"></div>
          </div>
          
          <div v-if="createMode == 2" :class="[isFocusPasswordConfirm ? 'campo-activo' : 'campo']">
            <user-icon size="1.5x" class="custom-class"></user-icon>
            <input class="input input--block" type="password" id="passwordConfirmInput" v-model="passwordConfirm" :placeholder="$t('login.passwordConfirm')" autocomplete="new-password" @focus="focusPasswordConfirmOn" @blur="focusPasswordConfirmOff"/>
          </div>

          <div v-if="createMode == 2" :class="[isFocusCode ? 'campo-activo' : 'campo']">
            <percent-icon size="1.5x" class="custom-class"></percent-icon>
            <input class="input input--block" v-if="!this.$route.query.coupon && this.accountHandleJoin === null && this.accountKeyJoin === null && this.registrationId === null" type="text" id="referrerCode" v-model="referrerCodeTemp" :placeholder="$t('login.referrerCode')" maxlength="29" @focus="focusCodeOn" @blur="focusCodeOff"/>
            <input class="input input--block" v-if="this.$route.query.coupon" type="text" id="referrerCode" v-model="referrerCodeTemp" :placeholder="$t('login.referrerCode')" maxlength="29" readonly="readonly" @focus="focusCodeOn" @blur="focusCodeOff"/>
          </div>

          <div v-if="createMode == 2" style="position: relative;">
            <div class="campo" style="border-radius: 10px; justify-content: space-between;">
              <div style="display: flex; align-items: center;">
                <p>{{ tarifaSeleccionada.name }}</p>
                <span>{{ $t('login.since') }} {{ (1.21 * tarifaSeleccionada.amount / 12).toFixed(2) }} {{ $t('login.€/month') }}</span>
              </div>
              <chevron-down-icon size="1.5x" class="chevron" @click="isShow = !isShow"></chevron-down-icon>
            </div>
            <nav id="menu-desplegable" :style="menuStyle">
              <div style="display: block; border: none;">
                <button v-for="(tarifa) in tarifas" :key="tarifa.id" :title="tarifa.name" :aria-label="tarifa.name" @click="cambioTarifa(tarifa)" type="button">
                  <div style="display: flex; align-items: center; justify-content: space-between;">
                    <p>{{ tarifa.name }}</p>
                    <span>{{ $t('login.since') }} {{ (1.21 * tarifa.amount / 12).toFixed(2) }} {{ $t('login.€/month') }}</span>
                  </div>
                </button>
              </div>
            </nav>
          </div>
          
          <div v-if="recaptcha" id="recaptcha"></div>
          <input class="button button--block" id="registerButton" type="submit" :value="createMode == 1 ? $t('login.verify') : $t('pricing.continue')">
        </form>
        <div class="mensaje">
          <template v-if="error !== ''">
            <div class="wrong">
              <alert-circle-icon size="1.5x" class="custom-class"></alert-circle-icon>
              <p v-html="error"></p>
            </div>
          </template>
        </div>
      </div>
      <div class="contenedor-publicidad">
        <div class="texto">
          <div>
            <h3>{{ $t('login.yourData') }}</h3>
            <h2>{{ $t('login.protected') }}.</h2>
          </div>
          <img src="/img/icons/Login/ibbLogoWhiteV.svg">
        </div>
        <p>{{ $t('login.haveAccount') }}</p>
        <button :aria-label="$t('login.submit')" :title="$t('login.submit')" @click="toggleMode" v-if="signup">
          <span>{{ $t('login.submit') }}</span>
        </button>
        <div class="contenedor-imagen">
          <img src="/img/icons/Login/ibbBmail.png" class="desktop">
        </div>
      </div>
      <div class="contenedor-login hidden-xs">
        <form @submit="submit" autocomplete="off">
          <h1>{{ $t('buttons.registry') }}</h1>

          <div v-if="createMode == 1" :class="[isFocusName ? 'campo-activo' : 'campo']">
            <user-icon size="1.5x" class="custom-class"></user-icon>
            <input class="input input--block" type="text" id="firstnameInput" v-model="firstname" :placeholder="$t('login.firstname')" @focus="focusNameOn" @blur="focusNameOff">
          </div>
          <div v-if="createMode == 1" :class="[isFocusSurname ? 'campo-activo' : 'campo']">
            <user-icon size="1.5x" class="custom-class"></user-icon>
            <input class="input input--block" type="text" id="lastnameInput" v-model="lastname" :placeholder="$t('login.lastname')" @focus="focusSurnameOn" @blur="focusSurnameOff">
          </div>
          <div v-if="createMode == 1" :class="[isFocusEmail ? 'campo-activo' : 'campo']">
            <mail-icon size="1.5x" class="custom-class"></mail-icon>
            <input class="input input--block" type="email" id="usernameInput" v-model="username" :placeholder="$t('login.username')" :disabled="emailDisabled" @focus="focusEmailOn" @blur="focusEmailOff">
          </div>

          <div v-if="createMode == 1" class="terms">
            <input class="input" type="checkbox" id="checkbox" v-model="checked">
            <label for="checkbox" v-html=" $t('login.termsOfService') "></label>
          </div>

          <div class="email" v-if="createMode == 2">
            <p>{{ username }}</p>
            <p v-if="this.accountHandleJoin === null && this.accountKeyJoin === null && this.registrationId === null" @click="changeEmail" class="change-email">{{ $t('login.changeEmail') }}</p>
          </div>
          
          <div class="pin" v-if="createMode == 2">{{ $t('login.pin') }}</div>
          <v-otp-input v-if="createMode == 2" ref="otpInput" class="otp" input-classes="otp-input" separator=" " :num-inputs="6" :should-auto-focus="true" :is-input-num="true" @on-complete="handleOnComplete"/>
          
          <input class="input input--block" v-if="createMode == 2" type="email" id="username" v-model="username" style="display: none;" autocomplete="off">
          <div v-if="createMode == 2" :class="[isFocusPassword ? 'campo-activo' : 'campo']">
            <user-icon size="1.5x" class="custom-class"></user-icon>
            <password id="passwordInput" v-model="password" @score="changeScore" :placeholder="$t('login.password')" :badge="false" :showStrengthMeter="false" :toggle="true" :labelHide="$t('login.hidePassword')" :labelShow="$t('login.showPassword')" @focus="focusPasswordOn" @blur="focusPasswordOff"/>
          </div>
          
          <div v-if="createMode == 2" class="scorebar">
            <div class="scorebar-value" :style="scoreBarStyles"></div>
          </div>
          
          <div v-if="createMode == 2" :class="[isFocusPasswordConfirm ? 'campo-activo' : 'campo']">
            <user-icon size="1.5x" class="custom-class"></user-icon>
            <input class="input input--block" type="password" id="passwordConfirmInput" v-model="passwordConfirm" :placeholder="$t('login.passwordConfirm')" autocomplete="new-password" @focus="focusPasswordConfirmOn" @blur="focusPasswordConfirmOff"/>
          </div>

          <div v-if="createMode == 2" :class="[isFocusCode ? 'campo-activo' : 'campo']">
            <percent-icon size="1.5x" class="custom-class"></percent-icon>
            <input class="input input--block" v-if="!this.$route.query.coupon && this.accountHandleJoin === null && this.accountKeyJoin === null && this.registrationId === null" type="text" id="referrerCode" v-model="referrerCodeTemp" :placeholder="$t('login.referrerCode')" maxlength="29" @focus="focusCodeOn" @blur="focusCodeOff"/>
            <input class="input input--block" v-if="this.$route.query.coupon" type="text" id="referrerCode" v-model="referrerCodeTemp" :placeholder="$t('login.referrerCode')" maxlength="29" readonly="readonly" @focus="focusCodeOn" @blur="focusCodeOff"/>
          </div>

          <div v-if="createMode == 2" style="position: relative;">
            <div class="campo" style="border-radius: 10px; justify-content: space-between;">
              <div style="display: flex; align-items: center;">
                <p>{{ tarifaSeleccionada.name }}</p>
                <span>{{ $t('login.since') }} {{ (1.21 * tarifaSeleccionada.amount / 12).toFixed(2) }} {{ $t('login.€/month') }}</span>
              </div>
              <chevron-down-icon size="1.5x" class="chevron" @click="isShow = !isShow"></chevron-down-icon>
            </div>
            <nav id="menu-desplegable" :style="menuStyle">
              <div style="display: block; border: none;">
                <button v-for="(tarifa) in tarifas" :key="tarifa.id" :title="tarifa.name" :aria-label="tarifa.name" @click="cambioTarifa(tarifa)" type="button">
                  <div style="display: flex; align-items: center; justify-content: space-between;">
                    <p>{{ tarifa.name }}</p>
                    <span>{{ $t('login.since') }} {{ (1.21 * tarifa.amount / 12).toFixed(2) }} {{ $t('login.€/month') }}</span>
                  </div>
                </button>
              </div>
            </nav>
          </div>
          
          <div v-if="recaptcha" id="recaptcha"></div>
          <input class="button button--block" id="registerButton" type="submit" :value="createMode == 1 ? $t('login.verify') : $t('pricing.continue')">
        </form>
        <div class="mensaje">
          <template v-if="error !== ''">
            <div class="wrong">
              <alert-circle-icon size="1.5x" class="custom-class"></alert-circle-icon>
              <p v-html="error"></p>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script async src="https://www.googletagmanager.com/gtag/js?id=AW-369408594"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'AW-369408594');
</script>

<script>
import * as auth from '@/utils/auth'
import { name, logoURL, recaptcha, recaptchaKey, signup } from '@/utils/constants'
import OtpInput from "@bachdgvn/vue-otp-input";
import Password from 'vue-password-strength-meter'
import vueHeadful from 'vue-headful';
import { mapState } from 'vuex'
import { UserIcon, PercentIcon, AlertCircleIcon, MailIcon, ChevronDownIcon } from 'vue-feather-icons'
import { files as api } from '@/api'

export default {
  name: 'register',
  computed: {
    ...mapState([ 'accountHandleJoin', 'accountKeyJoin', 'registrationId' ]),
    signup: () => signup,
    name: () => name,
    logoURL: () => logoURL,
    scoreBarStyles() {
      var widthBar;
      var background;
      if (this.passwordScore === 0) {
        widthBar = ((this.passwordScore+1)/5)*100 + '%';
      } else if (this.passwordScore === 1) {
        widthBar = ((this.passwordScore+1)/5)*100 + '%';
      } else if (this.passwordScore === 2) {
        widthBar = ((this.passwordScore+1)/5)*100 + '%';
      } else if (this.passwordScore === 3) {
        widthBar = ((this.passwordScore+1)/5)*100 + '%';
      } else if (this.passwordScore === 4) {
        widthBar = ((this.passwordScore+1)/5)*100 + '%';
      } else {
        widthBar = '0%'
      }
      return{width: widthBar, backgroundColor: background};
    },
    menuStyle () {
      if (this.isShow) {
        return {display: 'block'}
      } else {
        return {display: 'none'}
      }
    },
  },
  data: function () {
    return {
      createMode: 1,
      error: '',
      firstname: "",
      lastname: "",
      username: '',
      password: '',
      passwordScore: -1,
      recaptcha: recaptcha,
      passwordConfirm: '',
      referrerCodeTemp: '',
      pin: '',
      id: '',
      checked: false,
      emailDisabled: false,
      isFocusName: false,
      isFocusSurname: false,
      isFocusEmail: false,
      isFocusPassword: false,
      isFocusPasswordConfirm: false,
      isFocusCode: false,
      tarifas: [],
      features: 1,
      tarifaSeleccionada: '',
      precios: [],
      isShow: false
    }
  },
  components: {
    Password,
    'v-otp-input': OtpInput,
    'vue-headful': vueHeadful,
    UserIcon,
    PercentIcon,
    AlertCircleIcon,
    MailIcon,
    ChevronDownIcon
  },
  async mounted () {
    try {
      var action = await api.getSubscriptionOptionsWithFeatures(this.features)
      this.tarifas = action
            .filter(({period, features}) => period == 2 && features == this.features)
            .map(t => ({...t, amount: t.amount / 100.0}))
            .sort((a, b) => a.storage - b.storage);
      this.tarifaSeleccionada = this.tarifas[1];
    } catch (e) {
      this.$showError(e)
    }

    if (this.$route.query.email) {
      this.username = Base64.decode(this.$route.query.email);
      this.emailDisabled = true;
    }

    if (this.$route.query.firstname) {
      this.firstname = Base64.decode(this.$route.query.firstname);
    }

    if (this.$route.query.lastname) {
      this.lastname = Base64.decode(this.$route.query.lastname);
    }

    if (this.$route.query.id && this.firstname.length != 0 && this.lastname.length != 0) {
      this.id = this.$route.query.id;
      this.createMode = 2;
      setTimeout(function()
      { 
        document.getElementById('passwordInput').autocomplete = "new-password";
        document.getElementsByClassName("Password__toggle")[0].getElementsByTagName("button")[0].tabIndex=-1
      }, 0);
    }

    if (this.$route.query.pin) {
      this.pin = this.$route.query.pin;
    }

    if (this.$route.query.coupon) { 
      this.referrerCodeTemp = Base64.decode(this.$route.query.coupon);
    }

    if (!recaptcha) return

    window.grecaptcha.render('recaptcha', {
      sitekey: recaptchaKey
    })
  },
  methods: {
    cambioTarifa (tarifa) {
      this.tarifaSeleccionada = tarifa;
      this.isShow = false;
    },
    focusNameOn () {
      this.isFocusName = true;
    },
    focusNameOff () {
      this.isFocusName = false;
    },
    focusSurnameOn () {
      this.isFocusSurname = true;
    },
    focusSurnameOff () {
      this.isFocusSurname = false;
    },
    focusEmailOn () {
      this.isFocusEmail = true;
    },
    focusEmailOff () {
      this.isFocusEmail = false;
    },
    focusPasswordOn () {
      this.isFocusPassword = true;
    },
    focusPasswordOff () {
      this.isFocusPassword = false;
    },
    focusPasswordConfirmOn () {
      this.isFocusPasswordConfirm = true;
    },
    focusPasswordConfirmOff () {
      this.isFocusPasswordConfirm = false;
    },
    focusCodeOn () {
      this.isFocusCode = true;
    },
    focusCodeOff () {
      this.isFocusCode = false;
    },
    handleOnComplete: async function (value) {
      this.pin = parseInt(value);
      setTimeout(function(){ document.getElementById('passwordInput').focus(); }, 0);
    },
    toggleMode () {      
      this.username = '';
      this.password = '';
      this.passwordConfirm = '';
      this.passwordScore = -1;
      this.pin = '';
      this.id = '';
      this.error = '';
      this.referrerCodeTemp = '';
      
      this.createMode = 1;
      this.$router.push({ path: '/login' });
    },
    changeEmail () {
      this.username = '';
      this.password = '';
      this.passwordConfirm = '';
      this.passwordScore = -1;
      this.pin = '';
      this.id = '';
      this.error = '';
      this.referrerCodeTemp = '';
      
      this.createMode = 1;
      this.$router.push({ path: '/register' });
    },
    changeScore (score) {
      this.passwordScore = score;
    },
    async submit (event) {
      event.preventDefault()
      event.stopPropagation()

      var button = document.getElementById('registerButton');
      if (button.disabled) {
          return;
      }

      let redirect = this.$route.query.redirect
      if (redirect === '' || redirect === undefined || redirect === null) {
        redirect = '/profile/invoicing/plans'
      }

      let captcha = ''
      if (recaptcha) {
        captcha = window.grecaptcha.getResponse()

        if (captcha === '') {
          this.error = this.$t('login.wrongCredentials')
          return
        }
      }

      if (this.createMode == 1) {
          if (this.firstname.length == 0) {
            this.error = this.$t('login.enterFirstname');
            return;
          }
          if (this.lastname.length == 0) {
            this.error = this.$t('login.enterLastname');
            return;
          }
          if (!this.username || this.username.length == 0) {
            this.error = this.$t('login.enterEmail');
            return;
          }
          if (!this.checked) {
            this.error = this.$t('login.acceptTerms');
            return;
          }

          try {
            button.disabled = true;
            if (this.accountHandleJoin !== null && this.accountKeyJoin !== null && this.registrationId !== null) { 
              this.id = this.registrationId;
            } else {
              this.id = await auth.validate(this.username, this.firstname, this.lastname);
            }
            button.disabled = false;
            this.createMode = 2;
            this.error = '';

            if (this.referrerCodeTemp !== '') { 
              this.$router.replace({ path: '/register', query: { email: Base64.encodeURI(this.username), firstname: Base64.encodeURI(this.firstname), lastname: Base64.encodeURI(this.lastname), id: this.id, coupon: Base64.encodeURI(this.referrerCodeTemp) } });
            } else {
              this.$router.replace({ path: '/register', query: { email: Base64.encodeURI(this.username), firstname: Base64.encodeURI(this.firstname), lastname: Base64.encodeURI(this.lastname), id: this.id } });
            }
            setTimeout(function()
              { 
                  document.getElementById('passwordInput').autocomplete = "new-password";
                  document.getElementsByClassName("Password__toggle")[0].getElementsByTagName("button")[0].tabIndex=-1
              }, 0);
          } catch (e) {
            if (parseInt(e) == Module.ErrorType.E_DUPLICATE.value)
            {
                this.error = this.$t('login.alreadyExists');
            }
            else
            {
                this.error = this.$t('login.notInBeta');
            }
            button.disabled = false;
          }
          return;
      }

      if (this.firstname.length == 0) {
        this.error = this.$t('login.enterFirstname');
        return;
      }
      if (this.lastname.length == 0) {
        this.error = this.$t('login.enterLastname');
        return;
      }
      if (this.pin.length == 0) {
        this.error = this.$t('login.enterPin');
        return;
      }
      if (this.password.length == 0) {
        this.error = this.$t('login.enterPassword');
        return;
      }
      if (this.password !== this.passwordConfirm) {
        this.error = this.$t('login.passwordsDontMatch')
        return
      }
      if (this.tarifaSeleccionada == '') {
        this.error = this.$t('login.noSelectRate'); 
        return;
      }

      try {
        button.disabled = true;
        if (this.accountHandleJoin !== null && this.accountKeyJoin !== null && this.registrationId !== null) {
          await auth.joinAccount (this.accountHandleJoin, this.accountKeyJoin, this.registrationId, this.firstname, this.lastname, this.username, this.password, this.pin)
        } else {
          await auth.signup(this.id, this.username, this.password, this.pin, this.firstname, this.lastname, this.referrerCodeTemp.trim(), this.tarifaSeleccionada.id);
        }
        button.disabled = false;
        this.$router.push({ path: '/profile/invoicing/plans' })
      } catch (e) {
        if (e == Module.ErrorType.E_INVOICING.value)
        {
          this.$router.push({ path: '/profile/invoicing/plans' })
          return;
        }
        if (e == Module.ErrorType.E_COUPON.value) {
          this.error = this.$t('login.wrongReferrerCode');
          button.disabled = false;
          return;
        }
        this.error = this.$t('login.wrongPin')
        button.disabled = false;

        var thisComponent = this;
        setTimeout(function(){ thisComponent.error = '' }, 5000);
      }
    }
  }
}
</script>
