<template>
  <div class="card floating">
    <div v-if="isLoading" style="text-align:center;">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-else>
      <div class="card-content">
        <p>{{ $t('prompts.logout') }}</p>
      </div>
      <div class="card-action">
        <button @click="cancel" id ="buttonCancelDelete"
          class="button button--flat button--grey"
          :aria-label="$t('buttons.cancel')"
          :title="$t('buttons.cancel')">{{ $t('buttons.cancel') }}</button>
        <button @click="cambiarCuenta"
          class="button button--flat button--red" id ="buttonDelete"
          :aria-label="$t('buttons.changeAccount')"
          :title="$t('buttons.changeAccount')">{{ $t('buttons.changeAccount') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import * as auth from '@/utils/auth'

export default {
  name: 'logout',
  data: function () {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapState([ 'accountHandle' ])
  },
  methods: {
    ...mapMutations(['closeHovers', 'setAccountHandle']),
    async cambiarCuenta() {
      this.isLoading = true;
      await auth.getSessionForAccount(this.accountHandle);
      this.isLoading = false;
      this.$showSuccess(this.$t('settings.accountSuccessfullyChanged'))
      this.$store.commit('closeHovers')
      window.history.go('/received');
    },
    cancel () {
      this.$store.commit('setAccountHandle', null)
      this.$store.commit('closeHovers')
    }
  },
  beforeDestroy () {
    this.closeHovers()
  }
}
</script>