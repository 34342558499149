<template>
  <div id="contacts">
    <editor v-if="isEditor"></editor>
    <div v-else-if="$route.hash === '' || (isMobile && record !== '' && $route.hash !== '')" class="contenedor-global">
      <div v-if="!isMobile" class="container-listing" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
        <div class="title">
          <div class="buscador">
            <input class="input" type="text" :placeholder="$t('buttons.search')" v-model="buscador"> 
          </div>
          <div class="botones">
            <button v-if="this.permissions === 0" :aria-label="$t('bmail.write')" :title="$t('bmail.write')" class="action" id="new-inbox-button" @click="write">
              <p>{{ $t('bmail.write') }}</p>
              <edit-3-icon size="1.5x" class="custom-class"></edit-3-icon>
            </button>
          </div>
        </div>

        <div style="display: flex; align-items: center; padding: 0 20px; margin-bottom: 15px; margin-top: 20px;">
          <div :class="[checked ? 'checkbox-activo' : 'checkbox']">
            <input type="checkbox" id="checkbox" v-model="checked">
            <label v-if="checked === false" for="checkbox">{{ $t('bmail.markAll') }}</label>
            <label v-else for="checkbox">{{ $t('bmail.uncheck') }}</label>
          </div>

          <delete-button v-if="recordSelected.length > 0 && this.permissions === 0"></delete-button>
        </div>
        <div class="contenedor-listing" style="overflow-y: scroll; height: 80vh; overflow-x: hidden">
          <div style="height: auto;">
            <record-sent v-for="(record) in records"
            :key="record.handle"
            v-bind:name="record.name"
            v-bind:contact="record.contact"
            v-bind:message="record.message"
            v-bind:handle="record.handle"
            v-bind:parentHandle="record.parentHandle"
            v-bind:ctime="record.ctime"
            v-bind:index="records.indexOf(record)"
            v-bind:status="record.status"
            v-bind:isContainerRecord="isContainerRecord"
            v-bind:buscador="buscador"
            v-bind:showUnread="true"
            v-bind:checkedAll="checked"
            @open-record="openRecord"
            ></record-sent>
          </div>
        </div>
      </div>
      <div v-else class="container-listing" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
        <div v-if="records.length === 0 && record === ''" class="container-image">
          <div>
            <img src="/img/icons/Bmail Resources/Illustrations/ibb-nothing.png">
            <h2>{{ $t('records.nothingToSee') }}</h2>
            <h3>{{ $t('records.recordSentDisplayed') }}</h3>
          </div>
        </div>
        <div v-else-if="records.length > 0 && record === ''">
          <div class="title">
            <div class="buscador">
              <input class="input" type="text" :placeholder="$t('buttons.search')" v-model="buscador"> 
            </div>
            <div class="botones">
              <button v-if="this.permissions === 0" :aria-label="$t('bmail.write')" :title="$t('bmail.write')" class="action" id="new-inbox-button" @click="write">
                <p>{{ $t('bmail.write') }}</p>
                <edit-3-icon size="1.5x" class="custom-class"></edit-3-icon>
              </button>
            </div>
          </div>

          <div style="display: flex; align-items: center; padding: 0 20px; margin-bottom: 15px; margin-top: 20px;">
            <div :class="[checked ? 'checkbox-activo' : 'checkbox']">
              <input type="checkbox" id="checkbox" v-model="checked">
              <label v-if="checked === false" for="checkbox">{{ $t('bmail.markAll') }}</label>
              <label v-else for="checkbox">{{ $t('bmail.uncheck') }}</label>
            </div>

            <delete-button v-if="recordSelected.length > 0 && this.permissions === 0"></delete-button>
          </div>
          <div class="contenedor-listing">
            <div style="height: auto;">
              <record-sent v-for="(record) in records"
              :key="record.handle"
              v-bind:name="record.name"
              v-bind:contact="record.contact"
              v-bind:message="record.message"
              v-bind:handle="record.handle"
              v-bind:parentHandle="record.parentHandle"
              v-bind:ctime="record.ctime"
              v-bind:index="records.indexOf(record)"
              v-bind:status="record.status"
              v-bind:isContainerRecord="isContainerRecord"
              v-bind:buscador="buscador"
              v-bind:showUnread="true"
              v-bind:checkedAll="checked"
              @open-record="openRecord"
              ></record-sent>
            </div>
          </div>
        </div>
        <open-record v-else-if="record !== ''" @back="back"></open-record>
      </div>
      <div class="container-records">
        <div v-if="records.length === 0" class="container-image">
          <div>
            <img src="/img/icons/Bmail Resources/Illustrations/ibb-nothing.png">
            <h2>{{ $t('records.nothingToSee') }}</h2>
            <h3>{{ $t('records.recordSentDisplayed') }}</h3>
          </div>
        </div>
        <div v-else-if="selectedCount === 0" class="container-image">
          <div>
            <img src="/img/icons/Bmail Resources/Illustrations/img-envelope.png">
            <h2>{{ $t('records.everythingPlace') }}</h2>
            <h3>{{ $t('bmail.messageContent') }}</h3>
          </div>
        </div>
        <div v-else-if="record !== ''" class="scroll-record">
          <div style="height: auto;">
            <open-record></open-record>
          </div>
        </div>
      </div>
    </div>
    <preview v-else-if="isPreview" :key="$route.hash"></preview>
  </div>
</template>

<script>
import { Edit3Icon } from 'vue-feather-icons'
import { mapGetters, mapMutations, mapState } from 'vuex'
import DeleteButton from '@/components/buttons/Delete'
import RecordSent from '@/components/RecordSent'
import OpenRecord from '@/components/OpenRecord'
import Preview from '@/components/files/Preview'
import Editor from '@/components/files/Editor'

export default {
  name: 'files',
  components: {
    Edit3Icon,
    DeleteButton,
    RecordSent,
    OpenRecord,
    Preview,
    Editor
  },
  computed: {
    ...mapGetters([
      'selectedCount',
      'isListing',
      'isFiles',
      'isEditor'
    ]),
    ...mapState([
      'multiple',
      'reload',
      'record',
      'recordSelected',
      'loading',
      'permissions'
    ]),
    isPreview () {
      return !this.loading && !this.isEditor && this.flag
    },
    isMobile () {
      return window.innerWidth <= 768
    },
  },
  data: function () {
    return {
      isContainerRecord: false,
      buscador: '',
      checked: false,
      records: [],
      flag: true,
    }
  },
  watch: {
    '$route': 'reviewRecord',
  },
  created () {
    var thisComponent = this;
    var JavaEventListenerClass = Module.EventListener.extend("EventListener", {
      onEvent: function(event)
      {
        if ((event.type == Module.EventType.NEW_NODES.value && event.node.type == Module.NodeType.RECORD.value) || (event.type == Module.EventType.DELETED_NODE.value && event.node.type == Module.NodeType.RECORD.value))
        {
          thisComponent.resetSelected()
          thisComponent.fetchData()
        }
        return;
      }
    });

    this.fetchData()
    if (this.isMobile) {
      this.reviewRecord()
    }
    ibb.addEventListener(new JavaEventListenerClass());
  },
  beforeDestroy () {
    ibb.removeEventListener(this.interfaceObject);
  },
  destroyed () {
    this.$store.commit('updateRequest', {})
  },
  methods: {
    ...mapMutations([ 'setLoading', 'resetSelected', 'setRecord', 'addSelected' ]),
    async fetchData () {
      // Reset view information.
      this.$store.commit('setReload', false)
      //this.$store.commit('resetSelected')
      this.$store.commit('multiple', false)
      this.$store.commit('closeHovers')

      // Set loading to true and reset the error.
      this.setLoading(true)

      var action = await ibb.getRootNode(Module.NodeType.CONTACT_ROOT.value);
      if (action.error.type) {
        throw new Error("Error getting root node: " +  action.error.type)
      }
      this.records = []
      const {nodeList} = await ibb.getChildren(action.node.handle);
      for (let contact of nodeList) {
        action = await ibb.getChildren(contact.handle);
        const outboxList = action.nodeList
        for (let outbox of outboxList) {
          action = await ibb.getSortedChildren(outbox.handle, -Module.SortOrderType.SORT_ORDER_MODIFICATION_TIME.value);
          for (let record of action.nodeList) {
            if (!this.records.includes(record)) {
              this.records.push(record)
            }
          }
        }
      }

      this.records.sort((a, b) => {
        if (a.ctime === b.ctime) {
          return 0;
        } 
        if (a.ctime > b.ctime) {
          return -1;
        }
        return 1;
      });

      this.$store.commit('updateRequest', this.records)
      this.setLoading(false)
    },
    async reviewRecord () {
      if (this.$route.hash === '' && this.record !== '') {
        var index = -1
        for (let item of this.records) {
          if (item.handle === this.record.handle) {
            index = this.records.indexOf(item)
          }
        }
        if (index !== -1) {
          this.addSelected(index);
        }
      } else if (this.$route.hash !== '' && this.isMobile) {
        this.flag = false;
        var node = await ibb.getNodeById(this.$route.hash.replace('#', '')) 
        if (node.node.type === Module.NodeType.RECORD.value) {
          this.setRecord(node.node)
        } else {
          this.setRecord('')
        }
        this.flag = true;
      }
    },
    back() {
      this.$router.push({ path: this.$route.path })
      this.setRecord('')
    },
    mouseEnter() {
      this.isContainerRecord = false;
    },
    mouseLeave() {
      this.isContainerRecord = true;
    },
    write() {
      this.$store.commit('showHover', 'write')
    },
    async openRecord(handle) {
      this.setRecord('')
      var action = await ibb.getNodeById(handle);
      this.setRecord(action.node)
    },
  }
}
</script>