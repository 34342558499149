import { fetchURL, fetchJSON, removePrefix } from './utils'

import moment from 'moment'

export async function getInboxInfo(inboxLink) {
  console.log("Getting inbox link info: " + inboxLink);
  var action = await ibb.getInboxInfo(inboxLink);
  if (action.error.type) {
    throw new Error("Error getting inbox link info: " + action.error.type)
  }

  return action.node
}

export async function getHash(hash) {

  console.log("Getting link info: " + hash);
  var action = await ibb.getLinkInfo(hash);
  if (action.error.type) {
    throw new Error("Error getting link info: " + action.error.type)
  }

  var re = /(?:\.([^.]+))?$/;
  var extension = re.exec(action.node.name)[1] || "";
  extension = extension.toLowerCase();

  var type = "";
  if (extension == "jpg" || extension == "jpeg" || extension == "png" || extension == "bmp" || extension == "heic" || extension == "heif") {
    type = "image";
  }   
  else if (extension == "mp3" || extension == "wav" || extension == "flac") {
    type = "audio";
  }
  else if (extension == "avi" || extension == "mp4" || extension == "mkv") {
    type = "video";
  }
  else if (extension == "pdf") {
    type = "pdf";
  }
  else if (extension == "stl") {
      type = "stl";
  }
  else if (extension == "zip") {
    type = "zip";
}

  var ctime = action.node.ctime || (moment().valueOf() / 1000);
  var response = '{"path":"/' + action.node.name + '",'
              + '"name":"' + action.node.name + '",'
              + '"size":' + action.node.size + ','
              + '"extension":"' + extension + '",'
              + '"modified":"' + moment(ctime * 1000).format() + '",'
              + '"mode":420,"isDir":false,"type":"' + type + '",'
              + '"metadataInfo":"' + action.node.metadataInfo + '"}';

  console.log("Response: " + response);
  return JSON.parse(response); 

  //return fetchJSON(`/api/public/share/${hash}`)
}

export async function getFolderLink(link) {

  console.log("Getting folder link info: " + link);
  var action = await ibbLink.loginLink(link);
  if (action.error.type) {
    throw new Error("Error logging to folder link: " + action.error.type)
  }

  action = await ibbLink.getNodes();
  if (action.error.type) {
    throw new Error("Error getting nodes from folder link: " + action.error.type)
  }

  var ctime = action.node.ctime || (moment().valueOf() / 1000);
  var response = '{"path":"/' + action.node.name + '",'
              + '"name":"' + action.node.name + '",'
              + '"size":' + action.node.size + ','
              + '"handle":"' + action.node.handle + '",'              
              + '"modified":"' + moment(ctime * 1000).format() + '",'
              + '"mode":420,"isDir":true,"type":"folder",'
              + '"metadataInfo":"' + action.node.metadataInfo + '"}';

  return JSON.parse(response); 
}

export async function getId(handle) {
  console.log("Creating link for handle: " + handle);
  var action = await ibb.createLink(handle);

  var response = '[{"hash":"' + action.text 
  + '","link":"' + action.link 
  + '","name":"' + action.node.name
  + '","expire":0}]'; 

  return JSON.parse(response);
}

export async function get(url) {
  url = removePrefix(url)
  var path = decodeURIComponent(url);

  console.log("Creating link for path: " + path);

  var action = await ibb.getNodeByPath(path);
  if (action.error.type) {
    throw new Error("Error getting node by path: " + action.error.type)
  }

  action = await ibb.createLink(action.node.handle);

  var response = '[{"hash":"' + action.text 
  + '","link":"' + action.link 
  + '","path":"' + path 
  + '","userID":2,"expire":0}]'; 

  return JSON.parse(response);

  //return fetchJSON(`/api/share${url}`)
}

export async function remove(hash) {
  const res = await fetchURL(`/api/share/${hash}`, {
    method: 'DELETE'
  })

  if (res.status !== 200) {
    throw new Error(res.status)
  }
}

export async function create(url, expires = '', unit = 'hours') {
  url = removePrefix(url)
  url = `/api/share${url}`
  if (expires !== '') {
    url += `?expires=${expires}&unit=${unit}`
  }

  return fetchJSON(url, {
    method: 'POST'
  })
}
