<template>
  <div class="item" id="inbox-item"
  role="button"
  tabindex="0"
  draggable="true"
  @mouseenter="mouseEnter"
  @mouseleave="mouseLeave"
  @dragstart="dragStart"
  @touchstart="touchStart"
  @touchend="touchEnd"
  @touchmove="touchMove"
  @dragover="dragOver"
  @drop="drop"
  @click="clickMobile"
  :aria-label="name"
  :aria-selected="isSelected"
  :style="showContact">
    <div class="container">
      <div style="display: flex; align-items: center; margin-top: 30px;">
        <button :class="[checked ? 'container-name-contact-checked' : 'container-name-contact']" @click="seleccionarInbox" @touchstart="touchStartButton" @touchend="touchEndButton" @mouseenter="touchStartButton" @mouseleave="touchEndButton">
          <p v-if="!checked" class="inbox-name">{{ name.substr(0, 2) }}</p>
          <check-icon v-else size="1.5x" class="custom-class"></check-icon>
        </button>
        <p class="name">{{ name }}</p>
      </div>
      <div style="display: flex; align-items: center; justify-content: end;">
        <div class="botones">
          <button v-if="!isExternalContact && this.permissions === 0 && !isMaxStorage" @click="showAdd" @touchstart="touchStartDelete" @touchend="touchEndDelete" @mouseenter="touchStartDelete" @mouseleave="touchEndDelete" :aria-label="$t('bmail.addInbox')" :title="$t('bmail.addInbox')" class="action">
            <plus-circle-icon size="1.5x" class="custom-class"></plus-circle-icon>
          </button>
          <button v-if="this.permissions === 0" @click="showEdit" @touchstart="touchStartDelete" @touchend="touchEndDelete" @mouseenter="touchStartDelete" @mouseleave="touchEndDelete" :aria-label="$t('buttons.rename')" :title="$t('buttons.rename')" class="action" id="rename-button">
            <edit-icon size="1.5x" class="custom-class"></edit-icon>
          </button>
          <button v-if="this.permissions === 0" @click="showDelete" @touchstart="touchStartDelete" @touchend="touchEndDelete" @mouseenter="touchStartDelete" @mouseleave="touchEndDelete" :aria-label="$t('buttons.delete')" :title="$t('buttons.delete')" class="action" id="delete-button">
            <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
          </button>
          <div class="container-number">
            <p class="number">{{ numberInboxes }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex'
import { files as api } from '@/api'
import { CheckIcon, PlusCircleIcon, EditIcon, Trash2Icon } from 'vue-feather-icons'

export default {
  name: 'item-contact',
  data: function () {
    return {
      timer: 0,
      isItem: false,
      isMultiSelected: false,
      handleInbox: '',
      numberInboxes: 0,
      checked: false,
      isShow: true,
      interfaceObject: '',
      isButton: false,
      isDelete: false,
      isExternalContact: false,
    }
  },
  components: {
    CheckIcon,
    PlusCircleIcon,
    EditIcon,
    Trash2Icon
  },
  props: ['name', 'accountHandle', 'ctime', 'mtime', 'handle', 'isContainerRecord', 'checkedAll', 'buscador', 'index'],
  computed: {
    ...mapState(['selected', 'req', 'isMenu', 'isPrompt', 'contactSelected', 'permissions', 'isMaxStorage']),
    ...mapGetters(['selectedCount']),
    isSelected () {
      return (this.selected.indexOf(this.index) !== -1)
    },
    canDrop () {
      for (let i of this.selected) {
        if (this.req.items[i].url === this.url) {
          return false
        }
      }

      return true
    },
    showContact() {
      if (!this.isShow) {
        return {display: 'none'}
      } else {
        return {display: 'block'}
      }
    }
  },
  watch: {
    checkedAll (val) {
      if (val) {
        this.checked = true
        if (!this.contactSelected.includes(this.handle)) {
          this.addContactSelected(this.handle)
        }
      } else {
        this.checked = false
        this.removeContactSelected(this.handle)
      }
    },
    buscador (val) {
      if (!this.name.toLowerCase().includes(val.toLowerCase())) {
        this.isShow = false
      } else {
        this.isShow = true
      }
    }
  },
  created () {
    var thisComponent = this;
    var JavaEventListenerClass = Module.ActionListener.extend("ActionListener", {
      onActionFinish: function(action)
      {
        if (action.type != Module.ActionType.MANAGE_OUTBOXES.value) {
          return;
        }

        thisComponent.fetchData();
        if (thisComponent.index === thisComponent.selected[0]) {
          thisComponent.$emit('show-outboxes', thisComponent.handle);
        }
      }
    });

    this.interfaceObject = new JavaEventListenerClass();
    ibb.addActionListener(this.interfaceObject);
  },
  mounted: function () {
    if (window.location.href.includes('=')) {
      var location = window.location.href.split('=');
      this.handleInbox = location[1].split('&');
      if (this.handleInbox[0] === this.handle) {
        this.addSelected(this.index)
      }
    }

    this.fetchData();
    document.addEventListener('click', this.clickEvent);
    document.addEventListener('contextmenu', this.contextMenu);
  },
  beforeDestroy () {
    document.removeEventListener('click', this.clickEvent);
    document.removeEventListener('contextmenu', this.contextMenu);
    ibb.removeActionListener(this.interfaceObject);
  },
  methods: {
    ...mapMutations(['addSelected', 'removeSelected', 'resetSelected', 'setHandleBuzon', 'addContactSelected', 'removeContactSelected']),
    async fetchData() {
      const {nodeList} = await ibb.getChildren(this.handle);
      this.numberInboxes = nodeList.length

      var action = await ibb.getExternalInbox();
      for (let outbox of nodeList) {
        if (outbox.link === action.link) {
          this.isExternalContact = true;
          break
        }
      }
    },
    seleccionarInbox() {
      this.checked = !this.checked
      if (this.checked) {
        this.addContactSelected(this.handle)
      } else {
        this.removeContactSelected(this.handle)
      }
    },
    showAdd () {
      this.resetSelected()
      this.addSelected(this.index)
      this.$emit('show-outboxes', this.handle);
      this.$store.commit('showHover', 'add-inbox')
    },
    showEdit () {
      this.resetSelected()
      this.addSelected(this.index)
      this.$store.commit('showHover', 'edit-contact')
    },
    showDelete () {
      if (this.isMobile() && this.$store.state.selected.indexOf(this.index) === -1) {
        this.addSelected(this.index)
      }
      this.$store.commit('showHover', 'delete')
    },
    clickMobile() {
      if (this.isMobile()) {
        /*if (this.selectedCount !== 0) {
          if (this.$store.state.selected.indexOf(this.index) !== -1) {
            this.removeSelected(this.index)
            return
          }

          this.addSelected(this.index)
          return;
        }*/
        if (!this.isButton && !this.isDelete) {
          this.open();
        }
      }
    },
    clickEvent(event) {
      if (!this.isMobile()) {
        if (this.isItem) {
          if (!this.isSelected && !this.isButton) {
            this.select(event);
            this.$emit('show-outboxes', this.handle);
            this.isMultiSelected = false;
            return;
          } else if (this.isSelected && event.ctrlKey && !this.isButton) {
            this.removeSelected(this.index)
          }
        } else {
          this.isMultiSelected = true;
          if (!event.ctrlKey) {
            if(this.isSelected && !this.isMenu && !this.isContainerRecord && !this.isHeader && !this.isPrompt) {
              this.removeSelected(this.index)
              return
            } 
          } 
        }
      }
    },
    contextMenu: function (event) {
      if (this.selectedCount === 0) {
        this.isMultiSelected = false;
      } else if (this.selectedCount > 1) {
        this.isMultiSelected = true;
      }
      if (!this.isMobile() && !this.isSelected && this.isItem) {
        this.select(event);
        this.$emit('show-outboxes', this.handle);
        return;
      } else if (!this.isItem && this.isSelected && !this.isMultiSelected && !this.isContainerRecord) { 
        this.removeSelected(this.index);
        return;
      } 
    },
    mouseEnter() {
      this.isItem = true;
    },
    mouseLeave() {
      this.isItem = false;
    },
    touchStartButton() {
      this.isButton = true;
    },
    touchEndButton() {
      this.isButton = false;
    },
    touchStartDelete() {
      this.isDelete = true;
    },
    touchEndDelete() {
      this.isDelete = false;
    },
    dragStart: function () {
      if (this.selectedCount === 0) {
        this.addSelected(this.index)
        return
      }

      if (!this.isSelected) {
        this.resetSelected()
        this.addSelected(this.index)
      }
      else {
        this.resetSelected()
      }
    },
    dragOver: function (event) {
      if (!this.canDrop) return

      event.preventDefault()
      let el = event.target

      for (let i = 0; i < 5; i++) {
        if (!el.classList.contains('item')) {
          el = el.parentElement
        }
      }

      el.style.opacity = 1
    },
    drop: function (event) {
      if (!this.canDrop) return
      event.preventDefault()

      if (this.selectedCount === 0) return

      let items = []

      for (let i of this.selected) {
        items.push({
          from: this.req.items[i].handle,
          to: this.handle
        })
      }

      api.moveId(items)
        .then(() => {
          this.$store.commit('setReload', true)
        })
        .catch(this.$showError)
    },
    isMobile: function () {
      return window.innerWidth <= 768
    },
    isIos: function() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    /*dblclick: function (event) {
      if (!this.isMobile()) {
        this.open();
      }
    },*/
    select: function (event) {
      if (this.selectedCount !== 0) event.preventDefault()
      if (this.$store.state.selected.indexOf(this.index) !== -1) {
        this.removeSelected(this.index)
        return
      }

      if (event.shiftKey && this.selected.length === 1) {
        let fi = 0
        let la = 0

        if (this.index > this.selected[0]) {
          fi = this.selected[0] + 1
          la = this.index
        } else {
          fi = this.index
          la = this.selected[0] - 1
        }

        for (; fi <= la; fi++) {       
          this.addSelected(fi)
        }

        return
      }

      //if (!event.ctrlKey && !this.$store.state.multiple) this.resetSelected()
      if (!event.ctrlKey) {
        this.resetSelected()
      } 
      this.addSelected(this.index)
    },
    touchStart: function (event) {
      if (!this.isIos()) {
        return;
      }

      this.timer = setTimeout(() => {
        this.timer = 0;
        this.dragStart();
      }, 600)
    },
    touchEnd: function (event) {
      if (!this.isIos()) {
        return;
      }

      if (this.timer) {
        clearTimeout(this.timer);
	this.timer = 0;
      }
      else {
        event.preventDefault();
      }
    },
    touchMove: function (event) {
      if (!this.isIos()) {
        return;
      }

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = 0;
      }
    },
    open: function () { 
      this.$router.push({path: this.$route.path + "#" + this.handle})
    }
  }
}
</script>