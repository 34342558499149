<template>
  <div v-if='req.hasOwnProperty("numFiles") && (req.numDirs + req.numFiles) != 0' id="listing" class="list">

  <h2 v-if="req.numDirs > 0">{{ $t('files.folders') }}</h2>
  <div v-if="req.numDirs > 0">
    <item-record v-for="(item) in dirs"
      :key="base64(item.tag)"
      v-bind:index="item.index"
      v-bind:name="item.name"
      v-bind:type="item.type"
      v-bind:isDir="item.isDir"
      v-bind:url="item.url"
      v-bind:modified="item.modified"
      v-bind:size="item.size"
      v-bind:status="item.status"
      v-bind:errorCode="item.errorCode"
      v-bind:handle="item.handle">
    </item-record>
  </div>

  <h2 v-if="req.numFiles > 0">{{ $t('files.files') }}</h2>
  <div v-if="req.numFiles > 0">
    <item-record :ref="item.tag" v-for="(item) in files"
      :key="base64(item.index)"
      v-bind:progress="item.progress"
      v-bind:index="item.index"
      v-bind:tag="item.tag"
      v-bind:name="item.name"
      v-bind:isDir="item.isDir"
      v-bind:readonly="item.readonly"
      v-bind:modified="item.modified"
      v-bind:type="item.type"
      v-bind:size="item.size"
      v-bind:status="item.status"
      v-bind:errorCode="item.errorCode"
      v-bind:handle="item.handle">
    </item-record>
  </div>
</div>

</template>

<script>
import { mapState, mapMutations } from 'vuex'
import ItemRecord from './ListingItemRecord'
import css from '@/utils/css'
import { files as api } from '@/api'
import buttons from '@/utils/buttons'

export default {
  name: 'listing-record',
  components: { ItemRecord },
  data: function () {
    return {
      show: 50
    }
  },
  computed: {
    ...mapState(['req', 'selected', 'user']),
    items () {
      const dirs = []
      const files = []

      this.req.items.forEach((item) => {
        if (item.isDir) {
          dirs.push(item)
        } else {
          files.push(item)
        }
      })

      return { dirs, files }
    },
    dirs () {
      return this.items.dirs.slice(0, this.show)
    },
    files () {
      let show = this.show - this.items.dirs.length

      if (show < 0) show = 0

      return this.items.files.slice(0, show)
    }
  },
  mounted: function () {
    // Check the columns size for the first time.
    this.resizeEvent()

    // Add the needed event listeners to the window and document.
    window.addEventListener('keydown', this.keyEvent)
    window.addEventListener('resize', this.resizeEvent)
    window.addEventListener('scroll', this.scrollEvent)
    document.addEventListener('dragover', this.preventDefault)
  },
  beforeDestroy () {
    // Remove event listeners before destroying this page.
    window.removeEventListener('keydown', this.keyEvent)
    window.removeEventListener('resize', this.resizeEvent)
    window.removeEventListener('scroll', this.scrollEvent)
    document.removeEventListener('dragover', this.preventDefault)
  },
  methods: {
    ...mapMutations([ 'updateUser' ]),
    base64: function (name) {
      return window.btoa(unescape(encodeURIComponent(name)))
    },
    preventDefault (event) {
      // Wrapper around prevent default.
      event.preventDefault()
    },
    resizeEvent () {
      // Update the columns size based on the window width.
      let columns = Math.floor(document.querySelector('main').offsetWidth / 300)
      let items = css(['#listing.mosaic .item', '.mosaic#listing .item'])
      if (columns === 0) columns = 1
      items.style.width = `calc(${100 / columns}% - 1em)`
    },
    scrollEvent () {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        this.show += 50
      }
    }
  }
}
</script>