<template>
  <div id="reg">
    <editor v-if="isEditor"></editor>
    <div class="contenedor-global" v-else-if="isListing">
      <div v-if="!isMobile" class="container-listing" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
        <div class="title">
          <div class="buscador">
            <input class="input" type="text" :placeholder="$t('buttons.search')" v-model="buscador"> 
            <button @click="show" @mouseenter="mouseEnterMenu" @mouseleave="mouseLeaveMenu"> 
              <img src="/img/icons/Bmail Resources/Icons/Black Icons/sector5.png">
            </button>
            <nav id="menu-ordenacion" :style="menuStyle">
              <div style="display: block;">
                <div class="option" @click="sortInbox('name')">
                  <p>{{ $t('files.sortByName') }}</p>
                  <img v-if="order === 'name'" src="/img/icons/Bmail Resources/Icons/Blue Icons/sector28.png">
                </div>
                <div class="option" @click="sortInbox('modified')">
                  <p>{{ $t('files.sortByLastModified') }}</p>
                  <img v-if="order === 'date'" src="/img/icons/Bmail Resources/Icons/Blue Icons/sector28.png">
                </div>
              </div>
            </nav>
          </div>
          <div class="botones" @mouseenter="mouseEnterButton" @mouseleave="mouseLeaveButton">
            <button v-if="!isMaxStorage && this.permissions === 0" @click="newInbox" :aria-label="$t('buttons.new')" :title="$t('buttons.new')" class="action" id="new-inbox-button">
              <p>{{ $t('buttons.new') }}</p>
              <edit-3-icon size="1.5x" class="custom-class"></edit-3-icon>
            </button>
          </div>
        </div>

        <div style="display: flex; align-items: center; padding: 0 20px; margin-bottom: 15px; margin-top: 20px;">
          <div :class="[checked ? 'checkbox-activo' : 'checkbox']">
            <input type="checkbox" id="checkbox" v-model="checked">
            <label v-if="checked === false" for="checkbox">{{ $t('bmail.markAll') }}</label>
            <label v-else for="checkbox">{{ $t('bmail.uncheck') }}</label>
          </div>

          <delete-button v-if="inboxSelected.length > 0 && this.permissions === 0"></delete-button>
        </div>
        <div class="contenedor-listing" style="overflow-y: scroll; height: 80vh; overflow-x: hidden">
          <listing-inbox @show-records="showRecords" v-bind:checkedAll="checked" v-bind:buscador="buscador" v-bind:isContainerRecord="isContainerRecord" :class="{ multiple }"></listing-inbox>
        </div>
      </div>
      <div v-else class="container-listing">
        <div v-if="inboxes.length > 0">
          <div class="title">
            <div class="buscador">
              <input class="input" type="text" :placeholder="$t('buttons.search')" v-model="buscador"> 
              <button @click="show" @mouseenter="mouseEnterMenu" @mouseleave="mouseLeaveMenu"> 
                <img src="/img/icons/Bmail Resources/Icons/Black Icons/sector5.png">
              </button>
              <nav id="menu-ordenacion" :style="menuStyle">
                <div style="display: block;">
                  <div class="option" @click="sortInbox('name')">
                    <p>{{ $t('files.sortByName') }}</p>
                    <img v-if="order === 'name'" src="/img/icons/Bmail Resources/Icons/Blue Icons/sector28.png">
                  </div>
                  <div class="option" @click="sortInbox('modified')">
                    <p>{{ $t('files.sortByLastModified') }}</p>
                    <img v-if="order === 'date'" src="/img/icons/Bmail Resources/Icons/Blue Icons/sector28.png">
                  </div>
                </div>
              </nav>
            </div>
            <div class="botones" @mouseenter="mouseEnterButton" @mouseleave="mouseLeaveButton">
              <button v-if="!isMaxStorage && this.permissions === 0" @click="newInbox" :aria-label="$t('buttons.new')" :title="$t('buttons.new')" class="action" id="new-inbox-button">
                <p>{{ $t('buttons.new') }}</p>
                <edit-3-icon size="1.5x" class="custom-class"></edit-3-icon>
              </button>
            </div>
          </div>

          <div style="display: flex; align-items: center; padding: 0 20px; margin-bottom: 15px; margin-top: 20px;">
            <div :class="[checked ? 'checkbox-activo' : 'checkbox']">
              <input type="checkbox" id="checkbox" v-model="checked">
              <label v-if="checked === false" for="checkbox">{{ $t('bmail.markAll') }}</label>
              <label v-else for="checkbox">{{ $t('bmail.uncheck') }}</label>
            </div>

            <delete-button v-if="inboxSelected.length > 0 && this.permissions === 0"></delete-button>
          </div>
          <div class="contenedor-listing">
            <listing-inbox v-bind:checkedAll="checked" v-bind:buscador="buscador" v-bind:isContainerRecord="isContainerRecord" :class="{ multiple }"></listing-inbox>
          </div>
        </div>
        <div v-else-if="records.length > 0">
          <div class="title">
            <button :aria-label="$t('bmail.back')" :title="$t('bmail.back')" class="action" id="back-button" @click="backRecords">
              <arrow-left-icon size="1.5x" class="custom-class"></arrow-left-icon>
            </button>
            <div class="buscador">
              <input class="input" type="text" :placeholder="$t('buttons.search')" v-model="buscadorRecord"> 
            </div>
            <div class="botones">
              <button v-if="this.permissions === 0" :aria-label="$t('bmail.write')" :title="$t('bmail.write')" class="action" id="new-inbox-button" @click="write">
                <p>{{ $t('bmail.write') }}</p>
                <edit-3-icon size="1.5x" class="custom-class"></edit-3-icon>
              </button>
            </div>
          </div>

          <div class="container-options">
            <div style="display: flex; align-items: center;">
              <div :class="[checkedRecords ? 'checkbox-activo' : 'checkbox']">
                <input type="checkbox" id="checkboxRecords" v-model="checkedRecords">
                <label v-if="checkedRecords === false" for="checkboxRecords">{{ $t('bmail.markAll') }}</label>
                <label v-else for="checkboxRecords">{{ $t('bmail.uncheck') }}</label>
              </div>

              <delete-button v-if="recordSelected.length > 0 && this.permissions === 0"></delete-button>
              <button v-if="recordSelected.length > 0 && this.permissions === 0" :aria-label="$t('bmail.markUnread')" :title="$t('bmail.markUnread')" class="action" id="read-button" @click="markUnread">
                <eye-off-icon size="1.5x" class="custom-class"></eye-off-icon>
              </button>
              <button v-if="recordSelected.length > 0 && this.permissions === 0" :aria-label="$t('bmail.markRead')" :title="$t('bmail.markRead')" class="action" id="read-button" @click="markRead">
                <eye-icon size="1.5x" class="custom-class"></eye-icon>
              </button>
            </div>
            <button v-if="!isSmallMobile || (isSmallMobile && recordSelected.length === 0)" :aria-label="$t('bmail.unread')" :title="$t('bmail.unread')" :class="[showUnread ? 'action-activo' : 'action']" id="unread-button" @click="showUnreadRecord">
              <eye-off-icon v-if="!showUnread" size="1.5x" class="custom-class"></eye-off-icon>
              <eye-icon v-else size="1.5x" class="custom-class"></eye-icon>
              <span v-if="!showUnread">{{ $t('bmail.unread') }}</span>
              <span v-else>{{ $t('bmail.showEverything') }}</span>
            </button>
          </div>
          <div class="contenedor-listing">
            <record-sent v-for="(record) in records"
            :key="record.handle"
            v-bind:name="record.name"
            v-bind:contact="record.contact"
            v-bind:message="record.message"
            v-bind:handle="record.handle"
            v-bind:parentHandle="record.parentHandle"
            v-bind:ctime="record.ctime"
            v-bind:index="records.indexOf(record)"
            v-bind:status="record.status"
            v-bind:isContainerRecord="false"
            v-bind:buscador="buscadorRecord"
            v-bind:showUnread="showUnread"
            v-bind:checkedAll="checkedRecords"
            @open-record="openRecord"
            ></record-sent>
          </div>
        </div>
        <open-record v-else-if="(records.length === 0) && (record !== '')" @back="backListingRecords"></open-record>
        <div v-else class="container-image">
          <button :aria-label="$t('bmail.back')" :title="$t('bmail.back')" class="action" id="back-button" @click="backRecords">
            <arrow-left-icon size="1.5x" class="custom-class"></arrow-left-icon>
          </button>
          <div>
            <img src="/img/icons/Bmail Resources/Illustrations/ibb-nothing.png">
            <h2>{{ $t('records.nothingToSee') }}</h2>
            <h3>{{ $t('records.recordDisplayed') }}</h3>
          </div>
        </div>
      </div>
      <div class="container-records">
        <div v-if="selectedCount !== 1" class="container-image">
          <div>
            <img src="/img/icons/Bmail Resources/Illustrations/img-envelope.png">
            <h2>{{ $t('records.everythingPlace') }}</h2>
            <h3>{{ $t('records.contentInbox') }}</h3>
          </div>
        </div>
        <div class="records" v-else-if="(records.length > 0) && (selectedCount === 1) && (record === '')">
          <div class="title">
            <div class="buscador">
              <input class="input" type="text" :placeholder="$t('buttons.search')" v-model="buscadorRecord"> 
            </div>
            <div class="botones">
              <button v-if="this.permissions === 0" :aria-label="$t('bmail.write')" :title="$t('bmail.write')" class="action" id="new-inbox-button" @click="write">
                <p>{{ $t('bmail.write') }}</p>
                <edit-3-icon size="1.5x" class="custom-class"></edit-3-icon>
              </button>
            </div>
          </div>

          <div class="container-options">
            <div style="display: flex; align-items: center;">
              <div :class="[checkedRecords ? 'checkbox-activo' : 'checkbox']">
                <input type="checkbox" id="checkboxRecords" v-model="checkedRecords">
                <label v-if="checkedRecords === false" for="checkboxRecords">{{ $t('bmail.markAll') }}</label>
                <label v-else for="checkboxRecords">{{ $t('bmail.uncheck') }}</label>
              </div>

              <delete-button v-if="recordSelected.length > 0 && this.permissions === 0"></delete-button>
              <button v-if="recordSelected.length > 0 && this.permissions === 0" :aria-label="$t('bmail.markUnread')" :title="$t('bmail.markUnread')" class="action" id="read-button" @click="markUnread">
                <eye-off-icon size="1.5x" class="custom-class"></eye-off-icon>
              </button>
              <button v-if="recordSelected.length > 0 && this.permissions === 0" :aria-label="$t('bmail.markRead')" :title="$t('bmail.markRead')" class="action" id="read-button" @click="markRead">
                <eye-icon size="1.5x" class="custom-class"></eye-icon>
              </button>
            </div>
            <button :aria-label="$t('bmail.unread')" :title="$t('bmail.unread')" :class="[showUnread ? 'action-activo' : 'action']" id="unread-button" @click="showUnreadRecord">
              <eye-off-icon v-if="!showUnread" size="1.5x" class="custom-class"></eye-off-icon>
              <eye-icon v-else size="1.5x" class="custom-class"></eye-icon>
              <span v-if="!showUnread">{{ $t('bmail.unread') }}</span>
              <span v-else>{{ $t('bmail.showEverything') }}</span>
            </button>
          </div>
          <div class="contenedor-listing" style="overflow-y: scroll; height: 80vh; overflow-x: hidden;">
            <div style="height: auto;">
              <record v-for="(record) in records"
              :key="record.handle"
              v-bind:name="record.name"
              v-bind:contact="record.contact"
              v-bind:message="record.message"
              v-bind:handle="record.handle"
              v-bind:parentHandle="record.parentHandle"
              v-bind:ctime="record.ctime"
              v-bind:status="record.status"
              v-bind:buscador="buscadorRecord"
              v-bind:showUnread="showUnread"
              v-bind:checkedAll="checkedRecords"
              @open-record="openRecord"
              ></record>
            </div>
          </div>
        </div>
        <div v-else-if="(records.length > 0) && (selectedCount === 1) && (record !== '')" class="scroll-record">
          <div style="height: auto;">
            <open-record @back="back"></open-record>
          </div>
        </div>
        <div v-else class="container-image">
          <div>
            <img src="/img/icons/Bmail Resources/Illustrations/ibb-nothing.png">
            <h2>{{ $t('records.nothingToSee') }}</h2>
            <h3>{{ $t('records.recordDisplayed') }}</h3>
          </div>
        </div>
      </div>
    </div>
    <preview v-else-if="isPreview"></preview>
    <div v-else style="padding-top: 4em;">
      <h2 class="message">
        <span>{{ $t('files.loading') }}</span>
      </h2>
    </div>
  </div>
</template>

<script>
import Preview from '@/components/files/Preview'
import ListingInbox from '@/components/files/ListingInbox'
import Editor from '@/components/files/Editor'
import Record from '@/components/Record'
import RecordSent from '@/components/RecordSent'
import OpenRecord from '@/components/OpenRecord'
import { files as api } from '@/api'
import { mapGetters, mapState, mapMutations } from 'vuex'
import { Edit3Icon, EyeOffIcon, EyeIcon, ArrowLeftIcon } from 'vue-feather-icons'
import DeleteButton from '@/components/buttons/Delete'

function clean (path) {
  return path.endsWith('/') ? path.slice(0, -1) : path
}

export default {
  name: 'files',
  components: {
    Preview,
    ListingInbox,
    Editor,
    Record,
    Edit3Icon,
    DeleteButton,
    EyeOffIcon,
    EyeIcon,
    OpenRecord,
    RecordSent,
    ArrowLeftIcon
  },
  computed: {
    ...mapGetters([
      'selectedCount',
      'isListing',
      'isEditor',
      'isFiles'
    ]),
    ...mapState([
      'req',
      'user',
      'reload',
      'multiple',
      'loading',
      'sortOrder',
      'isDelete',
      'isRename',
      'selected',
      'parentHandle',
      'inboxSelected',
      'recordSelected',
      'record',
      'permissions',
      'isMaxStorage'
    ]),
    isPreview () {
      return !this.loading && !this.isEditor
    },
    isMobile () {
      return window.innerWidth <= 768
    },
    menuStyle () {
      if (this.isShow) {
        return {display: 'block'}
      } else {
        return {display: 'none'}
      }
    },
    isSmallMobile () {
      return window.innerWidth <= 450
    }
  },
  data: function () {
    return {
      error: null,
      bclist: [], 
      records: [],
      inboxes: [],
      isContainerRecord: false,
      sortOrderRecord: -Module.SortOrderType.SORT_ORDER_MODIFICATION_TIME.value,
      isShow: false,
      isButton: false,
      checked: false,
      checkedRecords: false,
      order: 'date',
      buscador: '',
      buscadorRecord: '',
      showUnread: false,
    }
  },
  created () {
    this.$store.commit('setSortOrder', -Module.SortOrderType.SORT_ORDER_CREATION_TIME.value)
    var thisComponent = this;
    var JavaActionListenerClass = Module.ActionListener.extend("ActionListener", {
      onActionFinish: function(action)
      {
        if (action.type == Module.ActionType.SET_RECORD_STATUS.value && !thisComponent.isMobile) {
          thisComponent.fetchData()
          return
        }
      }
    });

    var JavaEventListenerClass = Module.EventListener.extend("EventListener", {
      onEvent: function(event)
      {
        if (event.type == Module.EventType.NEW_NODES.value && event.node.type == Module.NodeType.RECORD.value)
        {
          if (thisComponent.selected.length > 0) {
            thisComponent.showRecords(thisComponent.req.items[thisComponent.selected[0]].handle)
          }
          thisComponent.fetchData()
          return;
        }

        if ((event.type == Module.EventType.NEW_NODES.value && event.node.type == Module.NodeType.INBOX.value) || (event.type == Module.EventType.DELETED_NODE.value && event.node.type == Module.NodeType.INBOX.value))
        {
          thisComponent.fetchData()
          return;
        }
        return;
      }
    });

    this.fetchData()
    this.interfaceObject = new JavaActionListenerClass();
    ibb.addActionListener(this.interfaceObject);
    ibb.addEventListener(new JavaEventListenerClass());
  },
  watch: {
    '$route': function () {
      this.fetchData(),
      this.reviewRecord()
    },
    'reload': function () {
      this.fetchData()
    }
  },
  mounted () {
    window.addEventListener('keydown', this.keyEvent)
    window.addEventListener('scroll', this.scroll)
    window.addEventListener('click', this.clickEvent)
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.keyEvent)
    window.removeEventListener('scroll', this.scroll)
    window.removeEventListener('click', this.clickEvent)
    ibb.removeActionListener(this.interfaceObject);
  },
  destroyed () {
    this.$store.commit('updateRequest', {})
  },
  methods: {
    ...mapMutations([ 'setLoading', 'setParentHandle', 'resetSelected', 'resetRecordSelected', 'setRecord', 'addSelected']),
    clickEvent() {
      if (!this.isButton && this.isShow) {
        this.isShow = !this.isShow
      }
    },
    write() {
      this.$store.commit('showHover', 'write')
    },
    show() {
      this.isShow = !this.isShow
    },
    back() {
      this.setRecord('')
    },
    backRecords() {
      this.$router.push({ path: this.$route.path })
    },
    backListingRecords() {
      this.$router.push({ path: this.$route.path + "#" + this.record.parentHandle })
      this.setRecord('')
    },
    showUnreadRecord() {
      this.showUnread = !this.showUnread
    },
    async markUnread() {
      if (this.recordSelected.length > 0) {
        for (let record of this.recordSelected) {
          await ibb.setRecordStatus(record, Module.RecordStatus.UNREAD.value)
        }
        this.resetRecordSelected();
      }
      if (this.isMobile) {
        this.fetchData()
      }
    },
    async markRead() {
      if (this.recordSelected.length > 0) {
        for (let record of this.recordSelected) {
          await ibb.setRecordStatus(record, Module.RecordStatus.READ.value)
        }
        this.resetRecordSelected();
      }
      if (this.isMobile) {
        this.fetchData()
      }
    },
    mouseEnterMenu() {
      this.isButton = true
    },
    mouseLeaveMenu() {
      this.isButton = false
    },
    async fetchData () {
      // Reset view information.
      this.$store.commit('setReload', false)
      //this.$store.commit('resetSelected')
      this.$store.commit('multiple', false)
      this.$store.commit('closeHovers')

      // Set loading to true and reset the error.
      this.setLoading(true)
      this.error = null

      let url = this.$route.hash;
      if (url === '') {
        url = '/reg'
      }
      else {
        url = url.slice(1);
      }

      try {
        var res;
        if (url[0] !== '/')
        {
          res = await api.fetchId(url, this.sortOrder)
          if (res.type === 'inbox') {
            this.showRecords(res.handle)
            this.inboxes = []
          } else if (res.type === 'record') {
            this.records = []
            this.inboxes = []
            this.openRecord(res.handle)
          }
        }
        else
        {
          res = await api.fetch(url, this.sortOrder)

          if(this.selectedCount !== 0 && this.parentHandle !== null) {
            try {
              const {nodeList} = await ibb.getSortedChildren(res.items[this.selected[0]].handle, this.sortOrderRecord);
              this.records = nodeList;
            } catch (e) {
              this.$showError(e)
            }
          } else if (this.parentHandle === null) {
            this.resetSelected();
            this.records = [];
          }
        }

        let breadcrumbs = []
        if (this.$route.hash === '') {
          this.inboxes = res.items;
        }
        
        var folder = res;
        while (folder.parentHandle) {
          breadcrumbs.unshift({ name: decodeURIComponent(folder.name), url: this.$route.path + '#' + folder.handle })
          folder = await api.getParent(folder.parentHandle);
        }

        if (breadcrumbs.length > 3) {
          while (breadcrumbs.length !== 4) {
            breadcrumbs.shift()
          }

          breadcrumbs[0].name = '...'
        }
        this.bclist = breadcrumbs;

        /*if (clean(res.path) !== clean(`/${this.$route.params.pathMatch}`)) {
          return
        }*/

        this.$store.commit('updateRequest', res)
        document.title = res.name
      } catch (e) {
        this.error = e
      } finally {
        this.setLoading(false)
      }
    },
    reviewRecord () {
      if (this.$route.hash === '' && this.record !== '') {
        var index = -1
        for (let item of this.records) {
          if (item.handle === this.record.handle) {
            for (let item2 of this.inboxes) {
              if (item2.handle === this.record.parentHandle) {
                index = this.inboxes.indexOf(item2)
              }
            }
          }
        }
        if (index !== -1) {
          this.addSelected(index);
        }
      }
    },
    keyEvent (event) {
      // Esc!
      if (event.keyCode === 27) {
        this.$store.commit('closeHovers')

        // If we're on a listing, unselect all
        // files and folders.
        if (this.isListing) {
          this.$store.commit('resetSelected')
        }
      }

      // Del!
      if (event.keyCode === 46) {
        if (this.isEditor ||
          !this.isFiles ||
          this.loading ||
          !this.user.perm.delete ||
          (this.isListing && this.selectedCount === 0) || this.isDelete) return

        this.$store.commit('showHover', 'delete')
      }

      // F1!
      if (event.keyCode === 112) {
        event.preventDefault()
        this.$store.commit('showHover', 'help')
      }

      // F2!
      if (event.keyCode === 113) {
        if (this.isEditor ||
          !this.isFiles ||
          this.loading ||
          !this.user.perm.rename ||
          (this.isListing && this.selectedCount === 0) ||
          (this.isListing && this.selectedCount > 1) || this.isRename) return

        this.$store.commit('showHover', 'rename')
      }

      // CTRL + S
      if (event.ctrlKey || event.metaKey) {
        if (this.isEditor) return

        if (String.fromCharCode(event.which).toLowerCase() === 's') {
          event.preventDefault()

          if (this.req.kind !== 'editor') {
            document.getElementById('download-button').click()
          }
        }
      }
    },
    scroll () {
      if (this.req.kind !== 'listing' || this.$store.state.user.viewMode === 'mosaic') return

      let top = 112 - window.scrollY

      if (top < 64) {
        top = 64
      }

      document.querySelector('#listing.list .item.header').style.top = top + 'px'
    },
    openSidebar () {
      this.$store.commit('showHover', 'sidebar')
    },
    openSearch () {
      this.$store.commit('showHover', 'search')
    },
    newInbox: function () {
      this.$store.commit('showHover', 'newInbox')
    },
    async openRecord(handle) {
      var action = await ibb.getNodeById(handle);
      if (action.node.status === Module.RecordStatus.UNREAD.value) {
        await ibb.setRecordStatus(handle, Module.RecordStatus.READ.value)
      }
      this.setRecord(action.node)
    },
    async showRecords(handle) {
      this.setParentHandle(handle);
      const {nodeList} = await ibb.getSortedChildren(handle, this.sortOrderRecord);
      this.records = nodeList;
      this.setRecord('')
    },
    mouseEnter() {
      this.isContainerRecord = false;
    },
    mouseLeave() {
      this.isContainerRecord = true;
    },
    mouseEnterButton() {
      this.isContainerRecord = true;
    },
    mouseLeaveButton() {
      this.isContainerRecord = false;
    },
    async sortInbox (by) {
      let sortOrder = Module.SortOrderType.SORT_ORDER_DEFAULT.value

      if (by === 'name') {
        sortOrder = Module.SortOrderType.SORT_ORDER_DEFAULT.value
        this.order = 'name'
      } else if (by === 'modified') {
        sortOrder = -Module.SortOrderType.SORT_ORDER_CREATION_TIME.value
        this.order = 'date'
      }

      this.$store.commit('setSortOrder', sortOrder)
      this.$store.commit('setReload', true)
    }
  }
}
</script>
