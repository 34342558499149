const getters = {
  isLogged: state => state.user !== null,
  isFiles: state => !state.loading && (state.route.name === 'Reg' || state.route.name === 'Contacts' || state.route.name === 'Sent' || state.route.name === 'Received'),
  isRegister: state => !state.loading && state.route.name === 'Reg',
  isListing: (state, getters) => getters.isFiles && state.req.isDir,
  isEditor: (state, getters) => getters.isFiles && (state.req.type === 'text' || state.req.type === 'textImmutable' || state.req.type === 'txt'),
  selectedCount: state => state.selected.length,
  isFolder: state => !state.loading && (state.route.name === 'Folder'),
  isShare: state => !state.loading && (state.route.name === 'Share'),
  isSettings: state => !state.loading && (state.route.name === 'Settings' || state.route.name === 'Invoicing' || state.route.name === 'Invoicing Plans' ),
}

export default getters
