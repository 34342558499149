<template>
  <div class="card floating" id="write">
    <div v-if="!isFiles">
      <button class="button button--flat button--grey" id ="buttonCloseRename"
        @click="$store.commit('closeHovers')"
        :aria-label="$t('buttons.close')"
        :title="$t('buttons.close')">
        <x-icon size="1.5x" class="custom-class"></x-icon>
      </button>
      <div class="botones">
        <button :aria-label="$t('bmail.secureMail')" :title="$t('bmail.secureMail')" :class="[isMail ? 'button-active' : 'button']" @click="isMail = true" :disabled="isSent">
          <mail-icon size="1.5x" class="custom-class"></mail-icon>
          <span>{{ $t('bmail.secureMail') }}</span>
        </button>
        <button :aria-label="$t('bmail.externalMail')" :title="$t('bmail.externalMail')" :class="[isMail ? 'button' : 'button-active']" @click="isMail = false" :disabled="isSent">
          <repeat-icon size="1.5x" class="custom-class"></repeat-icon>
          <span>{{ $t('bmail.externalMail') }}</span>
        </button>
      </div>
      <div v-if="isMail" class="secure-mail">
        <div class="option">
          <p>{{ $t('bmail.selectContact') }}</p>
          <div style="position: relative;">
            <div class="contenedor-input">
              <input class="input input--block" type="text" v-model.trim="contact" :disabled="url !== ''"> 
              <chevron-down-icon size="1.5x" class="custom-class" @click="isShowContacts = !isShowContacts" :disabled="url !== ''"></chevron-down-icon>
            </div>
            <nav id="menu-contactos" :style="menuStyle">
              <div style="display: block; border: none; text-align: left;">
                <contact v-for="(contacto) in contactos"
                :key="contacto.id"
                v-bind:name="contacto.name"
                v-bind:contact="contact"
                @select-contact="selectContact"></contact>
              </div>
            </nav>
          </div>
          <p :style="inboxStyle">{{ $t('bmail.chooseMailbox') }}</p>
          <div style="position: relative;">
            <div class="contenedor-input" :style="inboxStyle">
              <input class="input input--block" type="text" v-model.trim="inbox" :disabled="url !== ''">
              <chevron-down-icon size="1.5x" class="custom-class" @click="isShowInboxes = !isShowInboxes" :disabled="url !== ''"></chevron-down-icon>
            </div>
            <nav id="menu-contactos" :style="menuStyleInbox">
              <div style="display: block; border: none; text-align: left;">
                <inbox v-for="(item) in inboxes"
                :key="item.id"
                v-bind:name="item.name"
                v-bind:link="item.link"
                v-bind:inbox="inbox"
                @select-inbox="selectInbox"></inbox>
              </div>
            </nav>
          </div>
        </div>
        <div class="option url" :style="urlStyle">
          <p>{{ $t('bmail.pasteURL') }}</p>
          <div style="display: flex; align-items: center;">
            <input :class="[url !== '' ? 'input-active' : 'input']" type="text" v-model.trim="url" :placeholder="$t('bmail.urlInbox')">
            <button @click="removeData()" :disabled="isSent">
              <x-icon size="1.5x" class="custom-class"></x-icon>
            </button>
          </div>
        </div>
        <div class="contacts" v-if="contactos.length === 0 && contact === ''">
          <img src="/img/icons/Bmail Resources/Illustrations/img-contacts.png">
          <p v-html="$t('bmail.noContacts')"></p>
        </div>
        <div class="subject">
          <input class="input input--block" type="text" v-model.trim="subject" :placeholder="$t('bmail.writeSubject')" maxlength="60" :disabled="isSent">
          <span>{{ subject.length }}/60</span>
        </div>
        <div class="mensaje">
          <textarea class="input input--block" type="text" v-model.trim="message" rows="4" :placeholder="$t('bmail.writeMessage')" :disabled="isSent"></textarea>
          <div class="contenedor-ficheros">
            <div class="ficheros" @click="isFiles = true">
              <p>{{ archivosRecord.length }} ficheros subidos</p>
              <span>{{ $t('bmail.viewFiles') }}</span>
            </div>
            <button :aria-label="$t('bmail.attach')" :title="$t('bmail.attach')" class="action" @click="addFile" :disabled="isSent">
              <input style="display:none" type="file" id="upload-file-to-record" @change="uploadInput($event)" multiple>
              <paperclip-icon size="1.5x" class="custom-class"></paperclip-icon>
              <span>{{ $t('bmail.attach') }}</span>
            </button>
          </div>
        </div>
        <div class="boton">
          <button :aria-label="$t('bmail.sendMessage')" :title="$t('bmail.sendMessage')" class="action" id="send-message-button" @click="sendRecord" :disabled="isSent">
            <span>{{ $t('bmail.sendMessage') }}</span>
          </button>
        </div>
      </div>
      <div class="transfer" v-else>
        <div class="option">
          <p>{{ $t('bmail.enterEmailAddress') }}</p>
          <div style="display: flex; align-items: center;">
            <input :class="[email !== '' ? 'input-active' : 'input']" type="text" v-model.trim="email" :placeholder="$t('files.email')" :disabled="isSent">
            <button @click="email=''" :disabled="isSent">
              <x-icon size="1.5x" class="custom-class"></x-icon>
            </button>
          </div>
          <div style="display: flex; align-items: center; margin-top: 10px;">
            <info-icon size="1.5x" class="custom-class icon-info"></info-icon>
            <p v-html="$t('bmail.infoMessage')" class="info"></p>
          </div>
        </div>
        <div class="subject">
          <input class="input input--block" type="text" v-model.trim="subjectTransfer" :placeholder="$t('bmail.writeSubject')" maxlength="60" :disabled="isSent">
          <span>{{ subjectTransfer.length }}/60</span>
        </div>
        <div class="mensaje">
          <textarea class="input input--block" type="text" v-model.trim="messageTransfer" rows="6" :placeholder="$t('bmail.writeMessage')" :disabled="isSent"></textarea>
          <div class="contenedor-ficheros">
            <div class="ficheros" @click="isFiles = true">
              <p>{{ archivosTransfer.length }} ficheros subidos</p>
              <span>{{ $t('bmail.viewFiles') }}</span>
            </div>
            <button :aria-label="$t('bmail.attach')" :title="$t('bmail.attach')" class="action" @click="addFileTransfer" :disabled="isSent">
              <input style="display:none" type="file" id="upload-file-to-transfer" @change="uploadInputTransfer($event)" multiple>
              <paperclip-icon size="1.5x" class="custom-class"></paperclip-icon>
              <span>{{ $t('bmail.attach') }}</span>
            </button>
          </div>
        </div>
        <div class="boton">
          <button :aria-label="$t('bmail.encryptSend')" :title="$t('bmail.encryptSend')" class="action" id="send-message-button" @click="sendMail" :disabled="isSent">
            <span>{{ $t('bmail.encryptSend') }}</span>
          </button>
        </div>
      </div>
    </div>
    <div v-else class="uploaded-files">
      <div style="display: flex; align-items: center;">
        <arrow-left-icon size="1.5x" class="custom-class" @click="isFiles = false"></arrow-left-icon>
        <p>{{ $t('bmail.uploadedFiles') }}</p>
      </div>
      <div>
        <listing ref="downloads-list" v-bind:archivosRecord="archivosRecord" v-bind:archivosTransfer="archivosTransfer" v-bind:isMail="isMail" @delete-item="deleteItem"></listing>
      </div>
    </div>
  </div>
</template>

<script>
import { MailIcon, RepeatIcon, XIcon, PaperclipIcon, InfoIcon, ArrowLeftIcon, ChevronDownIcon } from 'vue-feather-icons'
import { mapState, mapMutations } from 'vuex'
import Listing from '@/components/records/Listing'
import Contact from '@/components/Contact'
import Inbox from '@/components/Inbox'
import { files as api } from '@/api'

export default {
  name: 'write',
  data: function () {
    return {
      contact: '',
      inbox: '',
      url: '',
      subject: '',
      message: '',
      subjectTransfer: '',
      messageTransfer: '',
      isMail: true,
      email: '',
      archivosRecord: [],
      archivosTransfer: [],
      isFiles: false,
      emailRecord: '',
      isShowContacts: false,
      contactos: [],
      inboxes: [],
      isShowInboxes: false,
      isSelectContact: false,
      isSelectInbox: false,
      isLink: false,
    }
  },
  components: {
    MailIcon,
    RepeatIcon, 
    XIcon,
    PaperclipIcon,
    InfoIcon,
    ArrowLeftIcon,
    Listing,
    ChevronDownIcon,
    Contact,
    Inbox
  },
  async created () {
    //Obtener los contactos
    var action = await ibb.getRootNode(Module.NodeType.CONTACT_ROOT.value);
    if (action.error.type) {
      throw new Error("Error getting root node: " +  action.error.type)
    }
    const {nodeList} = await ibb.getChildren(action.node.handle);
    this.contactos = nodeList

    var action = await ibb.getExternalInbox();
    
    var isExternalContact = false
    var index;

    for (let contacto of this.contactos) {
      const outboxList = await ibb.getChildren(contacto.handle);
      for (let buzon of outboxList.nodeList) {
        if (buzon.link === action.link) {
          isExternalContact = true;
          index = this.contactos.indexOf(contacto)
          break
        }
      }
    }

    if (isExternalContact) {
      this.contactos.splice(index, 1)
    }

    if (this.outbox !== '') {
      action = await ibb.getNodeById(this.outbox.parentHandle);
      this.contact = action.node.name
      this.isSelectContact = true
      this.isSelectInbox = true
      this.inbox = this.outbox.name
    }
  },
  async mounted () {
    try {
      var action = await ibb.currentEmail();
      this.emailRecord = action.email;
    } catch (e) {
      this.$showError(e)
    }
  },
  beforeDestroy () {
    this.setOutbox('')
  },
  watch: {
    async contact() {
      if (this.contact !== '' && !this.isSelectContact && this.contactos.length > 0) {
        this.isShowContacts = true
      } else {
        this.isShowContacts = false
      }
      
      if (this.contact.includes('https://iberbox.com/inbox/')) {
        this.url = this.contact
        this.isLink = true
      }

      for (let contacto of this.contactos) {
        if (this.contact === contacto.name) {
          this.isLink = false
          const {nodeList} = await ibb.getChildren(contacto.handle);
          this.inboxes = nodeList
        }
      }
    },
    inbox() {
      if (this.outbox !== '') {
        this.url = this.outbox.link
      }
      if (this.inbox !== '' && !this.isSelectInbox) {
        this.isShowInboxes = true
      } else {
        this.isShowInboxes = false
      }
    }
  },
  computed: {
   ...mapState(['req', 'outbox', 'downloadFiles', 'isSent', 'isMaxStorage']), 
    menuStyle () {
      if (this.isShowContacts && this.contactos.length > 0) {
        return {display: 'block'}
      } else {
        return {display: 'none'}
      }
    },
    menuStyleInbox () {
      if (this.isShowInboxes && this.inboxes.length > 0) {
        return {display: 'block'}
      } else {
        return {display: 'none'}
      }
    },
    inboxStyle () {
      if (this.contact === '' || this.contactos.length === 0 || this.isLink) {
        return {display: 'none'}
      } else {
        return {display: 'flex'}
      }
    },
    urlStyle () {
      if (this.isSelectInbox || this.url !== '') {
        return {display: 'block'}
      } else {
        return {display: 'none'}
      }
    },
  },
  methods: {
    ...mapMutations(['setOutbox', 'setIsSent', 'setTotalFiles', 'setDownloadFiles']),
    selectContact (nombre) {
      this.contact = nombre;
      this.isSelectContact = true;
    },
    selectInbox (nombre, link) {
      this.inbox = nombre;
      this.url = link;
      this.isSelectInbox = true;
    },
    removeData() {
      this.contact = '';
      this.inbox = '';
      this.url = '';
      this.isSelectContact = false;
      this.isSelectInbox = false;
    },
    uploadInput: function (event) {
      this.checkConflict(event.currentTarget.files, this.archivosRecord)
    },
    uploadInputTransfer: function (event) {
      this.checkConflict(event.currentTarget.files, this.archivosTransfer)
    },
    addFile: async function () {
      document.getElementById('upload-file-to-record').value = '';
      document.getElementById('upload-file-to-record').click()
    },
    addFileTransfer: async function () {
      document.getElementById('upload-file-to-transfer').value = '';
      document.getElementById('upload-file-to-transfer').click()
    },
    checkConflict (files, items) {
      if (typeof items === 'undefined' || items === null) {
        items = []
      }

      let conflict = false
      let res;
      let nombre;
      let file;
      for (let i = 0; i < files.length; i++) {
        res = items.findIndex(function hasConflict (element) {
          if (element.name === this) {
            nombre = element.name
            file = element
          }
          return (element.name === this)
        }, files[i].name)

        if (res >= 0) {
          conflict = true
          break
        }
      }

      if (!conflict) {
        this.uploadFile(files)
        return
      }

      this.$store.commit('showHover', {
        prompt: 'replace',
        confirm: (event) => {
          event.preventDefault()
          this.$store.commit('closeHovers')
          this.uploadFileConflict(files, nombre, file)
        }
      })
    },
    async uploadFileConflict(files, nombre, file) {
      await Module.deleteFile(nombre);
      if (this.isMail) {
        let i = this.archivosRecord.indexOf(file)
        if (i === -1) return
        this.archivosRecord.splice(i, 1)
      } else {
        let i = this.archivosTransfer.indexOf(file)
        if (i === -1) return
        this.archivosTransfer.splice(i, 1)
      }
      this.uploadFile(files)
    },
    uploadFile: async function (files) {
      for (let i = 0; i < files.length; i++) {
        let file = files[i]
        await Module.registerFile(file);
        if (this.isMail) {
          this.archivosRecord.push(file)
        } else {
          this.archivosTransfer.push(file)
        }
      }
    },
    deleteItem (item) {
      if (this.isMail) {
        let i = this.archivosRecord.indexOf(item)
        if (i === -1) return
        this.archivosRecord.splice(i, 1)
      } else {
        let i = this.archivosTransfer.indexOf(item)
        if (i === -1) return
        this.archivosTransfer.splice(i, 1)
      }
    },
    uploadRecord(action) {
      this.setDownloadFiles(this.downloadFiles + 1)
      if (action.error.type) {
        throw new Error("Error upload to record: " +  action.error.type)
      }

      var name = action.name;
      var re = /(?:\.([^.]+))?$/;
      var extension = re.exec(action.node.name)[1] || "";
      extension = extension.toLowerCase();

      if (extension == "jpg" || extension == "jpeg" || extension == "png" || extension == "bmp" || extension == "heic" || extension == "heif") {
        var file = Module.getFile(name);

        console.log("Image uploaded. Creating metadata. Orientation: " + file['orientation']);
        var handle = action.node.handle;
        var url = Module.getObjectURL(name);

        var thumbnailName = "///thumbnails/" + handle + ".jpg";
        var previewName = "///previews/" + handle + ".jpg";
        const thumbnailPromise = new Promise(resolve => {
          Module.createMetadata(url, Module.MetadataType.META_THUMBNAIL.value, function(data) {
            console.log("Thumbnail created: " + data.length + " bytes");
            Module.createFile(thumbnailName, data);
            ibbLink.uploadMetadata(thumbnailName, handle, Module.MetadataType.META_THUMBNAIL.value).then(resolve);
          }, file['orientation']);
        })
        const previewPromise = new Promise(resolve => {
          Module.createMetadata(url, Module.MetadataType.META_PREVIEW.value, function(data) {
            console.log("Preview created: " + data.length + " bytes");
            Module.createFile(previewName, data);
            ibbLink.uploadMetadata(previewName, handle, Module.MetadataType.META_PREVIEW.value).then(resolve);
          }, file['orientation']);
        })
        return Promise.all([thumbnailPromise, previewPromise])
      } 
    },
    async sendRecord () {
      if (this.subject === '') {
        this.$showWarning(this.$t('records.enterSubject'));
        return;
      }
      if (this.message === '') {
        this.$showWarning(this.$t('records.enterMessage'));
        return;
      }
      if (this.url !== '') {
        var thisComponent = this;
        ibbLink.getInboxInfo(thisComponent.url).then(action => {
          if (action.error.type) {
            thisComponent.$showError(thisComponent.$t('records.wrongInbox'));
            return
          }
          ibbLink.startRecord(thisComponent.url).then(async (action) => {
            if (action.error.type) {
              this.$showError(this.$t('records.notStorage'));
              this.$store.commit('closeHovers')
              return
            }

            await ibbLink.getNodes();
            

            if (thisComponent.archivosRecord.length > 0) {
              thisComponent.setIsSent(true)
              thisComponent.setTotalFiles(thisComponent.archivosRecord.length)
              await Promise.all(thisComponent.archivosRecord.map(file => ibbLink.uploadToRecord(file.name).then(thisComponent.uploadRecord)))
              thisComponent.setIsSent(false)
            }

            var action2 = await ibbLink.setRecordInfo(thisComponent.emailRecord, thisComponent.subject, thisComponent.message);
            if (action2.error.type) {
              throw new Error("Error setting record info: " +  action2.error.type)
            }

            if (this.isMaxStorage) {
              thisComponent.$store.commit('closeHovers')
              thisComponent.$store.commit('showHover', 'upgrade-plan')
              return 
            }
            
            action2 = await ibbLink.finishRecord();
            if (action2.error.type) {
              throw new Error("Error sending record: " +  action2.error.type)
            }
            
            var link = action2.link;

            action2 = await ibbLink.getInboxInfo(thisComponent.url)

            var accountHandle = action2.accountHandle
            var nameContact = action2.name
            var nameInbox = action2.node.name

            action2 = await ibb.addContact(accountHandle, nameContact)
            var contactHandle = action2.node.handle

            const {nodeList} = await ibb.getChildren(contactHandle);
            var flag = false
            var handleInbox;
            for (let outbox of nodeList) {
              if (outbox.name === nameInbox) {
                flag = true
                handleInbox = outbox.handle
              }
            }

            if (!flag) {
              action2 = await ibb.saveInbox(contactHandle, thisComponent.url, nameInbox)
              if (action2.error.type) {
                throw new Error("Error saving inbox: " +  action2.error.type)
              }
              handleInbox = action2.node.handle
            }

            action2 = await ibb.saveSentRecord(handleInbox, link)
            if (action2.error.type) {
              throw new Error("Error saving sent record: " +  action2.error.type)
            }

            thisComponent.$store.commit('closeHovers')
          })
          .catch (e => {
            this.$store.commit('showError', e)
          })
        })
      }
    },
    async sendMail() {
      var thisComponent = this;
      var actionLink = await ibb.getExternalInbox();
      var inboxLink = actionLink.link;

      ibbLink.getInboxInfo(inboxLink).then(action => {
        if (action.error.type) {
          this.$showError(this.$t('records.wrongInbox'));
          return
        }
        ibbLink.startRecord(inboxLink).then(async function(action) {
          if (action.error.type) {
            throw new Error("Error starting record: " +  action.error.type)
          }

          await ibbLink.getNodes();
          
          if (thisComponent.archivosTransfer.length > 0) {
            thisComponent.setIsSent(true)
            thisComponent.setTotalFiles(thisComponent.archivosTransfer.length)
            await Promise.all(thisComponent.archivosTransfer.map(file => ibbLink.uploadToRecord(file.name).then(thisComponent.uploadRecord)))
            thisComponent.setIsSent(false)
          }

          var action2 = await ibbLink.setEmailRecordInfo(thisComponent.emailRecord, thisComponent.email, thisComponent.subjectTransfer, thisComponent.messageTransfer);
          if (action2.error.type) {
            throw new Error("Error setting record info: " +  action2.error.type)
          }

          action2 = await ibbLink.finishRecord();
          if (action2.error.type) {
            throw new Error("Error sending record: " +  action2.error.type)
          }
          
          var link = action2.link;

          action2 = await ibbLink.getInboxInfo(inboxLink)

          var accountHandle = action2.accountHandle
          var nameContact = thisComponent.$t('bmail.externalContact')
          var nameInbox = 'Email'

          action2 = await ibb.addContact(accountHandle, nameContact)
          var contactHandle = action2.node.handle

          const {nodeList} = await ibb.getChildren(contactHandle);
          var flag = false
          var handleInbox;
          for (let outbox of nodeList) {
            if (outbox.name === nameInbox) {
              flag = true
              handleInbox = outbox.handle
            }
          }

          if (!flag) {
            action2 = await ibb.saveInbox(contactHandle, inboxLink, nameInbox)
            if (action2.error.type) {
              throw new Error("Error saving inbox: " +  action2.error.type)
            }
            handleInbox = action2.node.handle
          }

          action2 = await ibb.saveSentRecord(handleInbox, link)
          if (action2.error.type) {
            throw new Error("Error saving sent record: " +  action2.error.type)
          }

          var url = "mailto:" + thisComponent.email + "?subject=" + thisComponent.subjectTransfer + "&body=" + thisComponent.messageTransfer + "%0D%0A%0D%0A%0D%0A"  + thisComponent.$t('bmail.externalMailMessage') + "%0D%0A%0D%0A" + link.replace('iberbox.com', 'bmail.iberbox.com');
          window.location.href = url;
          thisComponent.$store.commit('closeHovers')
        })
      })
    }
  }
}
</script>

