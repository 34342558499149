<template>
  <div>
    <help v-if="showHelp" ></help>
    <download v-else-if="showDownload"></download>
    <new-file v-else-if="showNewFile"></new-file>
    <new-dir v-else-if="showNewDir"></new-dir>
    <new-inbox v-else-if="showNewInbox"></new-inbox>
    <add-inbox v-else-if="showAddInbox"></add-inbox>
    <edit-contact v-else-if="showEditContact"></edit-contact>
    <edit-outbox v-else-if="show === 'edit-outbox'"></edit-outbox>
    <new-contact v-else-if="showNewContact"></new-contact>
    <rename v-else-if="showRename"></rename>
    <delete v-else-if="showDelete"></delete>
    <info v-else-if="showInfo"></info>
    <logout v-else-if="showLogout"></logout>
    <free-trial v-else-if="showFreeTrial"></free-trial>
    <mfa v-else-if="showMfa"></mfa>
    <download-from-link v-else-if="showDownloadFromLink"></download-from-link>
    <link-file-list v-else-if="showLinkFileList"></link-file-list>
    <replace v-else-if="showReplace"></replace>
    <share v-else-if="show === 'share'"></share>
    <write v-else-if="show === 'write'"></write>
    <upgrade-plan v-else-if="show === 'upgrade-plan'"></upgrade-plan>
    <terms-conditions v-else-if="showTermsConditions"></terms-conditions>
    <div v-show="showOverlay" @click="resetPrompts" class="overlay"></div>
  </div>
</template>

<script>
import Help from './Help'
import Info from './Info'
import Mfa from './Mfa'
import Delete from './Delete'
import Rename from './Rename'
import Download from './Download'
import DownloadFromLink from './DownloadFromLink'
import NewFile from './NewFile'
import NewDir from './NewDir'
import NewInbox from './NewInbox'
import AddInbox from './AddInbox'
import EditContact from './EditContact'
import EditOutbox from './EditOutbox'
import NewContact from './NewContact'
import Replace from './Replace'
import Share from './Share'
import Write from './Write'
import Logout from './Logout'
import TermsConditions from './TermsConditions.vue'
import { mapState } from 'vuex'
import buttons from '@/utils/buttons'
import FreeTrial from './FreeTrial.vue'
import LinkFileList from './LinkFileList.vue'
import UpgradePlan from './UpgradePlan.vue'

export default {
  name: 'prompts',
  components: {
    Info,
    Mfa,
    Delete,
    Rename,
    Download,
    DownloadFromLink,
    Share,
    NewFile,
    NewDir,
    NewInbox,
    AddInbox,
    EditContact,
    EditOutbox,
    NewContact,
    Help,
    Replace,
    TermsConditions,
    Logout,
    FreeTrial,
    LinkFileList,
    Write,
    UpgradePlan
  },
  data: function () {
    return {
      pluginData: {
        buttons,
        'store': this.$store,
        'router': this.$router
      }
    }
  },
  computed: {
    ...mapState(['show', 'plugins']),
    showInfo: function () { return this.show === 'info' },
    showMfa: function () { return this.show === 'mfa' },
    showHelp: function () { return this.show === 'help' },
    showDelete: function () { return this.show === 'delete' },
    showRename: function () { return this.show === 'rename' },
    showDownloadFromLink: function () { return this.show === 'download-from-link' },
    showLinkFileList: function () { return this.show === 'link-file-list' },
    showNewFile: function () { return this.show === 'newFile' },
    showNewDir: function () { return this.show === 'newDir' },
    showNewInbox: function () { return this.show === 'newInbox' },
    showAddInbox: function () { return this.show === 'add-inbox' },
    showEditContact: function () { return this.show === 'edit-contact' },
    showNewContact: function () { return this.show === 'newContact' },
    showDownload: function () { return this.show === 'download' },
    showReplace: function () { return this.show === 'replace' },
    showTermsConditions: function () { return this.show === 'termsConditions'},
    showLogout: function () { return this.show === 'logout' },
    showFreeTrial: function () { return this.show === 'free-trial' },
    showOverlay: function () {
      return (this.show !== null && this.show !== 'search' && this.show !== 'more')
    }
  },
  methods: {
    resetPrompts () {
      this.$store.commit('closeHovers')
    }
  }
}
</script>
