<template>
  <nav id="menu" :style="{top: this.y + 'px', left: this.x + 'px'}" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
    <div>
      <rename-button v-show="showRenameButton"></rename-button>
      <download-button v-show="showDownloadButton"></download-button>
      <share-button v-show="showShareButton"></share-button>
      <delete-button v-show="showDeleteButton"></delete-button>
      <new-inbox-button v-show="isListing && isRegister && user.perm.create && !req.parentHandle && selectedCount === 0"></new-inbox-button>
      <new-folder-button v-show="isListing && !isRegister && user.perm.create && selectedCount === 0"></new-folder-button>
      <new-file-button v-show="isListing && !isRegister && user.perm.create && selectedCount === 0"></new-file-button>
      <switch-button v-show="isListing && selectedCount === 0"></switch-button>
      <upload-button v-show="showUpload"></upload-button>
    </div>
  </nav>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import SwitchButton from './buttons/SwitchView'
import NewFolderButton from './buttons/NewFolder'
import NewInboxButton from './buttons/NewInbox'
import NewFileButton from './buttons/NewFile'
import ShareButton from './buttons/Share'
import RenameButton from './buttons/Rename'
import UploadButton from './buttons/Upload'
import DeleteButton from './buttons/Delete'
import DownloadButton from './buttons/Download'

export default {
  name: 'menu-contextual',
  components: {
    ShareButton,
    SwitchButton,
    RenameButton,
    NewFolderButton,
    NewInboxButton,
    NewFileButton,
    DeleteButton,
    DownloadButton,
    UploadButton
  },
  data: function () {
    return {
      alturaMenu: 0,
      x: 0,
      y: 0,
      alturaPantalla: window.innerHeight,
      anchoPantalla: window.innerWidth,
      pluginData: {
        'store': this.$store,
      }
    }
  },
  props: {
    coordenadaX: Number,
    coordenadaY: Number
  },
  mounted: function () {
    this.x = this.coordenadaX + 5;
    this.y = this.coordenadaY - 10;

    //Calculamos la altura del menú cuando se crea
    this.alturaMenu = document.getElementById('menu').clientHeight;

    if (this.coordenadaX > this.anchoPantalla - 180) {
      this.x = this.coordenadaX - 170;
    }

    if (this.coordenadaY > this.alturaPantalla - this.alturaMenu + 10) {
      if (this.alturaMenu < 300) {
        this.y = this.coordenadaY - this.alturaMenu + 10;
      } else if (this.alturaMenu < 600 && this.alturaMenu > 300) {
        this.y = this.coordenadaY - this.alturaMenu + 200;
      } else if(this.alturaMenu > 600) {
        this.y = this.coordenadaY - this.alturaMenu + 300;
      }
    }
  },
  watch: {
    coordenadaX (val) {
      this.x = val + 5;

      if (this.coordenadaX > this.anchoPantalla - 180) {
        this.x = this.coordenadaX - 170;
      }
    },
    coordenadaY (val) {
      this.y = val - 10;

      //Calculamos la altura del menú cuando se crea
      this.alturaMenu = document.getElementById('menu').clientHeight;

      if (this.coordenadaY > this.alturaPantalla - this.alturaMenu + 10) {
        if (this.alturaMenu < 300) {
          this.y = this.coordenadaY - this.alturaMenu + 10;
        } else if (this.alturaMenu < 600 && this.alturaMenu > 300) {
          this.y = this.coordenadaY - this.alturaMenu + 200;
        } else if(this.alturaMenu > 600) {
          this.y = this.coordenadaY - this.alturaMenu + 300;
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'selectedCount',
      'isFiles',
      'isRegister',
      'isListing',
    ]),
    ...mapState([
      'req',
      'user',
      'isMenu'
    ]),
    showUpload () {
      return this.isListing && !this.isRegister && this.user.perm.create && this.selectedCount === 0;
    },
    showShareButton () {
        return this.isFiles && (this.isListing
        ? (this.selectedCount === 1 && this.user.perm.share && 
            (!this.req.items[this.$store.state.selected[0]].isDir 
            || this.req.items[this.$store.state.selected[0]].type == 'inbox'
            || this.req.items[this.$store.state.selected[0]].type == 'folder'))
        : this.user.perm.share)
    },
    showRenameButton () {
      if (this.isFiles && (this.isListing
        ? (this.selectedCount === 1 && this.user.perm.rename)
        : this.user.perm.rename))
      {
          if (this.isRegister && (this.selectedCount !== 1 || this.req.items[this.$store.state.selected[0]].nodeType != Module.NodeType.INBOX.value))
          {
            return false;
          }
          return true;
      }
      return false;
    },
    showDeleteButton () {
        if ((this.isFiles || this.isRegister) && (this.isListing
        ? (this.selectedCount > 0 && this.user.perm.delete)
        : this.user.perm.delete))
        {
            if (!this.isRegister) {
              return true;
            }

            for (var i = 0; i < this.$store.state.selected.length; i++) {
              var type = this.req.items[this.$store.state.selected[i]].nodeType;
              if (type != Module.NodeType.FILE.value && type != Module.NodeType.FOLDER.value
                  && type != Module.NodeType.DEVICE.value && type != Module.NodeType.BACKUP.value) {
                return true;
              }
            }
            return false;
        }
        return false;
    },
    showDownloadButton () {
      return this.isFiles && this.selectedCount === 1 && this.user.perm.download
    }
  },
  methods: {
    ...mapMutations(["setIsMenu"]),
    mouseEnter() {
      this.setIsMenu(true);
    },
    mouseLeave() {
      this.setIsMenu(false);
    }
  },
  beforeDestroy () {
    this.setIsMenu(false)
  }
}
</script>