<template>
  <div :class="recordClass" :style="showRecord" @click="openRecord">
    <div class="cabecera">
      <div class="container-title">
        <button :class="[checked ? 'container-name-checked' : 'container-name']" @click="seleccionarRecord" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
          <p v-if="!checked" class="record-name">{{ contactName.substr(0, 2) }}</p>
          <check-icon v-else size="1.5x" class="custom-class"></check-icon>
        </button>
        <div class="title">
          <h2>{{ name }}</h2>
          <div class="container-files" >
            <p>{{ contactName }}</p>
            <p class="size">{{ ficheros }} ficheros | {{ size }}</p>
          </div>
        </div>
        <p class="message">{{ message }}</p>
      </div>
      <p class="modified">
        <time :datetime="ctime">{{ date }}</time>
      </p>
    </div>
  </div>
</template>

<script>

import { mapState, mapMutations } from 'vuex'
import { files as api } from '@/api'
import moment from 'moment'
import { CheckIcon } from 'vue-feather-icons'
import filesize from 'filesize'

export default {
  name: 'record',
  data: function() {
    return {
      handleRecord: '',
      checked: false,
      size: 0,
      ficheros: 0,
      date: '',
      isShow: true,
      isShowUnread: true,
      isButton: false,
      contactName: ''
    }
  },
  components: {
    CheckIcon
  },
  props: ['name', 'contact', 'message', 'handle', 'ctime', 'status', 'buscador', 'showUnread', 'checkedAll', 'parentHandle'],
  computed: {
    ...mapState(['sortOrder', 'selected', 'isPreview', 'recordSelected']),
    recordClass() {
      if (this.status === 0) {
        return 'record-unread'
      } else {
        return 'record'
      }
    },
    showRecord() {
      if (!this.isShow) {
        return {display: 'none'}
      }
      if (!this.isShowUnread) {
        return {display: 'none'}
      }
      return {display: 'block'}
    }
  },
  watch: {
    buscador (val) {
      if (!this.name.toLowerCase().includes(val.toLowerCase())) {
        this.isShow = false
      } else {
        this.isShow = true
      }
    },
    showUnread (val) {
      if (val) {
        if (this.status === 0) {
          this.isShowUnread = true
        } else {
          this.isShowUnread = false
        }
      } else {
        this.isShowUnread = true
      }
    },
    checkedAll (val) {
      if (val) {
        this.checked = true
        if (!this.recordSelected.includes(this.handle)) {
          this.addRecordSelected(this.handle)
        }
      } else {
        this.checked = false
        this.removeRecordSelected(this.handle)
      }
    },
  },
  mounted: async function () {
    var action = await ibb.getChildren(this.handle)
    this.ficheros = action.nodeList.length;
    for (let file of action.nodeList) {
      this.size = this.size + file.size
    }
    if (this.size === 0) {
      this.size = '0 Bytes'
    } else {
      this.size = filesize(this.size)
    }
    this.date = moment(this.ctime * 1000).format()
    this.date = moment(this.date || 0).fromNow()

    action = await ibb.getNodeById(this.parentHandle)
    if (action.node.type === Module.NodeType.INBOX.value) {
      this.contactName = this.contact
    } else {
      var action2 = await ibb.getNodeById(action.node.parentHandle);
      this.contactName = action2.node.name
    }
  },
  methods: {
    ...mapMutations(['setIsPreview', 'addRecordSelected', 'removeRecordSelected']),
    isMobile: function () {
      return window.innerWidth <= 768
    },
    openRecord() {
      if (!this.isButton) {
        this.$emit('open-record', this.handle);
      }
    },
    seleccionarRecord() {
      this.checked = !this.checked
      if (this.checked) {
        this.addRecordSelected(this.handle)
      } else {
        this.removeRecordSelected(this.handle)
      }
    },
    mouseEnter() {
      this.isButton = true
    },
    mouseLeave() {
      this.isButton = false
    }
  }
}
</script>