import Vue from 'vue'
import VueI18n from 'vue-i18n'

import ar from './ar.json'
import de from './de.json'
import en from './en.json'
import es from './es.json'
import fr from './fr.json'
import it from './it.json'
import ja from './ja.json'
import pl from './pl.json'
import ko from './ko.json'
import pt from './pt.json'
import ptBR from './pt-br.json'
import ru from './ru.json'
import zhCN from './zh-cn.json'
import zhTW from './zh-tw.json'

Vue.use(VueI18n)

export function detectLocale () {
  let locale = (navigator.language || navigator.browserLanguage).toLowerCase()
  switch (true) {
    case /^en.*/i.test(locale):
      locale = 'en'
      break
    case /^es.*/i.test(locale):
      locale = 'es'
      break
    default:
      locale = 'en'
  }

  return locale
}

const i18n = new VueI18n({
  locale: detectLocale(),
  fallbackLocale: 'en',
  messages: {
    'en': en,
    'es': es,
  }
})

export default i18n
