<template>
  <button @click="show" :aria-label="$t('buttons.delete')" :title="$t('buttons.delete')" class="action" id="delete-button">
    <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
    <span>{{ $t('buttons.delete') }}</span>
  </button>
</template>

<script>

import { Trash2Icon } from 'vue-feather-icons'

export default {
  name: 'delete-button',
  components: {
    Trash2Icon
  },
  methods: {
    show: function () {
      this.$store.commit('showHover', 'delete')
    }
  }
}
</script>
