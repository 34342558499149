<template>
  <div>
    <div id="progress">
      <div v-bind:style="{ width: $store.state.progress + '%' }"></div>
    </div>
    <site-header v-if="isMobile() && $route.name !== 'Record'"></site-header>
    <sidebar v-if="!isFolder && !isShare && pMethod !== 0 && $route.name !== 'Record'"></sidebar>
    <main :style="payMethodStyle">
      <router-view></router-view>
      <shell v-if="isLogged && user.perm.execute" />
    </main>
    <button-new v-if="$route.name !== 'Record' && this.permissions === 0"></button-new>
    <faldon v-if="isSent"></faldon>
    <prompts></prompts>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import Sidebar from '@/components/Sidebar'
import Prompts from '@/components/prompts/Prompts'
import SiteHeader from '@/components/Header'
import Shell from '@/components/Shell'
import ButtonNew from '@/components/ButtonNew'
import Faldon from '@/components/Faldon'
import { files as ibbapi } from '@/api'

export default {
  name: 'layout',
  data: function () {
    return {
      pMethod: 0,
    }
  },
  components: {
    Sidebar,
    SiteHeader,
    Prompts,
    Shell,
    ButtonNew,
    Faldon
  },
  computed: {
    ...mapGetters([ 'isLogged', 'isFolder', 'isShare', 'isRegister', 'isFiles' ]),
    ...mapState([ 'user', 'selected', 'isSent', 'permissions' ]),
    isMobileRegister() {
      if (this.isRegister && this.isMobile()) {
        return true 
      } else if (this.isRegister && !this.isMobile()) {
        return false
      } else if (!this.isRegister) {
        return true
      }
    },
    payMethodStyle() {
      if (this.pMethod === 0 || this.isMobile()) {
        return {width: '100%'}
      } else if (this.isFiles && !this.isRegister && !this.isTablet()) {
        return {width: 'calc(100% - 32em)', marginRight: 'auto'}
      } else if (this.isFiles && !this.isRegister && this.isTablet()) {
        return {width: 'calc(100% - 346px)', marginLeft: '90px'}
      }
    },
  },
  watch: {
    '$route': function () {
      this.fetchData();
      this.$store.commit('resetSelected')
      this.$store.commit('multiple', false)
      if (this.$store.state.show !== 'success') this.$store.commit('closeHovers')
    }
  },
  created () {
    var thisComponent = this;
    var JavaEventListenerClass = Module.EventListener.extend("EventListener", {
      onEvent: function(event)
      {
        if (event.type == Module.EventType.NEW_NODES.value || event.type == Module.EventType.DELETED_NODE.value)
        {
          thisComponent.updateStorage()
        }
        return
      }
    });

    this.interfaceObject = new JavaEventListenerClass();
    ibb.addEventListener(this.interfaceObject);
  },
  beforeDestroy () {
    ibb.removeEventListener(this.interfaceObject);
  },
  async mounted() {
    this.fetchData();
    this.updateStorage();
  },
  methods: {
    ...mapMutations(['setPermissions', 'setIsMaxStorage']),
    async fetchData() {
      if (this.isLogged) {
        try {
          var invoicing = await ibbapi.getInvoicing()
          this.pMethod = invoicing.credit;

          let action = await ibbapi.getAccountInfo();
          this.setPermissions(action.accountInfo.accesslevel)
        } catch (e) {
          this.$showError(e)
        }
      }
    },
    async updateStorage() {
      if (this.isLogged) {
        try {
          var action = await ibbapi.getAccountInfo()
          if ((action.accountInfo.ustorage > action.accountInfo.tstorage) && (action.accountInfo.amount === 0)) {
            this.setIsMaxStorage(true)
          } else {
            this.setIsMaxStorage(false)
          }
        } catch (e) {
          this.$showError(e)
        }
      }
    },
    isMobile: function () {
      return window.innerWidth <= 768
    },
    isTablet: function () {
      return (window.innerWidth <= 1024 && window.innerWidth >= 768)
    }
  }
}
</script>
