<template>
  <div class="share" v-if="notFound">
    <div class="dashboard">
     <div class="card">
      <div class="card-content">
        <p>{{ $t('download.fileNotFound') }}</p>
      </div>
     </div>
    </div>
  </div>
  <div id="download-container-folder" class="downloads" v-else-if="loaded">
    <div class="contenedor-publicidad hidden-xs">
      <div class="texto">
        <div>
          <h3>{{ $t('login.theCloud') }}</h3>
          <h2>{{ $t('login.business') }}.</h2>
        </div>
        <img src="/img/icons/Login/ibbLogoWhiteV.svg">
      </div>
      <p>{{ $t('login.differentCloud') }}</p>
      <router-link to="/register" :title="$t('records.start')" :aria-label="$t('records.start')">
        <span>{{ $t('records.start') }}</span>
      </router-link>
      <div class="contenedor-imagen">
        <img src="/img/icons/Login/ibbWebImage.png" class="desktop hidden-xs">
        <img src="/img/icons/Login/ibbWebCroppedImage.png" class="desktop visible-xs">
      </div>
    </div>
    <div class="container-right">
      <div class="container-file">
        <progress-bar size="medium" :val="progress"></progress-bar>

        <div class="share__box__info" style="border-top: 1px solid rgba(0, 0, 0, 0.05);">
          <div id="previewDiv" class="preview">
            <img class="material-icons" src="/img/icons/WEBCLIENT_MimeTypes/ibbFolder.png">
          </div>

          <h2 class="share__box__title">{{ file.name }}</h2>
          <p class="size">{{ humanSize() }}</p>
          <a id="downloadLinkTag" :href="target">
            <div class="share__box__download" v-if="finished" v-on:click="this.downloadData">
              <download-icon size="1.5x" class="custom-class"></download-icon>
              {{ $t('download.downloadAgain') }}
            </div>
            <div class="share__box__download" v-else-if="downloading">
              <download-icon size="1.5x" class="custom-class"></download-icon>
              {{ $t('download.downloading') }}
            </div>
            <div id="textDivAndroid" class="share__box__download" v-else-if="android">
              <download-icon size="1.5x" class="custom-class"></download-icon>
              {{ $t('download.downloadWithAndroidApp') }}
            </div>
            <div id="textDiv" class="share__box__download" v-on:click="this.downloadData" v-else>
              <download-icon size="1.5x" class="custom-class"></download-icon>
              {{ $t('download.downloadFolder') }}
            </div>
          </a>
          <div v-if="android && !finished && !downloading" id="textDivBrowser" class="share__box__download" v-on:click="this.downloadData">
            <download-icon size="1.5x" class="custom-class"></download-icon>
            {{ $t('download.downloadWithBrowser') }}
          </div>
          <button class="button button-copy"
          :data-clipboard-text="link"
          :aria-label="$t('buttons.copyToClipboard')"
          :title="$t('buttons.copy')">
            <link-icon size="1x" class="custom-class"></link-icon>
            <p>{{ $t('buttons.copy') }}</p>
          </button>
        </div>
        <p class="contents">{{ $t('download.content') }}</p>
        <div class="container-downloads">
          <link-file-list @update:selected="val => dest = val"></link-file-list>
        </div>
      </div>
    </div>
    <div class="contenedor-publicidad visible-xs">
      <div class="texto">
        <div>
          <h3>{{ $t('login.theCloud') }}</h3>
          <h2>{{ $t('login.business') }}.</h2>
        </div>
        <img src="/img/icons/Login/ibbLogoWhiteV.svg">
      </div>
      <p>{{ $t('login.differentCloud') }}</p>
      <router-link to="/register" :title="$t('records.start')" :aria-label="$t('records.start')">
        <span>{{ $t('records.start') }}</span>
      </router-link>
      <div class="contenedor-imagen">
        <img src="/img/icons/Login/ibbWebImage.png" class="desktop hidden-xs">
        <img src="/img/icons/Login/ibbWebCroppedImage.png" class="desktop visible-xs">
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { share as api } from '@/api'
import { baseURL } from '@/utils/constants'
import i18n from '@/i18n'
import ProgressBar from 'vue-simple-progress'
import filesize from 'filesize'
import { isAndroid } from 'mobile-device-detect'
import LinkFileList from '@/components/prompts/LinkFileList'
import Clipboard from 'clipboard'
import { DownloadIcon, LinkIcon } from 'vue-feather-icons'

export default {
  name: 'folder',
  components: {
    ProgressBar,
    LinkFileList,
    DownloadIcon,
    LinkIcon
  },
  data: () => ({
    loaded: false,
    notFound: false,
    downloading: false,
    finished: false,
    file: null,
    progress: 0,
    completedSize: 0, 
    speed: 0,
    lastUpdate: 0,
    target: null,
    android: isAndroid,
    clip: null,
    current: window.location.pathname,
    dest: null,
    numeroHijos: 0,
    isDownload: false
  }),
  watch: {
    '$route': 'fetchData'
  },
  created: function () {
      var thisComponent = this;
      var JavaEventListenerClass = Module.ActionListener.extend("ActionListener", {
      onActionProgress: function(action)
      {
          if (action.type != Module.ActionType.DOWNLOAD.value
            && action.type != Module.ActionType.DOWNLOAD_ZIP.value) {
            return;
          }

          var seconds = Math.floor(new Date().getTime() / 100);
          if (thisComponent.lastUpdate == seconds)
          {
              return;
          }
          thisComponent.lastUpdate = seconds;

          thisComponent.speed = action.speed || 0;
          thisComponent.completedSize = action.completed;
          thisComponent.progress = action.size ? action.completed * 100 / action.size : 0;
      }
    });

    this.interfaceObject = new JavaEventListenerClass();
    ibbLink.addActionListener(this.interfaceObject);

    this.fetchData()
  },
  beforeDestroy () {
    ibbLink.removeActionListener(this.interfaceObject);
  },
  mounted() {
    this.clip = new Clipboard('.button-copy')
    this.clip.on('success', () => {
      this.$showSuccess(this.$t('success.linkCopied'))
    })
  },
  computed: {
    ...mapState([
      'req',
      'selected'
    ]),
    hash: function () {
      return document.location.href; //this.$route.params.pathMatch
    },
    link: function () {
      return document.location.href;
    },
    fullLink: function () {
      return document.location.href;
    },
  },
  methods: {
    fetchData: async function () {
      try {
        this.file = await api.getFolderLink(this.hash)

        if (this.android) {
          var re = /.*\/folder\/(.+)#(.+)/;
          var result = document.location.href.match(re);
          this.target = "intent:#Intent;action=com.iberbox.android;category=android.intent.category.DEFAULT;category=android.intent.category.BROWSABLE;package=com.iberbox.android;S.type=folder;S.id=" + result[1] + ";S.key=" + result[2] + ";end";
        }

        this.loaded = true;
      } catch (e) {
        console.log(e);
        this.notFound = true
      }

      try {
        var action = await ibb.getChildren(this.file.handle);
        this.numeroHijos = action.nodeList.length;
      } catch (e) {
        console.log(e);
      }
    },
    downloadData: async function () {
      if (this.downloading)
      {
          return;
      }

      if (!this.isDownload) {
        var fileName = this.file.name;
        console.log("Downloading folder: " + fileName);

        this.downloading = true;
        var link = document.location.href;
        var action = await ibbLink.downloadZip(this.file.handle, fileName);
        this.downloading = false;
        if (action.error.type) {
          throw new Error("Error downloading folder: " + action.error.type)
        }

        this.progress = 100;
        this.completedSize = this.file.size;
        this.isDownload = true;
        this.finished = true;
      }
    },
    humanSize: function () {
      return filesize(this.file.size)
    },
    humanSpeed: function () {
      return "" + filesize(this.speed) + "/s"
    },
    humanProgress: function () {
      var completed = "" + filesize(this.completedSize)
      return this.$t('download.progress', { completed: completed.padStart(6), total: filesize(this.file.size)}); 
    }
  }
}
</script>
