<template>
  <select v-on:change="change" :value="locale">
    <option value="en">{{ $t('languages.en') }}</option>
    <option value="es">{{ $t('languages.es') }}</option>
  </select>
</template>

<script>
export default {
  name: 'languages',
  props: [ 'locale' ],
  methods: {
    change (event) {
      localStorage.setItem('lang', event.target.value)
      this.$emit('update:locale', event.target.value)
    }
  }
}
</script>
