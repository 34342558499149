<template>
  <div class="item" id="inbox-item"
  role="button"
  tabindex="0"
  draggable="true"
  @mouseenter="mouseEnter"
  @mouseleave="mouseLeave"
  @dragstart="dragStart"
  @touchstart="touchStart"
  @touchend="touchEnd"
  @touchmove="touchMove"
  @dragover="dragOver"
  @drop="drop"
  @click="clickMobile"
  :data-dir="isDir"
  :aria-label="name"
  :aria-selected="isSelected"
  :style="showInbox">
    <div class="container">
      <p class="modified">
        <time :datetime="modified">{{ date }}</time>
      </p>
      <div style="display: flex; align-items: center;">
        <button :class="[checked ? 'container-name-checked' : 'container-name']" @click="seleccionarInbox" @touchstart="touchStartButton" @touchend="touchEndButton" @mouseenter="touchStartButton" @mouseleave="touchEndButton"> 
          <p v-if="!checked" class="inbox-name">{{ name.substr(0, 1) }}</p>
          <check-icon v-else size="1.5x" class="custom-class"></check-icon>
        </button>
        <p class="name">{{ name }}</p>
      </div>
      <div class="contenedor-ficheros">
        <div style="display: flex; align-items: center;">
          <img src="/img/icons/Bmail Resources/Icons/Black Icons/sector20.png">
          <p class="size">{{ ficheros }} ficheros | {{ size }}</p>
        </div>
        <div class="botones">
          <button @click="download" :aria-label="$t('buttons.download')" :title="$t('buttons.download')" id="download-button" class="action">
            <download-icon size="1.5x" class="custom-class"></download-icon>
          </button>
          <button @click="show" @mouseenter="touchStartButton" @mouseleave="touchEndButton" @touchstart="touchStartButton" @touchend="touchEndButton" :aria-label="$t('buttons.share')" :title="$t('buttons.share')" class="action" id="share-button">
            <share-icon size="1.5x" class="custom-class"></share-icon>
          </button>
          <button v-if="this.permissions === 0" @click="showRename" @mouseenter="touchStartRename" @mouseleave="touchEndRename" @touchstart="touchStartRename" @touchend="touchEndRename" :aria-label="$t('buttons.rename')" :title="$t('buttons.rename')" class="action" id="rename-button">
            <edit-icon size="1.5x" class="custom-class"></edit-icon>
          </button>
          <button v-if="this.permissions === 0" @click="showDelete" @mouseenter="touchStartDelete" @mouseleave="touchEndDelete" @touchstart="touchStartDelete" @touchend="touchEndDelete" :aria-label="$t('buttons.delete')" :title="$t('buttons.delete')" class="action" id="delete-button">
            <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
            <span>{{ $t('buttons.delete') }}</span>
          </button>
          <div class="container-number">
            <p class="number">{{ numberRecords }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex'
import moment from 'moment'
import { files as api } from '@/api'
import filesize from 'filesize'
import { DownloadIcon, ShareIcon, EditIcon, CheckIcon, Trash2Icon } from 'vue-feather-icons'

export default {
  name: 'item-inbox',
  data: function () {
    return {
      timer: 0,
      isItem: false,
      isMultiSelected: false,
      inicial: '',
      handleInbox: '',
      numberRecords: 0,
      ficheros: 0,
      size: 0,
      date: -1,
      checked: false,
      isShow: true,
      isButton: false,
      isRename: false,
      isDelete: false
    }
  },
  components: {
    ShareIcon,
    EditIcon,
    DownloadIcon,
    CheckIcon, 
    Trash2Icon
  },
  props: ['name', 'isDir', 'url', 'type', 'modified', 'index', 'handle', 'nodeType', 'contact', 'isContainerRecord', 'isHeader', 'checkedAll', 'buscador'],
  computed: {
    ...mapState(['selected', 'req', 'isMenu', 'isPrompt', 'inboxSelected', 'permissions']),
    ...mapGetters(['selectedCount']),
    isSelected () {
      return (this.selected.indexOf(this.index) !== -1)
    },
    canDrop () {
      if (!this.isDir) return false

      for (let i of this.selected) {
        if (this.req.items[i].url === this.url) {
          return false
        }
      }

      return true
    },
    showInbox() {
      if (!this.isShow) {
        return {display: 'none'}
      }
      if (this.numberRecords > 0) {
        return {borderLeft: '15px solid var(--blue)'}
      }
    }
  },
  watch: {
    checkedAll (val) {
      if (val) {
        this.checked = true
        if (!this.inboxSelected.includes(this.handle)) {
          this.addInboxSelected(this.handle)
        }
      } else {
        this.checked = false
        this.removeInboxSelected(this.handle)
      }
    },
    buscador (val) {
      if (!this.name.toLowerCase().includes(val.toLowerCase())) {
        this.isShow = false
      } else {
        this.isShow = true
      }
    }
  },
  mounted: async function () {
    if (window.location.href.includes('=')) {
      var location = window.location.href.split('=');
      this.handleInbox = location[1].split('&');
      if (this.handleInbox[0] === this.handle) {
        this.addSelected(this.index)
      }
    }
  
    document.addEventListener('click', this.clickEvent);
    document.addEventListener('contextmenu', this.contextMenu);

    var action = await ibb.getChildren(this.handle);
    if (action.nodeList.length > 0) {
      for (let record of action.nodeList) {
        if (record.mtime > this.date) {
          this.date = record.mtime
        }
        if (record.status === 0) {
          this.numberRecords = this.numberRecords + 1;
        }
        action = await ibb.getChildren(record.handle)
        this.ficheros = this.ficheros + action.nodeList.length
        for (let file of action.nodeList) {
          this.size = this.size + file.size
        }
      }
      this.date = moment(this.date * 1000).format()
      this.date = moment(this.date || 0).fromNow()
    } else {
      this.date = moment(this.modified || 0).fromNow()
    }
    if (this.size === 0) {
      this.size = '0 Bytes'
    } else {
      this.size = filesize(this.size)
    }
  },
  beforeDestroy () {
    document.removeEventListener('click', this.clickEvent);
    document.removeEventListener('contextmenu', this.contextMenu);
  },
  methods: {
    ...mapMutations(['addSelected', 'removeSelected', 'resetSelected', 'setHandleBuzon', 'addInboxSelected', 'removeInboxSelected']),
    openMenu(event) {
      this.$emit('open-menu', event);
    },
    seleccionarInbox() {
      this.checked = !this.checked
      if (this.checked) {
        this.addInboxSelected(this.handle)
      } else {
        this.removeInboxSelected(this.handle)
      }
    },
    download: function () {
      api.downloadId(this.handle)
      this.$showSuccess(this.$t('transfers.downloadadded'))
    },
    show () {
      this.setHandleBuzon(this.handle)
      this.$store.commit('showHover', 'share')
    },
    showRename () {
      this.resetSelected()
      this.addSelected(this.index)
      this.$store.commit('showHover', 'rename')
    },
    showDelete: function () {
      if (this.isMobile() && this.$store.state.selected.indexOf(this.index) === -1) {
        this.addSelected(this.index)
      }
      this.$store.commit('showHover', 'delete')
    },
    clickMobile() {
      if (this.isMobile()) {
        /*if (this.selectedCount !== 0) {
          if (this.$store.state.selected.indexOf(this.index) !== -1) {
            this.removeSelected(this.index)
            return
          }

          this.addSelected(this.index)
          return;
        }*/
        if (!this.isButton && !this.isRename && !this.isDelete) {
          this.open();
        }
      }
    },
    clickEvent(event) {
      if (!this.isMobile()) {
        if (this.isItem) {
          if (!this.isSelected && !this.isButton) {
            this.select(event);
            this.$emit('show-records', this.handle);
            this.isMultiSelected = false;
            return;
          } else if (this.isSelected && event.ctrlKey && !this.isButton) {
            this.removeSelected(this.index)
          }
        } else {
          this.isMultiSelected = true;
          if (!event.ctrlKey) {
            if(this.isSelected && !this.isMenu && !this.isContainerRecord && !this.isHeader && !this.isPrompt) {
              this.removeSelected(this.index)
              return
            } 
          } 
        }
      }
    },
    contextMenu: function (event) {
      if (this.selectedCount === 0) {
        this.isMultiSelected = false;
      } else if (this.selectedCount > 1) {
        this.isMultiSelected = true;
      }
      if (!this.isMobile() && !this.isSelected && this.isItem) {
        this.select(event);
        this.$emit('show-records', this.handle);
        return;
      } else if (!this.isItem && this.isSelected && !this.isMultiSelected && !this.isContainerRecord) { 
        this.removeSelected(this.index);
        return;
      } 
    },
    mouseEnter() {
      this.isItem = true;
    },
    mouseLeave() {
      this.isItem = false;
    },
    touchStartButton() {
      this.isButton = true;
    },
    touchEndButton() {
      this.isButton = false;
    },
    touchStartDelete() {
      this.isDelete = true;
    },
    touchEndDelete() {
      this.isDelete = false;
    },
    touchStartRename() {
      this.isRename = true;
    },
    touchEndRename() {
      this.isRename = false;
    },
    dragStart: function () {
      if (this.selectedCount === 0) {
        this.addSelected(this.index)
        return
      }

      if (!this.isSelected) {
        this.resetSelected()
        this.addSelected(this.index)
      }
      else {
        this.resetSelected()
      }
    },
    dragOver: function (event) {
      if (!this.canDrop) return

      event.preventDefault()
      let el = event.target

      for (let i = 0; i < 5; i++) {
        if (!el.classList.contains('item')) {
          el = el.parentElement
        }
      }

      el.style.opacity = 1
    },
    drop: function (event) {
      if (!this.canDrop) return
      event.preventDefault()

      if (this.selectedCount === 0) return

      let items = []

      for (let i of this.selected) {
        items.push({
          from: this.req.items[i].handle,
          to: this.handle
        })
      }

      api.moveId(items)
        .then(() => {
          this.$store.commit('setReload', true)
        })
        .catch(this.$showError)
    },
    isMobile: function () {
      return window.innerWidth <= 768
    },
    isIos: function() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    /*dblclick: function (event) {
      if (!this.isMobile()) {
        this.open();
      }
    },*/
    select: function (event) {
      if (this.selectedCount !== 0) event.preventDefault()
      if (this.$store.state.selected.indexOf(this.index) !== -1) {
        this.removeSelected(this.index)
        return
      }

      if (event.shiftKey && this.selected.length === 1) {
        let fi = 0
        let la = 0

        if (this.index > this.selected[0]) {
          fi = this.selected[0] + 1
          la = this.index
        } else {
          fi = this.index
          la = this.selected[0] - 1
        }

        for (; fi <= la; fi++) {       
          this.addSelected(fi)
        }

        return
      }

      //if (!event.ctrlKey && !this.$store.state.multiple) this.resetSelected()
      if (!event.ctrlKey) {
        this.resetSelected()
      } 
      this.addSelected(this.index)
    },
    touchStart: function (event) {
      if (!this.isIos()) {
        return;
      }

      this.timer = setTimeout(() => {
        this.timer = 0;
        this.dragStart();
      }, 600)
    },
    touchEnd: function (event) {
      if (!this.isIos()) {
        return;
      }

      if (this.timer) {
        clearTimeout(this.timer);
	this.timer = 0;
      }
      else {
        event.preventDefault();
      }
    },
    touchMove: function (event) {
      if (!this.isIos()) {
        return;
      }

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = 0;
      }
    },
    open: function () { 
      if (!this.isDir && this.size > 50*1024*1024)
      {
        api.downloadId(this.handle)
        this.$showSuccess(this.$t('transfers.downloadadded'))
        return;
      }
      this.$router.push({path: this.$route.path + "#" + this.handle})
    }
  }
}
</script>