<template>
  <div class="dashboard" id="profile"> 
    <div class="opciones">
      <div class="contenedor-opciones">
        <div class="opcion">
          <form @submit="downloadRecoveryKey">
            <h2>{{ $t('settings.recoveryTitle') }}</h2>
            <div ref="recoveryKeyQr" style="display: none;">
              <qrcode-vue :value="recoveryKeyLink" size="100" level="M"></qrcode-vue>
            </div>
            <p>{{ $t('settings.recoveryDescription') }}</p>
            <button class="button button-create" :title="$t('buttons.download')" type="submit">
              <download-cloud-icon size="1.5x" class="custom-class"></download-cloud-icon>
              <span>{{ $t('buttons.download') }}</span>
            </button>
          </form>
        </div>
        <div class="opcion">
          <form v-if="!user.lockPassword" @submit="updatePassword">
            <h2>{{ $t('settings.changePassword') }}</h2>
            <div class="inputs">
              <div :class="[isFocusPassword ? 'campo-activo' : 'campo']">
                <user-icon size="1.5x" class="custom-class"></user-icon>
                <password id="passwordInput" v-model="password" @score="changeScore" :placeholder="$t('settings.newPassword')" :badge="false" :showStrengthMeter="false" :toggle="true" :labelHide="$t('login.hidePassword')" :labelShow="$t('login.showPassword')" @focus="focusPasswordOn" @blur="focusPasswordOff"/>
              </div>
              
              <div class="scorebar">
                <div class="scorebar-value" :style="scoreBarStyles"></div>
              </div>
              
              <div :class="[isFocusPasswordConfirm ? 'campo-activo' : 'campo']">
                <user-icon size="1.5x" class="custom-class"></user-icon>
                <input class="input input--block" type="password" id="passwordConfirmInput" v-model="passwordConf" :placeholder="$t('settings.newPasswordConfirm')" autocomplete="new-password" @focus="focusPasswordConfirmOn" @blur="focusPasswordConfirmOff"/>
              </div>
            </div>
            <button class="button button-create" :title="$t('buttons.update')" type="submit">
              <span>{{ $t('buttons.update') }}</span>
            </button>
          </form>
        </div>
      </div>
      <div class="contenedor-opciones">
        <div class="opcion">
          <form @submit="updateSettings">
            <h2>{{ $t('settings.language') }}</h2>
            <div class="flex">
              <languages class="input input--block" :locale.sync="locale"></languages>
              <button class="button button-create" :title="$t('buttons.update')" type="submit">
                <span>{{ $t('buttons.update') }}</span>
              </button>
            </div>
          </form>
        </div>
        <div class="opcion">
          <form @submit="toggleMultiFactorAuth">
            <h2>{{ $t('settings.2fa') }}</h2>
            <div v-if="user.multiFactorAuth">
              <p>{{ $t('settings.2faisenabled') }}</p>
              <button class="button button-create" :title="$t('buttons.disable')" type="submit">
                <span>{{ $t('buttons.disable') }}</span>
              </button>
            </div>
            <div v-else>
              <p>{{ $t('settings.2faisdisabled') }}</p>
              <button class="button button-create" :title="$t('buttons.enable')" type="submit">
                <lock-icon size="1.5x" class="custom-class"></lock-icon>
                <span>{{ $t('buttons.enable') }}</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { users as api } from '@/api'
import { files as ibbapi } from '@/api'
import Languages from '@/components/settings/Languages'
import jsPDF from 'jspdf'
import i18n from '@/i18n'
import moment from 'moment'
import QrcodeVue from 'qrcode.vue'
import { DownloadCloudIcon, UserIcon, LockIcon } from 'vue-feather-icons'
import Password from 'vue-password-strength-meter'

export default {
  name: 'settings',
  components: {
    Languages,
    QrcodeVue,
    DownloadCloudIcon,
    UserIcon,
    Password,
    LockIcon
  },
  data: function () {
    return {
      password: '',
      passwordConf: '',
      locale: '',
      recoveryKey: '',
      recoveryKeyLink: '',
      isFocusPassword: false,
      isFocusPasswordConfirm: false,
      passwordScore: -1,
    }
  },
  computed: {
    ...mapState([ 'user' ]),
    passwordClass () {
      const baseClass = 'input input--block'

      if (this.password === '' && this.passwordConf === '') {
        return baseClass
      }

      if (this.password === this.passwordConf) {
        return `${baseClass} input--green`
      }

      return `${baseClass} input--red`
    },
    scoreBarStyles() {
      var widthBar = ((this.passwordScore+1)/5)*100 + '%';
      return{width: widthBar};
    },
  },
  created () {
    this.locale = this.user.locale
  },
  methods: {
    ...mapMutations([ 'updateUser' ]),
    focusPasswordOn () {
      this.isFocusPassword = true;
    },
    focusPasswordOff () {
      this.isFocusPassword = false;
    },
    focusPasswordConfirmOn () {
      this.isFocusPasswordConfirm = true;
    },
    focusPasswordConfirmOff () {
      this.isFocusPasswordConfirm = false;
    },
    changeScore (score) {
      this.passwordScore = score;
    },
    async updatePassword (event) {
      event.preventDefault()

      if (this.password !== this.passwordConf || this.password === '') {
        return
      }

      try {
        const data = { id: this.user.id, password: this.password }
        await api.update(data, ['password'])
        this.updateUser(data)
        this.$showSuccess(this.$t('settings.passwordUpdated'))
      } catch (e) {
        this.$showError(e)
      }
    },
    async toggleMultiFactorAuth (event) {
      event.preventDefault();

      this.$store.commit('showHover', 'mfa')
    },
    async updateSettings (event) {
      event.preventDefault()

      try {
        const data = { id: this.user.id, locale: this.locale }
        await api.update(data, ['locale'])
        this.updateUser(data)
        this.$showSuccess(this.$t('settings.settingsUpdated'))
      } catch (e) {
        this.$showError(e)
      }
    },
    async downloadRecoveryKey (event) {
      event.preventDefault()

      try {
        var action = await ibbapi.getAccountInfo()
        var companyName = action.accountInfo.name;
        var companyNIF = action.accountInfo.nif;
        action = await ibbapi.getUserInfo()
        var userEmail = action.userInfo.email;
        var userFirstname = action.userInfo.firstname;
        var userLastname = action.userInfo.lastname;

        this.recoveryKey = await ibbapi.getRecoveryKey()
        this.recoveryKeyLink = "https://iberbox.com/recover?email=" +  Base64.encodeURI(userEmail) + "#" + this.recoveryKey;
        await this.$nextTick();

        var offset = 50
        var doc = new jsPDF('p', 'pt');

        var img = new Image();
        img.src = '/img/icons/Bmail Resources/Icons/Application Icon/bmail_favicon_32.png';
        doc.addImage(img, 'PNG', 40, 33);

        var img2 = new Image();
        img2.src = '/img/icons/logo-2.png';
        doc.addImage(img2, 'JPEG', 390, 735, 200, 105);

        doc.text(i18n.t('recovery.documentTitle'), 80, offset);
        offset += 40
        
        doc.setFontSize(12);
        doc.setFontType("normal");
        var splitMessage = doc.splitTextToSize("            " + i18n.t('recovery.documentDescription'), 500);
        doc.text(splitMessage, 40, offset);

        offset += 12 * splitMessage.length + 40

        doc.setLineWidth(1.0); 
        doc.setDrawColor(0, 0, 0);
        doc.line(40, offset, 570, offset, 'S');
        offset += 40

        if (companyName.length != 0 && companyNIF.length != 0)
        {
            doc.setFontSize(13);
            doc.setFontType("normal");
            doc.text(i18n.t('recovery.documentCompany'), 40, offset);

            doc.setFontSize(12);
            doc.setFontType("bold");
            doc.text(companyName, 120, offset);
            offset += 20

            doc.setFontType("normal");
            doc.setFontSize(13);
            doc.text(i18n.t('recovery.documentNIF'), 40, offset);

            doc.setFontSize(12);
            doc.setFontType("bold");
            doc.text(companyNIF, 120, offset);
            offset += 40

            doc.setLineWidth(1.0); 
            doc.setDrawColor(0, 0, 0);
            doc.line(40, offset, 570, offset, 'S');
            offset += 40
        }

        doc.setFontType("normal");
        doc.setFontSize(16);
        doc.text(i18n.t('recovery.documentKeyTitle'), 300, offset, null, null, 'center');
        offset += 50

        doc.setFontType("bold");
        doc.setFontSize(13);
        doc.text(this.recoveryKey, 300, offset, null, null, 'center');
        offset += 20

        doc.addImage(this.$refs["recoveryKeyQr"].firstChild.firstChild.toDataURL('image/jpeg'), 250, offset, 100, 100);
        
        
        offset = 580

        doc.setFontType("bold");
        doc.setFontSize(11);
        doc.text(i18n.t('recovery.documentWarning'), 40, offset);
        offset += 20

        doc.setFontType("normal");
        doc.setFontSize(11);
        doc.text(i18n.t('recovery.documentStartRecovery'), 40, offset);
        offset += 20

        doc.setFontType("normal");
        doc.setFontSize(11);
        doc.text(i18n.t('recovery.documentValidation'), 40, offset);
        offset += 20

        doc.setFontType("normal");
        doc.setFontSize(11);
        doc.text(i18n.t('recovery.document2fa'), 40, offset);
        offset += 20

        doc.setFontType("normal");
        doc.setFontSize(11);
        doc.text(i18n.t('recovery.documentImportant'), 40, offset);
        offset += 20

        offset += 70

        doc.setLineWidth(1.0); 
        doc.setDrawColor(0, 0, 0);
        doc.line(40, 700, 570, 700, 'S');

        doc.setFontSize(10);
        doc.text(i18n.t('recovery.documentSaveSecurely'), 40, 725)

        doc.setFontSize(11);
        doc.setFontType("bold");
        doc.text(i18n.t('recovery.documentGeneratedBy'), 40, 755)
        doc.setFontSize(10);
        doc.text(userFirstname + " " + userLastname, 40, 755 + 30)
        doc.text(userEmail, 40, 755 + 45)

        doc.setFontSize(11);
        doc.setFontType("bold");
        doc.text(i18n.t('recovery.documentDate'), 430, 750)

        doc.setFontSize(10);
        doc.text(moment().format('DD/MM/YYYY - HH:mm'), 470, 750)

        doc.save(i18n.t('recovery.documentName') + '.pdf');
      } catch (e) {
        this.$showError(e)
      }
    } 
  }
}
</script>
