<template>
  <button @click="download" :aria-label="$t('buttons.download')" :title="$t('buttons.download')" id="download-button" class="action">
    <download-icon size="1.5x" class="custom-class"></download-icon>
    <span>{{ $t('buttons.download') }}</span>
  </button>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import { files as api } from '@/api'
import { DownloadIcon } from 'vue-feather-icons'

export default {
  name: 'download-button',
  components: {
    DownloadIcon
  },
  computed: {
    ...mapState(['req', 'selected']),
    ...mapGetters(['isListing', 'selectedCount'])
  },
  methods: {
    download: function () {
      if (!this.isListing || this.selected.length === 0) {
        api.downloadId(this.req.handle)
        this.$showSuccess(this.$t('transfers.downloadadded'))
        return
      }

      if (this.selectedCount === 1) {
        api.downloadId(this.req.items[this.selected[0]].handle)
        this.$showSuccess(this.$t('transfers.downloadadded'))
        return
      }

      this.$store.commit('showHover', 'download')
    }
  }
}
</script>
