<template>
  <div class="fichero">
    <div class="iconos">
      <div ref="holder">
        <img class="material-icons" :src="icon">
      </div>
      <div class="info">
        <p :title="name" class="name">{{ name }}</p>
        <span class="size">{{ humanSize() }}</span>
      </div>
    </div>
    <div class="botones">
      <button @click="open" :aria-label="$t('buttons.viewFile')" :title="$t('buttons.viewFile')" class="action" id="share-button">
        <eye-icon size="1.5x" class="custom-class"></eye-icon>
      </button>
      <button @click="download" :aria-label="$t('buttons.download')" :title="$t('buttons.download')" id="download-button" class="action">
        <download-icon size="1.5x" class="custom-class"></download-icon>
      </button>
    </div>
  </div>
</template>

<script>

import filesize from 'filesize'
import moment from 'moment'
import { files as api } from '@/api'
import { mapMutations } from 'vuex'
import { DownloadIcon, EyeIcon } from 'vue-feather-icons'

export default {
  name: 'record-file',
  data: function () {
    return {
      isFile: false
    }
  },
  components: {
    DownloadIcon,
    EyeIcon
  },
  props: ['name', 'size', 'modified', 'handle'],
  computed: {
    icon () {
      const thisComponent = this;
      var extension = this.name.split('.');
      if (extension[extension.length - 1] == "jpg" || extension[extension.length - 1] == "jpeg" || extension[extension.length - 1] == "png" || extension[extension.length - 1] == "bmp" || extension[extension.length - 1] == "heic" || extension[extension.length - 1] == "heif") { 
        var thumbnailName = "///thumbnails/" + this.handle + ".jpg";
        if (!window.gRequestedThumbnails.has(thumbnailName))
        {
          var uri = Module.getObjectURL(thumbnailName);
          if (uri)
          {
            setTimeout(function() { 
              const holder = thisComponent.$refs.holder
              if (holder) {
                holder.style.width = "auto";
                holder.style.height = "auto";
                holder.innerHTML='<img src="' + uri + '" style="filter: none;"  />';
              }
            }, 0);
          }
          else
          {
            window.gRequestedThumbnails.add(thumbnailName);
            var promise = ibb.downloadMetadata(this.handle, Module.MetadataType.META_THUMBNAIL.value, thumbnailName);
            promise.then(function(action) {
              if (!action.error.type)
              {
                window.gRequestedThumbnails.delete(thumbnailName);
                var uri = Module.getObjectURL(thumbnailName);
                const holder = thisComponent.$refs.holder
                if (holder) {
                  holder.style.width = "auto";
                  holder.style.height = "auto";
                  holder.innerHTML='<img src="' + uri + '" style="filter: none;"  />';
                }
              }
            });
          }
        }
        else {
          console.log("Thumbnail already requested");
        }
        return '/img/icons/WEBCLIENT_MimeTypes/ibbImage.png'
      } else if (extension[extension.length - 1] == "mp3" || extension[extension.length - 1] == "wav" || extension[extension.length - 1] == "flac") {
        return '/img/icons/WEBCLIENT_MimeTypes/ibbMusic.png'
      } else if (extension[extension.length - 1] == "avi" || extension[extension.length - 1] == "mp4" || extension[extension.length - 1] == "mkv" || extension[extension.length - 1] == "mov") {
        return '/img/icons/WEBCLIENT_MimeTypes/ibbVideo.png'
      } else if (extension[extension.length - 1] == "pdf") {
        return '/img/icons/WEBCLIENT_MimeTypes/ibbPDF.png'
      } else if (extension[extension.length - 1] == "zip" || extension[extension.length - 1] == "tar" || extension[extension.length - 1] == "rar") {
        return '/img/icons/WEBCLIENT_MimeTypes/ibbZip.png'
      } else if (extension[extension.length - 1] == "doc") {
        return '/img/icons/WEBCLIENT_MimeTypes/ibbDoc.png'
      } else if (extension[extension.length - 1] == "xls") {
        return '/img/icons/WEBCLIENT_MimeTypes/ibbXLS.png'
      } else if (extension[extension.length - 1] == "js" || extension[extension.length - 1] == "css" || extension[extension.length - 1] == "html" || extension[extension.length - 1] == "vue" || extension[extension.length - 1] == "java") {
        return '/img/icons/WEBCLIENT_MimeTypes/ibbCode.png'
      } else {
        return '/img/icons/WEBCLIENT_MimeTypes/ibbFile.png'
      }
    }
  },
  beforeDestroy () {
    this.setHandleArchivo(null);
  },
  methods: {
    ...mapMutations(['setHandleArchivo']),
    humanTime: function () {
      return moment((this.modified * 1000) || 0).fromNow()
    },
    humanSize: function () {
      return filesize(this.size)
    },
    download: function () {
      api.downloadId(this.handle);
      this.$showSuccess(this.$t('transfers.downloadadded'))
    },
    mouseEnter() {
      this.isFile = true;
    },
    mouseLeave() {
      this.isFile = false;
    },
    open: function () { 
      if (this.size > 50*1024*1024)
      {
        api.downloadId(this.handle)
        this.$showSuccess(this.$t('transfers.downloadadded'))
        return;
      }
      this.$router.push({path: this.$route.path + "#" + this.handle})
      this.$store.commit('updateRequest', this.handle)
    }
  }
}
</script>