<template>
  <button @click="newFolder" :aria-label="$t('prompts.newDir')" :title="$t('prompts.newDir')" class="action" id="new-file-button">
    <folder-plus-icon size="1.5x" class="custom-class"></folder-plus-icon>
    <span>{{ $t('prompts.newDir') }}</span>
  </button>
</template>

<script>

import { FolderPlusIcon } from 'vue-feather-icons'

export default {
  name: 'new-file-button',
  components: {
    FolderPlusIcon
  },
  methods: {
    newFolder: function () {
      this.$store.commit('showHover', 'newDir')
    }
  }
}
</script>