<template>
  <div class="card floating">
    <button class="button button--flat button--grey" id ="buttonCloseRename"
      @click="$store.commit('closeHovers')"
      :aria-label="$t('buttons.close')"
      :title="$t('buttons.close')">
      <x-icon size="1.5x" class="custom-class"></x-icon>
    </button>
    <div class="card-title">
      <h2>{{ $t('bmail.editMailbox') }}</h2>
    </div>

    <div class="card-content">
      <p>{{ $t('bmail.mailboxName') }}</p>
      <input class="input input--block" :style="inputStyle" v-focus type="text" @keyup.enter="submit" v-model.trim="name">

      <template v-if="isInboxNode">
        <p>{{ $t('bmail.mailboxDescription') }}</p>
        <textarea class="input input--block" type="text" @keyup.enter="submit" v-model.trim="description" rows="7"></textarea>
      </template>

      <button @click="submit" id ="buttonRename"
      class="button button--flat"
      type="submit"
      :aria-label="$t('bmail.editMailbox')"
      :title="$t('bmail.editMailbox')">{{ $t('bmail.editMailbox') }}</button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { files as api } from '@/api'
import { XIcon } from 'vue-feather-icons'

export default {
  name: 'rename',
  data: function () {
    return {
      name: '',
      description: ''
    }
  },
  components: {
    XIcon
  },
  created () {
    this.name = this.oldName()
    this.description = this.oldDesc()
  },
  mounted () {
    this.setIsDelete(true);
    this.setIsPrompt(true);
  },
  computed: {
    ...mapState(['req', 'selected', 'selectedCount', 'isDelete']),
    ...mapGetters(['isListing']),
    isInboxNode () {
      if (this.selected.length !== 0) {
        return this.req.items[this.selected[0]].type == 'inbox';
      } else {
        return false
      }
    },
    inputStyle() {
      if (this.name !== '') {
        return {border: '2px solid var(--blue)'}
      } else {
        return {border: '2px solid rgb(0 0 0 / 20%)'}
      }
    }
  },
  methods: {
    ...mapMutations(['setIsPrompt', 'setIsDelete']),
    cancel: function () {
      this.$store.commit('closeHovers')
    },
    oldName: function () {
      if (!this.isListing) {
        return this.req.name
      }

      if (this.selectedCount === 0 || this.selectedCount > 1) {
        // This shouldn't happen.
        return
      }

      if (this.req.items[this.selected[0]] !== null && this.selected.length !== 0) {
        return this.req.items[this.selected[0]].name
      }

      if (this.selected.length === 0) {
        return this.req.name
      }
      return
    },
    oldDesc: function () {
      if (!this.isListing) {
        return ''
      }

      if (this.req.items[this.selected[0]] !== null && this.selected.length !== 0) {
        return this.req.items[this.selected[0]].message
      }

      if (this.selected.length === 0) {
        return ''
      }
      return
    },
    submit: async function () {
      let oldLink = ''
      let newLink = ''

      if (!this.isListing || this.selected.length === 0) {
        oldLink = this.req.handle
      } else {
        if (this.req.items[this.selected[0]] !== null) {
          oldLink = this.req.items[this.selected[0]].handle
        }
      }

      document.getElementById("buttonRename").disabled = true;
      document.getElementById("buttonCloseRename").disabled = true;

      newLink = this.name

      try {
        if (!this.isInboxNode) {
          await api.renameId([{ from: oldLink, to: newLink }])
        }
        else {
          if (this.description === undefined) {
            this.description = ''
          }
          await api.setInboxInfo(oldLink, this.name, this.description)
        }
        this.$store.commit('setReload', true)
      } catch (e) {
        this.$showError(e)
      }

      this.$store.commit('closeHovers')
      this.$store.commit('resetSelected')
    }
  },
  beforeDestroy () {
    this.setIsPrompt(false);
    this.setIsDelete(false);
  }
}
</script>
