import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/views/Login'
import Register from '@/views/Register'
import Recover from '@/views/Recover'
import Layout from '@/views/Layout'
import Reg from '@/views/Reg'
import Contacts from '@/views/Contacts'
import Sent from '@/views/Sent'
import Received from '@/views/Received'
import Share from '@/views/Share'
import Folder from '@/views/Folder'
import Record from '@/views/Record'
import Profile from '@/views/Profile'
import Info from '@/views/profile/Info'
import Settings from '@/views/profile/Settings'
import Invoicing from '@/views/profile/Invoicing'
import Plans from '@/views/Plans'
import TransactionOk from '@/views/TransactionOk'
import TransactionError from '@/views/TransactionError'
import Error403 from '@/views/errors/403'
import Error404 from '@/views/errors/404'
import Error500 from '@/views/errors/500'
import store from '@/store'
import { baseURL } from '@/utils/constants'

Vue.use(Router)

//Aqui se ponen las rutas 
const router = new Router({
  base: baseURL,
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      beforeEnter: (to, from, next) => {
        if (store.getters.isLogged) {
          return next({ path: '/received' })
        }

        document.title = 'Login'
        next()
      }
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
      beforeEnter: () => {
        window.location.href = 'https://iberbox.com/register';
      }
    },
    {
      path: '/transaction-ok',
      name: 'TransactionOk',
      component: TransactionOk,
      beforeEnter: (to, from, next) => {
        document.title = 'Transaction OK'
        next()
      }
    },
    {
      path: '/transaction-error',
      name: 'TransactionError',
      component: TransactionError,
      beforeEnter: (to, from, next) => {
        document.title = 'Transaction error'
        next()
      }
    },
    {
      path: '/recover',
      name: 'Recover',
      component: Recover,
      beforeEnter: (to, from, next) => {
        if (store.getters.isLogged) {
          return next({ path: '/received' })
        }

        document.title = 'Recover'
        next()
      }
    },
    {
      path: '/*',
      component: Layout,
      children: [
        {
          path: '/file/*',
          name: 'Share',
          component: Share
        },
        {
          path: '/folder/*',
          name: 'Folder',
          component: Folder
        },
        {
          path: '/record/*',
          name: 'Record',
          component: Record
        },
        {
          path: '/reg',
          name: 'Reg',
          component: Reg,
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: '/contacts',
          name: 'Contacts',
          component: Contacts,
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: '/sent',
          name: 'Sent',
          component: Sent,
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: '/received',
          name: 'Received',
          component: Received,
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: '/profile',
          name: 'Profile',
          component: Profile,
          redirect: {
            path: '/profile/info'
          },
          meta: {
            requiresAuth: true
          },
          children: [
            {
              path: '/profile/settings',
              name: 'Settings',
              component: Settings
            },
            {
              path: '/profile/info',
              name: 'Information',
              component: Info,
            },
            {
              path: '/profile/invoicing',
              name: 'Invoicing',
              component: Invoicing,
              meta: {
                requiresNotAssociated: true
              },
            },
            {
              path: '/profile/invoicing/plans',
              name: 'Invoicing Plans',
              component: Plans,
              meta: {
                requiresNotAssociated: true
              },
            },
          ]
        },
        {
          path: '/403',
          name: 'Forbidden',
          component: Error403
        },
        {
          path: '/404',
          name: 'Not Found',
          component: Error404
        },
        {
          path: '/500',
          name: 'Internal Server Error',
          component: Error500
        },
        {
          path: '/*',
          redirect: to => `/received`
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  document.title = to.name

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLogged) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })

      return
    }

    if (to.matched.some(record => record.meta.requiresNotAssociated)) {
      if (store.state.user.perm.paymentMethod === 2) {
        next({ path: '/received' })
        return
      }
    }
  }

  next()
})

export default router
