<template>
  <div class="dashboard" id="invoicing"> 
    <div class="opciones">
      <div style="background-color: #fafafa;" class="contenedor-cards">
        <form class="card facturacion" @submit="updateInvoicingAddress">
          <div class="card-title">
            <h1>{{ $t('settings.invoicingAddress') }}</h1>
          </div>

          <div class="card-content">
            <div class="inputs">
              <input maxlength="58" :class="companyNameClass" type="text" :placeholder="$t('settings.companyName')" v-model.trim="companyName" name="companyName">
              <input maxlength="32" :class="companyNIFClass" type="text" :placeholder="$t('settings.companyNIF')" v-model.trim="companyNIF" name="companyNIF">
            </div>
            <div class="inputs">
              <input maxlength="58" :class="companyAddressClass" type="text" :placeholder="$t('settings.companyAddress')" v-model.trim="companyAddress" name="companyAddress">
              <input maxlength="58" class="input input--block" type="text" :placeholder="$t('settings.companyAddress2')" v-model.trim="companyAddress2" name="companyAddress2">
            </div>
          </div>

          <button :aria-label="$t('buttons.update')" :title="$t('buttons.update')" class="action update" type="submit">
            <span>{{ $t('buttons.update') }}</span>
          </button>
        </form>

        <form class="card payment-method" @submit="updatePaymentMethod">
          <div class="card-title">
            <h1>{{ $t('settings.paymentMethod') }}</h1>
          </div>

          <div class="card-content">
            <div v-if="typeMethod === 1"><div style="float: left; font-size: 20px; font-weight: 500;">{{ $t('settings.creditCard') }}</div><br /></div>
            <div v-else-if="typeMethod === 2"><div style="float: left; font-size: 20px; font-weight: 500;">{{ $t('settings.resellerCode') }}</div><br /></div>
            <div v-else-if="typeMethod === 3"><div style="float: left; font-size: 20px; font-weight: 500;">{{ $t('settings.domiciliation') }}</div><br /></div>
            <div v-else-if="typeMethod === 4">
              <div style="text-align:left; font-size: 20px; font-weight: 500;">
                {{ $t('settings.transfer') }}
                <p class="aviso" style="font-weight: 400; font-size: 16px; line-height: 20px; margin-bottom: 0;">{{ $t('pricing.validTransfer1') }} {{ this.handleCuenta }} {{ $t('pricing.validTransfer2') }} {{ this.importe }} {{ $t('pricing.validTransfer3') }} {{ this.renewDate }}</p>
              </div>
              <br />
            </div>
            <div v-else><div style="float: left; font-size: 16px; width: 100%; margin-bottom: 15px;">{{ $t('settings.noPaymentMethod') }}</div></div>

            <div v-if="typeMethod === 1" style="margin-top: 15px;">
              <div style="float: left;">{{ $t('settings.expirationTime') }}:</div>
              <div style="text-align: right;">{{ this.paymentMethodExpiration }}</div></div>
            <div v-if="typeMethod === 1" style="margin-top: 15px;">
              <div style="float: left;">{{ $t('settings.status') }}:</div>
              <div style="text-align: right;"><span v-html="this.paymentMethodStatus"></span></div>
            </div> 
            <div v-if="typeMethod === 3" style="margin-top: 15px;">
              <div style="float: left;">{{ $t('pricing.ibanNumber') }}:</div>
              <div style="text-align: right;">{{ this.iban }}</div>
            </div>                        
          </div>

          <button v-if="typeMethod !== 0" :aria-label="$t('buttons.removePaymentMethod')" :title="$t('buttons.removePaymentMethod')" class="action update" type="submit">
            <span>{{ $t('buttons.removePaymentMethod') }}</span>
          </button>
          <button v-else :aria-label="$t('buttons.configurePaymentMethod')" :title="$t('buttons.configurePaymentMethod')" class="action update" type="submit">
            <span>{{ $t('buttons.configurePaymentMethod') }}</span>
          </button>
        </form>
      </div>
      <div style="background-color: white;" class="contenedor-cards">
        <div class="card bills">
          <div class="card-title">
            <h1>{{ $t('settings.invoices') }}</h1>
          </div>

          <div class="card-content">
            <listing-invoices class="false"></listing-invoices>
          </div>
        </div>

        <div class="suscripcion">
          <h1>{{ $t('settings.selectRate') }}</h1>
          <div class="radio-button" v-for="tarifa in tarifas" :key="tarifa.id">
            <input v-if="tarifa.period === 2" type="radio" :id="$t('settings.annualFee')" :value="tarifa.period" v-model="picked">
            <label v-if="tarifa.period === 2" :for="$t('settings.annualFee')">{{ $t('settings.annualFee') }}</label>
            <span v-if="tarifa.period === 2">{{ $t('settings.16%discount') }}</span>

            <input v-if="tarifa.period === 1" type="radio" :id="$t('settings.monthlyFee')" :value="tarifa.period" v-model="picked">
            <label v-if="tarifa.period === 1" :for="$t('settings.monthlyFee')">{{ $t('settings.monthlyFee') }}</label>
          </div>
          <button :aria-label="$t('buttons.confirmRate')" :title="$t('buttons.confirmRate')" class="action update" :style="buttonVisible" @click="cambiarTarifa">
            <span>{{ $t('buttons.confirmRate') }}</span>
          </button>
          <p class="condiciones" v-html="$t('settings.checkRates')"></p>
          <h2>{{ $t('settings.mySubscription') }}</h2>
          <h3>{{ this.plan }}</h3>
          <p>{{ $t('settings.includesServices') }}</p>
          <div class="information">
            <div class="container-content"><div style="float: left; font-weight: 600;">{{ $t('settings.price') }}:</div><div class="content" style="text-align: right; margin-bottom: 7px;">{{ this.amount }}</div></div>
            <div class="container-content" v-if="this.discount"><div style="float: left; font-weight: 600;">{{ $t('settings.discount') }}:</div><div class="content" style="text-align: right; margin-bottom: 7px;">{{ this.discount }}</div></div>
            <div class="container-content"><div style="float: left; font-weight: 600;">{{ $t('settings.basePrice') }}:</div><div class="content" style="text-align: right; margin-bottom: 7px;">{{ this.base }}</div></div>
            <div class="container-content"><div style="float: left; font-weight: 600;">{{ $t('settings.vat') }} (21%):</div><div class="content" style="text-align: right; margin-bottom: 7px;">{{ this.vat }}</div></div>
            <div class="container-content"><div style="float: left; font-weight: 600;">{{ $t('settings.totalPrice') }}:</div><div class="content" style="text-align: right; margin-bottom: 7px;">{{ this.total }}</div></div>
            <div class="container-content"><div style="float: left; font-weight: 600;">{{ $t('settings.nextRenew') }}:</div><div class="content" style="text-align: right;">{{ this.renewDate }}</div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { users as api } from '@/api'
import { files as ibbapi } from '@/api'
import ListingInvoices from '@/components/invoices/ListingInvoices'
import moment from 'moment'
import i18n from '@/i18n'

export default {
  name: 'settings',
  components: {
    ListingInvoices
  },
  data: function () {
    return {
      companyName: '',
      companyAddress: '',
      companyAddress2: '',
      companyNIF: '',
      plan: '',
      amount: '',
      discount: '',
      base: '',
      vat: '',
      total: '',
      renewDate: '',
      paymentMethodExpiration: '',
      paymentMethodStatus: '',
      locale: '',
      typeMethod: 0,
      iban: '',
      userName: '',
      name: '', 
      email: '',
      handleCuenta: '',
      importe: '',
      period: '', 
      picked: '',
      isButtonVisible: false,
      tarifa: '',
      tarifas: [],
      nombreTarifa: ''
    }
  },
  computed: {
    ...mapState([ 'user' ]),
    companyNameClass () {
      const baseClass = 'input input--block'

      if (this.typeMethod !== 0 && this.companyName === '') {
        return `${baseClass} input--red`
      }

      return baseClass
    },
    companyAddressClass () {
      const baseClass = 'input input--block'

      if (this.typeMethod !== 0 && this.companyAddress === '') {
        return `${baseClass} input--red`
      }

      return baseClass
    },
    companyNIFClass () {
      const baseClass = 'input input--block'

      if (this.typeMethod !== 0 && this.companyNIF === '') {
        return `${baseClass} input--red`
      }

      return baseClass
    },
    buttonVisible () {
      if (this.isButtonVisible) {
        return {display: 'block'}
      } else {
        return {display: 'none'}
      }
    }
  },
  watch : {
    picked () {
      if (this.tarifa !== this.picked) {
        this.isButtonVisible = true;
      } else {
        this.isButtonVisible = false;
      }
    } 
  },
  async mounted () {
    this.locale = this.user.locale
    this.fetchData(); 

    try {
      var action = await ibbapi.getUserInfo()
      var userFirstname = action.userInfo.firstname;
      var userLastname = action.userInfo.lastname;
      this.name = userFirstname + " " + userLastname;
      this.userName = userFirstname.substr(0, 1) + userLastname.substr(0, 1);
      this.email = action.userInfo.email;
    } catch (e) {
      this.$showError(e)
    }

    try {
      action = await ibbapi.getAccountInfo();
      this.handleCuenta = action.accountInfo.handle;
    } catch (e) {
      this.$showError(e)
    }
  },
  methods: {
    ...mapMutations([ 'updateUser' ]),
    async fetchData() {
      try {
        var invoicing = await ibbapi.getInvoicing()
        this.companyName = invoicing.name;
        this.companyAddress = invoicing.address;
        this.companyAddress2 = invoicing.address2;
        this.companyNIF = invoicing.nif;

        this.$store.commit('updateInvoices', invoicing.invoices)

        var subscription = invoicing.subscription;
        if (subscription)
        {
          if (subscription.period === 1) {
            this.period = i18n.t('pricing.euroPerMonth');
          } else if (subscription.period === 2) {
            this.period = i18n.t('pricing.euroPerYear');
          }
          
          if (subscription.name === 'Iberbox 500 GB - Promoción Cliente Inicial') {
            this.tarifas.push(subscription)
          } else {
            this.nombreTarifa = subscription.name;
            try {
              var action = await ibbapi.getSubscriptionOptionsWithFeatures(this.user.perm.features)
              this.tarifas = [];
              for (let tarifa of action) {
                if (tarifa.name === this.nombreTarifa) {
                  this.tarifas.push(tarifa);
                }
              }
            } catch (e) {
              this.$showError(e)
            }
          }
          this.tarifa = subscription.period;
          this.picked = subscription.period;
          
          this.plan = subscription.name;
          this.amount = (subscription.amount / 100.0).toFixed(2) + this.period
          if (subscription.discount) {
            this.discount = "-" + (subscription.discount / 100.0).toFixed(2) + this.period
          }
          this.base = ((subscription.amount - subscription.discount) / 100.0).toFixed(2) + this.period
          this.vat = (0.21 * (subscription.amount - subscription.discount) / 100.0).toFixed(2) + this.period
          this.importe = (1.21 * (subscription.amount - subscription.discount) / 100.0).toFixed(2) + "€";
          this.total = (1.21 * (subscription.amount - subscription.discount) / 100.0).toFixed(2) + this.period

          this.renewDate = moment(invoicing.rtime * 1000).format("DD/MM/YYYY");
        }

        if (invoicing.paymentMethod)
        {
          this.typeMethod = invoicing.paymentMethod.type;
          var paymentInfo = invoicing.paymentMethod;

          if (this.typeMethod === 1) {
            this.paymentMethodExpiration =  moment(paymentInfo.etime * 1000).format("MM/YYYY");
            if (paymentInfo.status == Module.PaymentMethodStatus.PMS_ACTIVE.value) {
              this.paymentMethodStatus = i18n.t('settings.paymentMethodActive');
            }
            else {
              this.paymentMethodStatus = '<div style="color: red;">' + i18n.t('settings.paymentMethodFailed') + '</div>';
            }
          } 
          if (this.typeMethod === 3) {
            this.iban = invoicing.paymentMethod.name;
          } 
        }

      } catch (e) {
        this.$showError(e)
      }
    },
    async updateInvoicingAddress (event) {
      event.preventDefault()

      if (this.companyName.length == 0) {
        this.$showError(this.$t('settings.enterCompanyName'));
        return;
      }
      if (this.companyAddress.length == 0) {
        this.$showError(this.$t('settings.enterCompanyAddress'));
        return;
      }
      if (this.companyNIF.length == 0) {
        this.$showError(this.$t('settings.enterCompanyID'));
        return;
      }

      try {
        await ibbapi.setInvoicingAddress(this.companyName, this.companyAddress, this.companyAddress2, this.companyNIF);
        this.$showSuccess(this.$t('settings.invoicingAddressUpdated'))
      } catch (e) {
        this.$showError(e)
      }
    },
    async updateSuscription (event) {
      event.preventDefault()

    },
    async updatePaymentMethod (event) {
      event.preventDefault()

      if (this.typeMethod !== 0) {
        var action = await ibb.removePaymentMethod();
        if (action.error.type) {
          this.$showError("Error removing payment method: " +  action.error.type);
        }
        this.typeMethod = 0;
      }
      else {
        this.$router.push({ path: '/profile/invoicing/plans' });
      }
    },
    async cambiarTarifa () {
      var id = '';
      this.isButtonVisible = false;

      for (let tarifa of this.tarifas) {
        if (tarifa.period === this.picked) {
          id = tarifa.id;
        }
      }

      try {
        await ibbapi.setSubscription(id);
        this.$showSuccess(this.$t('settings.changeOfFee'))
        this.fetchData();
      } catch (e) {
        this.$showError(e)
      }
    }
  }
}
</script>
