<template>
  <div class="card floating" id="free-trial">
    <img src="/img/icons/handshake-icon.svg">
    <h2>{{ $t('prompts.tryCancel') }}</h2>
    <p v-html="$t('prompts.freePeriod')"></p>
    <h3>{{ $t('prompts.setUpAccount') }}</h3>
    <div class="recorrido">
      <div class="step">
        <div class="imagen">
          <span>1</span>
        </div>
        <p>{{ $t('prompts.enterBillingAddress') }}</p>
      </div>
      <div class="step">
        <div class="imagen">
          <span>2</span>
        </div>
        <p>{{ $t('prompts.definePaymentMethod') }}</p>
      </div>
      <div class="step">
        <div class="imagen">
          <span>3</span>
        </div>
        <p>{{ $t('prompts.chooseTypeRate') }}</p>
      </div>
    </div>
    <button @click="$store.commit('closeHovers')" :aria-label="$t('prompts.understandTrialFree')" :title="$t('prompts.understandTrialFree')">
      <span>{{ $t('prompts.understandTrialFree') }}</span>
    </button>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'free-trial',
  methods: {
    ...mapMutations(['closeHovers']),
  }
}
</script>