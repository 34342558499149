<template>
  <div :class="recordClass"
  role="button"
  :style="showRecord" 
  @mouseenter="mouseEnter"
  @mouseleave="mouseLeave"
  @click="clickMobile"
  :aria-label="name"
  :aria-selected="isSelected">
    <div class="cabecera">
      <p class="modified">
        <time :datetime="ctime">{{ date }}</time>
      </p>
      <div class="container-title">
        <button :class="[checked ? 'container-name-checked' : 'container-name']" @click="seleccionarRecord" @mouseenter="mouseEnterButton" @touchstart="mouseEnterButton" @mouseleave="mouseLeaveButton" @touchend="mouseLeaveButton">
          <p v-if="!checked" class="record-name">{{ contactName.substr(0, 2) }}</p>
          <check-icon v-else size="1.5x" class="custom-class"></check-icon>
        </button>
        <div class="title">
          <h2>{{ name }}</h2>
          <p v-if="$route.name === 'Sent' || $route.name === 'Contacts'">Para: {{ contactName }}</p>
          <p v-else>{{ contactName }}</p>
        </div>
      </div>
      <p class="message">{{ message }}</p>
      <div class="contenedor-ficheros">
        <div style="display: flex; align-items: center;">
          <img src="/img/icons/Bmail Resources/Icons/Black Icons/sector20.png">
          <p class="size">{{ ficheros }} ficheros | {{ size }}</p>
        </div>
        <div class="botones">
          <button v-if="$route.name !== 'Reg' && $route.name !== 'Contacts' && this.permissions === 0" @click="showDelete" :aria-label="$t('buttons.delete')" :title="$t('buttons.delete')" class="action" id="delete-button" @mouseenter="mouseEnterDelete" @touchstart="mouseEnterDelete" @mouseleave="mouseLeaveDelete" @touchend="mouseLeaveDelete">
            <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
            <span>{{ $t('buttons.delete') }}</span>
          </button>
          <p :title="outboxName" class="outbox-name">{{ outboxName }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapMutations, mapGetters } from 'vuex'
import moment from 'moment'
import { CheckIcon, Trash2Icon } from 'vue-feather-icons'
import filesize from 'filesize'

export default {
  name: 'record-sent',
  data: function() {
    return {
      isDesplegable: false,
      handleRecord: '',
      checked: false,
      size: 0,
      ficheros: 0,
      date: '',
      isShow: true,
      isItem: false,
      isButton: false,
      contactName: '',
      outboxName: '',
      isShowUnread: true,
      isDelete: false
    }
  },
  components: {
    CheckIcon,
    Trash2Icon
  },
  props: ['name', 'contact', 'message', 'handle', 'ctime', 'status', 'buscador', 'checkedAll', 'parentHandle', 'isContainerRecord', 'index', 'showUnread'],
  computed: {
    ...mapState(['sortOrder', 'selected', 'isPreview', 'recordSelected', 'isPrompt', 'permissions']),
    ...mapGetters(['selectedCount']),
    isSelected () {
      return (this.selected.indexOf(this.index) !== -1)
    },
    showRecord() {
      if (!this.isShow) {
        return {display: 'none'}
      }
      if (!this.isShowUnread) {
        return {display: 'none'}
      }
      return {display: 'block'}
    },
    recordClass() {
      if (this.status === 0 && (this.$route.name === 'Received' || this.$route.name === 'Reg')) {
        return 'record-sent-unread'
      } else {
        return 'record-sent'
      }
    },
  },
  watch: {
    buscador (val) {
      if (!this.name.toLowerCase().includes(val.toLowerCase())) {
        this.isShow = false
      } else {
        this.isShow = true
      }
    },
    checkedAll (val) {
      if (val) {
        this.checked = true
        if (!this.recordSelected.includes(this.handle)) {
          this.addRecordSelected(this.handle)
        }
      } else {
        this.checked = false
        this.removeRecordSelected(this.handle)
      }
    },
    showUnread (val) {
      if (val) {
        if (this.status === 0) {
          this.isShowUnread = true
        } else {
          this.isShowUnread = false
        }
      } else {
        this.isShowUnread = true
      }
    },
  },
  mounted: async function () {
    var action = await ibb.getChildren(this.handle)
    this.ficheros = action.nodeList.length;
    for (let file of action.nodeList) {
      this.size = this.size + file.size
    }
    if (this.size === 0) {
      this.size = '0 Bytes'
    } else {
      this.size = filesize(this.size)
    }
    this.date = moment(this.ctime * 1000).format()
    this.date = moment(this.date || 0).fromNow()

    action = await ibb.getNodeById(this.parentHandle)
    this.outboxName = action.node.name
    if (action.node.type === Module.NodeType.INBOX.value) {
      this.contactName = this.contact
    } else {
      var action2 = await ibb.getNodeById(action.node.parentHandle);
      this.contactName = action2.node.name
    }

    document.addEventListener('click', this.clickEvent);
  },
  beforeDestroy () {
    document.removeEventListener('click', this.clickEvent);
  },
  methods: {
    ...mapMutations(['setIsPreview', 'addRecordSelected', 'removeRecordSelected', 'addSelected', 'removeSelected', 'resetSelected']),
    isMobile: function () {
      return window.innerWidth <= 768
    },
    clickMobile() {
      if (this.isMobile()) {
        /*if (this.selectedCount !== 0) {
          if (this.$store.state.selected.indexOf(this.index) !== -1) {
            this.removeSelected(this.index)
            return
          }

          this.addSelected(this.index)
          return;
        }*/
        if (!this.isButton && !this.isDelete) {
          this.open();
        } 
      }
    },
    clickEvent(event) {
      if (!this.isMobile()) {
        if (this.isItem) {
          if (!this.isSelected && !this.isButton) {
            this.select(event);
            if (!this.isDelete) {
              this.$emit('open-record', this.handle);
            }
            return;
          } else if (this.isSelected && event.ctrlKey && !this.isButton) {
            this.removeSelected(this.index)
          }
        } else {
          if (!event.ctrlKey) {
            if(this.isSelected && !this.isContainerRecord && !this.isPrompt) {
              this.removeSelected(this.index)
              return
            } 
          } 
        }
      }
    },
    seleccionarRecord() {
      this.checked = !this.checked
      if (this.checked) {
        this.addRecordSelected(this.handle)
      } else {
        this.removeRecordSelected(this.handle)
      }
    },
    showDelete() {
      if (this.isMobile() && this.$store.state.selected.indexOf(this.index) === -1) {
        this.addSelected(this.index)
      }
      this.$store.commit('showHover', 'delete')
    },
    mouseEnter() {
      this.isItem = true;
    },
    mouseLeave() {
      this.isItem = false;
    },
    mouseEnterButton() {
      this.isButton = true;
    },
    mouseLeaveButton() {
      this.isButton = false;
    },
    mouseEnterDelete() {
      this.isDelete = true;
    },
    mouseLeaveDelete() {
      this.isDelete = false;
    },
    select: function (event) {
      if (this.selectedCount !== 0) event.preventDefault()
      if (this.$store.state.selected.indexOf(this.index) !== -1) {
        this.removeSelected(this.index)
        return
      }

      if (event.shiftKey && this.selected.length === 1) {
        let fi = 0
        let la = 0

        if (this.index > this.selected[0]) {
          fi = this.selected[0] + 1
          la = this.index
        } else {
          fi = this.index
          la = this.selected[0] - 1
        }

        for (; fi <= la; fi++) {       
          this.addSelected(fi)
        }

        return
      }

      //if (!event.ctrlKey && !this.$store.state.multiple) this.resetSelected()
      if (!event.ctrlKey) {
        this.resetSelected()
      } 
      this.addSelected(this.index)
    },
    open: function () { 
      this.$router.push({path: this.$route.path + "#" + this.handle})
    }
  }
}
</script>