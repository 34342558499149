<template>
  <header @mouseenter="mouseEnter" @mouseleave="mouseLeave">
    <div>
      <template v-if="isLogged">
        <button v-if="isMobile && pMethod !== 0" @click="openSidebar" :aria-label="$t('buttons.toggleSidebar')" :title="$t('buttons.toggleSidebar')" class="action">
          <i class="material-icons">menu</i>
        </button>

        <img v-if="isMobile" class="icon" src="/img/icons/ibb-bmail.svg">

        <div>
          <button v-show="showSaveButton" :aria-label="$t('buttons.save')" :title="$t('buttons.save')" class="action" id="save-button">
            <i class="material-icons">save</i>
          </button>
        </div>
      </template>

      <div v-show="showOverlay" @click="resetPrompts" class="overlay"></div>
    </div>
  </header>
</template>

<script>
import {mapGetters, mapState, mapMutations} from 'vuex'
import { logoURL } from '@/utils/constants'
import * as api from '@/api'
import buttons from '@/utils/buttons'
import { files as ibbapi } from '@/api'


export default {
  name: 'header-layout',
  data: function () {
    return {
      userName: '',
      name: '',
      userFirstname: '',
      userLastname: '',
      earnedMoney: '',
      email: '',
      isShow: false,
      isButton: false,
      width: window.innerWidth,
      pMethod: 0,
      pluginData: {
        api,
        buttons,
        'store': this.$store,
        'router': this.$router
      },
    }
  },
  watch: {
    '$route': 'fetchData'
  },
  created () { 
    window.addEventListener('resize', () => {
      this.width = window.innerWidth
    })
  },
  computed: {
    ...mapGetters([
      'selectedCount',
      'isFiles',
      'isRegister',
      'isEditor',
      'isListing',
      'isLogged',
      'isSettings',
    ]),
    ...mapState([
      'req',
      'user',
      'loading',
      'reload',
      'multiple',
      'isMenu',
      'referrerCode',
      'isCodeVisible'
    ]),
    logoURL: () => logoURL,
    isMobile () {
      return this.width <= 768
    },
    showOverlay () {
      return this.showMore
    },
    menuStyle () {
      if (this.isShow) {
        return {display: 'block'}
      } else {
        return {display: 'none'}
      }
    },
    showSaveButton () {
      return this.isEditor && !this.isRegister && this.user.perm.modify
    },
  },
  async mounted() {
    if (this.isLogged) {
      this.fetchData();
      try {
        var action = await ibbapi.getAccountInfo()
        this.setReferrerCode(action.accountInfo.referrercode);
        this.earnedMoney = action.accountInfo.balance;
        action = await ibbapi.getUserInfo()
        this.userFirstname = action.userInfo.firstname;
        this.userLastname = action.userInfo.lastname;
        this.email = action.userInfo.email;
      } catch (e) {
        this.$showError(e)
      }
      this.name = this.userFirstname + " " + this.userLastname;

      this.userName = this.userFirstname.substr(0, 1) + this.userLastname.substr(0, 1);
    }
    window.addEventListener("click", this.click);
  },
  beforeDestroy () {
    window.removeEventListener("click", this.click);
  },
  methods: {
    ...mapMutations(['setIsMenu', 'setReferrerCode']),
    async fetchData() {
      try {
        var action = await ibbapi.getInvoicing()
        this.pMethod = action.credit;
      } catch (e) {
        this.$showError(e)
      }
    },
    click() {
      if (!this.isButton && this.isShow) {
        this.isShow = !this.isShow
      }
    },
    mouseEnterButton() {
      this.isButton = true
    },
    mouseLeaveButton() {
      this.isButton = false
    },
    show() {
      this.isShow = !this.isShow
    },
    mouseEnter() {
      this.setIsMenu(true);
    },
    mouseLeave() {
      this.setIsMenu(false);
    },
    resetPrompts () {
      this.$store.commit('closeHovers')
    },
    openSidebar () {
      this.$store.commit('showHover', 'sidebar')
    },
  }
}
</script>
