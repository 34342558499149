<template>
  <button @click="show" :aria-label="$t('buttons.rename')" :title="$t('buttons.rename')" class="action" id="rename-button">
    <edit-icon size="1.5x" class="custom-class"></edit-icon>
    <span>{{ $t('buttons.rename') }}</span>
  </button>
</template>

<script>
import { EditIcon } from 'vue-feather-icons'

export default {
  name: 'rename-button',
  components: {
    EditIcon
  },
  methods: {
    show: function () {
      this.$store.commit('showHover', 'rename')
    }
  }
}
</script>
