<template>
  <div id="login" :class="{ recaptcha: recaptcha }">
    <vue-headful title="Inicia sesión en Iberbox" description="Área de clientes de Iberbox. Inicia sesión utilizando tus credenciales de acceso." keywords="iberbox, copias de seguridad, cifrado punto a punto, nube segura" image="https://iberbox.com/img/logo.svg" />
    <div class="contenedor-body">
      <div class="contenedor-login visible-xs">
        <form @submit="submit">
          <img src="/img/icons/Login/ibbLogo.svg">
          <h1>{{ $t('login.submit') }}</h1>
          <template v-if="mfaenabled">
            {{ $t('prompts.entermfacode') }}
            <br /><br />
          <v-otp-input ref="otpInput" class="otp" input-classes="otp-input" input-type="number" separator=" " :num-inputs="6" :should-auto-focus="true" :is-input-num="true" @on-complete="handleOnComplete"/>
            <br /><br />
          </template>
          <div :class="[isFocusName ? 'campo-activo' : 'campo']">
            <user-icon size="1.5x" class="custom-class"></user-icon>
            <input type="text" id="usernameInput" :placeholder="$t('login.username')" class="input input--block" @focus="focusNameOn" @blur="focusNameOff" v-model="username">
          </div>
          <div :class="[isFocusPassword ? 'campo-activo' : 'campo']">
            <lock-icon size="1.5x" class="custom-class"></lock-icon>
            <input type="password" id="passwordInput" :placeholder="$t('login.password')" class="input input--block" @focus="focusPasswordOn" @blur="focusPasswordOff" v-model="password">
          </div>
          <div class="forgot-password"> 
            <unlock-icon size="1.5x" class="custom-class unlock-icon"></unlock-icon>
            <p @click="goToAccountRecover" v-if="signup">{{ $t('login.forgotPassword') }}</p>
          </div>
          <div v-if="recaptcha" id="recaptcha"></div>
          <input class="button button--block" id="loginButton" type="submit" :value="$t('login.submit')" :disabled="username === '' || password === ''">
        </form>
        <div class="mensaje">
          <template v-if="error !== ''">
            <div class="wrong">
              <alert-circle-icon size="1.5x" class="custom-class"></alert-circle-icon>
              <p>{{ error }}</p>
            </div>
          </template>
          <template v-else-if="info !== ''">
            <div class="logininfo">
              <b-spinner label="Loading..."></b-spinner>
              <p>{{ info }}</p>
            </div>
          </template>
        </div>
      </div>
      <div class="contenedor-publicidad">
        <div class="texto">
          <div>
            <h3>{{ $t('login.theCloud') }}</h3>
            <h2>{{ $t('login.business') }}.</h2>
          </div>
          <img src="/img/icons/Login/ibbLogoWhiteV.svg">
        </div>
        <p>{{ $t('login.differentCloud') }}</p>
        <button :aria-label="$t('records.start')" :title="$t('records.start')" @click="toggleMode" v-if="signup">
          <span>{{ $t('login.signup') }}</span>
        </button>
        <div class="contenedor-imagen">
          <img src="/img/icons/Login/ibbBmail.png" class="desktop">
        </div>
      </div>
      <div class="contenedor-login hidden-xs">
        <form @submit="submit">
          <h1>{{ $t('login.submit') }}</h1>
          <template v-if="mfaenabled">
            {{ $t('prompts.entermfacode') }}
            <br /><br />
          <v-otp-input ref="otpInput" class="otp" input-classes="otp-input" input-type="number" separator=" " :num-inputs="6" :should-auto-focus="true" :is-input-num="true" @on-complete="handleOnComplete"/>
            <br /><br />
          </template>
          <div :class="[isFocusName ? 'campo-activo' : 'campo']">
            <user-icon size="1.5x" class="custom-class"></user-icon>
            <input type="text" id="usernameInput" :placeholder="$t('login.username')" class="input input--block" @focus="focusNameOn" @blur="focusNameOff" v-model="username">
          </div>
          <div :class="[isFocusPassword ? 'campo-activo' : 'campo']">
            <lock-icon size="1.5x" class="custom-class"></lock-icon>
            <input type="password" id="passwordInput" :placeholder="$t('login.password')" class="input input--block" @focus="focusPasswordOn" @blur="focusPasswordOff" v-model="password">
          </div>
          <div class="forgot-password"> 
            <unlock-icon size="1.5x" class="custom-class"></unlock-icon>
            <p @click="goToAccountRecover" v-if="signup">{{ $t('login.forgotPassword') }}</p>
          </div>
          <div v-if="recaptcha" id="recaptcha"></div>
          <input class="button button--block" id="loginButton" type="submit" :value="$t('login.submit')" :disabled="username === '' || password === ''">
        </form>
        <div class="mensaje">
          <template v-if="error !== ''">
            <div class="wrong">
              <alert-circle-icon size="1.5x" class="custom-class"></alert-circle-icon>
              <p>{{ error }}</p>
            </div>
          </template>
          <template v-else-if="info !== ''">
            <div class="logininfo">
              <b-spinner label="Loading..."></b-spinner>
              <p>{{ info }}</p>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as auth from '@/utils/auth'
import { name, logoURL, recaptcha, recaptchaKey, signup } from '@/utils/constants'
import OtpInput from "@bachdgvn/vue-otp-input";
import vueHeadful from 'vue-headful';
import { mapState, mapMutations } from 'vuex'
import { UserIcon, LockIcon, UnlockIcon, AlertCircleIcon } from 'vue-feather-icons'

export default {
  name: 'login',
  components: { 
    'v-otp-input': OtpInput,
    'vue-headful': vueHeadful,
    UserIcon,
    LockIcon,
    UnlockIcon,
    AlertCircleIcon
 },
  computed: {
    signup: () => signup,
    name: () => name,
    logoURL: () => logoURL,
    ...mapState([ 'accountHandleJoin', 'accountKeyJoin' ])
  },
  data: function () {
    return {
      error: '',
      username: '',
      password: '',
      recaptcha: recaptcha,
      mfaenabled: false, 
      code: -1,
      info: '',
      isFocusName: false,
      isFocusPassword: false
    }
  },
  mounted () {
    if (!recaptcha) return

    window.grecaptcha.render('recaptcha', {
      sitekey: recaptchaKey
    })
  },
  beforeDestroy () {
    this.setAccountHandleJoin(null);
    this.setAccountKeyJoin(null);
  },
  methods: {
    ...mapMutations([ 'setAccountHandleJoin', 'setAccountKeyJoin' ]),
    focusNameOn () {
      this.isFocusName = true;
    },
    focusNameOff () {
      this.isFocusName = false;
    },
    focusPasswordOn () {
      this.isFocusPassword = true;
    },
    focusPasswordOff () {
      this.isFocusPassword = false;
    },
    toggleMode () {      
      this.username = '';
      this.password = '';
      this.error = '';
      this.mfaenabled = false;
      this.code = -1;
      
      this.$router.push({ path: '/register' })
    },
    goToAccountRecover () {
      this.username = '';
      this.password = '';
      this.error = '';
      this.mfaenabled = false;
      this.code = -1;
      
      this.$router.push({ path: '/recover' })
    },
    handleOnComplete: async function (value) {
      this.code = parseInt(value);

      let captcha = ''

      try {
        this.info = this.$t('login.loggingin')
        var result = await auth.login(this.username, this.password, captcha, this.code)
        if (this.accountHandleJoin !== null && this.accountKeyJoin !== null) {
          try {
            await auth.joinAccountWithCurrentUser(this.accountHandleJoin, this.accountKeyJoin)
          } catch (e) {
            this.$showError(e)
          }
          try {
            await auth.validateLogin();
          } catch (e) {
            this.$showError(e)
          }
        }
        this.info = '';
        if (result)
        {
            if (result == Module.ErrorType.E_INVOICING.value)
            {
                this.$router.push({ path: '/profile/invoicing/plans' })
                return;
            }

            this.$refs.otpInput.clearInput();
            this.mfaenabled = false;
            this.error = this.$t('login.wrongCredentials');
            var thisComponent = this;
            setTimeout(function(){ thisComponent.error = '' }, 5000);
            document.getElementById("loginButton").disabled = false;
            document.getElementById("usernameInput").disabled = false;
            document.getElementById("passwordInput").disabled = false;
            return;
        }

        this.$router.push({ path: '/received' }).catch(() => {})
      } catch (e) {
        this.info = '';
        if (e.message == 409) {
          this.error = this.$t('login.usernameTaken')
        } else {
          this.error = e;//this.$t('login.wrongCredentials')
        }
        thisComponent = this;
        setTimeout(function(){ thisComponent.error = '' }, 5000);
      }

      document.getElementById("loginButton").disabled = false;
      document.getElementById("usernameInput").disabled = false;
      document.getElementById("passwordInput").disabled = false;
    },
    async submit (event) {
      event.preventDefault()
      event.stopPropagation()

      var button = document.getElementById("loginButton");
      if (button.disabled) {
        return;
      }

      let captcha = ''

      button.disabled = true;
      document.getElementById("usernameInput").disabled = true;
      document.getElementById("passwordInput").disabled = true;

      try {
        this.info = this.$t('login.loggingin')
        var result = await auth.login(this.username, this.password, captcha)
        if (this.accountHandleJoin !== null && this.accountKeyJoin !== null) {
          try {
            await auth.joinAccountWithCurrentUser(this.accountHandleJoin, this.accountKeyJoin)
          } catch (e) {
            console.log('Se ha producido un error al unirse a una cuenta', e)
            this.$showError(e)
          }
          try {
            await auth.validateLogin();
          } catch (e) {
            this.$showError(e)
          }
        }
        this.info = '';
        if (result)
        {
          if (result == Module.ErrorType.E_INVOICING.value)
          {
              this.$router.push({ path: '/profile/invoicing/plans' })
              return;
          }

          if (result != Module.ErrorType.E_CANCELLED.value)
          {
              this.error = this.$t('login.wrongCredentials');
              var thisComponent = this;
              setTimeout(function(){ thisComponent.error = '' }, 5000);
              document.getElementById("usernameInput").disabled = false;
              document.getElementById("passwordInput").disabled = false;
              button.disabled = false;
              return;
          }

          this.mfaenabled = true;
          return;
        } else if (result === null) {
          this.error = this.$t('login.dontHavePermissions');
          var thisComponent = this;
          setTimeout(function(){ thisComponent.error = '' }, 5000);
          document.getElementById("usernameInput").disabled = false;
          document.getElementById("passwordInput").disabled = false;
          button.disabled = false;
          return;
        }

        this.$router.push({ path: '/received' }).catch(() => {})
      } catch (e) {
        if (e.message == 409) {
          this.error = this.$t('login.usernameTaken')
        } else {
          this.error = e;//this.$t('login.wrongCredentials')
        }
        thisComponent = this;
        setTimeout(function(){ thisComponent.error = '' }, 5000);
      }

      document.getElementById("usernameInput").disabled = false;
      document.getElementById("passwordInput").disabled = false;
      button.disabled = false;
    }
  }
}
</script>
