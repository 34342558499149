<template>
  <div class="item"
  role="button"
  tabindex="0"
  style="cursor: auto;"
  draggable="true"
  @dragstart="dragStart"
  @touchstart="touchStart"
  @touchend="touchEnd"
  @touchmove="touchMove"
  @dragover="dragOver"
  @drop="drop"
  @click="click"
  @dblclick="dblclick"
  :aria-label="id"
  :aria-selected="isSelected">
    <div class="invoicerow">
      <p class="invoicedate"><a class="link" style="cursor: pointer;" @click="downloadInvoice(id)">{{ humanTime() }}</a></p>
      <p v-if="!isSmallMobile()" class="invoicesubject">{{ subject }}</p>
      <p v-if="!isSmallMobile()" class="invoicetotal">{{ humanTotal() }}</p>
      <div v-if="isSmallMobile()" class="column info">
        <button @click="isShow = true" :aria-label="$t('buttons.moreInformation')" :title="$t('buttons.moreInformation')" class="action" id="info-button">
          <info-icon size="1.5x" class="custom-class"></info-icon>
        </button>
        <div class="information" :style="infoStyle">
          <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 15px;">
            <h3>{{ $t('settings.invoiceInformation') }}</h3>
            <x-icon @click="isShow = false" size="1.5x" class="custom-class"></x-icon>
          </div>
          <p class="invoicedate column"><a class="link" style="cursor: pointer;" @click="downloadInvoice(id)">{{ humanTime() }}</a></p>
          <p class="invoicesubject column">{{ subject }}</p>
          <p class="invoicetotal column">{{ humanTotal() }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex'
import filesize from 'filesize'
import moment from 'moment'
import { XIcon, InfoIcon } from 'vue-feather-icons'

export default {
  name: 'item',
  data: function () {
    return {
      timer: 0,
      isShow: false
    }
  },
  components: {
    InfoIcon,
    XIcon
  },
  props: ['id', 'ctime', 'subject', 'total', 'paid'],
  computed: {
    ...mapState(['selected', 'req']),
    ...mapGetters(['selectedCount']),
    isSelected () {
      return (this.selected.indexOf(this.index) !== -1)
    },
    icon () {
      return 'today'
    },
    canDrop () {
      return false
    },
    infoStyle () {
      if (this.isShow) {
        return {display: 'block'}
      } else {
        return {display: 'none'}
      }
    },
  },
  mounted () {
  },
  methods: {
    ...mapMutations(['addSelected', 'removeSelected', 'resetSelected']),
    isSmallMobile: function () {
      return window.innerWidth <= 450
    },
    humanSize: function () {
      return filesize(this.size)
    },
    humanTime: function () {
      return moment(this.ctime * 1000).format("DD/MM/YYYY")
    },
    humanTotal: function () {
      return "" + (this.total / 100.0).toFixed(2) + " €"
    },
    downloadInvoice: async function (id) {
      var invoiceNumber = id - 1;
      console.log("Download invoice with ID " + id);
      var paddedId = "000000000" + invoiceNumber;
      paddedId = paddedId.substr(paddedId.length-6);

      var invoiceName = "Factura-" + moment(this.ctime * 1000).format("YYYY-MM-DD-") + paddedId + ".pdf";
      var invoiceId = "///invoices/Factura-" + invoiceName;
      var action = await ibb.downloadInvoice(id, invoiceId);
      if (action.error.type) {
        console.log("Invoice not found: " + id);
        this.$showError(this.$t('settings.invoiceNotFound'));
        return;
      }
      Module.downloadFileWithName(invoiceId, invoiceName);
    },
    dragStart: function () {
      /*if (this.selectedCount === 0) {
        this.addSelected(this.index)
        return
      }

      if (!this.isSelected) {
        this.resetSelected()
        this.addSelected(this.index)
      }
      else {
        this.resetSelected()
      }*/
    },
    dragOver: function (event) {
      if (!this.canDrop) return

      event.preventDefault()
      let el = event.target

      for (let i = 0; i < 5; i++) {
        if (!el.classList.contains('item')) {
          el = el.parentElement
        }
      }

      el.style.opacity = 1
    },
    drop: function (event) {
      if (!this.canDrop) return
      event.preventDefault()

      if (this.selectedCount === 0) return

    },
    isMobile: function () {
      return window.innerWidth <= 768
    },
    isIos: function() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    click: function (event) {
      /*if (!this.isMobile()) {
        this.select(event);
        return;
      }

      if (this.selectedCount !== 0) {
        if (this.$store.state.selected.indexOf(this.index) !== -1) {
          this.removeSelected(this.index)
          return
        }

        this.addSelected(this.index)
        return;
      }
      this.open();*/
    },
    dblclick: function (event) {
      if (!this.isMobile()) {
        this.open();
      }
    },
    select: function (event) {
      if (this.selectedCount !== 0) event.preventDefault()
      if (this.$store.state.selected.indexOf(this.index) !== -1) {
        this.removeSelected(this.index)
        return
      }

      if (event.shiftKey && this.selected.length === 1) {
        let fi = 0
        let la = 0

        if (this.index > this.selected[0]) {
          fi = this.selected[0] + 1
          la = this.index
        } else {
          fi = this.index
          la = this.selected[0] - 1
        }

        for (; fi <= la; fi++) {
          this.addSelected(fi)
        }

        return
      }

      //if (!event.ctrlKey && !this.$store.state.multiple) this.resetSelected()
      if (!event.ctrlKey) this.resetSelected()
      this.addSelected(this.index)
    },
    touchStart: function (event) {
      if (!this.isIos()) {
        return;
      }

      this.timer = setTimeout(() => {
        this.timer = 0;
        this.dragStart();
      }, 600)
    },
    touchEnd: function (event) {
      if (!this.isIos()) {
        return;
      }

      if (this.timer) {
        clearTimeout(this.timer);
	this.timer = 0;
      }
      else {
        event.preventDefault();
      }
    },
    touchMove: function (event) {
      if (!this.isIos()) {
        return;
      }

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = 0;
      }
    },
    open: function () { 
      this.$router.push({path: this.url})
    }
  }
}
</script>
