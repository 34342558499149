<template>
  <button @click="show" :aria-label="$t('buttons.share')" :title="$t('buttons.share')" class="action" id="share-button">
    <share-icon size="1.5x" class="custom-class"></share-icon>
    <span>{{ $t('buttons.share') }}</span>
  </button>
</template>

<script>
import { ShareIcon } from 'vue-feather-icons'

export default {
  name: 'share-button',
  components: {
    ShareIcon
  },
  methods: {
    show () {
      this.$store.commit('showHover', 'share')
    }
  }
}
</script>
