<template>
  <button @click="newFile" :aria-label="$t('sidebar.newFile')" :title="$t('sidebar.newFile')" class="action" id="new-file-button">
    <file-plus-icon size="1.5x" class="custom-class"></file-plus-icon>
    <span>{{ $t('sidebar.newFile') }}</span>
  </button>
</template>

<script>

import { FilePlusIcon } from 'vue-feather-icons'

export default {
  name: 'new-file-button',
  components: {
    FilePlusIcon
  },
  methods: {
    newFile: function () {
      this.$store.commit('showHover', 'newFile')
    }
  }
}
</script>
