<template>
  <div>
    <div id="new-floating-button" @click="isShow = true" :class="[isShow ? 'clicked' : '']">
      <plus-icon v-if="!isShow" size="1.5x" class="custom-class"></plus-icon>
      <x-icon v-else size="1.5x" class="custom-class"></x-icon>
    </div>
    <nav id="menu-nuevo" :style="menuStyle">
      <div style="text-align: left;">
        <button :aria-label="$t('bmail.newMessage')" :title="$t('bmail.newMessage')" class="action" @click="newMessage">
          <mail-icon size="1.5x" class="custom-class"></mail-icon>
          <span>{{ $t('bmail.newMessage') }}</span>
        </button>
        <button v-if="!isMaxStorage" :aria-label="$t('sidebar.newInbox')" :title="$t('sidebar.newInbox')" class="action" @click="newInbox">
          <inbox-icon size="1.5x" class="custom-class"></inbox-icon>
          <span>{{ $t('sidebar.newInbox') }}</span>
        </button>
        <button v-if="!isMaxStorage" :aria-label="$t('bmail.newContact')" :title="$t('bmail.newContact')" class="action" @click="newContact">
          <user-icon size="1.5x" class="custom-class"></user-icon>
          <span>{{ $t('bmail.newContact') }}</span>
        </button>
      </div>
    </nav>
    <div v-show="showOverlay" class="overlay" @click="isShow = false"></div>
  </div>
</template>

<script>
import { PlusIcon, XIcon, MailIcon, InboxIcon, UserIcon } from 'vue-feather-icons'
import { mapState } from 'vuex'

export default {
  name: 'button-new',
  data: function () {
    return {
      isShow: false,
    }
  },
  components: {
    PlusIcon,
    XIcon,
    MailIcon,
    InboxIcon,
    UserIcon
  },
  computed: {
    ...mapState([
      'isMaxStorage'
    ]),
    menuStyle() {
      if (this.isShow) {
        return {display: 'block'}
      } else {
        return {display: 'none'}
      }
    },
    showOverlay() {
      return this.isShow
    }
  },
  methods: {
    newMessage() {
      this.$store.commit('showHover', 'write')
      this.isShow = false
    },
    newInbox() {
      this.$store.commit('showHover', 'newInbox')
      this.isShow = false
    },
    newContact() {
      this.$store.commit('showHover', 'newContact')
      this.isShow = false
    }
  }
}
</script>

