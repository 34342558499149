import store from '@/store'
import { renew } from '@/utils/auth'
import { baseURL } from '@/utils/constants'

export async function fetchURL (url, opts) {
  opts = opts || {}
  opts.headers = opts.headers || {}

  let { headers, ...rest } = opts

  const res = await fetch(`${baseURL}${url}`, {
    headers: {
      'X-Auth': store.state.jwt,
      ...headers
    },
    ...rest
  })

  if (res.headers.get('X-Renew-Token') === 'true') {
    await renew(store.state.jwt)
  }

  return res
}

export async function fetchJSON (url, opts) {
  const res = await fetchURL(url, opts)

  if (res.status === 200) {
    return res.json()
  } else {
    throw new Error(res.status)
  }
}

export function removePrefix (url) {
  if (url.startsWith('/reg#')) {
    url = '//reg' + url.slice(5);
  } else if ((url.startsWith('/sent#')) || (url.startsWith('/contacts#'))) {
    url = '//cnt' + url.slice(5);
  } else if (url.startsWith('/reg/#')) {
    url = '//reg' + url.slice(6);
  } else if ((url.startsWith('/sent/#')) || (url.startsWith('/contacts/#'))) {
    url = '//cnt' + url.slice(6);
  } else if (url.startsWith('/reg')) {
    url = '//reg/';
  } else if ((url.startsWith('/sent')) || (url.startsWith('/contacts'))) {
    url = '//cnt/';
  }

  if (url === '') url = '/'
  if (url[0] === '#') url = url.substring(1);
  if (url[0] !== '/') url = '/' + url

  return url
}

