<template>
  <div id="mfa" class="card floating">
    <div class="card-title">
      <h2>{{ $t('prompts.mfa') }}</h2>
    </div>

      <div v-if="loading" class="card-content">
      {{ $t('prompts.loadingmfa') }}
      </div>
      <div v-else class="card-content">
        <template v-if="!user.multiFactorAuth && !codeScanned">
            {{ $t('prompts.scanmfacode') }}
            <qrcode-vue style="text-align: center; margin-top: 2em;" :value=url size="180" level="M"></qrcode-vue>
            <br />
            <p v-html="$t('prompts.linkmfacode', {link: url})"></p>

            <div class="card-action">
              <button type="submit"
                @click="codeScanned=true;"
                class="button button--flat"
                :aria-label="$t('buttons.done')"
                :title="$t('buttons.done')">{{ $t('buttons.done') }}</button>
            </div>
        </template>

        <template v-if="user.multiFactorAuth || codeScanned">
          {{ $t('prompts.entermfacode') }}
          <br /><br />
          <v-otp-input ref="otpInput" class="otp" input-classes="otp-input" input-type="number" separator=" " :num-inputs="6" :should-auto-focus="true" :is-input-num="true" @on-complete="handleOnComplete"/>
          <div v-if="error !== ''" class="wrong">{{ error }}</div>
        </template>
      </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import filesize from 'filesize'
import moment from 'moment'
import { users, files as api } from '@/api'
import QrcodeVue from 'qrcode.vue'
import OtpInput from "@bachdgvn/vue-otp-input";
import deepClone from 'lodash.clonedeep'

export default {
  name: 'mfa',
  components: { QrcodeVue, 
    "v-otp-input": OtpInput },
  data: function() {
    return {
      url: '',
      loading: true,
      code: -1,
      error: '',
      codeScanned: false
    };
  },
  computed: {
    ...mapState(['user'])
  },
  mounted () {
    if (!this.user.multiFactorAuth)
    {
        this.getCode();
    }
    else
    {
        this.loading = false;
    }
  },
  methods: {
    getCode: async function () {
        var action = await ibb.generate2faUrl();
        if (action.error.type) {
            this.$showError(this.$t('prompts.2faerrorgenerating') + " (Code: " + action.error.type + ")");
            this.$store.commit('closeHovers')
            return;
        }

        this.url = action.text;
        var thisComponent = this;
        setTimeout(function(){ thisComponent.loading = false }, 1500);
    },
    handleOnComplete: function (value) {
        this.code = parseInt(value);
        this.toggle2fa();
    },
    toggle2fa: async function() {
        var user = deepClone(this.user);
        if (this.url)
        {
            var action = await ibb.enable2fa(this.code);
            if (action.error.type) {
              if (action.error.type == Module.ErrorType.E_FORBIDDEN.value) {
                  this.error = this.$t('prompts.2fawrongcode');
                  this.$refs.otpInput.clearInput();
                  var thisComponent = this;
                  setTimeout(function(){ thisComponent.error = '' }, 5000);
                  return;
              }

              this.$showError(this.$t('prompts.2faerrorenabling') + " (Code: " + action.error.type + ")");
              this.$store.commit('closeHovers')
              return;
            }
            user.multiFactorAuth = 1;
            this.$showSuccess(this.$t('settings.2faenabled'))
        }
        else
        {
            var action = await ibb.disable2fa(this.code);
            if (action.error.type) {
              if (action.error.type == Module.ErrorType.E_FORBIDDEN.value) {
                  this.error = this.$t('prompts.2fawrongcode');
                  this.$refs.otpInput.clearInput();
                  var thisComponent = this;
                  setTimeout(function(){ thisComponent.error = '' }, 5000);
                  return;
              }

             this.$showError(this.$t('prompts.2faerrordisabling') + " (Code: " + action.error.type + ")");
             this.$store.commit('closeHovers')
             return;
            }
            user.multiFactorAuth = 0;
            this.$showSuccess(this.$t('settings.2fadisabled'))
        }

        this.$store.commit('setUser', user);
        this.$store.commit('closeHovers')
    }
  }
}
</script>
