import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import getters from './getters'

Vue.use(Vuex)

const state = {
  user: null,
  req: {},
  oldReq: {},
  lastSnapshots: {},
  nextSnapshots: {},  
  invoices: {},
  activeSessions: {},
  inactiveSessions: {},
  activeSessionHandle: '',
  clipboard: {
    key: '',
    items: []
  },
  jwt: '',
  progress: 0,
  loading: false,
  reload: false,
  selected: [],
  multiple: false,
  show: null,
  showShell: false,
  showMessage: null,
  showConfirm: null,
  sortOrder: 1,
  isMenu: false,
  isDelete: false,
  isRename: false,
  referrerCode: '',
  isCodeVisible: true,
  handleRecord: null,
  handleArchivo: null,
  handleBuzon: null,
  idCoupon: null,
  emailCoupon: '',
  parentHandle: null,
  accountHandle: null,
  joinLink: '',
  isPreview: false,
  accountHandleJoin: null,
  accountKeyJoin: null,
  registrationId: null,
  createAccountCoupon: '',
  linkInbox: '',
  handleOpenSpace: '',
  isPrompt: false,
  inboxSelected: [],
  contactSelected: [],
  recordSelected: [],
  record: '',
  outbox: '',
  outboxSelected: [],
  totalFiles: 0,
  downloadFiles: 1,
  isSent: false,
  permissions: 0,
  isMaxStorage: false
}

export default new Vuex.Store({
  strict: true,
  state,
  getters,
  mutations
})
