<template>
  <div id="contacts">
    <editor v-if="isEditor"></editor>
    <div v-else-if="$route.hash === '' || (isMobile && (record !== '' || contacts.length > 0 || outboxes.length > 0 || records !== '') && $route.hash !== '')" class="contenedor-global">
      <div v-if="!isMobile" class="container-listing" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
        <div class="title">
          <div class="buscador">
            <input class="input" type="text" :placeholder="$t('buttons.search')" v-model="buscador"> 
          </div>
          <div class="botones">
            <button v-if="!isMaxStorage && this.permissions === 0" @click="newContact" :aria-label="$t('buttons.new')" :title="$t('buttons.new')" class="action" id="new-inbox-button">
              <p>{{ $t('buttons.new') }}</p>
              <edit-3-icon size="1.5x" class="custom-class"></edit-3-icon>
            </button>
          </div>
        </div>

        <div style="display: flex; align-items: center; padding: 0 20px; margin-bottom: 15px; margin-top: 20px;">
          <div :class="[checked ? 'checkbox-activo' : 'checkbox']">
            <input type="checkbox" id="checkbox" v-model="checked">
            <label v-if="checked === false" for="checkbox">{{ $t('bmail.markAll') }}</label>
            <label v-else for="checkbox">{{ $t('bmail.uncheck') }}</label>
          </div>

          <delete-button v-if="contactSelected.length > 0 && this.permissions === 0"></delete-button>
        </div>
        <div class="contenedor-listing" style="overflow-y: scroll; height: 80vh; overflow-x: hidden">
          <listing-contact @show-outboxes="showOutboxes" v-bind:isContainerRecord="isContainerRecord" v-bind:contacts="contacts" v-bind:buscador="buscador" v-bind:checkedAll="checked" :class="{ multiple }"></listing-contact>
        </div>
      </div>
      <div v-else class="container-listing" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
        <div v-if="contacts.length > 0">
          <div class="title">
            <div class="buscador">
              <input class="input" type="text" :placeholder="$t('buttons.search')" v-model="buscador"> 
            </div>
            <div class="botones">
              <button v-if="!isMaxStorage && this.permissions === 0" @click="newContact" :aria-label="$t('buttons.new')" :title="$t('buttons.new')" class="action" id="new-inbox-button">
                <p>{{ $t('buttons.new') }}</p>
                <edit-3-icon size="1.5x" class="custom-class"></edit-3-icon>
              </button>
            </div>
          </div>

          <div style="display: flex; align-items: center; padding: 0 20px; margin-bottom: 15px; margin-top: 20px;">
            <div :class="[checked ? 'checkbox-activo' : 'checkbox']">
              <input type="checkbox" id="checkbox" v-model="checked">
              <label v-if="checked === false" for="checkbox">{{ $t('bmail.markAll') }}</label>
              <label v-else for="checkbox">{{ $t('bmail.uncheck') }}</label>
            </div>

            <delete-button v-if="contactSelected.length > 0 && this.permissions === 0"></delete-button>
          </div>
          <div class="contenedor-listing">
            <listing-contact @show-outboxes="showOutboxes" v-bind:isContainerRecord="isContainerRecord" v-bind:contacts="contacts" v-bind:buscador="buscador" v-bind:checkedAll="checked" :class="{ multiple }"></listing-contact>
          </div>
        </div>
        <div v-else-if="outboxes.length > 0">
          <div class="title">
            <button :aria-label="$t('bmail.back')" :title="$t('bmail.back')" class="action" id="back-button" @click="backRecords">
              <arrow-left-icon size="1.5x" class="custom-class"></arrow-left-icon>
            </button>
            <div class="buscador">
              <input class="input" type="text" :placeholder="$t('buttons.search')" v-model="buscadorInbox"> 
            </div>
            <div v-if="!isExternalContact" class="botones">
              <button v-if="!isMaxStorage && this.permissions === 0" @click="newOutbox" :aria-label="$t('buttons.new')" :title="$t('buttons.new')" class="action" id="new-outbox-button">
                <plus-circle-icon size="1.5x" class="custom-class"></plus-circle-icon>
                <p>{{ $t('buttons.new') }}</p>
              </button>
            </div>
          </div>
          <div style="display: flex; align-items: center; padding: 0 20px; margin-bottom: 15px; margin-top: 20px;">
            <div :class="[checkedInbox ? 'checkbox-activo' : 'checkbox']">
              <input type="checkbox" id="checkboxOutbox" v-model="checkedInbox">
              <label v-if="checkedInbox === false" for="checkboxOutbox">{{ $t('bmail.markAll') }}</label>
              <label v-else for="checkboxOutbox">{{ $t('bmail.uncheck') }}</label>
            </div>

            <delete-button v-if="outboxSelected.length > 0 && this.permissions === 0"></delete-button>
          </div>
          <div class="contenedor-listing">
            <outbox v-for="(outbox) in outboxes"
            :key="outbox.handle"
            v-bind:outbox="outbox"
            v-bind:buscador="buscadorInbox"
            v-bind:checkedAll="checkedInbox"
            @show-records="showRecords"
            ></outbox>
          </div>
        </div>
        <div v-else-if="records.length > 0">
          <div class="title">
            <button :aria-label="$t('bmail.back')" :title="$t('bmail.back')" class="action" id="back-button" @click="back">
              <arrow-left-icon size="1.5x" class="custom-class"></arrow-left-icon>
            </button>
            <div class="buscador">
              <input class="input" type="text" :placeholder="$t('buttons.search')" v-model="buscadorRecord"> 
            </div>
            <div class="botones">
              <button v-if="this.permissions === 0" :aria-label="$t('bmail.write')" :title="$t('bmail.write')" class="action" id="new-inbox-button" @click="write">
                <p>{{ $t('bmail.write') }}</p>
                <edit-3-icon size="1.5x" class="custom-class"></edit-3-icon>
              </button>
            </div>
          </div>

          <div style="display: flex; align-items: center; padding: 0 20px; margin-bottom: 15px; margin-top: 20px;">
            <div :class="[checkedRecords ? 'checkbox-activo' : 'checkbox']">
              <input type="checkbox" id="checkboxRecords" v-model="checkedRecords">
              <label v-if="checkedRecords === false" for="checkboxRecords">{{ $t('bmail.markAll') }}</label>
              <label v-else for="checkboxRecords">{{ $t('bmail.uncheck') }}</label>
            </div>

            <delete-button v-if="recordSelected.length > 0 && this.permissions === 0"></delete-button>
          </div>
          <div class="contenedor-listing">
            <record-sent v-for="(record) in records"
            :key="record.handle"
            v-bind:name="record.name"
            v-bind:contact="record.contact"
            v-bind:message="record.message"
            v-bind:handle="record.handle"
            v-bind:parentHandle="record.parentHandle"
            v-bind:ctime="record.ctime"
            v-bind:index="records.indexOf(record)"
            v-bind:status="record.status"
            v-bind:isContainerRecord="false"
            v-bind:buscador="buscadorRecord"
            v-bind:showUnread="true"
            v-bind:checkedAll="checkedRecords"
            @open-record="openRecord"
            ></record-sent>
          </div>
        </div>
        <open-record v-else-if="record !== ''" @back="backListingRecords"></open-record>
        <div v-else class="container-image">
          <button :aria-label="$t('bmail.back')" :title="$t('bmail.back')" class="action" id="back-button" @click="back">
            <arrow-left-icon size="1.5x" class="custom-class"></arrow-left-icon>
          </button>
          <div>
            <img src="/img/icons/Bmail Resources/Illustrations/ibb-nothing.png">
            <h2>{{ $t('records.nothingToSee') }}</h2>
            <h3>{{ $t('records.recordDisplayed') }}</h3>
          </div>
        </div>
      </div>
      <div class="container-records">
        <div v-if="selectedCount === 0" class="container-image">
          <div>
            <img src="/img/icons/Bmail Resources/Illustrations/img-contacts.png">
            <h2>{{ $t('records.everythingPlace') }}</h2>
            <h3>{{ $t('bmail.associatedInboxesList') }}</h3>
          </div>
        </div>
        <div class="records" v-else-if="(selectedCount === 1) && (outboxes.length > 0) && (records === '')">
          <div class="title">
            <div class="buscador">
              <input class="input" type="text" :placeholder="$t('buttons.search')" v-model="buscadorInbox"> 
            </div>
            <div v-if="!isExternalContact" class="botones">
              <button v-if="!isMaxStorage && this.permissions === 0" @click="newOutbox" :aria-label="$t('buttons.new')" :title="$t('buttons.new')" class="action" id="new-outbox-button">
                <plus-circle-icon size="1.5x" class="custom-class"></plus-circle-icon>
                <p>{{ $t('buttons.new') }}</p>
              </button>
            </div>
          </div>
          <div style="display: flex; align-items: center; padding: 0 20px; margin-bottom: 15px; margin-top: 20px;">
            <div :class="[checkedInbox ? 'checkbox-activo' : 'checkbox']">
              <input type="checkbox" id="checkboxOutbox" v-model="checkedInbox">
              <label v-if="checkedInbox === false" for="checkboxOutbox">{{ $t('bmail.markAll') }}</label>
              <label v-else for="checkboxOutbox">{{ $t('bmail.uncheck') }}</label>
            </div>

            <delete-button v-if="outboxSelected.length > 0 && this.permissions === 0"></delete-button>
          </div>
          <div class="contenedor-listing" style="overflow-y: scroll; height: 80vh; overflow-x: hidden">
            <div style="height: auto;">
              <outbox v-for="(outbox) in outboxes"
              :key="outbox.handle"
              v-bind:outbox="outbox"
              v-bind:buscador="buscadorInbox"
              v-bind:checkedAll="checkedInbox"
              @show-records="showRecords"
              ></outbox>
            </div>
          </div>
        </div>
        <div class="records" v-else-if="(selectedCount === 1) && (outboxes.length > 0) && (records.length > 0) && (record === '')">
          <div class="title">
            <button :aria-label="$t('bmail.back')" :title="$t('bmail.back')" class="action" id="back-button" @click="back">
              <arrow-left-icon size="1.5x" class="custom-class"></arrow-left-icon>
            </button>
            <div class="buscador">
              <input class="input" type="text" :placeholder="$t('buttons.search')" v-model="buscadorRecord"> 
            </div>
            <div class="botones">
              <button v-if="this.permissions === 0" :aria-label="$t('bmail.write')" :title="$t('bmail.write')" class="action" id="new-inbox-button" @click="write">
                <p>{{ $t('bmail.write') }}</p>
                <edit-3-icon size="1.5x" class="custom-class"></edit-3-icon>
              </button>
            </div>
          </div>

          <div style="display: flex; align-items: center; padding: 0 20px; margin-bottom: 15px; margin-top: 20px;">
            <div :class="[checkedRecords ? 'checkbox-activo' : 'checkbox']">
              <input type="checkbox" id="checkboxRecords" v-model="checkedRecords">
              <label v-if="checkedRecords === false" for="checkboxRecords">{{ $t('bmail.markAll') }}</label>
              <label v-else for="checkboxRecords">{{ $t('bmail.uncheck') }}</label>
            </div>

            <delete-button v-if="recordSelected.length > 0 && this.permissions === 0"></delete-button>
          </div>
          <div class="contenedor-listing" style="overflow-y: scroll; height: 80vh; overflow-x: hidden">
            <div style="height: auto;">
              <record v-for="(record) in records"
              :key="record.handle"
              v-bind:name="record.name"
              v-bind:contact="record.contact"
              v-bind:message="record.message"
              v-bind:handle="record.handle"
              v-bind:parentHandle="record.parentHandle"
              v-bind:ctime="record.ctime"
              v-bind:status="record.status"
              v-bind:buscador="buscadorRecord"
              v-bind:checkedAll="checkedRecords"
              @open-record="openRecord"
              ></record>
            </div>
          </div>
        </div>
        <div v-else-if="(records.length > 0) && (selectedCount === 1) && (record !== '') && (outboxes.length > 0)" class="scroll-record">
          <div style="height: auto;">
            <open-record @back="backRecord"></open-record>
          </div>
        </div>
        <div v-else-if="(selectedCount === 1) && (outboxes.length === 0)" class="container-image">
          <div>
            <img src="/img/icons/Bmail Resources/Illustrations/ibb-nothing.png">
            <h2>{{ $t('records.nothingToSee') }}</h2>
            <h3>{{ $t('records.outboxDisplayed') }}</h3>
          </div>
        </div>
        <div v-else-if="(selectedCount === 1) && (records.length === 0)" class="container-image">
          <div>
            <img src="/img/icons/Bmail Resources/Illustrations/ibb-nothing.png">
            <h2>{{ $t('records.nothingToSee') }}</h2>
            <h3>{{ $t('records.recordOutboxDisplayed') }}</h3>
          </div>
        </div>
      </div>
    </div>
    <preview v-else-if="isPreview" :key="$route.hash"></preview>
  </div>
</template>

<script>
import { Edit3Icon, PlusCircleIcon, ArrowLeftIcon } from 'vue-feather-icons'
import { mapGetters, mapMutations, mapState } from 'vuex'
import ListingContact from '../components/files/ListingContact.vue'
import DeleteButton from '@/components/buttons/Delete'
import Outbox from '@/components/Outbox'
import Record from '@/components/Record'
import RecordSent from '@/components/RecordSent'
import OpenRecord from '@/components/OpenRecord'
import Preview from '@/components/files/Preview'
import Editor from '@/components/files/Editor'

export default {
  name: 'files',
  components: {
    Edit3Icon,
    ListingContact,
    PlusCircleIcon,
    DeleteButton,
    Outbox,
    Record,
    ArrowLeftIcon,
    OpenRecord,
    Preview,
    Editor,
    RecordSent
  },
  computed: {
    ...mapGetters([
      'selectedCount',
      'isListing',
      'isFiles',
      'isEditor'
    ]),
    ...mapState([
      'multiple',
      'contactSelected',
      'outboxSelected',
      'recordSelected',
      'reload',
      'record',
      'loading',
      'selected',
      'permissions',
      'isMaxStorage'
    ]),
    isPreview () {
      return !this.loading && !this.isEditor && this.flag
    },
    isMobile () {
      return window.innerWidth <= 768
    },
  },
  data: function () {
    return {
      isContainerRecord: false,
      buscador: '',
      checked: false,
      contacts: [],
      buscadorInbox: '',
      checkedInbox: false,
      outboxes: [],
      interfaceObject: '',
      records: '',
      buscadorRecord: '',
      checkedRecords: false,
      handleOutbox: '',
      flag: true,
      isExternalContact: false,
    }
  },
  watch: {
    'reload': function () {
      if (this.isMobile) {
        this.reviewRecord()
      } else {
        this.fetchData()
      }
    },
    '$route': function () {
      this.reviewRecord()
    },
  },
  created () {
    var thisComponent = this;
    var JavaActionListenerClass = Module.ActionListener.extend("ActionListener", {
      onActionFinish: function(action)
      {
        if (action.type === Module.ActionType.DELETE_NODE.value) {
          thisComponent.showRecords(thisComponent.handleOutbox)
          return;
        }

        if (action.type === Module.ActionType.MANAGE_OUTBOXES.value && thisComponent.isMobile) {
          thisComponent.reviewRecord()
          return;
        }

        if (action.type != Module.ActionType.MANAGE_CONTACTS.value) {
          return;
        }

        thisComponent.fetchData()
        thisComponent.resetSelected()
      }
    });

    var JavaEventListenerClass = Module.EventListener.extend("EventListener", {
      onEvent: function(event)
      {
        if ((event.type == Module.EventType.NEW_NODES.value && event.node.type == Module.NodeType.CONTACT.value) || (event.type == Module.EventType.DELETED_NODE.value && event.node.type == Module.NodeType.CONTACT.value))
        {
          thisComponent.fetchData()
        }
        return;
      }
    });

    if (this.isMobile) {
      this.reviewRecord()
    } else {
      this.fetchData()
    }
    this.interfaceObject = new JavaActionListenerClass();
    ibb.addActionListener(this.interfaceObject);
    ibb.addEventListener(new JavaEventListenerClass());
  },
  beforeDestroy () {
    ibb.removeActionListener(this.interfaceObject);
  },
  methods: {
    ...mapMutations([ 'setLoading', 'resetSelected', 'setRecord', 'addSelected' ]),
    async fetchData () {
      // Reset view information.
      this.$store.commit('setReload', false)
      //this.$store.commit('resetSelected')
      this.$store.commit('multiple', false)
      this.$store.commit('closeHovers')

      // Set loading to true and reset the error.
      this.setLoading(true)

      var res;
      var action = await ibb.getRootNode(Module.NodeType.CONTACT_ROOT.value);
      if (action.error.type) {
        throw new Error("Error getting root node: " +  action.error.type)
      }
      const {nodeList} = await ibb.getChildren(action.node.handle);
      res = nodeList
      this.contacts = nodeList

      this.$store.commit('updateRequest', res)
      this.setLoading(false)
    },
    async reviewRecord () {
      if (this.$route.hash === '' && this.record !== '') {
        var index = -1
        for (let item of this.records) {
          if (item.handle === this.record.handle) {
            for (let outbox of this.outboxes) {
              if (outbox.handle === this.record.parentHandle) {
                for (let contact of this.contacts) {
                  if (contact.handle === outbox.parentHandle) {
                    index = this.contacts.indexOf(contact)
                  }
                }
              }
            }
          }
        }
        if (index !== -1) {
          this.addSelected(index);
        }
      } else if (this.$route.hash !== '' && this.isMobile) {
        this.flag = false;
        var node = await ibb.getNodeById(this.$route.hash.replace('#', '')) 
        if (node.node.type === Module.NodeType.CONTACT.value) {
          this.contacts = []
          this.showOutboxes(node.node.handle)
        } else if (node.node.type === Module.NodeType.OUTBOX.value) {
          this.contacts = []
          this.outboxes = []
          this.showRecords(node.node.handle)
        } else if (node.node.type === Module.NodeType.RECORD.value) {
          this.contacts = []
          this.outboxes = []
          this.records= ''
          this.setRecord(node.node)
          this.flag = true
        } else {
          this.contacts = []
          this.outboxes = []
          this.records= ''
          this.setRecord('')
          this.flag = true
        }
      } else if (this.$route.hash === '' && this.isMobile) {
        this.fetchData()
      }
    },
    mouseEnter() {
      this.isContainerRecord = false;
    },
    mouseLeave() {
      this.isContainerRecord = true;
    },
    newContact: function () {
      this.$store.commit('showHover', 'newContact')
    },
    newOutbox () {
      this.$store.commit('showHover', 'add-inbox')
    },
    write() {
      this.$store.commit('showHover', 'write')
    },
    async showOutboxes(handle) {
      const {nodeList} = await ibb.getSortedChildren(handle, -Module.SortOrderType.SORT_ORDER_CREATION_TIME.value);
      this.outboxes = nodeList
      var action = await ibb.getExternalInbox();
      this.isExternalContact = false
      for (let outbox of this.outboxes) {
        if (outbox.link === action.link) {
          this.isExternalContact = true;
          break
        }
      }
      this.records = ''
      this.flag = true
    },
    async showRecords(handle) {
      const {nodeList} = await ibb.getSortedChildren(handle, -Module.SortOrderType.SORT_ORDER_MODIFICATION_TIME.value);
      this.records = nodeList
      this.handleOutbox = handle
      this.flag = true
    },
    async openRecord(handle) {
      var action = await ibb.getNodeById(handle);
      this.setRecord(action.node)
    },
    async back() {
      this.flag = false;
      this.records = '';
      if (this.isMobile) {
        var node = await ibb.getNodeById(this.$route.hash.replace('#', ''))
        this.$router.push({ path: this.$route.path + '#' + node.node.parentHandle })
      }
    },
    backRecord() {
      this.setRecord('')
    },
    backRecords() {
      this.$router.push({ path: this.$route.path })
    },
    backListingRecords() {
      this.$router.push({ path: this.$route.path + "#" + this.record.parentHandle })
      this.setRecord('')
    }
  }
}
</script>