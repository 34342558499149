<template>
  <div class="share" v-if="error">
    <div class="dashboard">
    <h2 style="text-transform: uppercase;">{{ $t('records.electronicRegister') }}</h2>
     <div class="card">
      <div class="card-content">
        <p>{{ $t('records.recordNotFound') }}</p>
      </div>
     </div>
    </div>
  </div>
  <div class="share" v-else-if="loaded">
    <div class="contenedor-body">
      <div class="dashboard visible-xs">
        <div class="icon">
          <img src="/img/icons/ibb-bmail.svg">
        </div>
        
        <div class="card">
          <div class="card-title">
            <h2>{{ $t('records.receiver') }}</h2>
          </div>

          <div class="card-content">
            <h4>{{ this.receiver }}</h4>
            <br />
            <p><strong>{{ $t('records.addressee') }}</strong></p>
            <p>{{ this.inbox }}</p>
          </div>
        </div>

        <div class="card">

          <div class="card-title">
            <h2>{{ $t('records.recordInfo') }}</h2>
          </div>

          <div class="card-content">
            <p>{{ $t('records.email') }}</p>
            <input class="input input--block" v-focus type="text" v-model.trim="email" readonly>

            <p>{{ $t('records.subject') }}</p>
            <input class="input input--block" v-focus type="text" v-model.trim="recordSubject" readonly>

            <p>{{ $t('records.message') }}</p>
            <textarea class="input input--block" type="text" v-model.trim="message" rows="5" readonly></textarea>

            <p>{{ $t('records.files') }}</p>
            <div v-if="attachments.length">
              <listing-record ref="downloads-list"></listing-record>
              <button :aria-label="$t('bmail.downloadAll')" :title="$t('bmail.downloadAll')" class="action" id="download-button" @click="downloadAll">
                <span>{{ $t('bmail.downloadAll') }}</span>
              </button>
            </div>
            <p v-else>{{ $t('records.noAttachments') }}</p>
          </div>
        </div>

        <div class="card">
          <div class="card-title">
            <h2>{{ $t('records.checkData') }}</h2>
          </div>

          <div class="card-content">
            <p>{{ $t('records.link') }}</p>
            <div style="word-wrap: break-word;">{{ link }}</div>
            <p><qrcode-vue :value="link" size="100" level="M"></qrcode-vue></p>
          </div>

          <div class="card-action">
            <button @click="submit" id ="buttonDownloadRecept"
              class="button button--flat"
              type="submit"
              :aria-label="$t('records.downloadReceipt')"
              :title="$t('records.downloadReceipt')">{{ $t('records.downloadReceipt') }}</button>
          </div>
        </div>
      </div>
      <div class="contenedor-publicidad">
        <div class="texto">
          <div>
            <h3>{{ $t('records.getTouch') }}</h3>
            <h2>{{ $t('records.confidentialSecure') }}</h2>
          </div>
          <img src="/img/icons/Login/ibbLogoWhiteV.svg">
        </div>
        <p>{{ $t('login.differentCloud') }}</p>
        <a href="https://iberbox.com/register" target="_blank" :title="$t('records.start')" :aria-label="$t('records.start')">
          <span>{{ $t('login.signup') }}</span>
        </a>
        <div class="contenedor-imagen">
          <img src="/img/icons/Login/ibbBmail.png" class="desktop">
        </div>
      </div>
      <div class="dashboard hidden-xs" style="overflow-y: scroll; height: 100vh; overflow-x: hidden">
        <div style="height: auto;">
          <div class="icon">
            <img src="/img/icons/ibb-bmail.svg">
          </div>
          
          <div class="card">
            <div class="card-title">
              <h2>{{ $t('records.receiver') }}</h2>
            </div>

            <div class="card-content">
              <h4>{{ this.receiver }}</h4>
              <br />
              <p><strong>{{ $t('records.addressee') }}</strong></p>
              <p>{{ this.inbox }}</p>
            </div>
          </div>

          <div class="card">

            <div class="card-title">
              <h2>{{ $t('records.recordInfo') }}</h2>
            </div>

            <div class="card-content">
              <p>{{ $t('records.email') }}</p>
              <input class="input input--block" v-focus type="text" v-model.trim="email" readonly>

              <p>{{ $t('records.subject') }}</p>
              <input class="input input--block" v-focus type="text" v-model.trim="recordSubject" readonly>

              <p>{{ $t('records.message') }}</p>
              <textarea class="input input--block" type="text" v-model.trim="message" rows="5" readonly></textarea>

              <p>{{ $t('records.files') }}</p>
              <div v-if="attachments.length">
                <listing-record ref="downloads-list"></listing-record>
                <button :aria-label="$t('bmail.downloadAll')" :title="$t('bmail.downloadAll')" class="action" id="download-button" @click="downloadAll">
                  <span>{{ $t('bmail.downloadAll') }}</span>
                </button>
              </div>
              <p v-else>{{ $t('records.noAttachments') }}</p>
            </div>
          </div>

          <div class="card">
            <div class="card-title">
              <h2>{{ $t('records.checkData') }}</h2>
            </div>

            <div class="card-content">
              <p>{{ $t('records.link') }}</p>
              <div style="word-wrap: break-word;">{{ link }}</div>
              <p><qrcode-vue :value="link" size="100" level="M"></qrcode-vue></p>
            </div>

            <div class="card-action">
              <button @click="submit" id ="buttonDownloadRecept"
                class="button button--flat"
                type="submit"
                :aria-label="$t('records.downloadReceipt')"
                :title="$t('records.downloadReceipt')">{{ $t('records.downloadReceipt') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { share as api } from '@/api'
import { mapState, mapMutations } from 'vuex'
import QrcodeVue from 'qrcode.vue'
import i18n from '@/i18n'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import filesize from 'filesize'
import ListingRecord from '@/components/records/ListingRecord'
import { files as fileapi } from '@/api'

export default {
  name: 'share',
  components: {
    QrcodeVue,
    ListingRecord
  },
  data: () => ({
    loaded: false,
    notFound: false,
    progress: 0,
    id: '',
    email: '',
    inbox: '',
    recordSubject: '',
    message: '',
    receiver: '',
    link: '',
    ctime: 0,
    mtime: 0,
    attachments: [],
    error: null,
    downloading: false,
    recordName: '',
    recordHandle: ''
  }),
  watch: {
    '$route': 'fetchData'
  },
  created: function () {
      var thisComponent = this;
      var JavaEventListenerClass = Module.ActionListener.extend("ActionListener", {
      onActionProgress: function(action)
      {
          if (action.type != Module.ActionType.DOWNLOAD.value
            && action.type != Module.ActionType.DOWNLOAD_ZIP.value) {
            return;
          }

          thisComponent.progress = action.size ? action.completed * 100 / action.size : 0;
      }
    });

    this.interfaceObject = new JavaEventListenerClass();
    ibb.addActionListener(this.interfaceObject);

    this.fetchData()
  },
  beforeDestroy () {
    ibb.removeActionListener(this.interfaceObject);
  },
  computed: {
    ...mapState(['req']),
  },
  methods: {
    ...mapMutations(['setIsSent', 'setTotalFiles', 'setDownloadFiles']),
    fetchData: async function () {
      var link = document.location.href;
      var action = await ibbLink.getRecordInfo(link);
      try {
        if (action.error.type) {
          this.error = action.error.type;
          return;
        }
      } catch (e) {
        this.$showError(e)
        return;
      }

      this.id = action.text;
      this.email = action.node.contact;
      this.inbox = action.node.inbox;
      this.recordSubject = action.node.subject;
      this.message = action.node.message;
      this.link = document.location.href;
      this.ctime = action.node.ctime;
      this.mtime = action.node.mtime;
      this.receiver = action.name;
      this.recordHandle = action.node.handle;
      this.recordName = action.node.name;

      var data = {};
      data["isTransfers"] = 1;
      data["transferType"] = Module.TransferType.UPLOAD.value;
      data["items"] = [];
      data["numDirs"] = 0;

      if (action.nodeList) {
        data["numFiles"] = action.nodeList.length;
        for (var i = 0; i < action.nodeList.length; i++) {
          var node = action.nodeList[i];
          data["items"].push(fileapi.nodeToObject("", node));
          data["items"][i]["readonly"] = 1;
          data["items"][i]["index"] = i;
          this.attachments.push([action.nodeList[i].name, filesize(action.nodeList[i].size), action.nodeList[i].hash]);
        }
      }
      else {
        data["numFiles"] = 0;
      }

      this.$store.commit('updateRequest', data)
            
      this.loaded = true
    },
    submit: function () {
      console.log("Creating PDF")
      if (window.innerWidth <= 736) {
        this.$showSuccess(i18n.t('records.creatingPDF'));
        setTimeout(this.generatePDF, 1000);
      }
      else {
        this.generatePDF();
      }
    },
    generatePDF: function () {
      var vm = this
      var columns = [
        [ i18n.t('records.name'), i18n.t('records.size'), i18n.t('records.hash')]
      ];

      var offset = 50
      var doc = new jsPDF('p', 'pt');

      var img = new Image();
      img.src = '/img/icons/Bmail Resources/Icons/Application Icon/bmail_favicon_32.png';
      doc.addImage(img, 'PNG', 40, 33);

      var img2 = new Image();
      img2.src = '/img/icons/logo-2.png';
      doc.addImage(img2, 'JPEG', 390, 735, 200, 105);

      doc.text(i18n.t('records.receiptTitle'), 80, offset);
      offset += 30
      
      doc.setFontSize(13);
      doc.setFontType("normal");
      doc.text(i18n.t('records.receiver'), 40, offset);

      doc.setFontSize(12);
      doc.setFontType("bold");
      doc.text(this.receiver, 150, offset);
      offset += 25

      doc.setFontType("normal");
      doc.setFontSize(13);
      doc.text(i18n.t('records.registerName'), 40, offset);
      offset += 20

      doc.setFontType("italic");
      doc.setFontSize(12);
      doc.text(this.inbox, 40, offset);
      offset += 20

      doc.setLineWidth(1.0); 
      doc.setDrawColor(0, 0, 0);
      doc.line(40, offset, 570, offset, 'S');
      offset += 30

      doc.setFontType("normal");
      doc.setFontSize(16);
      doc.text(i18n.t('records.recordInfo'), 40, offset);
      offset += 20

      doc.setFontType("normal");
      doc.setFontSize(13);
      doc.text(i18n.t('records.email'), 40, offset);
      offset += 20

      doc.setFontType("italic");
      doc.setFontSize(12);
      doc.text(this.email, 40, offset);
      offset += 20

      doc.setFontType("normal");
      doc.setFontSize(13);
      doc.text(i18n.t('records.subject'), 40, offset);
      offset += 20

      doc.setFontType("italic");
      doc.setFontSize(12);
      doc.text(this.recordSubject, 40, offset);
      offset += 20

      doc.setFontType("normal");
      doc.setFontSize(13);
      doc.text(i18n.t('records.message'), 40, offset);
      offset += 20

      doc.setFontType("italic");
      doc.setFontSize(12);

      var splitMessage = doc.splitTextToSize(this.message, 500);
      doc.text(splitMessage, 40, offset);

      offset += 12 * splitMessage.length + 20

      doc.setFontType("normal");
      doc.setFontSize(13);
      doc.text(i18n.t('records.files'), 40, offset);
      offset += 10

      if (vm.attachments.length) {
        doc.autoTable({
          margin: {top: offset},
          head: columns,
          body: vm.attachments
        })

        offset += (vm.attachments.length + 2) * 20 + 20
      }
      else
      {
        offset += 10
        doc.setFontType("italic");
        doc.setFontSize(12);
        doc.text(i18n.t('records.noAttachments'), 40, offset);
      }

      offset += 70
      doc.addImage(document.querySelector('canvas').toDataURL('image/jpeg'), 40, 740, 80, 80);

      doc.setLineWidth(1.0); 
      doc.setDrawColor(0, 0, 0);
      doc.line(40, 700, 570, 700, 'S');

      doc.setFontSize(10);
      doc.text(this.link, 40, 725)

      //doc.setFontSize(11);
      //doc.setFontType("bold");
      //doc.text(i18n.t('records.signedBy'), 150, 755)
      //doc.setFontSize(10);
      //doc.text("FRANCISCO JAVIER SERRANO RODRÍGUEZ", 150, 755 + 30)
      //doc.text("DNI: 70885618-P", 150, 755 + 45)

      doc.setFontSize(11);
      doc.setFontType("bold");
      doc.text(i18n.t('records.date'), 430, 750)

      doc.setFontSize(10);
      doc.text(moment(this.ctime * 1000).format('DD/MM/YYYY - HH:mm'), 470, 750)

      doc.save(i18n.t('records.receipt') + '-' + this.id + '.pdf');
    },
    async downloadAll() {
      try {
        await api.getFolderLink(document.location.href)
      } catch (e) {
        console.log(e);
      }

      if (this.downloading)
      {
        return;
      }

      this.$showSuccess(this.$t('transfers.downloadadded'))

      this.downloading = true;
      this.setIsSent(true)
      this.setDownloadFiles(1)
      this.setTotalFiles('')
      var action = await ibbLink.downloadZip(this.recordHandle, this.recordName);
      this.setIsSent(false)
      this.downloading = false;
      if (action.error.type) {
        throw new Error("Error downloading folder: " + action.error.type)
      }
    }
  }
}
</script>
