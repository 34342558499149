<template>
  <div>
    <div v-if="isReady" id="transaction-ok" style="position: absolute; top: 0; left: 0; width: 100%; ">
      <vue-headful
        title="Planes de precios de Iberbox"
        description="Contrata nuestros servicios y disfruta del almacenamiento cifrado más seguro."
        keywords="iberbox, planes de precio, copias de seguridad, cifrado punto a punto, nube segura"
        image="https://iberbox.com/img/logo.svg"
      />

      <div style="width: 90%; margin: 0 auto; max-width: 600px;">
        <a href="https://www.iberbox.com"><img style="float:left; width: 3em; height: 2.3em; display: block;" src="/img/logo.svg" alt="IBERBOX"></a>
        <h1 style="margin: .3em 0 .3em;" >IBERBOX</h1>
      </div>
      <div style="width: 90%; margin: 0 auto; max-width: 600px;">
          <h1 style="margin: 0.8em 0 0 0;">{{ $t("settings.transactionCancelled") }}</h1>
          <form class="card">
            <div class="card-title">
              <center><h2>{{ $t("settings.transactionNotCompleted") }}</h2></center>
            </div>

            <div class="card-content" style="font-size: 1.2em">
              <p>
                {{ $t("settings.transactionFailed") }}
              </p>
            </div>

            <div class="card-action">
              <input
                v-on:click="goToCancel"
                style="float: left; width: 48%"
                class="button button--block"
                type="button"
                :value="$t('buttons.cancel')"
              />
              <input
                v-on:click="retry"
                style="float: right; width: 48%"
                class="button button--block"
                type="button"
                :value="$t('buttons.retry')"
              />
              <br /><br />
            </div>
          </form>
      </div>
    </div>
  </div>
</template>

<script>
import * as auth from "@/utils/auth";
import { files as api } from "@/api";
import { name, logoURL } from "@/utils/constants";
import vueHeadful from "vue-headful";
import moment from "moment";
import i18n from "@/i18n";

export default {
  name: "transaction-error",
  components: {
    "vue-headful": vueHeadful,
  },
  computed: {
    name: () => name,
    logoURL: () => logoURL,
  },
  data: function () {
    return {
      isReady: false,
    };
  },
  mounted() {},
  created() {
    this.isReady = true;
  },
  methods: {
    async goToCancel () {
      var action = await ibb.getNodeByPath("/");
      if (action.error.type || !action.node)
      {
          await auth.logout();
      }
      else
      {
          this.$router.push({ path: '/profile/invoicing' })
      }
    },
    retry() {
      this.$router.push({ path: "/profile/invoicing/plans" });
    },
  },
};
</script>
