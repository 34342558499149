<template>
  <div class="dashboard profile">
    <div v-if="pMethod !== 0 && !this.isMobile" class="user-data">
      <div class="container-image">
        <p class="user-name">{{ this.userName }}</p>
      </div>
      <div class="text">
        <h3>{{ this.name }}</h3>
        <p>{{ this.emailUser }}</p>
      </div>
      <span v-if="isAdmin" class="admin">{{ $t('settings.administrator') }}</span>
      <span v-else class="admin">{{ $t('settings.guest') }}</span>

      <div class="menu">
        <router-link :class="[($route.name === 'Information') ? 'option-active' : 'option']" to="/profile/info" :aria-label="$t('sidebar.info')" :title="$t('sidebar.info')" tag="button">
          <div style="display: flex; align-items: center;">
            <info-icon size="1x" class="custom-class"></info-icon>
            <span>{{ $t('sidebar.info') }}</span>
          </div>
          <chevron-right-icon size="1x" class="custom-class"></chevron-right-icon>
        </router-link>
        <router-link :class="[($route.name === 'Settings') ? 'option-active' : 'option']" to="/profile/settings" :aria-label="$t('sidebar.settings')" :title="$t('sidebar.settings')" tag="button">
          <div style="display: flex; align-items: center;">
            <settings-icon size="1x" class="custom-class"></settings-icon>
            <span>{{ $t('sidebar.settings') }}</span>
          </div>
          <chevron-right-icon size="1x" class="custom-class"></chevron-right-icon>
        </router-link>
        <router-link :class="[($route.name === 'Invoicing' || $route.name === 'Invoicing Plans') ? 'option-active' : 'option']" to="/profile/invoicing" :aria-label="$t('buttons.planBilling')" :title="$t('buttons.planBilling')" tag="button">
          <div style="display: flex; align-items: center;">
            <dollar-sign-icon size="1x" class="custom-class"></dollar-sign-icon>
            <span>{{ $t('buttons.planBilling') }}</span>
          </div>
          <chevron-right-icon size="1x" class="custom-class"></chevron-right-icon>
        </router-link>
      </div>
    </div>
    <div v-else-if="this.isMobile && pMethod !== 0" style="background: #e7edff; padding: 0 1em;">
      <div style="display: flex; align-items: center; justify-content: center;">
        <div class="container-image">
          <p class="user-name">{{ this.userName }}</p>
        </div>
        <div class="text">
          <h3>{{ this.name }}</h3>
          <p>{{ this.emailUser }}</p>
          <span v-if="isAdmin" class="admin">{{ $t('settings.administrator') }}</span>
          <span v-else class="admin">{{ $t('settings.guest') }}</span>
        </div>
      </div>
      <ul id="nav">
        <li :class="{ active: $route.path === '/profile/info' }"><router-link to="/profile/info">{{ $t('sidebar.info') }}</router-link></li>
        <li :class="{ active: $route.path === '/profile/settings' }"><router-link to="/profile/settings">{{ $t('sidebar.settings') }}</router-link></li>
        <li :class="{ active: ($route.path === '/profile/invoicing' || $route.path === '/profile/invoicing/plans') }"><router-link to="/profile/invoicing">{{ $t('settings.plan') }}</router-link></li>
      </ul>
    </div> 
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { files as ibbapi } from '@/api'
import { InfoIcon, ChevronRightIcon, SettingsIcon, UserIcon, DollarSignIcon } from 'vue-feather-icons'

export default {
  name: 'settings',
  components: {
    InfoIcon,
    ChevronRightIcon,
    SettingsIcon,
    UserIcon,
    DollarSignIcon
  },
  data: function () {
    return {
      paymentMethod: 0,
      pMethod: 0,
      isAdmin: false,
      emailAdmin: '',
      emailUser: '',
      userName: '',
      name: '',
    }
  },
  watch: {
    '$route': 'fetchData'
  },
  computed: {
    ...mapState([ 'user' ]),
    isMobile () {
      return window.innerWidth <= 768
    },
  },
  async mounted () {
    try {
      var action = await ibbapi.getInvoicing()
      if (action.paymentMethod !== undefined) {
        this.paymentMethod = action.paymentMethod.type;
      }
    } catch (e) {
      this.$showError(e)
    }

    try {
      action = await ibbapi.getAccountInfo();
      this.emailAdmin = action.email;
    } catch (e) {
      this.$showError(e)
    }

    try {
      action = await ibbapi.getUserInfo();
      this.emailUser = action.userInfo.email;
      if (this.emailAdmin === this.emailUser) {
        this.isAdmin = true
      }
      var userFirstname = action.userInfo.firstname;
      var userLastname = action.userInfo.lastname;
      this.name = userFirstname + " " + userLastname;
      this.userName = userFirstname.substr(0, 1) + userLastname.substr(0, 1);
    } catch (e) {
      this.$showError(e)
    }

    this.fetchData();
  },
  methods : {
    async fetchData() {
      try {
        var action = await ibbapi.getInvoicing()
        this.pMethod = action.credit;
      } catch (e) {
        this.$showError(e)
      }
    }
  }
}
</script>
