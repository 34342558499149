<template>
  <div>
    <ul class="download-file-list">
      <li @click="select($event, item)"
        @dblclick="next"
        @touchstart="touchstart"
        role="button"
        tabindex="0"
        :aria-label="item.name"
        :aria-selected="selected == item.url"
        :key="item.handle" v-for="item in items"
        :data-isdir="item.isDir"
        :data-url="item.url">
        <img class="material-icons" :src="icon(item)">
        <div class="information">
          <p>{{ item.name }}</p>
          <span style="text-align: right; width: 100%; max-width: 100px; font-size: 11px;">{{ humanSize(item) }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import url from '@/utils/url'
import { files, utils } from '@/api'
import filesize from 'filesize'

export default {
  name: 'link-file-list',
  data: function () {
    return {
      items: [],
      touches: {
        id: '',
        count: 0
      },
      selected: null,
      current: window.location.pathname,
      currentHandle: 0,
      name: '',
      size: '',
    }
  },
  computed: {
    ...mapState([ 'req' ]),
    nav () {
      return decodeURIComponent(utils.removePrefix(this.current));
    },
  },
  mounted () {
    console.log("Initializing file list");
    files.fetch('/', Module.SortOrderType.SORT_ORDER_DEFAULT.value, ibbLink)
      .then(this.fillOptions)
      .catch(this.$showError)
  },
  
  methods: {
    icon: function (item) {
      if (item.isDir) {
        return '/img/icons/WEBCLIENT_MimeTypes/ibbFolder.png';
      } else {
        if (item.type === 'image') 
        {  
          return '/img/icons/WEBCLIENT_MimeTypes/ibbImage.png'
        }
        if (item.type === 'audio') {
          return '/img/icons/WEBCLIENT_MimeTypes/ibbMusic.png'
        }
        if (item.type === 'video') {
          return '/img/icons/WEBCLIENT_MimeTypes/ibbVideo.png'
        } 
        if (item.type === 'txt') {
          return '/img/icons/WEBCLIENT_MimeTypes/ibbTxt.png'
        }
        if (item.type === 'pdf') {
          return '/img/icons/WEBCLIENT_MimeTypes/ibbPDF.png'
        }
        if (item.type === 'zip') {
          return '/img/icons/WEBCLIENT_MimeTypes/ibbZip.png'
        }
        return '/img/icons/WEBCLIENT_MimeTypes/ibbFile.png'
      } 
    },
    humanSize: function (item) {
      if (item.isDir)
      {
        return '';
      }
      return filesize(item.size)
    },
    fillOptions (req) {
      // Sets the current path and resets
      // the current items.
      this.current = req.url;
      this.currentHandle = req.handle;
      this.items = []

      this.$emit('update:selected', this.currentHandle)

      // If the path isn't the root path,
      // show a button to navigate to the previous
      // directory.
      if (decodeURIComponent(utils.removePrefix(req.url)) !== '/') {
        this.items.push({
          name: '..',
          url: req.parentHandle,
          isDir: true,
          icon: '/img/icons/mimetype_folder.png'
        })
      }

      // If this folder is empty, finish here.
      if (req.items === null) return

      // Otherwise we add every directory to the
      // move options.
      for (let item of req.items) {
        //if (!item.isDir) continue

        console.log(item);
        console.log(item.handle);
        this.items.push({
          name: item.name,
          url: item.handle,
          isDir: item.isDir,
          size: item.size,
          type: item.type
        })
      }
    },
    next: function (event) {
      // Retrieves the URL of the directory the user
      // just clicked in and fill the options with its
      // content.

      console.log(event.currentTarget.dataset);
      if (!event.currentTarget.dataset.isdir)
      {
        return;
      }

      let uri = event.currentTarget.dataset.url

      console.log("Opening " + uri);
      files.fetchId(uri, Module.SortOrderType.SORT_ORDER_DEFAULT.value, ibbLink)
        .then(this.fillOptions)
        .catch(this.$showError)
    },
    touchstart (event) {
      if (!/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
        return;
      }

      let url = event.currentTarget.dataset.url

      // In 300 milliseconds, we shall reset the count.
      setTimeout(() => {
        this.touches.count = 0
      }, 300)

      // If the element the user is touching
      // is different from the last one he touched,
      // reset the count.
      if (this.touches.id !== url) {
        this.touches.id = url
        this.touches.count = 1
        return
      }

      this.touches.count++

      // If there is more than one touch already,
      // open the next screen.
      if (this.touches.count > 1) {
        event.preventDefault();
        this.next(event)
      }
    },
    select: function (event, item) {
      this.name = item.name;
      this.size = this.humanSize(item);
      // If the element is already selected, unselect it.
      if (this.selected === event.currentTarget.dataset.url) {
        this.selected = null
        this.$emit('update:selected', this.currentHandle)
        return
      }

      // Otherwise select the element.
      this.selected = event.currentTarget.dataset.url
      this.$emit('update:selected', this.selected)
    },
  }
}
</script>
