<template>
  <button @click="newInbox" :aria-label="$t('sidebar.newInbox')" :title="$t('sidebar.newInbox')" class="action" id="new-inbox-button">
    <plus-square-icon size="1.5x" class="custom-class"></plus-square-icon>
    <span>{{ $t('sidebar.newInbox') }}</span>
  </button>
</template>

<script>

import { PlusSquareIcon } from 'vue-feather-icons'

export default {
  name: 'new-inbox-button',
  components: {
    PlusSquareIcon
  },
  methods: {
    newInbox: function () {
      this.$store.commit('showHover', 'newInbox')
    }
  }
}
</script>
