<template>
  <div class="share" v-if="notFound">
    <div class="dashboard">
     <div class="card">
      <div class="card-content">
        <p>{{ $t('download.fileNotFound') }}</p>
      </div>
     </div>
    </div>
  </div>
  <div id="download-container" class="downloads" v-else-if="loaded">
    <div class="contenedor-publicidad hidden-xs">
      <div class="texto">
        <div>
          <h3>{{ $t('login.theCloud') }}</h3>
          <h2>{{ $t('login.business') }}.</h2>
        </div>
        <img src="/img/icons/Login/ibbLogoWhiteV.svg">
      </div>
      <p>{{ $t('login.differentCloud') }}</p>
      <router-link to="/register" :title="$t('records.start')" :aria-label="$t('records.start')">
        <span>{{ $t('records.start') }}</span>
      </router-link>
      <div class="contenedor-imagen">
        <img src="/img/icons/Login/ibbWebImage.png" class="desktop hidden-xs">
        <img src="/img/icons/Login/ibbWebCroppedImage.png" class="desktop visible-xs">
      </div>
    </div>
    <div class="container-right">
      <div class="container-file">
        <progress-bar size="medium" :val="progress"></progress-bar>

        <div class="share__box__info" style="border-top: 1px solid rgba(0, 0, 0, 0.05);">
          <div id="previewDiv" class="preview">
            <template v-if="finished">
              <template v-if="file.size < 50*1024*1024 && (file.type == 'image' || file.type == 'video' || file.type == 'audio' || file.type == 'pdf' || file.type == 'stl')">
                <i class="material-icons" style="font-size: 1.5em; position: absolute; top: 0px; right: 0px; cursor: pointer; z-index: 999; background:white;" v-on:click="this.toggleFullscreen">{{ fullScreenButton }}</i>
                <img id="previewControl" v-if="file.type == 'image'" :src="raw" style="max-height:100%; max-width:100%;" hidden>
                <audio id="previewControl" v-else-if="file.type == 'audio'" :src="raw" style="max-height:100%; max-width:100%;" autoplay controls hidden></audio>
                <video id="previewControl" v-else-if="file.type == 'video'" :src="raw" style="max-height:100%; max-width:100%;" autoplay controls hidden>
                  <track
                    kind="captions"
                    v-for="(sub, index) in subtitles"
                    :key="index"
                    :src="sub"
                    :label="'Subtitle ' + index" :default="index === 0">
                  <font color="white">Sorry, your browser doesn't support embedded videos,
                  but don't worry, you can <a id="previewControlAlt" :download="nodeName" :href="raw">download it</a>
                  and watch it with your favorite video player!</font>
                </video>
                <object id="previewControl" v-else-if="file.type == 'pdf'"  class="pdf" :data="raw" type="application/pdf" style="overflow-y: auto; height: 100%; width: 100%">
                  <pdf id="previewControl" class="pdf" :src="raw" style="overflow-y: auto; height: 100%;"></pdf>
                </object>
                <model-stl v-else-if="file.type == 'stl'" :src="raw" style="max-height:100%; max-width:100%;"></model-stl>
              </template>
              <a v-else id="previewControlAlt" download="file.raw" :href="raw" hidden>
                <img class="material-icons" :src="icon">
              </a>
            </template>
            <img v-else-if="metadata" id="previewControl" :src="metadata" style="max-height:100%; max-width:100%;">
            <img v-else class="material-icons" :src="icon">
          </div>

          <h2 class="share__box__title">{{ file.name }}</h2>
          <p class="size" v-if="!downloading">{{ humanSize() }}</p>
          <p class="size" v-else>
            <table>
              <tr>
                <td style="text-align:left; padding: 0px 0px 0px 10px;">{{ humanProgress() }}</td>
                <td style="text-align:right; padding: 0px 10px 0px 0px;">{{ humanSpeed() }}</td>
              </tr>
            </table>
          </p>
          <a id="downloadLinkTag" :href="target">
            <div class="share__box__download" v-if="finished" v-on:click="this.downloadData">
              <download-icon size="1.5x" class="custom-class"></download-icon>
              {{ $t('download.downloadAgain') }}
            </div>
            <div class="share__box__download" v-else-if="downloading">
              <download-icon size="1.5x" class="custom-class"></download-icon>
              {{ $t('download.downloading') }}
            </div>
            <div id="textDivAndroid" class="share__box__download" v-else-if="android">
              <download-icon size="1.5x" class="custom-class"></download-icon>
              <span v-if="calculateSize()">{{ $t('download.downloadWithAndroidApp') }}</span>
              <span v-else>{{ $t('download.downloadWithAndroidAppRecommended') }}</span>
            </div>
            <div id="textDiv" class="share__box__download" v-on:click="this.downloadData" v-else>
              <download-icon size="1.5x" class="custom-class"></download-icon>
              {{ $t('download.downloadFile') }}
            </div>
          </a>
          <div v-if="android && !finished && !downloading" id="textDivBrowser" class="share__box__download" v-on:click="this.downloadData">
            <download-icon size="1.5x" class="custom-class"></download-icon>
            {{ $t('download.downloadWithBrowser') }}
          </div>
          <button class="button button-copy"
          :data-clipboard-text="link"
          :aria-label="$t('buttons.copyToClipboard')"
          :title="$t('buttons.copy')">
            <link-icon size="1x" class="custom-class"></link-icon>
            <p>{{ $t('buttons.copy') }}</p>
          </button>
        </div>
      </div>
    </div>
    <div class="contenedor-publicidad visible-xs">
      <div class="texto">
        <div>
          <h3>{{ $t('login.theCloud') }}</h3>
          <h2>{{ $t('login.business') }}.</h2>
        </div>
        <img src="/img/icons/Login/ibbLogoWhiteV.svg">
      </div>
      <p>{{ $t('login.differentCloud') }}</p>
      <router-link to="/register" :title="$t('records.start')" :aria-label="$t('records.start')">
        <span>{{ $t('records.start') }}</span>
      </router-link>
      <div class="contenedor-imagen">
        <img src="/img/icons/Login/ibbWebImage.png" class="desktop hidden-xs">
        <img src="/img/icons/Login/ibbWebCroppedImage.png" class="desktop visible-xs">
      </div>
    </div>
  </div>
</template>

<script>
import { share as api } from '@/api'
import ProgressBar from 'vue-simple-progress'
import filesize from 'filesize'
import { ModelStl } from 'vue-3d-model';
import pdf from 'vue-pdf'
import { isAndroid } from 'mobile-device-detect';
import { files as ibbapi } from '@/api';
import Clipboard from 'clipboard'
import { DownloadIcon, LinkIcon } from 'vue-feather-icons'

export default {
  name: 'share',
  components: {
    ProgressBar,
    ModelStl,
    pdf,
    DownloadIcon,
    LinkIcon
  },
  data: () => ({
    loaded: false,
    notFound: false,
    downloading: false,
    finished: false,
    file: null,
    progress: 0,
    completedSize: 0, 
    speed: 0,
    lastUpdate: 0,
    fullPreview: false,
    fullScreenButton: "fullscreen",
    metadata: null,
    target: null,
    android: isAndroid,
    clip: null,
    isDownload: false
  }),
  watch: {
    '$route': 'fetchData'
  },
  created: function () {
      var thisComponent = this;
      var JavaEventListenerClass = Module.ActionListener.extend("ActionListener", {
      onActionProgress: function(action)
      {
          if (action.type != Module.ActionType.DOWNLOAD.value
            && action.type != Module.ActionType.DOWNLOAD_ZIP.value) {
            return;
          }

          var seconds = Math.floor(new Date().getTime() / 100);
          if (thisComponent.lastUpdate == seconds)
          {
              return;
          }
          thisComponent.lastUpdate = seconds;

          thisComponent.speed = action.speed || 0;
          thisComponent.completedSize = action.completed;
          thisComponent.progress = action.size ? action.completed * 100 / action.size : 0;
      }
    });

    this.interfaceObject = new JavaEventListenerClass();
    ibb.addActionListener(this.interfaceObject);

    this.fetchData()
  },
  beforeDestroy () {
    ibb.removeActionListener(this.interfaceObject);
  },
  async mounted() {
    this.clip = new Clipboard('.button-copy')
    this.clip.on('success', () => {
      this.$showSuccess(this.$t('success.linkCopied'))
    })
  },
  computed: {
    hash: function () {
      return document.location.href; //this.$route.params.pathMatch
    },
    link: function () {
      return document.location.href;
    },
    fullLink: function () {
      return document.location.href;
    },
    raw: function() {
      var fileName = this.file.name;
      console.log("RAW file: " + fileName);
      var link = document.location.href;
      var linkid = link + "/" + fileName;
      var uri = Module.getObjectURL(linkid);

      setTimeout(function() { 
        var previewControl = document.getElementById('previewControl');
        if (previewControl) {
          previewControl.src = uri;
          previewControl.hidden = false;
        }

        var previewControlAlt = document.getElementById('previewControlAlt');
        if (previewControlAlt) {
          previewControlAlt.href = uri;
          previewControlAlt.download = fileName;
          previewControlAlt.hidden = false;
        }

        var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        if (iOS) {
            var reader = new FileReader();
            var out = Module.getFileBlob(linkid);
            reader.onload = function(e) {
                var downloadLinkTag = document.getElementById('downloadLinkTag');
                if (downloadLinkTag) {
                  downloadLinkTag.href = reader.result;
                  downloadLinkTag.download = fileName;
                }

                if (!fileName.endsWith(".txt") && !fileName.endsWith(".pdf")) {
                  var previewControlAlt = document.getElementById('previewControlAlt');
                  if (previewControlAlt) {
                    previewControlAlt.href = reader.result;
                    previewControlAlt.download = fileName;
                    previewControlAlt.hidden = false;
                  }
	              }
                downloadLinkTag.click();
            }
            reader.readAsDataURL(out);
        }
      }, 0);
      
      if (fileName.endsWith(".pdf")) {
        console.log('URI: ', uri + '#view=Fit')
        return uri + '#view=Fit';
      }
      return uri;
    },
    icon () {
      if (this.file.isDir) 
      {  
          if (this.file.type === 'device')
          {
            return '/img/icons/WEBCLIENT_MimeTypes/ibbDevice.png';
          } 
          else if (this.file.type === 'backup')
          {
            return '/img/icons/WEBCLIENT_MimeTypes/ibbBackup.png';
          }
          else if (this.file.type === 'snapshot')
          {
            return '/img/icons/WEBCLIENT_MimeTypes/ibbCalendar.png'
          }
          else if (this.file.type === 'inbox')
          {
            return '/img/icons/mimetype_inbox.png'
          }
          else if (this.file.type === 'record')
          {
            return '/img/icons/mimetype_record.png'
          }
          else
          {
            return '/img/icons/WEBCLIENT_MimeTypes/ibbFolder.png';
          }
      }
      if (this.file.type === 'image') 
      {  
        return '/img/icons/WEBCLIENT_MimeTypes/ibbImage.png'
      }
      if (this.file.type === 'audio') {
        return '/img/icons/WEBCLIENT_MimeTypes/ibbMusic.png'
      }
      if (this.file.type === 'video') {
        return '/img/icons/WEBCLIENT_MimeTypes/ibbVideo.png'
      } 
      if (this.file.type === 'pdf') {
        return '/img/icons/WEBCLIENT_MimeTypes/ibbPDF.png'
      }
      if (this.file.type === 'txt') {
        return '/img/icons/WEBCLIENT_MimeTypes/ibbTxt.png'
      } 
      if (this.file.type === 'zip') {
        return '/img/icons/WEBCLIENT_MimeTypes/ibbZip.png'
      }
      return '/img/icons/WEBCLIENT_MimeTypes/ibbFile.png'
    }
  },
  methods: {
    fetchData: async function () {
      try {
        this.file = await api.getHash(this.hash)
        
        if (this.android) {
          var re = /.*\/file\/(.+)#(.+)/;
          var result = document.location.href.match(re);
          this.target = "intent:#Intent;action=com.iberbox.android;category=android.intent.category.DEFAULT;category=android.intent.category.BROWSABLE;package=com.iberbox.android;S.type=file;S.id=" + result[1] + ";S.key=" + result[2] + ";end";
        }

        this.loaded = true

        if (this.file.metadataInfo.length)
        {
            var link = document.location.href;
            var thumbnailName = "///previews/" + link + ".jpg";
            var action = await ibb.downloadPublicMetadata(this.file.metadataInfo, Module.MetadataType.META_PREVIEW.value, thumbnailName);
            if (!action.error.type)
            {
                this.metadata = Module.getObjectURL(thumbnailName);
            }
        }
      } catch (e) {
        console.log(e);
        this.notFound = true
      }
    },
    downloadData: async function () {
      if (this.downloading)
      {
          return;
      }

      var fileName = this.file.name;
      console.log("Downloading file: " + fileName);

      var link = document.location.href;
      var linkid = link + "/" + fileName;

      if (!this.isDownload) {
        this.downloading = true;
        var action = await ibb.downloadLink(link, linkid);
        this.downloading = false;
        if (action.error.type) {
          throw new Error("Error downloading link: " + action.error.type)
        }

        this.progress = 100;
        this.completedSize = this.file.size;
        this.finished = true;
      }
      var uri = Module.getObjectURL(linkid);
      if (uri) {
        var downloadLinkTag = document.getElementById('downloadLinkTag');
        if (downloadLinkTag) {
          downloadLinkTag.href = uri;
          downloadLinkTag.download = fileName;
        }

        if (!this.isDownload) {
          downloadLinkTag.click();
          this.isDownload = true;
        }
      }
    },
    humanSize: function () {
      return filesize(this.file.size)
    },
    calculateSize: function() {
      var tamaño = this.humanSize().split(' ');
      if (tamaño[1] === 'KB' || tamaño[1] === 'B') {
        return true
      } else if (tamaño[1] === 'MB' && tamaño[0] < 400) {
        return true 
      } else {
        return false
      }
    },
    humanSpeed: function () {
      return "" + filesize(this.speed) + "/s"
    },
    humanProgress: function () {
      var completed = "" + filesize(this.completedSize)
      return this.$t('download.progress', { completed: completed.padStart(6), total: filesize(this.file.size)}); 
    },
    toggleFullscreen: function ()
    {
      var control = document.getElementById('previewDiv');
      if (!this.fullPreview)
      {
        control.style["z-index"]=9999;
        control.style["width"]="100%";
        control.style["height"]="100%";
        control.style["position"]="fixed";
        control.style["top"]=0;
        control.style["left"]=0;
        control.style["background"]="white";
        window.dispatchEvent(new Event('resize'));
        this.fullPreview=true;
        this.fullScreenButton = "fullscreen_exit"
      }
      else
      {
        control.style["z-index"]=null;
        control.style["width"]=null;
        control.style["height"]=null;
        control.style["position"]=null;
        control.style["top"]=null;
        control.style["left"]=null;
        control.style["background"]=null;
        window.dispatchEvent(new Event('resize'));
        this.fullPreview=false;      
        this.fullScreenButton = "fullscreen"
      }
    },
  }
}
</script>
