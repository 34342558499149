import * as i18n from '@/i18n'
import moment from 'moment'

const mutations = {
  closeHovers: state => {
    state.show = null
    state.showMessage = null
  },
  toggleShell: (state) => {
    state.showShell = !state.showShell
  },
  showHover: (state, value) => {
    if (typeof value !== 'object') {
      state.show = value
      return
    }

    state.show = value.prompt
    state.showMessage = value.message
    state.showConfirm = value.confirm
  },
  showError: (state, value) => {
    state.show = 'error'
    state.showMessage = value
  },
  showSuccess: (state, value) => {
    state.show = 'success'
    state.showMessage = value
  },
  setLoading: (state, value) => { state.loading = value },
  setReload: (state, value) => { state.reload = value },
  setIsMenu: (state, value) => { state.isMenu = value },
  setIsPrompt: (state, value) => { state.isPrompt = value },
  setIsCodeVisible: (state, value) => { state.isCodeVisible = value },
  setHandleRecord: (state, value) => { state.handleRecord = value },
  setHandleArchivo: (state, value) => { state.handleArchivo = value },
  setHandleBuzon: (state, value) => { state.handleBuzon = value },
  setIdCoupon: (state, value) => { state.idCoupon = value },
  setEmailCoupon: (state, value) => { state.emailCoupon = value },
  setParentHandle: (state, value) => { state.parentHandle = value },
  setAccountHandle: (state, value) => { state.accountHandle = value },
  setAccountHandleJoin: (state, value) => { state.accountHandleJoin = value },
  setAccountKeyJoin: (state, value) => { state.accountKeyJoin = value },
  setRegistrationId: (state, value) => { state.registrationId = value },
  setCreateAccountCoupon: (state, value) => { state.createAccountCoupon = value },
  setJoinLink: (state, value) => { state.joinLink = value },
  setHandleOpenSpace: (state, value) => { state.handleOpenSpace = value },
  setReferrerCode: (state, value) => { state.referrerCode = value },
  setRecord: (state, value) => { state.record = value },
  setOutbox: (state, value) => { state.outbox = value },
  setIsDelete: (state, value) => { state.isDelete = value },
  setIsRename: (state, value) => { state.isRename = value },
  setIsPreview: (state, value) => { state.isPreview = value },
  setSortOrder: (state, value) => { state.sortOrder = value },
  setLinkInbox: (state, value) => { state.linkInbox = value },
  setTotalFiles: (state, value) => { state.totalFiles = value }, 
  setDownloadFiles: (state, value) => { state.downloadFiles = value },
  setIsSent: (state, value) => { state.isSent = value }, 
  setPermissions: (state, value) => { state.permissions = value },
  setIsMaxStorage: (state, value) => { state.isMaxStorage = value },
  setUser: (state, value) => {
    if (value === null) {
      state.user = null
      return
    }

    let locale = value.locale

    if (locale === '') {
      locale = i18n.detectLocale()
       if (locale == "es" || locale == "en")
       {
          value.locale = locale;
       }
       else
       {
          locale = "en";
          value.locale = "en";
       }
    }

    moment.locale(locale)
    i18n.default.locale = locale
    state.user = value
  },
  setJWT: (state, value) => (state.jwt = value),
  multiple: (state, value) => (state.multiple = value),
  addSelected: (state, value) => (state.selected.push(value)),
  addInboxSelected: (state, value) => (state.inboxSelected.push(value)),
  addContactSelected: (state, value) => (state.contactSelected.push(value)),
  addRecordSelected: (state, value) => (state.recordSelected.push(value)),
  addOutboxSelected: (state, value) => (state.outboxSelected.push(value)),
  addPlugin: (state, value) => {
    state.plugins.push(value)
  },
  removeSelected: (state, value) => {
    let i = state.selected.indexOf(value)
    if (i === -1) return
    state.selected.splice(i, 1)
  },
  removeInboxSelected: (state, value) => {
    let i = state.inboxSelected.indexOf(value)
    if (i === -1) return
    state.inboxSelected.splice(i, 1)
  },
  removeContactSelected: (state, value) => {
    let i = state.contactSelected.indexOf(value)
    if (i === -1) return
    state.contactSelected.splice(i, 1)
  },
  removeRecordSelected: (state, value) => {
    let i = state.recordSelected.indexOf(value)
    if (i === -1) return
    state.recordSelected.splice(i, 1)
  },
  removeOutboxSelected: (state, value) => {
    let i = state.outboxSelected.indexOf(value)
    if (i === -1) return
    state.outboxSelected.splice(i, 1)
  },
  resetSelected: (state) => {
    state.selected = []
  },
  resetInboxSelected: (state) => {
    state.inboxSelected = []
  },
  resetContactSelected: (state) => {
    state.contactSelected = []
  },
  resetRecordSelected: (state) => {
    state.recordSelected = []
  },
  resetOutboxSelected: (state) => {
    state.outboxSelected = []
  },
  updateUser: (state, value) => {
    if (typeof value !== 'object') return

    for (let field in value) {
      if (field === 'locale') {
        moment.locale(value[field])
        i18n.default.locale = value[field]
      }

      state.user[field] = value[field]
    }
  },
  updateTransferProgress: (state, {index, value}) => {
    if (state.req.items && state.req.items.length > 0) {
          state.req.items[index].progress = value;
    }
  },
  updateRequest: (state, value) => {
    state.oldReq = state.req
    state.req = value
  },
  updateNextSnapshots: (state, value) => {
    state.nextSnapshots = value
  },
  updateLastSnapshots: (state, value) => {
    state.lastSnapshots = value
  },
  updateInvoices: (state, value) => {
    state.invoices = value
  },
  updateActiveSessionHandle: (state, value) => {
    state.activeSessionHandle = value
  },
  updateActiveSessions: (state, value) => {
    state.activeSessions = value
  },
  updateInactiveSessions: (state, value) => {
    state.inactiveSessions = value
  },
  updateClipboard: (state, value) => {
    state.clipboard.key = value.key
    state.clipboard.items = value.items
  },
  resetClipboard: (state) => {
    state.clipboard.key = ''
    state.clipboard.items = []
  },
  setProgress: (state, value) => {
    state.progress = value
  }
}

export default mutations
