<template>
  <div class="card floating">
    <div class="card-content">
      <p v-if="(inboxSelected.length === 0 && recordSelected.length === 0 && contactSelected.length === 0 && outboxSelected.length === 0 && req.kind !== 'listing')">{{ $t('prompts.deleteMessageSingle') }}</p>
      <p v-else-if="inboxSelected.length !== 0">{{ $t('prompts.deleteMessageMultiple', { count: inboxSelected.length}) }}</p>
      <p v-else-if="recordSelected.length !== 0">{{ $t('prompts.deleteMessageMultiple', { count: recordSelected.length}) }}</p>
      <p v-else-if="contactSelected.length !== 0">{{ $t('prompts.deleteMessageMultiple', { count: contactSelected.length}) }}</p>
      <p v-else-if="outboxSelected.length !== 0">{{ $t('prompts.deleteMessageMultiple', { count: outboxSelected.length}) }}</p>
      <p v-else>{{ $t('prompts.deleteMessageMultiple', { count: selectedCount}) }}</p>
    </div>
    <div class="card-action">
      <button @click="$store.commit('closeHovers')" id ="buttonCancelDelete"
        class="button button--flat button--grey"
        :aria-label="$t('buttons.cancel')"
        :title="$t('buttons.cancel')">{{ $t('buttons.cancel') }}</button>
      <button @click="submit"
        class="button button--flat button--red" id ="buttonDelete"
        :aria-label="$t('buttons.delete')"
        :title="$t('buttons.delete')">{{ $t('buttons.delete') }}</button>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex'
import { files as api } from '@/api'
import url from '@/utils/url'
import buttons from '@/utils/buttons'

export default {
  name: 'delete',
  mounted () {
    this.setIsPrompt(true);
    this.setIsRename(true);
  },
  computed: {
    ...mapGetters(['isListing', 'selectedCount']),
    ...mapState(['req', 'selected', 'isRename', 'handleRecord', 'inboxSelected', 'recordSelected', 'outbox', 'contactSelected', 'outboxSelected', 'record']),
    isMobile () {
      return window.innerWidth <= 768
    },
  },
  methods: {
    ...mapMutations(['closeHovers', 'setIsPrompt', 'setIsRename', 'setParentHandle', 'resetInboxSelected', 'resetRecordSelected', 'resetContactSelected', 'resetOutboxSelected', 'setRecord', 'setOutbox']),
    submit: async function () {
      buttons.loading('delete')
      document.getElementById("buttonDelete").disabled = true;
      document.getElementById("buttonCancelDelete").disabled = true;
      try {
        if (this.handleRecord !== null) {
          await api.removeId(this.handleRecord)
          buttons.success('delete')
          this.closeHovers()
          if (this.isMobile) {
            if (this.$route.name == 'Received' || this.$route.name == 'Sent') {
              this.$router.push({ path: this.$route.path})
            } else {
              this.$router.push({ path: this.$route.path + '#' + this.record.parentHandle })
            }
          }
          this.setRecord('')
          this.$store.commit('setReload', true)
          return
        }
        if (this.outbox !== '') {
          await ibb.removeSavedInbox(this.outbox.handle)
          this.setOutbox('')
          buttons.success('delete')
          this.closeHovers()
          this.$store.commit('setReload', true)
          return
        }
        if (this.selected.length === 0 && this.inboxSelected.length === 0 && this.recordSelected.length === 0 && this.contactSelected.length === 0 && this.outboxSelected.length === 0) {
          await api.removeId(this.req.handle)
          buttons.success('delete')
          this.closeHovers()
          this.$router.push({ path: this.$route.path + "#" + this.req.parentHandle })
          return
        }
        if (this.selectedCount === 0 && this.inboxSelected.length === 0 && this.recordSelected.length === 0 && this.contactSelected.length === 0 && this.outboxSelected.length === 0) {
          return
        }

        let promises = []
        if (this.inboxSelected.length > 0) {
          for (let inbox of this.inboxSelected) {
            promises.push(api.removeId(inbox))
            this.setParentHandle(null)
          }
          this.resetInboxSelected();
        } else if (this.recordSelected.length > 0) {
          for (let record of this.recordSelected) {
            promises.push(api.removeId(record))
          }
          this.resetRecordSelected();
        } else if (this.contactSelected.length >0) {
          for (let contact of this.contactSelected) {
            promises.push(ibb.removeContact(contact))
          }
          this.resetContactSelected();
        } else if (this.outboxSelected.length > 0) {
          console.log(this.outboxSelected)
          for (let outbox of this.outboxSelected) {
            promises.push(ibb.removeSavedInbox(outbox))
          }
          this.resetOutboxSelected();
        } else {
          for (let index of this.selected) { 
            if (this.req.items === undefined) {
              if (this.req[index].type === Module.NodeType.CONTACT.value) {
                promises.push(ibb.removeContact(this.req[index].handle))
              } else {
                promises.push(api.removeId(this.req[index].handle))
              }
            } else {
              promises.push(api.removeId(this.req.items[index].handle))
              this.setParentHandle(null)
            }
          }
        }

        await Promise.all(promises)
        buttons.success('delete')
        this.closeHovers()
        this.$store.commit('setReload', true)
      } catch (e) {
        buttons.done('delete')
        this.closeHovers()
        this.$showError(e)
        if (this.isListing) this.$store.commit('setReload', true)
      }
    }
  },
  beforeDestroy () {
    this.setIsPrompt(false);
    this.setIsRename(false);
  }
}
</script>