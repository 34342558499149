<template>
  <div id="faldon">
    <h3 class="porcentaje">{{ percentage.toFixed(0) }}%</h3>
    <div class="ficheros">
      <span>{{ $t('bmail.file') }} {{ downloadFiles }} {{ $t('bmail.of') }} {{ totalFiles }}</span>
      <p>{{ completed }} {{ $t('bmail.of') }} {{ total }}</p>
    </div>
    <p v-if="!isDownload" class="message">{{ $t('bmail.messageSent') }}</p>
    <p v-else class="message">{{ $t('bmail.messageDownload') }}</p>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import filesize from 'filesize'

export default {
  name: 'faldon',
  data: function () {
    return {
      percentage: 0,
      completed: 0,
      total: 0,
      isDownload: false
    }
  },
  created () {
    var thisComponent = this;
    var JavaEventListenerClass = Module.ActionListener.extend("ActionListener", {
      onActionProgress: function(action)
      {
        if (action.type === Module.ActionType.UPLOAD_TO_RECORD.value || action.type === Module.ActionType.DOWNLOAD_ZIP.value) {
          thisComponent.percentage = (action.completed*100)/action.size;
          thisComponent.completed = filesize(action.completed)
          thisComponent.total = filesize(action.size)
        } else {
          return
        }
      },
      onActionFinish: function(action)
      {
        if (action.type === Module.ActionType.UPLOAD_TO_RECORD.value || action.type === Module.ActionType.DOWNLOAD_ZIP.value) {
          thisComponent.percentage = 0
          thisComponent.completed = 0
          thisComponent.total = 0
        } else {
          return
        }
      },
    });

    this.interfaceObject = new JavaEventListenerClass();
    ibbLink.addActionListener(this.interfaceObject);
    ibb.addActionListener(this.interfaceObject);
  },
  mounted () {
    if (this.totalFiles === '') {
      this.setTotalFiles(1)
      this.isDownload = true;
    }
  },
  computed: {
   ...mapState(['totalFiles', 'downloadFiles']), 
  },
  methods: {
    ...mapMutations(['setTotalFiles', 'setDownloadFiles']),
  },
  beforeDestroy () {
    this.setTotalFiles(0)
    this.setDownloadFiles(1)
    ibbLink.removeActionListener(this.interfaceObject);
    ibb.removeActionListener(this.interfaceObject);
  },
}
</script>