<template>
  <div class="open-record">
    <div class="cabecera">
      <div class="botones">
        <button v-if="isMobile || ($route.name !== 'Sent' && $route.name !== 'Received')" :aria-label="$t('bmail.back')" :title="$t('bmail.back')" class="action" id="back-button" @click="back">
          <arrow-left-icon size="1.5x" class="custom-class"></arrow-left-icon>
        </button>
        <button v-if="isInbox && this.permissions === 0" :aria-label="$t('bmail.markUnread')" :title="$t('bmail.markUnread')" class="action" id="unread-button" @click="markUnread">
          <eye-off-icon size="1.5x" class="custom-class"></eye-off-icon>
        </button>
        <button v-if="this.permissions === 0" @click="show" :aria-label="$t('buttons.delete')" :title="$t('buttons.delete')" class="action" id="delete-button">
          <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
        </button>
      </div>
      <p class="modified">
        <time :datetime="record.ctime">{{ date }}</time>
      </p>
    </div>
    <div class="container-title">
      <div class="container-name">
        <p class="user-name">{{ contactName.substr(0, 2) }}</p>
      </div>
      <div class="user-data">
        <h2>{{ record.name }}</h2>
        <p>{{ contactName }}</p>
      </div>
    </div>
    <pre class="message">{{ record.message }}</pre>
    <div class="ficheros" v-if="archivos !== undefined && archivos.length > 0">
      <h2>{{ $t('bmail.attachedFiles') }}</h2>
      <div class="lista-ficheros">
        <record-file v-for="(archivo) in archivos"
        :key="archivo.handle"
        v-bind:name="archivo.name"
        v-bind:size="archivo.size"
        v-bind:modified="archivo.ctime"
        v-bind:handle="archivo.handle"></record-file>
      </div>
      <div class="container-size">
        <div style="display: flex; align-items: center;">
          <img src="/img/icons/Bmail Resources/Icons/Black Icons/sector20.png">
          <p class="size">{{ archivos.length }} ficheros | {{ size }}</p>
        </div>
        <button :aria-label="$t('bmail.downloadAll')" :title="$t('bmail.downloadAll')" class="action" id="download-button" @click="downloadAll">
          <span>{{ $t('bmail.downloadAll') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapMutations } from 'vuex'
import moment from 'moment'
import { ArrowLeftIcon, EyeOffIcon, Trash2Icon } from 'vue-feather-icons'
import filesize from 'filesize'
import RecordFile from './RecordFile.vue'
import { files as api } from '@/api'

export default {
  name: 'open-record',
  components: {
    ArrowLeftIcon,
    EyeOffIcon,
    RecordFile,
    Trash2Icon
  },
  data: function() {
    return {
      date: '',
      archivos: [],
      size: 0,
      isInbox: '',
      contactName: '',
      downloading: false,
    }
  },
  computed: {
    ...mapState([
      'record',
      'permissions'
    ]),
    isMobile () {
      return window.innerWidth <= 768
    },
  },
  async created () {
    const {nodeList} = await ibb.getChildren(this.record.handle);
    this.archivos = nodeList;
    for (let file of this.archivos) {
      this.size = this.size + file.size
    }
    if (this.size === 0) {
      this.size = '0 Bytes'
    } else {
      this.size = filesize(this.size)
    }
  },
  mounted: async function () {
    this.date = moment(this.record.ctime * 1000).format()
    this.date = moment(this.date || 0).fromNow()

    var action = await ibb.getNodeById(this.record.parentHandle)
    if (action.node.type === Module.NodeType.INBOX.value) {
      this.isInbox = true
      this.contactName = this.record.contact
    } else {
      this.isInbox = false
      var action2 = await ibb.getNodeById(action.node.parentHandle);
      this.contactName = action2.node.name
    }
  },
  beforeDestroy () {
    //this.setRecord('')
    //this.setHandleRecord(null);
  },
  methods: {
    ...mapMutations(['setHandleRecord', 'setRecord', 'resetSelected', 'setIsSent', 'setTotalFiles', 'setDownloadFiles']),
    back() {
      this.$emit('back');
    },
    async markUnread() {
      await ibb.setRecordStatus(this.record.handle, Module.RecordStatus.UNREAD.value)
      if (this.$route.name === 'Received') {
        this.resetSelected()
      }
      if (this.isMobile) {
        if (this.$route.name === 'Received') {
          this.$router.push({ path: this.$route.path})
        } else if (this.$route.name === 'Reg') {
          this.$router.push({ path: this.$route.path + '#' + this.record.parentHandle})
        }
        this.setRecord('')
      }
    },
    show() {
      this.setHandleRecord(this.record.handle)
      this.$store.commit('showHover', 'delete')
    },
    async downloadAll() {
      if (this.downloading)
      {
        return;
      }

      var fileName = this.record.name;
      this.$showSuccess(this.$t('transfers.downloadadded'))

      this.downloading = true;
      this.setIsSent(true)
      this.setDownloadFiles(1)
      this.setTotalFiles('')
      var action = await ibb.downloadZip(this.record.handle, fileName);
      this.setIsSent(false)
      this.downloading = false;
      if (action.error.type) {
        throw new Error("Error downloading folder: " + action.error.type)
      }
    }
  }
}
</script>