<template>
  <div id="previewer">
    <div class="bar">
      <button @click="back" class="action" :title="$t('files.closePreview')" :aria-label="$t('files.closePreview')" id="close">
        <i class="material-icons">close</i>
      </button>

      <rename-button v-if="!isRegister && user.perm.rename"></rename-button>
      <delete-button v-if="!isRegister && user.perm.delete"></delete-button>
      <download-button v-if="user.perm.download"></download-button>
      <info-button></info-button>
    </div>

    <button class="action" @click="prev" v-show="hasPrevious" :aria-label="$t('buttons.previous')" :title="$t('buttons.previous')">
      <i class="material-icons">chevron_left</i>
    </button>
    <button class="action" @click="next" v-show="hasNext" :aria-label="$t('buttons.next')" :title="$t('buttons.next')">
      <i class="material-icons">chevron_right</i>
    </button>

    <div class="preview" id="previewDiv">
      <img id="previewControl" v-if="type == 'image'" :src="raw" style="max-width: 100%; min-width: 10%;">
      <audio id="previewControl" v-else-if="type == 'audio'" :src="raw" autoplay controls></audio>
      <video id="previewControl" v-else-if="type == 'video'" :src="raw" autoplay controls>
        <track
          kind="captions"
          v-for="(sub, index) in subtitles"
          :key="index"
          :src="sub"
          :label="'Subtitle ' + index" :default="index === 0">
        <font color="white">Sorry, your browser doesn't support embedded videos,
        but don't worry, you can <a id="previewControlAlt" :download="nodeName" :href="raw">download it</a>
        and watch it with your favorite video player!</font>
      </video>
      <object id="previewControl" v-else-if="type == 'pdf'" class="pdf" :data="raw" type="application/pdf" style="overflow-y: auto; height: 100%; width: 100%">
        <pdf id="previewControl" class="pdf" :src="raw" style="overflow-y: auto; height: 100%;"></pdf>
      </object>
      <model-stl v-else-if="type == 'stl'" :src="raw"></model-stl>
      <a v-else-if="(type == 'blob' || type == 'zip')" id="previewControlAlt" download="file.raw" :href="raw">
        <h2 class="message">{{ $t('buttons.download') }} <i class="material-icons">file_download</i></h2>
      </a>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import url from '@/utils/url'
import { baseURL } from '@/utils/constants'
import { files as api } from '@/api'
import InfoButton from '@/components/buttons/Info'
import DeleteButton from '@/components/buttons/Delete'
import RenameButton from '@/components/buttons/Rename'
import DownloadButton from '@/components/buttons/Download'
import pdf from 'vue-pdf'
import { ModelStl } from 'vue-3d-model';

const mediaTypes = [
  "image",
  "video",
  "audio",
  "pdf",
  "stl",
  "blob"
]

export default {
  name: 'preview',
  components: {
    InfoButton,
    DeleteButton,
    RenameButton,
    DownloadButton,
    ModelStl,
    pdf
  },
  data: function () {
    return {
      previousLink: '',
      nextLink: '',
      listing: null,
      subtitles: [],
      node: '',
      type: '',
      extension: ''
    }
  },
  computed: {
  ...mapGetters([
      'selectedCount',
      'isFiles',
      'isRegister',
      'isEditor',
      'isListing',
      'isLogged'
    ]),
    ...mapState(['req', 'user', 'oldReq', 'jwt']),
    isMobile () {
      return window.innerWidth <= 768
    },
    hasPrevious () {
      return (this.previousLink !== '')
    },
    hasNext () {
      return (this.nextLink !== '')
    },
    download () {
      return `${baseURL}/api/raw${this.req.path}?auth=${this.jwt}`
    },
    raw () {
      if (this.node !== null) {
        var fileName = this.node.name
        var uri = '';

        if (this.type === 'image') {
          const file = Module.getFile("///previews/" + this.node.handle + "." + this.extension);
          if (file) {
            uri = Module.getObjectURL("///previews/" + this.node.handle + "." + this.extension);
          } else {
            this.getFile()
            return "";
          }
        } else {
          const file = Module.getFile(this.node.handle + "/" + fileName) 
          if (file && (file.size == this.node.size)) {
            uri = Module.getObjectURL(this.node.handle + "/" + fileName);
          } else {
            this.getFile()
            return "";
          }
        }

        if (uri) {
          var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
          if (iOS) {
              if (this.type == 'pdf') {
                  setTimeout(function() {
                    var previewControl = document.getElementById('previewControl');
                    if (previewControl) {
                      var previewDiv = document.getElementById('previewDiv');
                      if (previewDiv) {
                        previewDiv.innerHTML = previewControl.innerHTML;
                      }
                    }

                    var previewControlAlt = document.getElementById('previewControlAlt');
                    if (previewControlAlt) {
                      previewControlAlt.href = uri;
                      previewControlAlt.download = fileName;
                    }
                  }, 0);

                  return null;
              }
              else {
                  var reader = new FileReader();
                  var out = Module.getFileBlob(this.node.handle + "/" + fileName);
                  reader.onload = function(e) {
                  var previewControlAlt = document.getElementById('previewControlAlt');
                    if (previewControlAlt) {
                      previewControlAlt.href = reader.result;
                      previewControlAlt.download = fileName;
                    }
                  }
                  reader.readAsDataURL(out);
              }
          }
          else {
              setTimeout(function() { 
                var previewControlAlt = document.getElementById('previewControlAlt');
                if (previewControlAlt) {
                  previewControlAlt.href = uri;
                  previewControlAlt.download = fileName;
                }
              }, 0);
          }
          
          if (this.type == 'pdf') {
            return uri + '#view=Fit';
          }

          return uri;
        }
        this.getFile();
        return "";
      } 
    },
    nodeName () {
      var path = `${this.req.path}`;
      path = decodeURIComponent(path);
      var fileName = this.node.name
      return fileName;
    },
  },
  async created () {
    if (this.$route.name === 'Received' || this.$route.name === 'Sent' || this.$route.name === 'Contacts') {
      this.node = await ibb.getNodeById(this.$route.hash.replace('#', ''))
    } else {
      this.node = await ibb.getNodeById(this.req.handle)
    }

    this.node = this.node.node
    this.extension = this.node.name.split('.');
    if (this.extension[this.extension.length - 1] == "jpg" || this.extension[this.extension.length - 1] == "jpeg" || this.extension[this.extension.length - 1] == "png" || this.extension[this.extension.length - 1] == "bmp" || this.extension[this.extension.length - 1] == "heic" || this.extension[this.extension.length - 1] == "heif") {  
      this.type = 'image'
    } else if (this.extension[this.extension.length - 1] == "mp3" || this.extension[this.extension.length - 1] == "wav" || this.extension[this.extension.length - 1] == "flac") {
      this.type = 'audio'
    } else if (this.extension[this.extension.length - 1] == "avi" || this.extension[this.extension.length - 1] == "mp4" || this.extension[this.extension.length - 1] == "mkv" || this.extension[this.extension.length - 1] == "mov") {
      this.type = 'video'
    } else if (this.extension[this.extension.length - 1] == "pdf") {
      this.type = 'pdf'
    } else if (this.extension[this.extension.length - 1] == "zip" || this.extension[this.extension.length - 1] == "tar" || this.extension[this.extension.length - 1] == "rar") {
      this.type = 'zip'
    } else {
      this.type = 'file'
    }
    
    window.addEventListener('keyup', this.key)

    if (this.req.subtitles) {
      this.subtitles = this.req.subtitles.map(sub => `${baseURL}/api/raw${sub}?auth=${this.jwt}&inline=true`)
    }

    try {
      if (this.oldReq.items) {
        this.updateLinks(this.oldReq.items)
      } else {
        const res = await api.fetchId(this.node.parentHandle)
        this.updateLinks(res.items)
      }
    } catch (e) {
      this.$showError(e)
    }
  },
  beforeDestroy () {
    window.removeEventListener('keyup', this.key)
  },
  methods: {
    ...mapMutations(['setIsPreview']),
    async back () {
      if (this.isMobile) {
        this.$router.push({ path: this.$route.path + '#' + this.node.parentHandle })
      } else {
        this.$router.push({ path: this.$route.path })
      }
    },
    prev () {
      this.$router.push({hash: '#' + this.previousLink})
    },
    next () {
      this.$router.push({hash: '#' + this.nextLink})
    },
    key (event) {
      event.preventDefault()

      if (event.which === 13 || event.which === 39) { // right arrow
        if (this.hasNext) this.next()
      } else if (event.which === 37) { // left arrow
        if (this.hasPrevious) this.prev()
      }
    },
    async getFile () {
      var fileName = this.node.name

      var action;
      if (this.type === 'image') {
        action = await ibb.downloadMetadata(this.node.handle, Module.MetadataType.META_PREVIEW.value, "///previews/" + this.node.handle + "." + this.extension);
      } else {
        action = await ibb.downloadWithCustomData(this.node.handle, this.node.handle + "/" + fileName, 'preview');
      }
      if (action.error.type) {
        throw new Error("Error downloading node: " + action.error.type)
      }

      var uri
      if (this.type === 'image') {
        uri = Module.getObjectURL("///previews/" + this.node.handle + "." + this.extension);
      } else {
        uri = Module.getObjectURL(this.node.handle + "/" + fileName);
      }
      
      var previewControl = document.getElementById('previewControl');
      previewControl.src = uri;
      previewControl.data = uri;

      var previewControlAlt = document.getElementById('previewControlAlt');
      if (previewControlAlt) {
        previewControlAlt.href = uri;
        previewControlAlt.download = this.node.name;
      }

      return uri;
    },
    updateLinks (items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].name !== this.node.name) {
          continue
        }

        for (let j = i - 1; j >= 0; j--) {
          if (mediaTypes.includes(items[j].type) && items[j].size <= 50*1024*1024) {
            this.previousLink = items[j].handle
            break
          }
        }

        for (let j = i + 1; j < items.length; j++) {
          if (mediaTypes.includes(items[j].type) && items[j].size <= 50*1024*1024) {
            this.nextLink = items[j].handle
            break
          }
        }

        return
      }
    }
  }
}
</script>
