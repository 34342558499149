<template>
 <div>
  <div v-if="req.nodeType == 44">
    <div class="card">
      <div class="card-title">
        <h2>{{ $t('records.recordInfo') }}</h2>
      </div>

      <div class="card-content">
        <p>{{ $t('records.email') }}</p>
        <input class="input input--block" v-focus type="text" @keyup.enter="submit" v-model.trim="req.contact" readonly>

        <p>{{ $t('records.subject') }}</p>
        <input class="input input--block" v-focus type="text" @keyup.enter="submit" v-model.trim="req.name" readonly>

        <p>{{ $t('records.message') }}</p>
        <textarea class="input input--block" type="text" @keyup.enter="submit" v-model.trim="req.message" rows="5" readonly></textarea>
      </div>
    </div>
  </div>

  <div v-if="(req.numDirs + req.numFiles) == 0">
    <h2 class="message">
      <i class="material-icons">folder_open</i>
      <span>{{ $t('files.lonely') }}</span>
    </h2>
    <input style="display:none" type="file" id="upload-input" @change="uploadInput($event)" multiple>
  </div>
  <div v-else id="listing"
    :class="user.viewMode"
    @dragenter="dragEnter"
    @dragend="dragEnd">

    <div v-if="inboxes.length > 0">
      <item-inbox v-for="(item) in inboxes"
        :key="item.handle"
        v-bind:index="item.index"
        v-bind:name="item.name"
        v-bind:isDir="item.isDir"
        v-bind:url="item.url"
        v-bind:modified="item.modified"
        v-bind:type="item.type"
        v-bind:handle="item.handle"
        v-bind:nodeType="item.nodeType"
        v-bind:contact="item.contact"
        v-bind:isContainerRecord="isContainerRecord"
        v-bind:isHeader="isHeader"
        v-bind:checkedAll="checkedAll"
        v-bind:buscador="buscador"
        @open-menu="mostrarMenu"
        @show-records="showRecords">
      </item-inbox>
    </div>

    <div class="records" v-if="records.length > 0">
      <record v-for="(item) in records"
        :key="base64(item.handle)"
        v-bind:name="item.name"
        v-bind:contact="item.contact"
        v-bind:message="item.message"
        v-bind:handle="item.handle">
      </record>
    </div>

    <input style="display:none" type="file" id="upload-input" @change="uploadInput($event)" multiple>

    <div :class="{ active: $store.state.multiple }" id="multiple-selection">
      <p>{{ $t('files.multipleSelectionEnabled') }}</p>
      <div @click="$store.commit('multiple', false)" tabindex="0" role="button" :title="$t('files.clear')" :aria-label="$t('files.clear')" class="action">
        <i class="material-icons">clear</i>
      </div>
    </div>
  </div>
  <menu-contextual id="menu" v-if="showMenu" :coordenadaX="coordenadaX" :coordenadaY="coordenadaY"></menu-contextual>
 </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import ItemInbox from './ListingItemInbox'
import css from '@/utils/css'
import { users, files as api } from '@/api'
import buttons from '@/utils/buttons'
import MenuContextual from '../Menu-contextual.vue'
import Record from '@/components/Record.vue'

export default {
  name: 'listing-inbox',
  components: { 
    ItemInbox,
    MenuContextual,
    Record
  },
  data: function () {
    return {
      show: 50,
      isShowMenu: false,
      x: 0,
      y: 0,
      coordenadaX: 0,
      coordenadaY: 0,
      isOptions: false,
      isHeader: false
    }
  },
  props: ['isContainerRecord', 'checkedAll', 'buscador'],
  computed: {
    ...mapGetters([
      'isFiles',
      'isRegister',
      'selectedCount'
    ]),
    ...mapState(['req', 'selected', 'user', 'isMenu']),
    items () {
      const dirs = []
      const files = []
      const inboxes = []
      const records = []

      this.req.items.forEach((item) => {
        if (item.isDir) {
          dirs.push(item)

          if (item.type === "inbox") {
            inboxes.push(item);
          } else if (item.type === "record") {
            records.push(item);
          }
        } else {
          files.push(item)
        }
      })

      return { dirs, files, inboxes, records }
    },
    dirs () {
      return this.items.dirs.slice(0, this.show)
    },
    inboxes () {
      return this.items.inboxes.slice(0, this.show)
    },
    records () {
      return this.items.records.slice(0, this.show)
    },
    files () {
      let show = this.show - this.items.dirs.length

      if (show < 0) show = 0

      return this.items.files.slice(0, show)
    },
    showMenu() {
      if (this.isShowMenu && !this.isMobile()) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted: function () {
    // Check the columns size for the first time.
    this.resizeEvent()

    // Add the needed event listeners to the window and document.
    window.addEventListener('resize', this.resizeEvent)
    window.addEventListener('scroll', this.scrollEvent)
    window.addEventListener('click', this.mouseEvent)
    window.addEventListener('contextmenu', this.mouseEvent)
    if (!this.isRegister)
    {
      window.addEventListener('keydown', this.keyEvent)
      document.addEventListener('dragover', this.preventDefault)
      document.addEventListener('drop', this.drop)
    }
  },
  beforeDestroy () {
    // Remove event listeners before destroying this page.
    window.removeEventListener('resize', this.resizeEvent)
    window.removeEventListener('scroll', this.scrollEvent)
    window.removeEventListener('click', this.mouseEvent)
    window.removeEventListener('contextmenu', this.mouseEvent)
    if (!this.isRegister)
    {
      window.removeEventListener('keydown', this.keyEvent)
      document.removeEventListener('dragover', this.preventDefault)
      document.removeEventListener('drop', this.drop)
    }
  },
  methods: {
    ...mapMutations([ 'updateUser', 'resetSelected']),
    showRecords(handle) {
      this.$emit('show-records', handle);
    },
    base64: function (name) {
      return window.btoa(unescape(encodeURIComponent(name)))
    },
    isMobile: function () {
      return window.innerWidth <= 768
    },
    mouseEvent (event) {
      if(event.type === 'contextmenu' && !this.isMenu) {
        event.preventDefault();
        if (!this.isContainerRecord) {
          this.isShowMenu = false;
          this.obtenerCoordenadas(event);
          this.isShowMenu = true;
        }
      } else if(event.type === 'click' && !this.isOptions) {
        this.isShowMenu = false;
      } else {
        this.isOptions = false;
      }
    },
    mostrarMenu (evento) {
      this.isOptions = true;
      this.isShowMenu = false;
      this.obtenerCoordenadas(evento);
      this.isShowMenu = true;
    },
    obtenerCoordenadas(event) {
      this.coordenadaX = event.clientX;
      this.coordenadaY = event.clientY;
    },
    keyEvent (event) {
      if (!event.ctrlKey && !event.metaKey) {
        return
      }

      let key = String.fromCharCode(event.which).toLowerCase()

      switch (key) {
        case 'f':
          event.preventDefault()
          this.$store.commit('showHover', 'search')
          break
        case 'c':
        case 'x':
          this.copyCut(event, key)
          break
        case 'v':
          this.paste(event)
          break
      }
    },
    preventDefault (event) {
      // Wrapper around prevent default.
      event.preventDefault();
    },
    copyCut (event, key) {
      if (event.target.tagName.toLowerCase() === 'input') {
        return
      }

      let items = []

      for (let i of this.selected) {
        items.push({
          from: this.req.items[i].handle,
          name: encodeURIComponent(this.req.items[i].name)
        })
      }

      if (items.length == 0) {
        return
      }

      this.$store.commit('updateClipboard', {
        key: key,
        items: items
      })
    },
    paste (event) {
      if (event.target.tagName.toLowerCase() === 'input') {
        return
      }

      let items = []

      for (let item of this.$store.state.clipboard.items) {
        const from = item.from
        const to = this.req.handle
        console.log('Pasting from ' + from + " to " + to);
        items.push({ from, to })
      }

      if (items.length === 0) {
        return
      }

      if (this.$store.state.clipboard.key === 'x') {
        api.moveId(items).then(() => {
          this.$store.commit('setReload', true)
        }).catch(this.$showError)
        return
      }

      api.copyId(items).then(() => {
        this.$store.commit('setReload', true)
      }).catch(this.$showError)
    },
    resizeEvent () {
      // Update the columns size based on the window width.
      let columns = Math.floor(document.querySelector('main').offsetWidth / 300)
      let items = css(['#listing.mosaic .item', '.mosaic#listing .item'])
      if (columns === 0) columns = 1
      items.style.width = `calc(${100 / columns}% - 1em)`
    },
    scrollEvent () {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        this.show += 50
      }
    },
    dragEnter () {
      // When the user starts dragging an item, put every
      // file on the listing with 50% opacity.
      let items = document.getElementsByClassName('item')

      Array.from(items).forEach(file => {
        file.style.opacity = 0.5
      })
    },
    dragEnd () {
      this.resetOpacity()
    },
    drop: function (event) {
      event.preventDefault()
      this.resetOpacity()

      let dt = event.dataTransfer
      let files = dt.files
      let el = event.target

      if (files.length <= 0) return

      for (i = 0; i < dt.items.length; i++) {
        if (typeof (dt.items[i].webkitGetAsEntry) == "function") {
            if (!dt.items[i].webkitGetAsEntry().isFile) {
              console.log("Folder upload");
              this.$showWarning(this.$t('transfers.unsupportedfolderupload'))
              return;
            }
        } else if (typeof (dt.items[i].getAsEntry) == "function") {
            if (!dt.items[i].getAsEntry().isFile) {
              console.log("Folder upload");
              this.$showWarning(this.$t('transfers.unsupportedfolderupload'))
              return;
            }
        }
      }      

      for (let i = 0; i < 5; i++) {
        if (el !== null && !el.classList.contains('item')) {
          el = el.parentElement
        }
      }

      let base = ''
      var index = -1;

      if (el !== null && el.classList.contains('item') && el.dataset.dir === 'true') {
        base = el.querySelector('.name').innerHTML
        for (i = 0; i < this.req.items.length; i++)
        {
          if (this.req.items[i].name == base)
          {
            index = i;
            break;
          }
        }
      }

      if (base !== '' && index >= 0) {
        api.fetchId(this.req.items[index].handle)
          .then(req => {
            this.checkConflict(files, req.items, this.req.items[index].handle)
          })
          .catch(this.$showError)

        return
      }

      this.checkConflict(files, this.req.items, '')
    },
    checkConflict (files, items, base) {
      if (typeof items === 'undefined' || items === null) {
        items = []
      }

      let conflict = false
      let res;
      for (let i = 0; i < files.length; i++) {
        res = items.findIndex(function hasConflict (element) {
          return (element.name === this)
        }, files[i].name)

        if (res >= 0) {
          conflict = true
          break
        }
      }

      if (!conflict) {
        this.handleFiles(files, base)
        return
      }

      this.$store.commit('showHover', {
        prompt: 'replace',
        confirm: (event) => {
          event.preventDefault()
          this.$store.commit('closeHovers')
          this.handleFiles(files, base, items[res].handle)
        }
      })
    },
    uploadInput (event) {
      this.checkConflict(event.currentTarget.files, this.req.items, '')
    },
    resetOpacity () {
      let items = document.getElementsByClassName('item')

      Array.from(items).forEach(file => {
        file.style.opacity = 1
      })
    },
    handleFiles (files, base, overwrite = false) {
      buttons.loading('upload')
      let promises = []
      let progress = new Array(files.length).fill(0)

      let onupload = (id) => (event) => {
        progress[id] = (event.loaded / event.total) * 100

        let sum = 0
        for (let i = 0; i < progress.length; i++) {
          sum += progress[i]
        }

        this.$store.commit('setProgress', Math.ceil(sum / progress.length))
      }

      console.log("Uploading to " + this.req.url + " base: " + base);

      for (let i = 0; i < files.length; i++) {
        let file = files[i]
        promises.push(api.postId(base ? base : this.req.handle, file, overwrite, onupload(i)))
      }
      this.$showSuccess(this.$t('transfers.uploadadded'))


      let finish = () => {
        buttons.success('upload')
        this.$store.commit('setProgress', 0)
      }

      Promise.all(promises)
        .then(() => {
          finish()
          this.$store.commit('setReload', true)
        })
        .catch(error => {
          finish()
          this.$showError(error)
        })

      return false
    },
    mouseEnter() {
      this.isHeader = true;
    },
    mouseLeave() {
      this.isHeader = false;
    },
  }
}
</script>