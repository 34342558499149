<template>
  <div class="card floating">
    <div class="card-title">
      <h2>{{ $t('prompts.browse') }}</h2>
    </div>

    <div class="card-content">
      <link-file-list @update:selected="val => dest = val"></link-file-list>
    </div>

    <div class="card-action">
      <button class="button button--flat" id ="buttonCancelDownload"
        @click="$store.commit('closeHovers')"
        :aria-label="$t('buttons.close')"
        :title="$t('buttons.close')">{{ $t('buttons.close') }}</button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LinkFileList from './LinkFileList'
import { files as api } from '@/api'
import buttons from '@/utils/buttons'

export default {
  name: 'download-from-link',
  components: { LinkFileList },
  data: function () {
    return {
      current: window.location.pathname,
      dest: null
    }
  },
  computed: mapState(['req', 'selected'])
}
</script>
