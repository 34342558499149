<template>
  <div class="card floating" id="terms-conditions">
    <div class="card-title">
      <h2>{{ $t('conditions.title') }}</h2>
    </div>

    <div class="card-content">
      <p class="text-terms">{{ $t('conditions.textTitle1') }}</p>
      <p class="text-terms">{{ $t('conditions.textTitle2') }}</p>
      <ul>
        <li style="display: block;">
          <p class="text-terms">{{ $t('conditions.textA') }}</p>
        </li>
        <li style="display: block;">
          <p class="text-terms">{{ $t('conditions.textB') }}</p>
        </li>
        <li style="display: block;">
          <p class="text-terms">{{ $t('conditions.textC') }}</p>
        </li>
        <li style="display: block;">
          <p class="text-terms">{{ $t('conditions.textD') }}</p>
        </li>
        <li style="display: block;">
          <p class="text-terms">{{ $t('conditions.textE') }}</p>
        </li>
        <li style="display: block;">
          <p class="text-terms">{{ $t('conditions.textF') }}</p>
        </li>
        <li style="display: block;">
          <p class="text-terms">{{ $t('conditions.textG') }}</p>
        </li>
        <li style="display: block;">
          <p class="text-terms">{{ $t('conditions.textH') }}</p>
        </li>
        <li style="display: block;">
          <p class="text-terms">{{ $t('conditions.textI') }}</p>
        </li>
        <li style="display: block;">
          <p class="text-terms">{{ $t('conditions.textJ') }}</p>
        </li>
        <li style="display: block;">
          <p class="text-terms">{{ $t('conditions.textK') }}</p>
        </li>
      </ul>
    </div>

    <div class="card-action">
      <button class="button button--flat"
        @click="$store.commit('closeHovers')"
        :aria-label="$t('buttons.close')"
        :title="$t('buttons.close')">{{ $t('buttons.close') }}</button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'terms-conditions',
  methods: {
    ...mapMutations(['closeHovers']),
  }
}
</script>