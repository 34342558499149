<template>
  <div class="dashboard-info">
    <div class="contenedor-info">
      <div class="space">
        <svg width="350" height="175">
          <circle class="track" cx="175" cy="175" r="140" stroke="#e7edff" stroke-width="45" fill="none"></circle>
          <circle class="progress" cx="175" cy="175" r="140" :stroke="colorStroke" stroke-width="45" fill="none" :stroke-dasharray="Math.PI*140" :stroke-dashoffset="-Math.PI*140*this.occupiedSpace"></circle>
          <text x="50%" y="75%" dominant-baseline="middle" text-anchor="middle" style="font-weight: 600; font-size: 48px;">{{ this.availableSpace }}</text>
          <text x="50%" y="95%" dominant-baseline="middle" text-anchor="middle" style="font-size: 11px;">{{ $t('info.availableSpace') }} {{ spaceAvailableString }}</text>
        </svg>
      </div>
      <div class="contenedor-cards">
        <div>
          <div class="card-title">
            <h2>{{ $t('info.sessions') }}</h2>
            <div class="botones">
              <button :aria-label="$t('settings.openSessions')" :title="$t('settings.openSessions')" id="open" :class="[sessions === 'open' ? 'active' : 'no-active']" @click="changeSessions('open')">
                <span>{{ $t('settings.openSessions') }}</span>
              </button>
              <button :aria-label="$t('settings.closedSessions')" :title="$t('settings.closedSessions')" id="close" :class="[sessions === 'close' ? 'active' : 'no-active']" @click="changeSessions('close')">
                <span>{{ $t('settings.closedSessions') }}</span>
              </button>
            </div>
          </div>
          <div class="card">
            <div class="card-content">
              <listing-active-sessions v-if="sessions === 'open'" class="false"></listing-active-sessions>
              <listing-closed-sessions v-else class="false"></listing-closed-sessions>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { files as api } from '@/api'
import ListingActiveSessions from '@/components/sessions/ListingActiveSessions'
import ListingClosedSessions from '@/components/sessions/ListingClosedSessions'
import filesize from 'filesize'
import i18n from '@/i18n'
import moment from 'moment'

export default {
  name: 'settings',
  components: {
    ListingActiveSessions,
    ListingClosedSessions,
  },
  data: function () {
    return {
      password: '',
      passwordConf: '',
      locale: '',
      storageUsed: 0,
      storageTotal: 0,
      statusString: '',
      availableSpace: '0%',
      features: '',
      occupiedSpace: 0,
      sessions: 'open'
    }
  },
  computed: {
    ...mapState([
      'req',
      'user',
      'reload',
      'multiple',
      'loading',
    ]),
    passwordClass () {
      const baseClass = 'input input--block'

      if (this.password === '' && this.passwordConf === '') {
        return baseClass
      }

      if (this.password === this.passwordConf) {
        return `${baseClass} input--green`
      }

      return `${baseClass} input--red`
    },
    spaceAvailableString () {
      if (!this.storageTotal)
      {
          return this.$t('info.loading');
      }

      if (this.storageTotal > this.storageUsed)
      {
          return this.$t('info.availableSpaceInfo', { available: filesize(this.storageUsed), total: filesize(this.storageTotal)});
      }

      return this.$t('info.exhausted', { available: filesize(this.storageTotal), total: filesize(this.storageTotal)});
    },
    isMobile () {
      return window.innerWidth <= 768
    },
    colorStroke () {
      if (this.storageTotal > this.storageUsed) {
        return 'var(--blue)'
      } else {
        return 'red'
      }
    }
  },
  created () {
    this.locale = this.user.locale
    var thisComponent = this;
    var JavaEventListenerClass = Module.EventListener.extend("EventListener", {
      onEvent: function(event)
      {
          if (event.type == Module.EventType.NEW_NODES.value)
          {
            var node = event.node;
            if (node && (node.type == Module.NodeType.SNAPSHOT.value
              || node.type == Module.NodeType.BACKUP.value
              || node.type == Module.NodeType.DEVICE.value))
            {
              thisComponent.fetchInfo();
            }
            else
            {
              thisComponent.fetchInfo();
            }
          }
          else if (event.type == Module.EventType.DELETED_NODE.value)
          {   
            thisComponent.fetchInfo();
          }
          else if (event.type == Module.EventType.FULL_RELOAD.value)
          {
            thisComponent.fetchInfo();
          } else if (event.type == Module.EventType.UPDATED_NODE.value) {
            node = event.node;
          }
      }
    });

    this.interfaceObject = new JavaEventListenerClass();
    ibb.addEventListener(this.interfaceObject);
    this.fetchInfo()
  },
  async mounted() {
    try {
      var action = await ibb.getSessionInfo()
      if (action.error.type) {
        this.$showError("Error getting session info: " +  action.error.type)
        return;
      }

      console.log(action)
      this.$store.commit('updateActiveSessionHandle', action.nodeHandle)
      this.$store.commit('updateActiveSessions', action.activeSessions, action.nodeHandle)
      this.$store.commit('updateInactiveSessions', action.inactiveSessions)
    } catch (e) {
      this.$showError(e)
    }
  },
  watch: {
    'reload': function () {
      this.fetchInfo()
    }
  },
  beforeDestroy () {
    ibb.removeEventListener(this.interfaceObject);
  },
  methods: {
    ...mapMutations([ 'updateUser', 'setLoading' ]),
    changeSessions (type) {
      if (type === 'open') {
        this.sessions = 'open'
      } else {
        this.sessions = 'close'
      }
    },
    async fetchInfo () {      
      try {
        var action = await api.getAccountInfo();
        var info = action.accountInfo;
        this.storageUsed = info.ustorage;
        this.storageTotal = info.tstorage;
        this.features = action.accountInfo.features;

        if (this.storageTotal > this.storageUsed) {
          this.occupiedSpace = (this.storageUsed/this.storageTotal).toFixed(2)
          this.availableSpace = ((this.storageUsed/this.storageTotal)*100).toFixed(2) + '%'
        } else {
          this.occupiedSpace = 1
          this.availableSpace = 100 + '%'
        }

        if (!info.credit)
        {
            this.$router.go()
            return;
        }

        if (!info.pmethod)
        {
            var currentTime = Date.now();
            var renewTime = info.rtime * 1000;
            var rtimeString = moment(renewTime).format("DD/MM/YYYY");
            if (currentTime > (renewTime + 86400000)) // Expired more than one day ago
            {
                this.statusString = "<font color=\"red\">" + i18n.t('info.accountExpired', {"0": rtimeString, "1": "<a href=\"/profile/invoicing/plans\" style=\"color: #2B5597;\">", "2": "</a>"}) + "</font>";
            }
            else if (currentTime + 432000000 > renewTime) // Will expire in less than 5 days
            {
                this.statusString = "<font color=\"brown\">" + i18n.t('info.accountAboutToExpire', {"0": rtimeString, "1": "<a href=\"/profile/invoicing/plans\" style=\"color: #2B5597;\">", "2": "</a>"}) + "</font>";
            }
            else
            {
              this.statusString = i18n.t('info.correct');
            }
        }
        else
        {
          this.statusString = i18n.t('info.correct');
        }
      } catch (e) {
        console.log("ERROR: " + e)
        this.error = e
      }
    },
    async updateSuscription (event) {
      event.preventDefault()
    },
  }
}
</script>
