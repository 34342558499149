import store from '@/store'
import router from '@/router'
import i18n from '@/i18n'
import { Base64 } from 'js-base64'
import { baseURL } from '@/utils/constants'

export async function validateLogin () {
  console.log("Looking for a previous session");
  let uri = window.location.search.substring(1); 
  let params = new URLSearchParams(uri);
  let sessionInUrl = params.get("session");
  if (sessionInUrl)
  {
    console.log("Session in URL");
    if (localStorage.getItem('jwt') !== "null") {
      alert(i18n.t('bmail.logout'));
    }
    localStorage.setItem('jwt', sessionInUrl)
    var action = await ibb.resumeSession(sessionInUrl);
    if (action.error.type) {
      router.push({path: '/login'})
    }
  }

  try {
    if (localStorage.getItem('jwt') && localStorage.getItem('jwt') != "null") {
      console.log("Session found. Logging in...");      
      var action = await ibb.resumeSession(localStorage.getItem('jwt'));
      if (action.error.type && action.error.type != Module.ErrorType.E_INVOICING.value) {
        store.commit('setJWT', '')
        store.commit('setUser', null)
        localStorage.setItem('jwt', null)
        var action2 = await ibb.getAccountInfo();
        if (action2.error.type) {
          throw new Error("Error getting account info: " + action2.error.type)
        }
        removeSession(action2.accountInfo.handle)
        console.warn("Invalid session: " + action.error.type);
        return action.error.type;  
      }
      var username = action.email;
      var multiFactorAuth = action.intValue;

      var lang = localStorage.getItem('lang');
      if (lang != "es" && lang != "en")
      {
          lang = "";
      }

      var action2 = await ibb.getAccountInfo();
      if (action2.error.type) {
        throw new Error("Error getting account info: " + action2.error.type)
      }
      var accountHandle = action2.accountInfo.handle;
      var isReseller = action2.accountInfo.isreseller;
      var features = action2.accountInfo.features;

      var action3 = await ibb.getInvoicingInfo();
      if (action3.error.type) {
        return null
      }
      if (action3.invoicingInfo.paymentMethod !== undefined) {
        var paymentMethod = action3.invoicingInfo.paymentMethod.type;
      } else {
        var paymentMethod = 0;
      }

      var user = JSON.parse(`{"id":1,"email":"` + username + `","locale":"` + lang + `","viewMode":"mosaic","multiFactorAuth":` + multiFactorAuth +
                          `,"perm":{"admin":false,"execute":false,"create":true,"rename":true,
                                  "modify":true,"delete":true,"share":true,"download":true, "reseller":` + isReseller + `, "paymentMethod":` + paymentMethod + `, "features":` + features + `},
                          "commands":[],"lockPassword":false}`)

      if (action.error.type == Module.ErrorType.E_INVOICING.value) {
        console.log("Invoicing info required");
        store.commit('setJWT', username)
        store.commit('setUser', user)
        return Module.ErrorType.E_INVOICING.value;
      }

      action = await ibb.getNodes();
      if (action.error.type) {
        store.commit('setJWT', '')
        store.commit('setUser', null)
        localStorage.setItem('jwt', null)
        removeSession(accountHandle)
        console.warn("Error getting nodes: " + action.error.type)
        return action.error.type;
      }
      store.commit('setJWT', username)
      store.commit('setUser', user)

      var JavaEventListenerClass = Module.EventListener.extend("EventListener", {
        onEvent: function(event)
        {
          if (event.type == Module.EventType.LOGOUT.value)
          {
              location.reload();
              return;
          }

          clearTimeout(window.gTimeout);
          window.gTimeout = setTimeout(() => {
            //store.commit('setReload', true)
          }, 1000)
        }
      });

      ibb.addEventListener(new JavaEventListenerClass());

      if (sessionInUrl)
      {
          router.replace({path: window.location.pathname})
      }

      return Module.ErrorType.E_SUCCESS.value;
    }
    else {
      console.log("Previous session not found");
      return Module.ErrorType.E_NOTFOUND.value;
    }
  } catch (_) {
    console.warn('Invalid JWT token in storage') // eslint-disable-line
    store.commit('setJWT', '')
    store.commit('setUser', null)
    localStorage.setItem('jwt', null)
    removeSession(accountHandle)
  }
}

export function addNewSessionForAccount(account, session) { 
  var sessions = JSON.parse(localStorage.getItem('sessions')) || {};
  sessions[account] = session
  localStorage.setItem('sessions', JSON.stringify(sessions))
}

export function removeSession(account) { 
  var sessions = JSON.parse(localStorage.getItem('sessions')) || {};
  if (sessions[account]) {
    delete sessions[account]
  }
  localStorage.setItem('sessions', JSON.stringify(sessions))
}

export async function getSessionForAccount(account) { 
  var sessions = JSON.parse(localStorage.getItem('sessions')) || {};
  if (sessions[account]) {
    localStorage.setItem('jwt', sessions[account])
    validateLogin();
  } else {
    await newSessionIntoAccount(account);
  }
}

export async function newSessionIntoAccount(handle) {
  var action = await ibb.newSessionIntoAccount(handle);
  if (action.error.type && action.error.type != Module.ErrorType.E_INVOICING.value) {
    store.commit('setJWT', '')
    store.commit('setUser', null)
    localStorage.setItem('jwt', null)
    removeSession(handle)
    console.warn("Invalid session: " + action.error.type);
    return action.error.type;  
  } else {
    localStorage.setItem('jwt', action.session);
    addNewSessionForAccount(handle, action.session);
    validateLogin(); 
  }
}

export async function getNodes() {
  var action = await ibb.getNodes();
  if (action.error.type) {
    store.commit('setJWT', '')
    store.commit('setUser', null)
    localStorage.setItem('jwt', null)

    var action2 = await ibb.getAccountInfo();
    if (action2.error.type) {
      throw new Error("Error getting account info: " + action2.error.type)
    }
    removeSession(action2.accountInfo.handle)
    throw new Error("Error getting nodes: " + action.error.type)
  }

  var JavaEventListenerClass = Module.EventListener.extend("EventListener", {
      onEvent: function(event)
      {
          if (event.type == Module.EventType.LOGOUT.value)
          {
              location.reload();
              return;
          }

          clearTimeout(window.gTimeout);
          window.gTimeout = setTimeout(() => {
            //store.commit('setReload', true)
          }, 1000)
      }
  });

  ibb.addEventListener(new JavaEventListenerClass());
  return 0;
}

export async function login (username, password, recaptch, code) {
  
  var action;
  if (typeof code === 'undefined')
  {
      action = await ibb.login(username, password)
      if (action.error.type && action.error.type != Module.ErrorType.E_INVOICING.value) {
        return action.error.type;
      }
  }
  else
  {
      action = await ibb.login2fa(username, password, code)
      if (action.error.type && action.error.type != Module.ErrorType.E_INVOICING.value) {
        return action.error.type;
      }
  }

  var multiFactorAuth = action.intValue;
  var session = action.session;

  var lang = localStorage.getItem('lang');
  if (lang != "es" && lang != "en")
  {
      lang = "";
  }

  var action2 = await ibb.getAccountInfo();
  if (action2.error.type) {
    throw new Error("Error getting account info: " + action2.error.type)
  }
  var isReseller = action2.accountInfo.isreseller;
  var accountHandle = action2.accountInfo.handle;
  var features = action2.accountInfo.features;

  if (!(features & Module.FeatureType.FEATURE_BMAIL.value)) {
    return null
  }

  var action3 = await ibb.getInvoicingInfo();
  if (action3.error.type) {
    return null
  }
  if (action3.invoicingInfo.paymentMethod !== undefined) {
    var paymentMethod = action3.invoicingInfo.paymentMethod.type;
  } else {
    var paymentMethod = 0;
  }

  var user = JSON.parse(`{"id":1,"email":"` + username + `","locale":"` + lang + `","viewMode":"mosaic","multiFactorAuth":` + multiFactorAuth +
                          `,"perm":{"admin":false,"execute":false,"create":true,"rename":true,
                                  "modify":true,"delete":true,"share":true,"download":true, "reseller":` + isReseller + `, "paymentMethod":` + paymentMethod + `, "features":` + features + `},
                          "commands":[],"lockPassword":false}`)

  localStorage.setItem('jwt', session)
  addNewSessionForAccount(accountHandle, session)
  store.commit('setJWT', username)
  store.commit('setUser', user)

  if (action.error.type == Module.ErrorType.E_INVOICING.value) {
    return action.error.type;
  }

  var result = await getNodes();
  return result;
}

export async function validate (username, firstname, lastname) {
  var action = await ibb.verifyEmail(username, firstname, lastname)
  if (action.error.type) {
    console.log("Error in verifyEmail");
    throw action.error.type;
  }
  return action.text;
}

export async function joinAccountWithCurrentUser (account, accountKey) {
  var action = await ibb.joinAccountWithCurrentUser(account, accountKey)
  if (action.error.type && action.error.type != Module.ErrorType.E_INVOICING.value) {
    store.commit('setJWT', '')
    store.commit('setUser', null)
    localStorage.setItem('jwt', null)
    removeSession(account)
    console.warn("Invalid session: " + action.error.type);
    return action.error.type;  
  } else {
    localStorage.setItem('jwt', action.session);
    addNewSessionForAccount(account, action.session)
  }
}

export async function forgotPassword (username) {
  var action = await ibb.forgotPassword(username)
  if (action.error.type) {
    console.log("Error starting forgotPassword");
    throw action.error.type;
  }
  return action.text;
}

export async function createAccountForCurrentUser (username, referrerCode) {
  var action = await ibb.createAccountForCurrentUser(referrerCode);
  if (action.error.type && action.error.type != Module.ErrorType.E_INVOICING.value) {
    console.log("Error in createAccountForCurrentUser");
    throw action.error.type;
  }

  var session = action.session;
  var lang = localStorage.getItem('lang');
  if (lang != "es" && lang != "en")
  {
      lang = "";
  }

  var action2 = await ibb.getAccountInfo();
  if (action2.error.type) {
    throw new Error("Error getting account info: " + action2.error.type)
  }
  var isReseller = action2.accountInfo.isreseller;
  var accountHandle = action2.accountInfo.handle;
  var features = action2.accountInfo.features;

  var action3 = await ibb.getInvoicingInfo();
  if (action3.error.type) {
    return null
  }
  if (action3.invoicingInfo.paymentMethod !== undefined) {
    var paymentMethod = action3.invoicingInfo.paymentMethod.type;
  } else {
    var paymentMethod = 0;
  }

  var user = JSON.parse(`{"id":1,"email":"` + username + `","locale":"` + lang + `","viewMode":"mosaic","multiFactorAuth":0,
                        "perm":{"admin":false,"execute":false,"create":true,"rename":true,
                        "modify":true,"delete":true,"share":true,"download":true, "reseller":` + isReseller + `, "paymentMethod":` + paymentMethod + `, "features":` + features + `},
                        "commands":[],"lockPassword":false}`)


  localStorage.setItem('jwt', session)
  addNewSessionForAccount(accountHandle, session)
  store.commit('setJWT', username)
  store.commit('setUser', user)
                            
  if (action.error.type && action.error.type == Module.ErrorType.E_INVOICING.value) {
    throw action.error.type;
  }

  action = await ibb.getNodes();
  if (action.error.type) {
    console.log("Error getting nodes");
    store.commit('setJWT', '')
    store.commit('setUser', null)
    localStorage.setItem('jwt', null)
    removeSession(accountHandle)
    throw new Error("Error getting nodes: " + action.error.type)
  }

  var JavaEventListenerClass = Module.EventListener.extend("EventListener", {
      onEvent: function(event)
      {
          if (event.type == Module.EventType.LOGOUT.value)
          {
              location.reload();
              return;
          }

          clearTimeout(window.gTimeout);
          window.gTimeout = setTimeout(() => {
            store.commit('setReload', true)
          }, 1000)
      }
  });

  ibb.addEventListener(new JavaEventListenerClass());

  return;
}

export async function joinAccount (account, accountKey, id, firstname, lastname, username, password, pin) {
  var action = await ibb.joinAccount(account, accountKey, id, firstname, lastname, username, password, parseInt(pin));
  if (action.error.type && action.error.type != Module.ErrorType.E_INVOICING.value) {
    console.log("Error in joinAccount");
    throw action.error.type;
  }

  var session = action.session;
  var lang = localStorage.getItem('lang');
  if (lang != "es" && lang != "en")
  {
      lang = "";
  }

  var action2 = await ibb.getAccountInfo();
  if (action2.error.type) {
    throw new Error("Error getting account info: " + action2.error.type)
  }
  var isReseller = action2.accountInfo.isreseller;
  var accountHandle = action2.accountInfo.handle;
  var features = action2.accountInfo.features;

  var action3 = await ibb.getInvoicingInfo();
  if (action3.error.type) {
    return null
  }
  if (action3.invoicingInfo.paymentMethod !== undefined) {
    var paymentMethod = action3.invoicingInfo.paymentMethod.type;
  } else {
    var paymentMethod = 0;
  }

  var user = JSON.parse(`{"id":1,"email":"` + username + `","locale":"` + lang + `","viewMode":"mosaic","multiFactorAuth":0
                      ,"perm":{"admin":false,"execute":false,"create":true,"rename":true,
                              "modify":true,"delete":true,"share":true,"download":true, "reseller":` + isReseller + `, "paymentMethod":` + paymentMethod + `, "features":` + features + `},
                      "commands":[],"lockPassword":false}`)


  localStorage.setItem('jwt', session)
  addNewSessionForAccount(accountHandle, session)
  store.commit('setJWT', username)
  store.commit('setUser', user)
                            
  if (action.error.type && action.error.type == Module.ErrorType.E_INVOICING.value) {
    throw action.error.type;
  }

  action = await ibb.getNodes();
  if (action.error.type) {
    console.log("Error getting nodes");
    store.commit('setJWT', '')
    store.commit('setUser', null)
    localStorage.setItem('jwt', null)
    removeSession(accountHandle)
    throw new Error("Error getting nodes: " + action.error.type)
  }

  var JavaEventListenerClass = Module.EventListener.extend("EventListener", {
      onEvent: function(event)
      {
          if (event.type == Module.EventType.LOGOUT.value)
          {
              location.reload();
              return;
          }

          clearTimeout(window.gTimeout);
          window.gTimeout = setTimeout(() => {
            store.commit('setReload', true)
          }, 1000)
      }
  });

  ibb.addEventListener(new JavaEventListenerClass());

  return;
}

export async function signup (id, username, password, pin, firstname, lastname, referrerCode, planId) {
  var action = await ibb.createAccountWithPlan(id, firstname, lastname, username, password, parseInt(pin), referrerCode, planId);
  if (action.error.type && action.error.type != Module.ErrorType.E_INVOICING.value) {
    console.log("Error in createAccountWithPlan");
    throw action.error.type;
  }

  var session = action.session;
  var lang = localStorage.getItem('lang');
  if (lang != "es" && lang != "en")
  {
      lang = "";
  }

  var action2 = await ibb.getAccountInfo();
  if (action2.error.type) {
    throw new Error("Error getting account info: " + action2.error.type)
  }
  var isReseller = action2.accountInfo.isreseller;
  var accountHandle = action2.accountInfo.handle;
  var features = action2.accountInfo.features;

  var action3 = await ibb.getInvoicingInfo();
  if (action3.error.type) {
    return null
  }
  if (action3.invoicingInfo.paymentMethod !== undefined) {
    var paymentMethod = action3.invoicingInfo.paymentMethod.type;
  } else {
    var paymentMethod = 0;
  }

  var user = JSON.parse(`{"id":1,"email":"` + username + `","locale":"` + lang + `","viewMode":"mosaic","multiFactorAuth":0,
  "perm":{"admin":false,"execute":false,"create":true,"rename":true,
                              "modify":true,"delete":true,"share":true,"download":true, "reseller":` + isReseller + `, "paymentMethod":` + paymentMethod + `, "features":` + features + `},
                      "commands":[],"lockPassword":false}`)


  localStorage.setItem('jwt', session)
  addNewSessionForAccount(accountHandle, session)
  store.commit('setJWT', username)
  store.commit('setUser', user)
                            
  if (action.error.type && action.error.type == Module.ErrorType.E_INVOICING.value) {
    throw action.error.type;
  }

  action = await ibb.getNodes();
  if (action.error.type) {
    console.log("Error getting nodes");
    store.commit('setJWT', '')
    store.commit('setUser', null)
    localStorage.setItem('jwt', null)
    removeSession(accountHandle)
    throw new Error("Error getting nodes: " + action.error.type)
  }

  var JavaEventListenerClass = Module.EventListener.extend("EventListener", {
      onEvent: function(event)
      {
          if (event.type == Module.EventType.LOGOUT.value)
          {
              location.reload();
              return;
          }

          clearTimeout(window.gTimeout);
          window.gTimeout = setTimeout(() => {
            store.commit('setReload', true)
          }, 1000)
      }
  });

  ibb.addEventListener(new JavaEventListenerClass());

  return;
}

export async function recoverAccount (id, username, recoveryKey, pin, code) {

  var action;
  if (typeof code === 'undefined')
  {
    action = await ibb.recoverAccount(id, username, recoveryKey, parseInt(pin));
    if (action.error.type) {
      console.log("Error in recoverAccount: " + action.error.type);
      return action.error.type;
    }
  }
  else
  {
    action = await ibb.recoverAccount2fa(id, username, recoveryKey, parseInt(pin), parseInt(code));
    if (action.error.type) {
      console.log("Error in recoverAccount2fa: " + action.error.type);
      return action.error.type;
    }
  }

  var multiFactorAuth = action.intValue;
  var session = action.session;
  action = await ibb.getNodes();
  if (action.error.type) {
    throw new Error("Error getting nodes: " + action.error.type)
  }

  var lang = localStorage.getItem('lang');
  if (lang != "es" && lang != "en")
  {
      lang = "";
  }

  var action2 = await ibb.getAccountInfo();
  if (action2.error.type) {
    throw new Error("Error getting account info: " + action2.error.type)
  }
  var isReseller = action2.accountInfo.isreseller;
  var accountHandle = action2.accountInfo.handle;
  var features = action2.accountInfo.features;

  var action3 = await ibb.getInvoicingInfo();
  if (action3.error.type) {
    return null
  }
  if (action3.invoicingInfo.paymentMethod !== undefined) {
    var paymentMethod = action3.invoicingInfo.paymentMethod.type;
  } else {
    var paymentMethod = 0;
  }

  var user = JSON.parse(`{"id":1,"email":"` + username + `","locale":"` + lang + `","viewMode":"mosaic","multiFactorAuth":` + multiFactorAuth +
                      `,"perm":{"admin":false,"execute":false,"create":true,"rename":true,
                              "modify":true,"delete":true,"share":true,"download":true, "reseller":` + isReseller + `, "paymentMethod":` + paymentMethod + `, "features":` + features + `},
                      "commands":[],"lockPassword":false}`)

  localStorage.setItem('jwt', session)
  addNewSessionForAccount(accountHandle, session)
  store.commit('setJWT', username)
  store.commit('setUser', user)

  var JavaEventListenerClass = Module.EventListener.extend("EventListener", {
      onEvent: function(event)
      {
          if (event.type == Module.EventType.LOGOUT.value)
          {
              location.reload();
              return;
          }

          clearTimeout(window.gTimeout);
          window.gTimeout = setTimeout(() => {
            store.commit('setReload', true)
          }, 1000)
      }
  });

  ibb.addEventListener(new JavaEventListenerClass());

  return 0;
}

export async function logout () {
  var action = await ibb.getAccountInfo();
  if (action.error.type) {
    throw new Error("Error getting account info: " + action.error.type)
  }
  var handle = action.accountInfo.handle;
  removeSession(handle)
  await ibb.logout();
  store.commit('setJWT', '')
  store.commit('setUser', null)
  localStorage.setItem('jwt', null)
  localStorage.setItem('lang', null)
  router.push({path: '/login'})
}

export async function logoutJoin () {
  var action = await ibb.getAccountInfo();
  if (action.error.type) {
    throw new Error("Error getting account info: " + action.error.type)
  }
  var handle = action.accountInfo.handle;
  removeSession(handle)
  await ibb.logout();
  store.commit('setJWT', '')
  store.commit('setUser', null)
  localStorage.setItem('jwt', null)
  localStorage.setItem('lang', null)
}
