<template>
  <div :style="payMethodStyle">
    <div v-if="isReady" id="plans">
      <vue-headful title="Planes de precios de Iberbox" description="Contrata nuestros servicios y disfruta del almacenamiento cifrado más seguro." keywords="iberbox, planes de precio, copias de seguridad, cifrado punto a punto, nube segura" image="https://iberbox.com/img/logo.svg" />
      <div class="contenedor-izquierda">
        <form v-if="!invoicingAddressSet">
          <div class="card-title">
            <h1>{{ $t('settings.billingSettings') }}</h1>
          </div>

          <div class="card-content">
            <div style="display: flex; justify-content: space-between;">
              <input maxlength="58" style="width: 57%;" :class="companyNameClass" type="text" :placeholder="$t('settings.companyName')" v-model.trim="companyName" name="companyName">
              <input maxlength="32" style="width: 40%;" :class="companyNIFClass" type="text" :placeholder="$t('settings.companyNIF')" v-model.trim="companyNIF" name="companyNIF">
            </div>
            <input maxlength="58" :class="companyAddressClass" type="text" :placeholder="$t('settings.companyAddress')" v-model.trim="companyAddress" name="companyAddress">
            <input maxlength="58" class="input input--block" type="text" :placeholder="$t('settings.companyAddress2')" v-model.trim="companyAddress2" name="companyAddress2">           
          </div>
        </form>
        <h1>{{ $t('pricing.setUpPaymentMethod') }}</h1>
        <div class="opciones-pago">
          <div class="opcion" @mouseenter="mouseEnterCredit" @mouseleave="mouseLeaveCredit" @click="clickCredit">
            <div :class="[(isCredit || pMethod === 'credit') ? 'card-active' : 'card']">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z"/><path d="M11 2l7.298 2.28a1 1 0 0 1 .702.955V7h2a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1l-3.22.001c-.387.51-.857.96-1.4 1.33L11 22l-5.38-3.668A6 6 0 0 1 3 13.374V5.235a1 1 0 0 1 .702-.954L11 2zm0 2.094L5 5.97v7.404a4 4 0 0 0 1.558 3.169l.189.136L11 19.58 14.782 17H10a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h7V5.97l-6-1.876zM11 12v3h9v-3h-9zm0-2h9V9h-9v1z"/></svg>
            </div>
            <p :class="[(isCredit || pMethod === 'credit') ? 'text-active' : 'text']">{{ $t('settings.creditCard') }}</p>
          </div>
          <!--<div class="opcion" @mouseenter="mouseEnterBank" @mouseleave="mouseLeaveBank" @click="clickBank">
            <div :class="[(isBank || pMethod === 'bank') ? 'card-active' : 'card']">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z"/><path d="M2 20h20v2H2v-2zm2-8h2v7H4v-7zm5 0h2v7H9v-7zm4 0h2v7h-2v-7zm5 0h2v7h-2v-7zM2 7l10-5 10 5v4H2V7zm2 1.236V9h16v-.764l-8-4-8 4zM12 8a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/></svg>
            </div>
            <p :class="[(isBank || pMethod === 'bank') ? 'text-active' : 'text']">{{ $t('settings.domiciliation') }}</p>
          </div>
          <div class="opcion" @mouseenter="mouseEnterTransfer" @mouseleave="mouseLeaveTransfer" @click="clickTransfer">
            <div :class="[(isTransfer || pMethod === 'transfer') ? 'card-active' : 'card']">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z"/><path d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm1 2v14h16V5H4zm6.05 6H15v2h-4.95a2.5 2.5 0 0 0 4.064 1.41l1.7 1.133A4.5 4.5 0 0 1 8.028 13H7v-2h1.027a4.5 4.5 0 0 1 7.788-2.543L14.114 9.59A2.5 2.5 0 0 0 10.05 11z"/></svg>
            </div>
            <p :class="[(isTransfer || pMethod === 'transfer') ? 'text-active' : 'text']">{{ $t('settings.transfer') }}</p>
          </div>-->
          <div class="opcion" @mouseenter="mouseEnterReseller" @mouseleave="mouseLeaveReseller" @click="clickReseller">
            <div :class="[(isReseller || pMethod === 'reseller') ? 'card-active' : 'card']">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z"/><path d="M14 14.252v2.09A6 6 0 0 0 6 22l-2-.001a8 8 0 0 1 10-7.748zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm5.793 8.914l3.535-3.535 1.415 1.414-4.95 4.95-3.536-3.536 1.415-1.414 2.12 2.121z"/></svg>
            </div>
            <p :class="[(isReseller || pMethod === 'reseller') ? 'text-active' : 'text']">{{ $t('settings.resellerCode') }}</p>
          </div>
        </div>
        <div v-if="pMethod === 'credit'">
          <div class="advertencia-seguridad">
            <div class="aviso">
              <h3>{{ $t('settings.securityWarning') }}</h3>
              <p>{{ $t('settings.redirectCreditCard') }}</p>
            </div>
          </div>
          <input type="checkbox" id="accept" name="accept" v-model="userAccepts" disabled="true">
          <label for="accept" v-html="$t('pricing.acceptPricingTermsCard')"></label>
          <form ref="redsysForm" :action="paymentUrl" method="POST">
            <input type="hidden" name="Ds_SignatureVersion" :value="signatureVersion"/>
            <input type="hidden" name="Ds_MerchantParameters" :value="paymentInfo"/>
            <input type="hidden" name="Ds_Signature" :value="signature"/>
            <div class="botones">
              <input v-on:click="goToCancel" class="button button--block" id="cancelButton" type="button" :value="$t('buttons.cancel')">
              <input v-on:click="updatePaymentMethod(pMethod)" class="button button--block" id="payButton" type="button" :value="$t('pricing.continue')">
            </div>
          </form>
        </div>
        <!--<div v-if="pMethod === 'bank'">
          <form class="domiciliacion" @submit.prevent="updatePaymentMethod(pMethod)">
            <h2>{{ $t('pricing.ibanNumber') }}</h2>
            <input maxlength="24" :class="ibanClass" type="text" :placeholder="$t('pricing.ibanNumber')" v-model.trim="iban" name="iban">
            <h2>{{ $t('pricing.accountHolder') }}</h2>
            <input maxlength="58" :class="accountHolderClass" type="text" :placeholder="$t('pricing.accountHolder')" v-model.trim="accountHolder" name="accountHolder">
            <input type="checkbox" id="accept" name="accept" v-model="userAccepts" disabled="true">
            <label for="accept" v-html="$t('pricing.acceptPricingTermsBank')"></label>
            <div class="botones">
              <input v-on:click="goToCancel" class="button button--block" id="cancelButton" type="button" :value="$t('buttons.cancel')">
              <input v-if="payMethod === 0" class="button button--block" id="payButton" type="submit" :value="$t('login.signup')">
              <input v-else class="button button--block" id="payButton" type="submit" :value="$t('buttons.accept')">
            </div>
          </form>
        </div>
        <div v-if="pMethod === 'transfer'" class="transfer">
          <p class="aviso">{{ $t('pricing.validTransfer1') }} {{ this.handleCuenta }} {{ $t('pricing.validTransfer2') }} {{ this.importe }} {{ $t('pricing.validTransfer3') }} {{ this.renewDate }}</p>
          <input type="checkbox" id="accept" name="accept" v-model="userAccepts" disabled="true">
          <label for="accept" v-html="$t('pricing.acceptPricingTermsTransfer')"></label>
          <form @submit.prevent="updatePaymentMethod(pMethod)">
            <div class="botones">
              <input v-on:click="goToCancel" class="button button--block" id="cancelButton" type="button" :value="$t('buttons.cancel')">
              <input v-if="payMethod === 0" class="button button--block" id="payButton" type="submit" :value="$t('login.signup')">
              <input v-else class="button button--block" id="payButton" type="submit" :value="$t('buttons.accept')">
            </div>
          </form>
        </div>-->
        <div v-if="pMethod === 'reseller'">
          <form class="reseller" @submit.prevent="updatePaymentMethod(pMethod)">
            <h2>{{ $t('settings.resellerCode') }}</h2>
            <input maxlength="30" :class="codeClass" type="text" :placeholder="$t('settings.resellerCode')" v-model.trim="code" name="code">
            <input type="checkbox" id="accept" name="accept" v-model="userAccepts" disabled="true">
            <label for="accept" v-html="$t('pricing.acceptPricingTermsReseller')"></label>
            <div class="botones">
              <input v-on:click="goToCancel" class="button button--block" id="cancelButton" type="button" :value="$t('buttons.cancel')">
              <input v-if="payMethod === 0" class="button button--block" id="payButton" type="submit" :value="$t('login.signup')">
              <input v-else class="button button--block" id="payButton" type="submit" :value="$t('buttons.accept')">
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as auth from '@/utils/auth'
import { files as api } from '@/api'
import { name, logoURL } from '@/utils/constants'
import vueHeadful from 'vue-headful';
import moment from 'moment'
import i18n from '@/i18n'
import { mapState } from 'vuex'

export default {
  name: 'plans',
  components: { 
    'vue-headful': vueHeadful
  },
  data: function () {
    return {
      error: '',
      paymentInfo: '',
      paymentUrl: '',
      signature: '',
      signatureVersion: '',
      companyName: '',
      companyAddress: '',
      companyAddress2: '',
      companyNIF: '',
      iban: '',
      code: '',
      accountHolder: '',
      plan: '',
      amount: '',
      discount: '',
      base: '',
      vat: '',
      total: '',
      renewDate: '',
      paymentMethodExpiration: '',
      hasCredit: false,
      isLogged: false,
      isReady: false,
      userAccepts: true,
      invoicingAddressSet: false,
      failedInvoicingAddress: false,
      daysToPaymentDate: 0,
      currentEmail: '',
      isCredit: false,
      isBank: false,
      isTransfer: false,
      isReseller: false,
      pMethod: '',
      userName: '',
      nombre: '', 
      email: '',
      importe: '',
      handleCuenta: '',
      period: '',
      payMethod: 0,
      picked: '',
      isButtonVisible: false,
      tarifa: '',
      tarifas: [],
      nombreTarifa: '',
    }
  },
  computed: {
    ...mapState([
      'user',
    ]),
    name: () => name,
    logoURL: () => logoURL,
    ibanClass () {
      const baseClass = 'input input--block'

      if (this.failedInvoicingAddress && this.iban === '') {
        return `${baseClass} input--red`
      }

      return baseClass
    },
    accountHolderClass () {
      const baseClass = 'input input--block'

      if (this.failedInvoicingAddress && this.accountHolder === '') {
        return `${baseClass} input--red`
      }

      return baseClass
    },
    codeClass () {
      const baseClass = 'input input--block'

      if (this.failedInvoicingAddress && this.code === '') {
        return `${baseClass} input--red`
      }

      return baseClass
    },
    companyNameClass () {
      const baseClass = 'input input--block'

      if (this.failedInvoicingAddress && this.companyName === '') {
        return `${baseClass} input--red`
      }

      return baseClass
    },
    companyAddressClass () {
      const baseClass = 'input input--block'

      if (this.failedInvoicingAddress && this.companyAddress === '') {
        return `${baseClass} input--red`
      }

      return baseClass
    },
    companyNIFClass () {
      const baseClass = 'input input--block'

      if (this.failedInvoicingAddress && this.companyNIF === '') {
        return `${baseClass} input--red`
      }

      return baseClass
    },
    buttonVisible () {
      if (this.isButtonVisible) {
        return {display: 'block'}
      } else {
        return {display: 'none'}
      }
    },
    payMethodStyle() {
      if (this.payMethod === 0 || this.isMobile) {
        return {width: '100%', marginLeft: '0', padding: '0 50px'}
      } else {
        return {width: 'calc(100% - 250px)', marginLeft: '250px'}
      } 
    },
    isMobile () {
      return window.innerWidth <= 768
    },
  },
  watch : {
    picked () {
      if (this.tarifa !== this.picked) {
        this.isButtonVisible = true;
      } else {
        this.isButtonVisible = false;
      }
    } 
  },
  mounted () {
    this.pMethod = 'credit';
  },
  async created () {
    try {
      var action = await api.getUserInfo()
      var userFirstname = action.userInfo.firstname;
      var userLastname = action.userInfo.lastname;
      this.nombre = userFirstname + " " + userLastname;
      this.userName = userFirstname.substr(0, 1) + userLastname.substr(0, 1);
      this.email = action.userInfo.email;
    } catch (e) {
      this.$showError(e)
    }

    try {
      action = await api.getAccountInfo();
      this.handleCuenta = action.accountInfo.handle;
    } catch (e) {
      this.$showError(e)
    }

    await this.fetchData();
    
    this.isReady = true;
    if (!this.invoicingAddressSet && this.payMethod === 0) {
      this.$store.commit('showHover', 'free-trial')
    }
  },
  methods: {
    async fetchData() {
      try {
        var invoicing = await api.getInvoicing()
        this.payMethod = invoicing.credit;
        if (invoicing) {
          var action = await ibb.currentEmail();
          this.currentEmail = action.email;
          this.companyName = invoicing.name;
          this.companyAddress = invoicing.address;
          this.companyAddress2 = invoicing.address2;
          this.companyNIF = invoicing.nif;
          if (this.companyName.length == 0
            || this.companyAddress.length == 0
            || this.companyNIF.length == 0) {
              this.invoicingAddressSet = false;
          }
          else {
            this.invoicingAddressSet = true;
          }

          var subscription = invoicing.subscription;
          if (subscription)
          {
              if (subscription.period === 1) {
                this.period = i18n.t('pricing.euroPerMonth');
              } else if (subscription.period === 2) {
                this.period = i18n.t('pricing.euroPerYear');
              }
              
              if (subscription.name === 'Iberbox 500 GB - Promoción Cliente Inicial') {
                this.tarifas.push(subscription)
              } else {
                this.nombreTarifa = subscription.name;
                try {
                  action = await api.getSubscriptionOptionsWithFeatures(this.user.perm.features)
                  this.tarifas = [];
                  for (let tarifa of action) {
                    if (tarifa.name === this.nombreTarifa) {
                      this.tarifas.push(tarifa);
                    }
                  }
                } catch (e) {
                  this.$showError(e)
                }
              }
              this.tarifa = subscription.period;
              this.picked = subscription.period;
              this.plan = subscription.name;
              this.amount = (subscription.amount / 100.0).toFixed(2) + " " + this.period;
              if (subscription.discount) {
                this.discount = "-" + (subscription.discount / 100.0).toFixed(2) + " " + this.period;
              }
              this.base = ((subscription.amount - subscription.discount) / 100.0).toFixed(2) + " " + this.period;
              this.vat = (0.21 * (subscription.amount - subscription.discount) / 100.0).toFixed(2) + " " + this.period;
              this.importe = (1.21 * (subscription.amount - subscription.discount) / 100.0).toFixed(2) + "€";
              this.total = (1.21 * (subscription.amount - subscription.discount) / 100.0).toFixed(2) + " " + this.period;
          }
          else
          {
              action = await ibb.getSubscriptionOptions();
              subscription = action.subscriptionInfoList[0];

              if (subscription.period === 1) {
                this.period = i18n.t('pricing.euroPerMonth');
              } else if (subscription.period === 2) {
                this.period = i18n.t('pricing.euroPerYear');
              }
              this.tarifa = subscription.name + '/' + subscription.period;
              this.picked = subscription.name + '/' + subscription.period;
              this.plan = subscription.name;
              this.amount = (subscription.amount / 100.0).toFixed(2) + " " + this.period;
              if (subscription.discount) {
                this.discount = "-" + (subscription.discount / 100.0).toFixed(2) + " " + this.period;
              }
              this.base = ((subscription.amount - subscription.discount) / 100.0).toFixed(2) + " " + this.period;
              this.vat = (0.21 * (subscription.amount - subscription.discount) / 100.0).toFixed(2) + " " + this.period;
              this.importe = (1.21 * (subscription.amount - subscription.discount) / 100.0).toFixed(2) + "€";
              this.total = (1.21 * (subscription.amount - subscription.discount) / 100.0).toFixed(2) + " " + this.period;
          }

          this.renewDate = moment(invoicing.rtime * 1000).format("DD/MM/YYYY");
          this.daysToPaymentDate = moment(invoicing.rtime * 1000).diff(moment(), 'days')
          if (this.daysToPaymentDate < 0)
          {
            this.daysToPaymentDate = 0;
          }
          
          this.isLogged = true;
        }
        else
        {
          action = await ibb.getSubscriptionOptions();
          subscription = action.subscriptionInfoList[0];

          if (subscription.period === 1) {
            this.period = i18n.t('pricing.euroPerMonth');
          } else if (subscription.period === 2) {
            this.period = i18n.t('pricing.euroPerYear');
          }
          this.tarifa = subscription.name + '/' + subscription.period;
          this.picked = subscription.name + '/' + subscription.period;
          this.plan = subscription.name;
          this.amount = (subscription.amount / 100.0).toFixed(2) + " " + this.period;
          if (subscription.discount) {
            this.discount = "-" + (subscription.discount / 100.0).toFixed(2) + " " + this.period;
          }
          this.base = ((subscription.amount - subscription.discount) / 100.0).toFixed(2) + " " + this.period;
          this.vat = (0.21 * (subscription.amount - subscription.discount) / 100.0).toFixed(2) + " " + this.period;
          this.total = (1.21 * (subscription.amount - subscription.discount) / 100.0).toFixed(2) + " " + this.period;
        }

      } catch (e) {
        console.log("ERROR: " + e)
        this.error = e
      }
    },
    mouseEnterCredit () {
      this.isCredit = true;
    },
    mouseLeaveCredit () {
      this.isCredit = false;
    },
    /*mouseEnterBank () {
      this.isBank = true;
    },
    mouseLeaveBank () {
      this.isBank = false;
    },
    mouseEnterTransfer () {
      this.isTransfer = true;
    },
    mouseLeaveTransfer () {
      this.isTransfer = false;
    },*/
    mouseEnterReseller () {
      this.isReseller = true;
    },
    mouseLeaveReseller () {
      this.isReseller = false;
    },
    clickCredit () {
      this.pMethod = 'credit';
    },
    /*clickBank () {
      this.pMethod = 'bank';
    },
    clickTransfer () {
      this.pMethod = 'transfer';
    },*/
    clickReseller () {
      this.pMethod = 'reseller';
    },
    goToLogin () {            
      this.$router.push({ path: '/login', query: { redirect: '/profile/invoicing/plans' } })
    },
    async goToCancel () {
      if (this.payMethod === 0)
      {
          await auth.logout();
      }
      else
      {
          this.$router.push({ path: '/profile/invoicing' })
      }
    },
    goToSignup () {            
      this.$router.push({ path: '/signup', query: { redirect: '/profile/invoicing/plans' } })
    },
    async updateInvoicingAddress () {
      if (this.companyName.length == 0) {
        this.$showError(this.$t('settings.enterCompanyName'));
        return false;
      }
      if (this.companyAddress.length == 0) {
        this.$showError(this.$t('settings.enterCompanyAddress'));
        return false;
      }
      if (this.companyNIF.length == 0) {
        this.$showError(this.$t('settings.enterCompanyID'));
        return false;
      }

      try {
        await ibb.setInvoicingAddress(this.companyName, this.companyAddress, this.companyAddress2, this.companyNIF);
        return true;
      } catch (e) {
        this.$showError(e)
        return false;
      }
    },
    async updatePaymentMethod (paymentMethod) {
      if (!this.invoicingAddressSet)
      {
          if (this.companyName.length == 0
                || this.companyAddress.length == 0
                || this.companyNIF.length == 0) {
            this.failedInvoicingAddress = true;
            this.$showError(this.$t('settings.configureInvoicingAddress'));
            return;
          }

          var success = await this.updateInvoicingAddress();
          if (!success)
          {
              return;
          }
      }

      if (paymentMethod === 'credit') {
        var action = await ibb.getPaymentInfo(1);
        this.paymentInfo = action.paymentInfo.info;
        this.signature = action.paymentInfo.signature;
        this.signatureVersion = action.paymentInfo.signatureVersion;
        this.paymentUrl = action.paymentInfo.url;

        await this.$nextTick();
        this.$refs["redsysForm"].submit();
        this.gtag_report_conversion()
        return;
      } /*else if (paymentMethod === 'bank') {
        if (this.iban === '' || this.accountHolder === '') {
          this.$showError(this.$t('settings.enterAccountDetails'))
          return
        } else {
          action = await ibb.addDirectDebitAsPaymentMethod(this.iban, this.accountHolder);
          if (action.error.type) {
            this.$showError(this.$t('settings.ibanNumberNotValid'))
            return
          } else {
            this.gtag_report_conversion()
            if (this.payMethod === 0 && !this.invoicingAddressSet) {
              this.$router.push({ path: '/received' }).catch(() => {})
              window.history.go('/received');
            }
            else {
              this.$router.push({ path: '/profile/invoicing' })
            }
          }
        }
      } else if (paymentMethod === 'transfer') {
        try {
          action = await ibb.addBankTransferAsPaymentMethod();
        } catch (e) {
          this.$showError(e)
          return
        }
        this.gtag_report_conversion()
        if (this.payMethod === 0 && !this.invoicingAddressSet) {
          this.$router.push({ path: '/received' }).catch(() => {})
          window.history.go('/received');
        }
        else {
          this.$router.push({ path: '/profile/invoicing' })
        }
      }*/ else if (paymentMethod === 'reseller') {
        if (this.code === '') {
          this.$showError(this.$t('settings.enterResellerCode'))
          return
        } else {
          action = await ibb.addResellerCodeAsPaymentMethod(this.code);
          if (action.error.type) {
            this.$showError(this.$t('settings.resellerCodeInvalid'))
            return
          } else {
            this.gtag_report_conversion()
            this.$router.push({ path: '/reg' }).catch(() => {})
            window.history.go('/reg');
          }
        }
      }
      try {
        await auth.getNodes();
      } catch (e) {
        this.$showError(e)
      }
    },
    async cambiarTarifa () {
      var id = '';
      this.isButtonVisible = false;

      for (let tarifa of this.tarifas) {
        if (tarifa.period === this.picked) {
          id = tarifa.id;
        }
      }

      try {
        await api.setSubscription(id);
        this.$showSuccess(this.$t('settings.changeOfFee'))
        this.fetchData();
      } catch (e) {
        this.$showError(e)
      }
    },
    gtag_report_conversion (url) {
      var callback = function () {
        if (typeof(url) != 'undefined') {
          window.location = url;
        }
      };
      gtag('event', 'conversion', {
          'send_to': 'AW-369408594/wmf8CN_MkfoCENL0krAB',
          'transaction_id': '',
          'event_callback': callback
      });
      return false;
    }
  }
}
</script>
