<template>
  <div class="card floating">
    <button class="button button--flat button--grey" id ="buttonCloseRename"
      @click="$store.commit('closeHovers')"
      :aria-label="$t('buttons.close')"
      :title="$t('buttons.close')">
      <x-icon size="1.5x" class="custom-class"></x-icon>
    </button>
    <div class="card-title">
      <h2>{{ $t('bmail.createContact') }}</h2>
    </div>

    <div class="card-content">
      <p>{{ $t('bmail.contactName') }}</p>
      <input class="input input--block" :style="inputStyleName" type="text" v-model.trim="name" v-focus>

      <p>{{ $t('bmail.urlInboxContact') }}</p>
      <input class="input input--block" :style="inputStyleUrl" @keyup.enter="submit" type="text" v-model.trim="url" :placeholder="$t('bmail.urlInbox')">
      <button
        class="button button--flat" id ="buttonNewContact"
        :aria-label="$t('bmail.createContact')"
        :title="$t('bmail.createContact')"
        @click="submit"
      >{{ $t('bmail.createContact') }}</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { files as api } from '@/api'
import { XIcon } from 'vue-feather-icons'

export default {
  name: 'new-contact',
  data: function() {
    return {
      name: '',
      url: ''
    };
  },
  components: {
    XIcon
  },
  computed: {
    ...mapGetters([ 'isFiles', 'isListing' ]),
    ...mapState(['req']),
    inputStyleName() {
      if (this.name !== '') {
        return {border: '2px solid var(--blue)'}
      } else {
        return {border: '2px solid rgb(0 0 0 / 20%)'}
      }
    },
    inputStyleUrl() {
      if (this.url !== '') {
        return {border: '2px solid var(--blue)', color: 'var(--blue)'}
      } else {
        return {border: '2px solid rgb(0 0 0 / 20%)'}
      }
    }
  },
  methods: {
    submit: async function(event) {
      if (this.name === '') {
        this.$showWarning(this.$t('bmail.nameContactRequired'));
        return
      }
      if (this.url === '') {
        this.$showWarning(this.$t('bmail.urlContactRequired'));
        return
      }
      event.preventDefault()

      var action = await ibbLink.getInboxInfo(this.url)
      if (action.error.type) {
        this.$showError(this.$t('records.wrongInbox'));
        return
      }
      var accountHandle = action.accountHandle
      var nameInbox = action.node.name
      document.getElementById("buttonNewContact").disabled = true;
      document.getElementById("buttonCloseRename").disabled = true;

      action = await ibb.addContact(accountHandle, this.name)
      var contactHandle = action.node.handle

      action = await ibb.saveInbox(contactHandle, this.url, nameInbox)
      if (action.error.type) {
        throw new Error("Error saving inbox: " +  action.error.type)
      }

      this.$store.commit('closeHovers')
    }
  }
};
</script>

