<template>
  <button :style="showContact" :title="name" :aria-label="name" @click="selectContact(name)" type="button">
    <span>{{ name }}</span>
  </button>
</template>

<script>
export default {
  name: 'contact',
  data: function () {
    return {
      isShow: true
    }
  },
  props: ['name', 'contact'],
  computed: {
    showContact() {
      if (!this.isShow) {
        return {display: 'none'}
      }
      return {display: 'block'}
    }
  },
  watch: {
    contact (val) {
      if (!this.name.toLowerCase().includes(val.toLowerCase())) {
        this.isShow = false
      } else {
        this.isShow = true
      }
    }
  },
  methods: {
    selectContact() {
      this.$emit('select-contact', this.name);
    }
  }
}
</script>