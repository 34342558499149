<template>
  <div :style="showRecord" class="outbox">
    <div class="cabecera">
      <div class="container-title">
        <button :class="[checked ? 'container-name-checked' : 'container-name']" @click="seleccionarRecord" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
          <p v-if="!checked" class="record-name">{{ outbox.name.substr(0, 1) }}</p>
          <check-icon v-else size="1.5x" class="custom-class"></check-icon>
        </button>
        <div class="title">
          <h2 @click="showRecords">{{ outbox.name }}</h2>
          <p @click="showRecords">{{ $t('bmail.showHistory') }}</p>
        </div>
      </div>
      <h3>{{ $t('records.receiver') }} {{ receiver }}</h3>
      <div class="botones">
        <button v-if="this.permissions === 0 && !isMaxStorage" @click="write" :aria-label="$t('bmail.sendMessage')" :title="$t('bmail.sendMessage')" class="action" id="send-button">
          <mail-icon size="1.5x" class="custom-class"></mail-icon>
          <span>{{ $t('bmail.sendMessage') }}</span>
        </button>
        <button v-if="this.permissions === 0 && !isMaxStorage" @click="editOutbox" :aria-label="$t('bmail.editMailbox')" :title="$t('bmail.editMailbox')" class="action" id="share-button">
          <edit-icon size="1.5x" class="custom-class"></edit-icon>
        </button>
        <button v-if="!isExternalContact && this.permissions === 0" @click="deleteOutbox" :aria-label="$t('buttons.delete')" :title="$t('buttons.delete')" class="action" id="delete-button">
          <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapMutations } from 'vuex'
import { CheckIcon, EditIcon, Trash2Icon, MailIcon } from 'vue-feather-icons'

export default {
  name: 'outbox',
  data: function() {
    return {
      checked: false,
      isShow: true,
      isButton: false,
      receiver: '',
      isExternalContact: false,
    }
  },
  components: {
    CheckIcon,
    EditIcon,
    Trash2Icon,
    MailIcon
  },
  props: ['outbox', 'buscador', 'checkedAll'],
  computed: {
    ...mapState(['sortOrder', 'selected', 'isPreview', 'outboxSelected', 'permissions', 'isMaxStorage']),
    showRecord() {
      if (!this.isShow) {
        return {display: 'none'}
      }
      return {display: 'block'}
    },
    isMobile () {
      return window.innerWidth <= 768
    },
  },
  watch: {
    buscador (val) {
      if (!this.outbox.name.toLowerCase().includes(val.toLowerCase())) {
        this.isShow = false
      } else {
        this.isShow = true
      }
    },
    checkedAll (val) {
      if (val) {
        this.checked = true
        if (!this.outboxSelected.includes(this.outbox.handle)) {
          this.addOutboxSelected(this.outbox.handle)
        }
      } else {
        this.checked = false
        this.removeOutboxSelected(this.outbox.handle)
      }
    },
  },
  mounted: async function () {
    var action = await ibb.getNodeById(this.outbox.parentHandle);
    this.receiver = action.node.name

    action = await ibb.getExternalInbox();
    if (action.link === this.outbox.link) {
      this.isExternalContact = true
    }
  },
  methods: {
    ...mapMutations(['setIsPreview', 'addOutboxSelected', 'removeOutboxSelected', 'setOutbox']),
    seleccionarRecord() {
      this.checked = !this.checked
      if (this.checked) {
        this.addOutboxSelected(this.outbox.handle)
      } else {
        this.removeOutboxSelected(this.outbox.handle)
      }
    },
    mouseEnter() {
      this.isButton = true
    },
    mouseLeave() {
      this.isButton = false
    },
    write() {
      this.setOutbox(this.outbox)
      this.$store.commit('showHover', 'write')
    },
    editOutbox() {
      this.setOutbox(this.outbox)
      this.$store.commit('showHover', 'edit-outbox')
    },
    deleteOutbox() {
      this.setOutbox(this.outbox)
      this.$store.commit('showHover', 'delete')
    },
    showRecords() {
      if (this.isMobile) {
        this.$router.push({path: this.$route.path + "#" + this.outbox.handle})
      } else {
        this.$emit('show-records', this.outbox.handle);
      }
    }
  }
}
</script>