<template>
  <div class="list-items">
    <div v-if="isMail">
      <item v-for="(item) in archivosRecord"
        :key="item.name"
        v-bind:item="item"
        @delete-item="deleteItem">
      </item>
    </div>
    <div v-else>
      <item v-for="(item) in archivosTransfer"
        :key="item.name"
        v-bind:item="item"
        @delete-item="deleteItem">
      </item>
    </div>
  </div>

</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Item from './ListingItem'
import css from '@/utils/css'
import { files as api } from '@/api'
import buttons from '@/utils/buttons'

export default {
  name: 'listing',
  components: { Item },
  data: function () {
    return {
      show: 50
    }
  },
  props: ['archivosRecord', 'archivosTransfer', 'isMail'],
  computed: {
    ...mapState(['req', 'selected', 'user']),
  },
  mounted: function () {
    // Check the columns size for the first time.
    this.resizeEvent()

    // Add the needed event listeners to the window and document.
    window.addEventListener('keydown', this.keyEvent)
    window.addEventListener('resize', this.resizeEvent)
    window.addEventListener('scroll', this.scrollEvent)
    document.addEventListener('dragover', this.preventDefault)
  },
  beforeDestroy () {
    // Remove event listeners before destroying this page.
    window.removeEventListener('keydown', this.keyEvent)
    window.removeEventListener('resize', this.resizeEvent)
    window.removeEventListener('scroll', this.scrollEvent)
    document.removeEventListener('dragover', this.preventDefault)
  },
  methods: {
    ...mapMutations([ 'updateUser' ]),
    base64: function (name) {
      return window.btoa(unescape(encodeURIComponent(name)))
    },
    preventDefault (event) {
      // Wrapper around prevent default.
      event.preventDefault()
    },
    resizeEvent () {
      // Update the columns size based on the window width.
      let columns = Math.floor(document.querySelector('main').offsetWidth / 300)
      let items = css(['#listing.mosaic .item', '.mosaic#listing .item'])
      if (columns === 0) columns = 1
      items.style.width = `calc(${100 / columns}% - 1em)`
    },
    scrollEvent () {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        this.show += 50
      }
    },
    deleteItem (item) {
      this.$emit('delete-item', item);
    }
  }
}
</script>
