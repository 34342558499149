<template>
  <div class="item"
  role="button"
  tabindex="0"
  draggable="false"
  :aria-label="item.name"
    style="cursor: auto;">
    <div style="display: flex; align-items: center; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
      <img class="material-icons" :src="icon">
      <p class="transfername">{{ item.name }}</p>
    </div>
    <button @click="deleteItem" id="deleteItem"
      class="button button--flat"
      :disabled="isSent"
      :aria-label="$t('bmail.deleteFile')"
      :title="$t('bmail.deleteFile')">
      <x-icon size="1.5x" class="custom-class"></x-icon>
    </button> 
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex'
import { XIcon } from 'vue-feather-icons'

export default {
  name: 'item',
  data: function () {
    return {
    }
  },
  components: {
    XIcon
  },
  props: ['item'],
  computed: {
    ...mapState(['selected', 'req', 'isSent']),
    ...mapGetters(['selectedCount']),
    icon () {
      var extension = this.item.type.split('/');
      if (extension[1] == "mp3" || extension[1] == "wav" || extension[1] == "flac") {
        return '/img/icons/WEBCLIENT_MimeTypes/ibbMusic.png'
      }
      if (extension[1] == "avi" || extension[1] == "mp4" || extension[1] == "mkv" || extension[1] == "mov") {
        return '/img/icons/WEBCLIENT_MimeTypes/ibbVideo.png'
      } 
      if (extension[1] == "txt") {
        return '/img/icons/WEBCLIENT_MimeTypes/ibbTxt.png'
      }
      if (extension[1] == "pdf") {
        return '/img/icons/WEBCLIENT_MimeTypes/ibbPDF.png'
      }
      if (extension[1] == "jpg" || extension[1] == "jpeg" || extension[1] == "png" || extension[1] == "bmp" || extension[1] == "heic" || extension[1] == "heif") {
        return '/img/icons/WEBCLIENT_MimeTypes/ibbImage.png'
      }
      if (extension[1] == "zip" || extension[1] == "tar" || extension[1] == "rar") {
        return '/img/icons/WEBCLIENT_MimeTypes/ibbZip.png'
      } 
      if (extension[1] == "doc") {
        return '/img/icons/WEBCLIENT_MimeTypes/ibbDoc.png'
      } 
      if (extension[1] == "xls") {
        return '/img/icons/WEBCLIENT_MimeTypes/ibbXLS.png'
      } 
      if (extension[1] == "js" || extension[1] == "css" || extension[1] == "html" || extension[1] == "vue" || extension[1] == "java") {
        return '/img/icons/WEBCLIENT_MimeTypes/ibbCode.png'
      }
      return '/img/icons/WEBCLIENT_MimeTypes/ibbFile.png'
    },
  },
  methods: {
    ...mapMutations(['addSelected', 'removeSelected', 'resetSelected']),
    deleteItem: async function () {
      Module.deleteFile(this.item.name);
      this.$emit('delete-item', this.item);
    },
  }
}
</script>
