<template>
  <div class="card floating" id="upgrade-plan">
    <h2>{{ $t('bmail.upgradePlan') }}</h2>
    <p v-html="$t('bmail.upgradePlanText')"></p>
    <div class="buttons">
      <button :aria-label="$t('buttons.cancel')" :title="$t('buttons.cancel')" class="action" id="cancel-button" @click="$store.commit('closeHovers')"> 
        <span>{{ $t('buttons.cancel') }}</span>
      </button>
      <button :aria-label="$t('bmail.sendAnyway')" :title="$t('bmail.sendAnyway')" class="action" @click="sendRecord">
        <span>{{ $t('bmail.sendAnyway') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'upgrade-plan',
  methods: {
    ...mapMutations(['closeHovers']),
    async sendRecord() {
      var action = await ibbLink.finishRecord();
      if (action.error.type) {
        throw new Error("Error sending record: " +  action.error.type)
      }
      this.$store.commit('closeHovers')
    }
  }
}
</script>